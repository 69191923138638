import React, { useRef, useEffect, useContext, useState } from "react";
import { GLContext } from "./GLContextProvider";

type GLRendererProps = {
    options: any;
    style?: React.CSSProperties;
    setCanvasRef?: (canvas: HTMLCanvasElement) => void;
};

const GLRenderer: React.FC<GLRendererProps> = ({ options, style = {}, setCanvasRef }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const { renderScene } = useContext(GLContext);

    const dpr = 1;//window.devicePixelRatio || 1; // Device pixel ratio
    const [rendererSize, setRendererSize] = useState<{ width: number; height: number }>({
        width: options.width === "100%" ? 0 : (options.width as number),
        height: options.height === "100%" ? 0 : (options.height as number),
    });

    const updateSize = () => {
        if (!canvasRef.current) return;

        const parent = canvasRef.current.parentElement;
        if (!parent) return;

        const computedStyle = getComputedStyle(parent);

        // Include padding and margins
        const paddingLeft = parseFloat(computedStyle.paddingLeft || "0");
        const paddingRight = parseFloat(computedStyle.paddingRight || "0");
        const paddingTop = parseFloat(computedStyle.paddingTop || "0");
        const paddingBottom = parseFloat(computedStyle.paddingBottom || "0");

        const marginLeft = parseFloat(computedStyle.marginLeft || "0");
        const marginRight = parseFloat(computedStyle.marginRight || "0");
        const marginTop = parseFloat(computedStyle.marginTop || "0");
        const marginBottom = parseFloat(computedStyle.marginBottom || "0");

        // Calculate parent dimensions including padding
        const rect = parent.getBoundingClientRect();

        const parentWidth =
            options.width === "100%"
                ? rect.width - paddingLeft - paddingRight - marginLeft - marginRight
                : (options.width as number);

        const parentHeight =
            options.height === "100%"
                ? rect.height - paddingTop - paddingBottom - marginTop - marginBottom
                : (options.height as number);

        setRendererSize({
            width: parentWidth,
            height: parentHeight,
        });

        // Set actual canvas size considering scaling
        const scaling = options.scaling || dpr; // Use options.scaling if provided
        canvasRef.current.width = parentWidth * scaling;
        canvasRef.current.height = parentHeight * scaling;

        if (setCanvasRef) {
            setCanvasRef(canvasRef.current);
        }
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        if (canvas) {
            updateSize();
            if (setCanvasRef) {
                setCanvasRef(canvas);
            }
        }

        const render = () => {
            const canvas = canvasRef.current;
            if (!canvas) return;

            // Set canvas style for rendering
            canvas.style.width = `${rendererSize.width}px`;
            canvas.style.height = `${rendererSize.height}px`;

            if (renderScene) {
                renderScene({
                    ...options,
                    secondaryCanvasRef: canvasRef,
                    width: rendererSize.width,
                    height: rendererSize.height,
                });
            }

            requestAnimationFrame(render);
        };

        const frameId = requestAnimationFrame(render);
        window.addEventListener("resize", updateSize);

        return () => {
            window.removeEventListener("resize", updateSize);
            cancelAnimationFrame(frameId);
        };
    }, [renderScene, rendererSize.width, rendererSize.height, options.buffersUUID]);

    return (
        <canvas
            style={{ ...style }}
            ref={canvasRef}
            width={rendererSize.width * dpr}
            height={rendererSize.height * dpr}
        />
    );
};

export default GLRenderer;
