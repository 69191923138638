// Tap, Tap Two, Tap Hold (Begin/End), Move, Scale, Rotate

import {Vec2, Vec2Interface} from "./Vec2";
import {angle_difference, arrayMedian} from "./Utils";
import {audioPlayer} from "./AudioPlayer";

export const CONTEXT_MENU_OPEN = false;


export interface QuickInputOptions {
    context?: (info: { context: Vec2Interface | Vec2, target: Event }) => void;
    wheel?: (info: { wheel: Vec2Interface | Vec2 }) => void;
    move?: (info: { move: Vec2Interface | Vec2 }) => void;
    down?: (info: { down: Vec2Interface | Vec2, target: Event  }) => void;
    up?: (info: { up: Vec2Interface | Vec2 }) => void;
    scale?: (info: { scale: number }) => void;
    rotate?: (info: { rotate: number }) => void;
    drag?: (info: { drag: Vec2Interface | Vec2 }) => void;
    tap?: (info: { tap: Vec2Interface | Vec2, target: EventTarget }) => void;
    key?: (info: { key: string, type:string }) => void;
    joystickRotate?: (info: { rotate: Vec2Interface | Vec2, joystickIndex: number }) => void;
    buttonPress?: (info: { buttonIndex: number }) => void;
}

export interface QuickInputEvent {
    wheel?: Vec2Interface | Vec2;
    move?: Vec2Interface | Vec2;
    down?: Vec2Interface | Vec2;
    up?: Vec2Interface | Vec2;
    position?: Vec2Interface | Vec2;
    scale?: number;
    rotate?: number;
    context?: Vec2Interface | Vec2;
    drag?: Vec2Interface | Vec2;
    tap?: Vec2Interface | Vec2;
    key?: string;
    which?: number;
    type?: string;
    joystickRotate?: any;
    buttonPress?: any;
    target?: EventTarget;

}

export class QuickInput {
    private dragContextTimeout: NodeJS.Timeout | number | null = null;
    private element: HTMLElement;
    private options: QuickInputOptions;

    singleTapTimeoutHandler: any;
    previousTouchPositions: any;

    changedTime: number = 0;
    changedDistance: any;
    dragDistanceThreshold: number = 20;

    willEmitTap: boolean = true;
    didEmitGesture: boolean = false;

    mousePos: Vec2 = new Vec2();

    inputType: any;// "mouse" | "pointer" | false;

    pressedKeys: any[] = [];
    private gamepadButtonStates: boolean[] = [];


    constructor(el: any, options?: any) {
        this.element = el;
        this.options = options || {};


        this.element.ontouchstart = this.element.onmousedown = (event: any) => {
            this.resetGestureState();

            this.mousePos.copy({x: event.clientX, y: event.clientY});

            this.down(event);
            try {
                audioPlayer.resumeAudioContext();
            } catch (e) {
                console.error(e);
            }
        }

        this.element.ontouchmove = this.element.onmousemove = (event: any) => {
            this.updateMousePosFromEvent(event);
            //this.mousePos.copy({x: event.clientX, y: event.clientY});
            this.move(event);
        }

        //@ts-ignore
        this.element.ontouchend = this.element.ontouchup = this.element.onmouseup = (event: any) => {
            this.mousePos.copy({x: event.clientX, y: event.clientY});
            this.up(event);
        }

        document.body.onkeydown = document.body.onkeypress = (event: any) => {
            // @ts-ignore
            if (this.pressedKeys.indexOf(event.code) === -1) {
                // @ts-ignore
                this.pressedKeys.push(event.code);
            }
            this.key(event);
        };
        document.body.onkeyup = (event: any) => {
            // @ts-ignore
            if (this.pressedKeys.indexOf(event.code) !== -1) {
                this.pressedKeys = this.pressedKeys.filter(e => e !== event.code);
            }
            this.key(event);
            // this.key(event);
        };

        document.body.oncontextmenu = (event: any) => {
            //if (this.options && this.options.context) {
            // this.options.context({context: {x: event.clientX, y: event.clientY}});
            event.preventDefault();
            event.cancelBubble = true;
            // }
            return false;
        }

        window.addEventListener("gamepadconnected", this.handleGamepadConnected);
        window.addEventListener("gamepaddisconnected", this.handleGamepadDisconnected);


        this.element.onwheel = (event: any) => {
            //this.inputType = "mouse";
            if (this.options && this.options.wheel) {
                console.log(event.deltaX, event.wheelDeltaX, event.deltaY, event.wheelDeltaY);
                if (Math.abs(event.wheelDelta) < 100) {
                    this.options.wheel({
                        wheel: {
                            x: arrayMedian([-1, event.deltaX / 100, 1]),
                            y: arrayMedian([-1, event.deltaY / 100, 1])
                        }
                    });

                } else {
                    if (this.options.scale) {
                        this.options.scale({
                            scale: 1 - arrayMedian([-1, (event.deltaY / 100), 1])
                        });
                    }
                }

                if (event || event.ctrlKey) {
                    event.preventDefault();
                    event.cancelBubble = true;
                }
            } else {
                //event.preventDefault();
            }
        }

    }

    private handleGamepadConnected = (event: GamepadEvent): void => {
        console.log("Gamepad connected:", event.gamepad);
        this.gamepadButtonStates = Array(event.gamepad.buttons.length).fill(false);
        requestAnimationFrame(this.updateGamepadState);
    };

    private handleGamepadDisconnected = (event: GamepadEvent): void => {
        console.log("Gamepad disconnected:", event.gamepad);
        this.gamepadButtonStates = [];
    };

    private updateGamepadState = (): void => {
        const gamepads = navigator.getGamepads();
        for (const gamepad of gamepads) {
            if (gamepad) {
                this.handleGamepadAxis(gamepad);
                this.handleGamepadButtons(gamepad);
            }
        }
        requestAnimationFrame(this.updateGamepadState);
    };

    private handleGamepadAxis = (gamepad: Gamepad): void => {

        if (gamepad && gamepad.axes) {
            let x0 = gamepad.axes[2];
            let y0 = gamepad.axes[5];
            const rotateRVec = new Vec2().set(x0, y0);
            if (rotateRVec.mag() > 0.025) {
                this.options.joystickRotate?.({rotate: rotateRVec, joystickIndex: 1});
            }


            let x1 = gamepad.axes[0];
            let y1 = gamepad.axes[1];
            const rotateLVec = new Vec2().set(x1, y1);
            if (rotateLVec.mag() > 0.05) {
                this.options.joystickRotate?.({rotate: rotateLVec, joystickIndex: 0});
            }
        }

    };

    private handleGamepadButtons = (gamepad: Gamepad): void => {
        gamepad.buttons.forEach((button, index) => {
            const wasPressed = this.gamepadButtonStates[index];
            const isPressed = button.pressed;

            if (isPressed && !wasPressed) {
                this.options.buttonPress?.({buttonIndex: index});
            }

            this.gamepadButtonStates[index] = isPressed;
        });
    };


    key(event: any) {
        if (!this.options.key) {
            return
        }
        this.options.key({key: event.code, type:event.type});
    }

    async down(event: any) {

        if (!this.dragContextTimeout) {
            this.dragContextTimeout = window.setTimeout(async () => {
                console.log('CREATING DRAG TIMEOUT', this.dragContextTimeout);
                if (this.willEmitTap && !CONTEXT_MENU_OPEN && !this.didEmitGesture) {
                    if (this.options.context) {
                        await this.options.context({context: this.mousePos, target: event.target});
                    }
                    this.willEmitTap = false;
                    this.didEmitGesture = true;
                }
            }, 240);
            // console.log("SETUP TIMEOUT", this.dragContextTimeout);
        } else {
            //console.log("DRAGON TIMEOUT ALERADY EXISTS");
        }

        if (!CONTEXT_MENU_OPEN) {
            this.willEmitTap = true;
            this.updateMousePosFromEvent(event);
        }

        if (this.options.down){
            this.updateMousePosFromEvent(event);// @ts-ignore
            this.options.down({target: event.target, down: this.mousePos.clone()});
        }

    }

    private resetGestureState(): void {
        this.willEmitTap = true;
        this.didEmitGesture = false;
        this.previousTouchPositions = null;
        this.changedDistance = 0;
    }


    move(event: any) {



        if (this.options && this.options.move) {
           // console.log("MOVE");
            this.updateMousePosFromEvent(event);// @ts-ignore
            this.options.move({target: event.target, move: this.mousePos.clone()});
        }

        if (event && Date.now() - this.changedTime > 10) {


            const currentTouchPositions = [];

            if (event && event.touches) {
                this.inputType = "pointer";

                for (let i = 0; i < event.touches.length; i++) {
                    const d = {
                        x: event.touches[i].clientX,
                        y: event.touches[i].clientY,
                        force: event.touches[i].force || 0
                    };
                    currentTouchPositions.push(d);
                    this.mousePos.copy(d);
                }
                this.willEmitTap = false;
            } else {
                this.inputType = "mouse";

                if (event.buttons === 2 || event.which === 2) {
                    const d = {
                        x: event.clientX,
                        y: event.clientY
                    };
                    currentTouchPositions.push(d);
                    this.mousePos.copy(d);
                }

            }

            if (this.previousTouchPositions) {
                if (this.previousTouchPositions.length >= currentTouchPositions.length) {

                    // console.log("Multitouch",currentTouchPositions.length, "prev angle/dist", previousAngle, previousDist);


                    if (this.previousTouchPositions.length >= 2 && currentTouchPositions.length >= 2) {

                        let previousDelta = (new Vec2()).copy(this.previousTouchPositions[0]).sub((new Vec2()).copy(this.previousTouchPositions[1]));
                        let previousDist = previousDelta.mag()
                        let previousAngle = previousDelta.toDeg();

                        let currentDelta = (new Vec2()).copy(currentTouchPositions[0]).sub((new Vec2()).copy(currentTouchPositions[1]));
                        let currentDist = currentDelta.mag()
                        let currentAngle = currentDelta.toDeg();


                        this.willEmitTap = false;
                        this.didEmitGesture = true;
                        let changeDist = currentDist / previousDist;
                        let changeAngle = angle_difference(previousAngle, currentAngle);

                        console.log(event.deltaX, event.deltaY, event.deltaZ, event.wheelDelta, event.wheelDeltaX, event.wheelDeltaY);

                        if (Math.abs(changeDist - 1) > 0.0001) {
                            if (this.options && this.options.scale) {
                                this.options.scale({scale: changeDist});
                            }
                        }

                        if (Math.abs(changeAngle) > 5) {
                            if (this.options && this.options.rotate) {
                                this.options.rotate({rotate: changeAngle});

                            }
                        }
                    } else if (this.previousTouchPositions.length === 1 && currentTouchPositions.length === 1) {

                        let previousPosition = (new Vec2()).copy(this.previousTouchPositions[0]);
                        let currentPosition = (new Vec2()).copy(currentTouchPositions[0]);
                        let offset = previousPosition.sub(currentPosition);

                        this.changedDistance += offset.mag();
                        if (this.changedDistance > 8 && offset.mag() > 1) {
                            this.willEmitTap = false;
                            this.didEmitGesture = true;
                            if (this.options && this.options.drag) {
                                this.options.drag({drag: offset});
                            }
                        }

                    }

                }
            }

            this.previousTouchPositions = currentTouchPositions.concat([]);
            this.changedTime = Date.now();

            return;
        }


    }

    updateMousePosFromEvent(event: any) {
        if (event) {
            if (event.clientX && event.clientY) {
                this.mousePos.copy({x: event.clientX, y: event.clientY});
            } else if (event.touches && event.touches[0]) {
                //console.log("touches",event.touches,event.touches[0]);
                this.mousePos.copy({
                    x: event.touches[0].clientX,
                    y: event.touches[0].clientY,                // @ts-ignore

                    force: event.touches[0].force || 0
                });
            } else if (event.changedTouches && event.changedTouches[0]) {
                //  console.log("changedTouches",event.changedTouches,event.changedTouches[0]);
                this.mousePos.copy({
                    x: event.changedTouches[0].clientX,
                    y: event.changedTouches[0].clientY,                // @ts-ignore

                    force: event.changedTouches[0].force || 0
                });
            }
        }
    }

    async up(event: any) {

        if (CONTEXT_MENU_OPEN) {
            return
        }

        if (event && event.touches) {
            this.inputType = "pointer";
        } else {
            this.inputType = "mouse";
        }

        this.updateMousePosFromEvent(event);

        if (this.willEmitTap && !this.didEmitGesture) {
            console.log(event);
            if (event.buttons === 1 || event.which === 1) {
                this.options && this.options.tap && this.options.tap({
                    tap: this.mousePos.clone(),
                    target: event.target
                });
            } else if (event.buttons === 2 || event.which === 3) {
                if (this.options.context) {
                    await this.options.context({context: this.mousePos.clone(), target: event});
                }
            }

            this.willEmitTap = false;
        }

        if (this.dragContextTimeout) {
            //console.log('CLEAR ME', this.dragContextTimeout);
            window.clearTimeout(this.dragContextTimeout);
            this.dragContextTimeout = null;
        }

        if (this.options.up) {
            this.options.up({up: this.mousePos.clone()});
        }

    }

    clearHandlers() {
        if (this.singleTapTimeoutHandler) {
            window.clearTimeout(this.singleTapTimeoutHandler);
        }

        this.singleTapTimeoutHandler = null;
    }

    isMobileInput(): boolean {
        var hasTouchScreen = false;

        if ("maxTouchPoints" in navigator) {
            hasTouchScreen = navigator.maxTouchPoints > 0;
        } else if ("msMaxTouchPoints" in navigator) {
            // @ts-ignore
            hasTouchScreen = navigator.msMaxTouchPoints > 0;
        } else {
            // @ts-ignore

            var mQ = window.matchMedia && matchMedia("(pointer:coarse)");
            if (mQ && mQ.media === "(pointer:coarse)") {
                hasTouchScreen = !!mQ.matches;
            } else if ('orientation' in window) {
                hasTouchScreen = true; // deprecated, but good fallback
            } else {
                // Only as a last resort, fall back to user agent sniffing
                // @ts-ignore
                var UA = navigator.userAgent;
                hasTouchScreen = (
                    /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
                    /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
                );
            }
        }

        return hasTouchScreen;
    }


}
