import { FC, useEffect, useRef, useState } from "react";

export interface RGBALike {
    r: number; // 0 - 1, representative of 0 - 255
    g: number;
    b: number;
    a: number;
}

interface RGBEditorProps {
    label: string;
    color?: RGBALike;
    onChange?: (color: RGBALike) => void;
}

export const RGBEditor: FC<RGBEditorProps> = ({ label, color, onChange }) => {
    const [currentColor, setCurrentColor] = useState<RGBALike>(
        color || { r: 1, g: 1, b: 1, a: 1 }
    );
    const [showPalette, setShowPalette] = useState(false);
    const paletteRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setCurrentColor(color || { r: 1, g: 1, b: 1, a: 1 });
    }, [color]);

    const handleClickOutside = (event: MouseEvent) => {
        if (paletteRef.current && !paletteRef.current.contains(event.target as Node)) {
            setShowPalette(false);
        }
    };

    useEffect(() => {
        if (showPalette) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showPalette]);

    const updateColor = (newColor: RGBALike) => {
        setCurrentColor(newColor);
        if (onChange) {
            onChange(newColor);
        }
    };

    const predefinedColors: RGBALike[] = [
        // Common Colors
        { r: 1, g: 0.5, b: 0.5, a: 1 }, // Coral / Pink
        { r: 1, g: 0, b: 0, a: 1 }, // Ruby Red
        { r: 0.65, g: 0.0, b: 0.0, a: 1 }, // Abyssal Red
        { r: 0.4, g: 1, b: 0.4, a: 1 }, // Lime Green
        { r: 0.05, g: 0.8, b: 0.1, a: 1 }, // Emerald Green
        { r: 0, g: 0.5, b: 0.02, a: 1 }, // Dark Green
        { r: 0, g: 1, b: 1, a: 1 }, // Cyan
        { r: 0.2, g: 0.5, b: 0.8, a: 1 }, // Metallic Blue
        { r: 0.02, g: 0.1, b: 0.8, a: 1 }, // Dark Blue
       // { r: 0.3, g: 0.7, b: 0.7, a: 1 }, // Teal
        { r: 1, g: 1, b: 0, a: 1 }, // Yellow
        { r: 1, g: 0.84, b: 0, a: 1 }, // Gold

        { r: 1, g: 0.5, b: 0, a: 1 }, // Orange

        { r: 0.7, g: 0.6, b: 0.5, a: 1 }, // Bronze
        { r: 0.8, g: 0.5, b: 0.2, a: 1 }, // Copper
        { r: 0.6, g: 0.3, b: 0.1, a: 1 }, // Brown



        // Hues and Variations
        { r: 1, g: 0, b: 1, a: 1 }, // Magenta
        { r: 0.6, g: 0.1, b: 0.8, a: 1 }, // Violet
        { r: 0.5, g: 0, b: 0.5, a: 1 }, // Purple

        { r: 1, g: 1, b: 1, a: 1 }, // White
        { r: 0.8, g: 0.8, b: 0.7, a: 1 }, // Silver
        { r: 0.7, g: 0.7, b: 0.75, a: 1 }, // Steel
        { r: 0.8, g: 0.8, b: 0.8, a: 1 }, // Grey
        // Grayscale
        { r: 0.4, g: 0.4, b: 0.4, a: 1 }, // Dark Gray
        { r: 0, g: 0, b: 0, a: 1 }, // Black
    ];


    return (
        <div>
             {showPalette && (
                 <div
                     ref={paletteRef}
                     style={{
                         position: "absolute",
                         background: "#fff",
                         border: "1px solid #ccc",
                         padding: "0.5em",
                         zIndex: 100,
                         display: "flex",
                         flexWrap: "wrap",
                         gap: "0.5em",
                         width: "max-content",
                         maxWidth:"10.5em"
                     }}
                 >
                     {predefinedColors.map((preset, index) => (
                         <div style={{border:"1px solid #333"}}><div
                             key={index}
                             style={{
                                 border: "1px solid #fff",
                                 width: "1em",
                                 height: "1em",
                                 backgroundColor: `rgba(${preset.r * 255}, ${
                                     preset.g * 255
                                 }, ${preset.b * 255}, ${preset.a})`,
                                 cursor: "pointer",
                             }}
                             onClick={() => {
                                 updateColor(preset);
                                 setShowPalette(false);
                             }}
                         /></div>
                     ))}
                 </div>
             )}
            {label&&<span>{label}</span>}
            <div style={{border:"1px solid #333",display: "inline-flex"}}><div
                className={""}
                style={{
                   // display: "inline-block",
                    width: "1em",
                    height: "1em",
                    backgroundColor: `rgba(${currentColor.r * 255}, ${
                        currentColor.g * 255
                    }, ${currentColor.b * 255}, ${currentColor.a})`,
                    border: "1px solid #fff",
                    cursor: "pointer",
                }}
                onClick={() => setShowPalette(!showPalette)}
            /></div>

        </div>
    );
};
