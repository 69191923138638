import {FC, useEffect, useState} from "react";
import {Button, Divider, Space} from "../layout/Content";
import {Vec3, Vec3Interface} from "../../model/Vec3";

interface VectorEditorProps {
    label: string;
    vec?: Vec3;
    increment?: number;
    onChange?: (v: Vec3) => void;
}

export const VectorEditor: FC<VectorEditorProps> = ({label, vec, onChange, increment = 1}) => {

    const [vector, setVector] = useState<Vec3>(vec || new Vec3());

    const sizes = increment > 1 ? [0.1, 1, increment] : [0.01, 0.1, 1];

    useEffect(() => {
        setVector(vec || new Vec3());
    }, [vec])

    const update = (vec: Vec3) => {
        const v = vec.clone();
        setVector(v);
        if (onChange) {
            onChange(v);
        }
    }

    const bP = "2px 4px";

    return <Space direction={"vertical"} GapSm>

        <span>{label}:</span>
        <Space Gap>

            <Space direction={"vertical"}>

                <strong>x</strong>{vector.x.toFixed(2)}
                <Space GapSm align={"center"}>
                    <Space direction={"vertical"}>{sizes.map((size: number) => {
                        return <button style={{padding: bP, width: "100%"}} onClick={() => {
                            vector.x -= size;
                            update(vector);
                        }}>-{size}</button>
                    })}</Space>
                    <Space direction={"vertical"}>{sizes.map((size: number) => {
                        return <button style={{padding: bP, width: "100%"}} onClick={() => {
                            vector.x += size;
                            update(vector);
                        }}>{size}</button>
                    })}</Space>
                </Space>
            </Space>

            <Space direction={"vertical"}>
                <strong>y</strong>{vector.y.toFixed(2)}

                <Space GapSm align={"center"}>
                    <Space direction={"vertical"}>{sizes.map((size: number) => {
                        return <button style={{padding: bP, width: "100%"}} onClick={() => {
                            vector.y -= size;
                            update(vector);
                        }}>-{size}</button>
                    })}</Space>
                    <Space direction={"vertical"}>{sizes.map((size: number) => {
                        return <button style={{padding: bP, width: "100%"}} onClick={() => {
                            vector.y += size;
                            update(vector);
                        }}>{size}</button>
                    })}</Space>
                </Space>
            </Space>

            <Space direction={"vertical"}>
                <strong>z</strong>{vector.z.toFixed(2)}

                <Space GapSm align={"center"}>
                    <Space direction={"vertical"}>{sizes.map((size: number) => {
                        return <button style={{padding: bP, width: "100%"}} onClick={() => {
                            vector.z -= size;
                            update(vector);
                        }}>-{size}</button>
                    })}</Space>
                    <Space direction={"vertical"}>{sizes.map((size: number) => {
                        return <button style={{padding: bP, width: "100%"}} onClick={() => {
                            vector.z += size;
                            update(vector);
                        }}>{size}</button>
                    })}</Space>
                </Space>
            </Space>
        </Space>
    </Space>
}