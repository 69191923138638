import {Vec3, Vec3Interface} from "../../model/Vec3";
import {Camera} from "../../model/Camera";
import {mat4, vec3} from 'gl-matrix';
import {RGBALike} from "../../model/RGBA";
import {Vec2, Vec2Interface} from "../../model/Vec2";
import {Surface} from "../../model/Surface";
import {ClientRenderingOptions} from "../../model/RemotePLYLoader";
import {PLYData} from "../../model/PLY"; // Assuming you're using gl-matrix for math operations


//export const SHADOW_MAP_SIZE = window.innerWidth < 420?256:1024;
//export const SHADOW_PCF_SAMPLES = window.innerWidth < 420?4:32;

export const camera = new Camera();
camera.from.set(0, 15, 15);
camera.to.set(0, 0, 2);
camera.fov = 60;

export const light = new Camera();
light.from.set(-40, 20, 20);
light.to.set(0, 0, 0);
light.fov = 60;


export interface MeshBounds {
    upper: Vec3;
    lower: Vec3;
}



export function MeshFallsWithinBounds(camera: Camera, surface: Surface, origin: Vec3, direction: Vec3, plyData: PLYData, bounds: Vec2Interface[]): boolean {
    //  camera.projection.set(camera);
    const lower = new Vec2().set((Math.min(bounds[0].x, bounds[1].x)), (Math.min(bounds[0].y, bounds[1].y)));
    const upper = new Vec2().set((Math.max(bounds[0].x, bounds[1].x)), (Math.max(bounds[0].y, bounds[1].y)));

    for (let i = 0; i < plyData.indices.length; i += 3) {
        let index0 = plyData.indices[i] * 3;
        let index1 = plyData.indices[i + 1] * 3;
        let index2 = plyData.indices[i + 2] * 3;

        let vertex0 = new Vec3().set(
            plyData.positions[index0],
            plyData.positions[index0 + 1],
            plyData.positions[index0 + 2]
        );

        let vertex1 = new Vec3().set(
            plyData.positions[index1],
            plyData.positions[index1 + 1],
            plyData.positions[index1 + 2]
        );

        let vertex2 = new Vec3().set(
            plyData.positions[index2],
            plyData.positions[index2 + 1],
            plyData.positions[index2 + 2]
        );

        const p0 = camera.projection.toScreen(surface, vertex0, origin);
        p0.x /= surface.getWidth();
        p0.y /= surface.getHeight();

        if (p0.x > lower.x && p0.x < upper.x && p0.y > lower.y && p0.y < upper.y) {
            return true;
        }

    }



    return false;
}

// Supported vertex properties and their expected final arrays
// We map property names to which array they contribute to and their order.
const PROPERTY_MAP = {
    x: { target: 'positions', index: 0 },
    y: { target: 'positions', index: 1 },
    z: { target: 'positions', index: 2 },

    nx: { target: 'normals', index: 0 },
    ny: { target: 'normals', index: 1 },
    nz: { target: 'normals', index: 2 },

    s: { target: 'uvs', index: 0 },
    t: { target: 'uvs', index: 1 },

    red:   { target: 'colors', index: 0 },
    green: { target: 'colors', index: 1 },
    blue:  { target: 'colors', index: 2 },
    alpha: { target: 'colors', index: 3 },
};


export const loadShader = (gl: WebGLRenderingContext, type: GLenum, source: string): WebGLShader | null => {
    const shader = gl.createShader(type);
    if (!shader) {
        console.error('An error occurred creating shaders');
        return null;
    }
    gl.shaderSource(shader, source);
    gl.compileShader(shader);

    if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
        console.error('An error occurred compiling the shaders: ' + gl.getShaderInfoLog(shader));
        gl.deleteShader(shader);
        return null;
    }

    return shader;
};

export const initShaderProgram = (gl: WebGLRenderingContext, vsSource: string, fsSource: string): WebGLProgram | null => {
    const vertexShader = loadShader(gl, gl.VERTEX_SHADER, vsSource);
    const fragmentShader = loadShader(gl, gl.FRAGMENT_SHADER, fsSource);
    if (!vertexShader || !fragmentShader) {
        return null;
    }

    const shaderProgram = gl.createProgram();
    if (!shaderProgram) {
        console.error('Unable to create shader program');
        return null;
    }
    gl.attachShader(shaderProgram, vertexShader);
    gl.attachShader(shaderProgram, fragmentShader);
    gl.linkProgram(shaderProgram);

    if (!gl.getProgramParameter(shaderProgram, gl.LINK_STATUS)) {
        alert('Unable to initialize the shader program: ' + gl.getProgramInfoLog(shaderProgram));
        return null;
    }

    return shaderProgram;
};

export interface VBOHelper {
    elements: number;
    position: WebGLBuffer | null;
    normal: WebGLBuffer | null;
    color: WebGLBuffer | null;
    indices: WebGLBuffer | null;
    materials: WebGLBuffer | null;
}

export const isProgramValidForContext = (gl: WebGLRenderingContext, program: WebGLProgram | null): boolean => {
    return program !== null;// && gl.isProgram(program);
};

const isCurrentProgram = (gl: WebGLRenderingContext, program: WebGLProgram | null): boolean => {
    if (!program) {
        console.error("Shader program is null.");
        return false;
    }

    const currentProgram = gl.getParameter(gl.CURRENT_PROGRAM);
    return program === currentProgram;
};


const isBufferValid = (gl: WebGLRenderingContext, buffer?: WebGLBuffer | null): buffer is WebGLBuffer => {
    return buffer !== null && buffer !== undefined;// && gl.isBuffer(buffer);
};

const remakeBuffer = (plyData: PLYData, existing: VBOHelper) => {
    return (!existing);// || (existing.elements!==plyData.indices.length));
}

export const MATERIAL_PROPERTIES = [
    { name:"default", diffuse: 1.0, specular: 0.1, reflection: 0.0, emission: 0.0, receiveShadow: 1.0 }, // DEFAULT
    { name:"emission", diffuse: 0.0, specular: 0.0, reflection: 0.0, emission: 1.0, receiveShadow: 0.0 }, // EMISSION
    { name:"matte", diffuse: 0.5, specular: 0.0, reflection: 0.0, emission: 0.1, receiveShadow: 0.0 }, // MATTE
    { name:"metallic", diffuse: 0.6, specular: 1, reflection: 0.9, emission: 0.0, receiveShadow: 1.0 }, // METALLIC
    { name:"translucent", diffuse: 0.4, specular: 0.6, reflection: 0.0, emission: 0.6, receiveShadow: 0.0 }, // TRANSLUCENT
];




// @ts-ignore
export const initBuffers = (gl: WebGLRenderingContext, plyData: PLYData, existing: VBOHelper = {
    elements: 0,
    position: null,
    normal: null,
    color: null,
    indices: null
}): VBOHelper => {

    const remake = !existing || remakeBuffer(plyData, existing);

    if (remake) {
        console.error("REMAKING BUFFERS", existing.elements, plyData.indices.length);
    }

    const positionBuffer = (!remake) && isBufferValid(gl, existing.position) ? existing.position : gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, (plyData.positions), gl.DYNAMIC_DRAW);

    const normalBuffer = (!remake) && isBufferValid(gl, existing.normal) ? existing.normal : gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, normalBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, (plyData.normals), gl.DYNAMIC_DRAW);

    const colorBuffer = (!remake) && isBufferValid(gl, existing.color) ? existing.color : gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, colorBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, (plyData.colors), gl.DYNAMIC_DRAW);

    const indexBuffer = (!remake) && isBufferValid(gl, existing.indices) ? existing.indices : gl.createBuffer();
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, indexBuffer);
    gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, (plyData.indices), gl.DYNAMIC_DRAW);

    const materialProperties = new Float32Array(plyData.materials.length * 3 * 4); // 4 floats per vertex
    let offset = 0;
    for (let i = 0; i < plyData.materials.length; i++) {
        const material = MATERIAL_PROPERTIES[plyData.materials[i]]||MATERIAL_PROPERTIES[0]; // Replace with logic to fetch material
        materialProperties[i * 4] = material.diffuse;
        materialProperties[i * 4 + 1] = material.specular;
        materialProperties[i * 4 + 2] = material.reflection;
        materialProperties[i * 4 + 3] = material.emission;
    }

    // console.log(materialBufferData);

    // Create buffer for material properties
    const materialBuffer =  (!remake) && isBufferValid(gl, existing.materials) ? existing.materials : gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, materialBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, materialProperties, gl.DYNAMIC_DRAW);


    return {
        elements: plyData.indices.length,
        position: positionBuffer,
        normal: normalBuffer,
        color: colorBuffer,
        indices: indexBuffer,
        materials: materialBuffer
    };
};

export function createShadowMap(gl: WebGLRenderingContext, width: number, height: number): {
    framebuffer: WebGLFramebuffer,
    renderbuffer: WebGLRenderbuffer,
    texture: WebGLTexture,
    size: number
} | null {
    // Create and bind a framebuffer
    const frameBuffer = gl.createFramebuffer();
    if (!frameBuffer) {
        console.error('Unable to create framebuffer');
        return null;
    }
    gl.bindFramebuffer(gl.FRAMEBUFFER, frameBuffer);

    // Create a texture to store the shadow map
    const texture = gl.createTexture();
    if (!texture) {
        console.error('Unable to create shadow map texture');
        gl.deleteFramebuffer(frameBuffer);
        return null;
    }
    gl.bindTexture(gl.TEXTURE_2D, texture);
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, width, height, 0, gl.RGBA, gl.UNSIGNED_BYTE, null);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);

    // Attach the texture to the framebuffer as the color attachment
    gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, texture, 0);

    // Create a renderbuffer for depth
    const renderBuffer = gl.createRenderbuffer();
    if (!renderBuffer) {
        console.error('Unable to create renderbuffer');
        gl.deleteFramebuffer(frameBuffer);
        gl.deleteTexture(texture);
        return null;
    }
    gl.bindRenderbuffer(gl.RENDERBUFFER, renderBuffer);
    gl.renderbufferStorage(gl.RENDERBUFFER, gl.DEPTH_COMPONENT16, width, height);

    // Attach the renderbuffer to the framebuffer as the depth attachment
    gl.framebufferRenderbuffer(gl.FRAMEBUFFER, gl.DEPTH_ATTACHMENT, gl.RENDERBUFFER, renderBuffer);

    // Check if the framebuffer is complete
    if (gl.checkFramebufferStatus(gl.FRAMEBUFFER) !== gl.FRAMEBUFFER_COMPLETE) {
        console.error('Framebuffer is not complete!');
        gl.deleteFramebuffer(frameBuffer);
        gl.deleteTexture(texture);
        gl.deleteRenderbuffer(renderBuffer);
        return null;
    }

    // Unbind the framebuffer, the texture, and the renderbuffer
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    gl.bindTexture(gl.TEXTURE_2D, null);
    gl.bindRenderbuffer(gl.RENDERBUFFER, null);

    // Return the framebuffer and shadow map texture
    return {framebuffer: frameBuffer, texture: texture, renderbuffer: renderBuffer, size: width};
}


export function renderSceneFromLightPerspective(
    gl: WebGLRenderingContext,
    shadowShaderProgram: WebGLProgram,
    fbo: any,
    buffers: any,
    lightProjectionMatrix: mat4,
    lightViewMatrix: mat4
) {
    gl.useProgram(shadowShaderProgram);


    gl.viewport(0, 0, fbo.size, fbo.size); // Use the same size as the shadow map

    gl.bindFramebuffer(gl.FRAMEBUFFER, fbo.framebuffer);

    gl.clearColor(0.0, 0.0, 0.0, 0.0);
    gl.clearDepth(1.0);
    gl.depthFunc(gl.LEQUAL);
    gl.enable(gl.DEPTH_TEST);
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
    gl.enable(gl.CULL_FACE);


    //  gl.bindRenderbuffer(gl.RENDERBUFFER, fbo.renderbuffer)


    // Set light matrices
    gl.uniformMatrix4fv(
        gl.getUniformLocation(shadowShaderProgram, 'uLightProjectionMatrix'),
        false,
        lightProjectionMatrix
    );
    gl.uniformMatrix4fv(
        gl.getUniformLocation(shadowShaderProgram, 'uLightViewMatrix'),
        false,
        lightViewMatrix
    );

    // Bind and set buffers
    gl.bindBuffer(gl.ARRAY_BUFFER, buffers.position);
    const positionLocation = gl.getAttribLocation(shadowShaderProgram, 'aVertexPosition');
    gl.enableVertexAttribArray(gl.getAttribLocation(shadowShaderProgram, 'aVertexPosition'));
    gl.vertexAttribPointer(positionLocation, 3, gl.FLOAT, false, 0, 0);

    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, buffers.indices);
    gl.drawElements(gl.TRIANGLES, buffers.elements, gl.UNSIGNED_SHORT, 0);

    // Clean up
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    //gl.bindRenderbuffer(gl.RENDERBUFFER, null);
    gl.disableVertexAttribArray(positionLocation);
}


export function drawSceneShadowMapped(
    gl: WebGLRenderingContext,
    cameraShaderProgram: WebGLProgram,
    buffers: VBOHelper,
    fbo: any,
    camera: Camera,
    lightProjectionMatrix: mat4,
    lightViewMatrix: mat4
) {

    const fieldOfView = camera.fov * Math.PI / 180;
    const aspect = camera.aspect;
    const zNear = 1;
    const zFar = 300.0;
    const projectionMatrix = mat4.create();
    mat4.perspective(projectionMatrix, fieldOfView, aspect, zNear, zFar);

    const modelViewMatrix = mat4.create();
    mat4.identity(modelViewMatrix);
    const cameraFrom = camera.getFromWithUserZoomPositionRotationGL();
    const cameraTo = camera.getToWithUserPositionGL();
    mat4.lookAt(modelViewMatrix, [cameraFrom.x, cameraFrom.y, cameraFrom.z], [cameraTo.x, cameraTo.y, cameraTo.z], [0, 0, 1]);
    gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);
    gl.useProgram(cameraShaderProgram);

    gl.clearColor(0.0, 0.0, 0.0, 0.0);
    gl.clearDepth(1.0);
    gl.depthFunc(gl.LEQUAL);
    gl.enable(gl.DEPTH_TEST);
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
    gl.enable(gl.CULL_FACE);

    // Activate the shadow map texture
    gl.activeTexture(gl.TEXTURE0);
    gl.bindTexture(gl.TEXTURE_2D, fbo.texture);
    gl.uniform1i(gl.getUniformLocation(cameraShaderProgram, 'uShadowMap'), 0);

    // Set the camera's view and projection matrices
    const uProjectionMatrix = gl.getUniformLocation(cameraShaderProgram, 'uProjectionMatrix');
    const uModelViewMatrix = gl.getUniformLocation(cameraShaderProgram, 'uModelViewMatrix');
    gl.uniformMatrix4fv(uProjectionMatrix, false, projectionMatrix);
    gl.uniformMatrix4fv(uModelViewMatrix, false, modelViewMatrix);

    // Set light matrices
    const uLightProjectionMatrix = gl.getUniformLocation(cameraShaderProgram, 'uLightProjectionMatrix');
    const uLightViewMatrix = gl.getUniformLocation(cameraShaderProgram, 'uLightViewMatrix');
    gl.uniformMatrix4fv(uLightProjectionMatrix, false, lightProjectionMatrix);
    gl.uniformMatrix4fv(uLightViewMatrix, false, lightViewMatrix);

    const uLightDirection = gl.getUniformLocation(cameraShaderProgram, 'uLightDirection');
    gl.uniform3fv(uLightDirection, [-1.0, 1.0, 1.0]);

    // Bind and set buffers
    gl.bindBuffer(gl.ARRAY_BUFFER, buffers.position);
    const positionLocation = gl.getAttribLocation(cameraShaderProgram, 'aVertexPosition');
    gl.vertexAttribPointer(positionLocation, 3, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(positionLocation);

    // Bind and set buffers
    gl.bindBuffer(gl.ARRAY_BUFFER, buffers.color);
    const colorLocation = gl.getAttribLocation(cameraShaderProgram, 'aVertexColor');
    gl.vertexAttribPointer(colorLocation, 4, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(colorLocation);

    // Bind and set buffers
    gl.bindBuffer(gl.ARRAY_BUFFER, buffers.normal);
    const normalLocation = gl.getAttribLocation(cameraShaderProgram, 'aVertexNormal');
    gl.vertexAttribPointer(normalLocation, 3, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(normalLocation);

    // If using color or other attributes, bind them here as well
    gl.bindBuffer(gl.ARRAY_BUFFER, buffers.materials);
    const materialLocation = gl.getAttribLocation(cameraShaderProgram, "aMaterialProperties");
    gl.vertexAttribPointer(materialLocation, 4, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(materialLocation);


    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, buffers.indices);
    gl.drawElements(gl.TRIANGLES, buffers.elements, gl.UNSIGNED_SHORT, 0);

    // console.log(buffers.elements/3);

    // Clean up
    gl.bindTexture(gl.TEXTURE_2D, null);
    gl.disableVertexAttribArray(positionLocation);
    gl.disableVertexAttribArray(colorLocation);
    gl.disableVertexAttribArray(normalLocation);
}

export function debugVisualizeShadowMap(gl: any, debugProgram: any, shadowMapTexture: any) {
    // Full-screen quad vertices and texture coordinates
    const vertices = new Float32Array([
        -1.0, -1.0, 0.0, 1.0,
        1.0, -1.0, 1.0, 1.0,
        -1.0, 1.0, 0.0, 0.0,
        1.0, 1.0, 1.0, 0.0,
    ]);

    // Create vertex buffer
    const vertexBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, vertices, gl.STATIC_DRAW);

    // Use the debug shader program
    gl.useProgram(debugProgram);
    gl.disable(gl.CULL_FACE);

    gl.viewport(0, 0, 512, 512); // Use the same size as the shadow map


    // Set up vertex attribute pointers
    const positionLocation = gl.getAttribLocation(debugProgram, 'aPosition');
    gl.vertexAttribPointer(positionLocation, 2, gl.FLOAT, false, 4 * Float32Array.BYTES_PER_ELEMENT, 0);
    gl.enableVertexAttribArray(positionLocation);

    const uvLocation = gl.getAttribLocation(debugProgram, 'aUV');
    gl.vertexAttribPointer(uvLocation, 2, gl.FLOAT, false, 4 * Float32Array.BYTES_PER_ELEMENT, 2 * Float32Array.BYTES_PER_ELEMENT);
    gl.enableVertexAttribArray(uvLocation);

    // Set the shadow map texture
    gl.activeTexture(gl.TEXTURE0);
    gl.bindTexture(gl.TEXTURE_2D, shadowMapTexture);
    gl.uniform1i(gl.getUniformLocation(debugProgram, 'uShadowMap'), 0);

    // Draw the quad
    gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);

    // Clean up
    gl.disableVertexAttribArray(positionLocation);
    gl.disableVertexAttribArray(uvLocation);
    gl.bindBuffer(gl.ARRAY_BUFFER, null);
    gl.bindTexture(gl.TEXTURE_2D, null);
    gl.useProgram(null);


    // let pixels = new Uint8Array(gl.canvas.width * gl.canvas.height * 4);
    //gl.readPixels(0, 0, gl.canvas.width, gl.canvas.height, gl.RGBA, gl.UNSIGNED_BYTE, pixels);
    // console.log('Shadow map pixels:', pixels);
}


export function renderFrameBuffer(gl: any, fbo: any) {
    if (!gl) {
        return
    }
    if (!fbo.framebuffer) {
        return;
    }
    //  this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, null);

    gl.bindFramebuffer(gl.READ_FRAMEBUFFER, fbo.framebuffer);
    gl.readBuffer(gl.COLOR_ATTACHMENT0);
    gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, null);
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
    gl.viewport(0, 0, fbo.size, fbo.size);
    gl.blitFramebuffer(0, 0, fbo.size, fbo.size, 0, 0, 512, 512,
        gl.COLOR_BUFFER_BIT, gl.NEAREST);
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
}
