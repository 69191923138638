import React, {
    useContext,
    useReducer,
    createContext,
    useState,
    useEffect,
    ReactNode,
    FC
} from "react";
import {
    Button,
    Card, Col,
    Content,
    Divider, DropDown,
    Layout,
    Page,
    Paragraph,
    Row,
    Small,
    Space,
    Title,
} from "./layout/Content";
import { Statistic } from "./layout/Defaults";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCalculator,
    faQuestionCircle,
    faFolderOpen,
    faCamera,
    faStickyNote
} from "@fortawesome/free-solid-svg-icons";

import "./KeyPiece.scss";
import {NotepadApp, NotepadAppClass} from "./keypiece-apps/NotepadApp";
import { CameraApp, CameraProvider } from "./keypiece-apps/CameraApp";
import {GameApp, GameAppClass} from "./keypiece-apps/SimpleGameApp";
import {
    APP_CALCULATOR,
    APP_CAMERA,
    APP_DOCUMENTS,
    APP_HELP, APP_NOTEPAD, APP_SIMPLE_GAME, APP_WIKI,
    AppAction, AppName,
    AppState,
    BaseApp
} from "./keypiece-apps/BaseApp";
import {WikiAppClass} from "./keypiece-apps/WikiApp";

const DEFAULT_APP_STATE: AppState = {
    openApps: [],
    activeApp: null,
};

const APP_STATE_STORAGE_KEY = "system_app_state";

class SystemStateManager {
    static loadAppState(): AppState {
        try {
            const stored = localStorage.getItem(APP_STATE_STORAGE_KEY);
            if (stored) {
                const state = JSON.parse(stored);
                // Basic validation to ensure structure
                if (Array.isArray(state.openApps) && (state.activeApp === null || typeof state.activeApp === 'string')) {
                    return state;
                }
            }
        } catch (e) {
            console.error("Failed to load app state:", e);
        }
        return DEFAULT_APP_STATE;
    }

    static saveAppState(state: AppState) {
        localStorage.setItem(APP_STATE_STORAGE_KEY, JSON.stringify(state));
    }
}

const appReducer = (state: AppState, action: AppAction): AppState => {
    switch (action.type) {
        case "OPEN_APP":
            return {
                ...state,
                openApps: state.openApps.includes(action.app)
                    ? state.openApps
                    : [...state.openApps, action.app],
                activeApp: action.app,
            };
        case "CLOSE_APP":
            return {
                ...state,
                openApps: state.openApps.filter((app) => app !== action.app),
                activeApp: state.activeApp === action.app ? null : state.activeApp,
            };
        case "SWITCH_APP":
            return {
                ...state,
                activeApp: state.activeApp === action.app ? null : action.app,
            };
        case "MINIMIZE_APP":
            return {
                ...state,
                activeApp: null,
            };
        default:
            return state;
    }
};

interface AppContextProps {
    state: AppState;
    dispatch: React.Dispatch<AppAction>;
}

const AppContext = createContext<AppContextProps | undefined>(undefined);

export const AppProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [initialState] = useState(() => SystemStateManager.loadAppState());
    const [state, dispatch] = useReducer(appReducer, initialState);

    useEffect(() => {
        SystemStateManager.saveAppState(state);
    }, [state]);

    return (
        <AppContext.Provider value={{ state, dispatch }}>
            {children}
        </AppContext.Provider>
    );
};

const useAppContext = (): AppContextProps => {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error("useAppContext must be used within an AppProvider");
    }
    return context;
};

const useCurrentTime = () => {
    const [time, setTime] = useState<number>(() => new Date().getSeconds());

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(new Date().getSeconds());
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return time;
};

const Taskbar: FC = () => {
    const { state, dispatch } = useAppContext();
    const time = useCurrentTime();

    return (
        <Row
            style={{
                position: "fixed",
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: "#eee",
                padding: "10px",
                zIndex: 1000,
            }}
        >
            <Space direction="horizontal" Gap NoWrap>
                {state.openApps.map((app) => (
                    <Button
                        key={app}
                        type={state.activeApp === app ? "primary" : "ghost"}
                        onClick={() =>
                            dispatch({
                                type: state.activeApp === app ? "MINIMIZE_APP" : "SWITCH_APP",
                                app,
                            })
                        }
                    >
                        {AppRegistry.getClass(app).title}
                    </Button>
                ))}

                <Statistic title={"Profile"} value={<span>Gordon</span>} />
                <Statistic title={"Time"} value={time} />
            </Space>
        </Row>
    );
};

interface FullScreenAppProps {
    title: string;
    onClose: () => void;
    onMinimize?: () => void;
    children: ReactNode;
}

export const FullScreenApp: FC<FullScreenAppProps> = ({ title, onClose, onMinimize, children }) => (
    <Layout

        style={{
          //  position: "absolute",
         //   top: 0,
          //  left: 0,
           // right: 0,
          //  bottom: "50px",
            backgroundColor: "#fff",
            zIndex: 1000,
        }}
    >
        <Space justify="space-between" Gap>
            <Title>{title}</Title>
            <Space GapSm>
                <Button onClick={onMinimize}>_</Button>
                <Button type="danger" onClick={onClose}>
                    X
                </Button>
            </Space>
        </Space>
        <Divider />
        {children}
    </Layout>
);

const CalculatorApp: FC<{ onClose: () => void; onMinimize: () => void }> = ({ onClose, onMinimize }) => (
    <FullScreenApp
        title={APP_CALCULATOR}
        onClose={onClose}
        onMinimize={onMinimize}
    >
        <Paragraph>Calculator functionality goes here.</Paragraph>
    </FullScreenApp>
);

const DocumentsApp: FC<{ onClose: () => void; onMinimize: () => void }> = ({ onClose, onMinimize }) => (
    <FullScreenApp
        title={APP_DOCUMENTS}
        onClose={onClose}
        onMinimize={onMinimize}
    >
        <Paragraph>DOCUMENTS</Paragraph>
    </FullScreenApp>
);

class HelpAppClass extends BaseApp {
    static id = APP_HELP;
    static title = "Start Here";
    static icon = faQuestionCircle;

    static getComponent(onClose: () => void, onMinimize: () => void): ReactNode {
        return <HelpApp onClose={onClose} onMinimize={onMinimize} />;
    }
}

const HelpApp: FC<{ onClose: () => void; onMinimize: () => void }> = ({ onClose, onMinimize }) => (
    <FullScreenApp
        title={APP_HELP}
        onClose={onClose}
        onMinimize={onMinimize}
    >
        <Content>
            <Space Gap direction={"vertical"}>
                <Title>Welcome to your KeyPiece virtual desktop!</Title>
                <Paragraph>
                    Click on icons to open applications.
                    Use the taskbar to switch between or minimize apps.
                </Paragraph>
                <Paragraph>Notepad allows you to take notes.</Paragraph>
                <Paragraph>The Calculator app provides basic arithmetic functions.</Paragraph>
                <Paragraph>The Camera app lets you access your webcam and take photos.</Paragraph>
            </Space>
        </Content>
    </FullScreenApp>
);

class CalculatorAppClass extends BaseApp {
    static id = APP_CALCULATOR;
    static title = "Calculator";
    static icon = faCalculator;

    static getComponent(onClose: () => void, onMinimize: () => void): ReactNode {
        return <CalculatorApp onClose={onClose} onMinimize={onMinimize} />;
    }
}

class DocumentsAppClass extends BaseApp {
    static id = APP_DOCUMENTS;
    static title = "Documents";
    static icon = faFolderOpen;

    static getComponent(onClose: () => void, onMinimize: () => void): ReactNode {
        return <DocumentsApp onClose={onClose} onMinimize={onMinimize} />;
    }
}


class CameraAppClass extends BaseApp {
    static id = APP_CAMERA;
    static title = "Camera";
    static icon = faCamera;

    static getComponent(onClose: () => void, onMinimize: () => void): ReactNode {
        return (
            <CameraProvider>
                <CameraApp onClose={onClose} onMinimize={onMinimize} />
            </CameraProvider>
        );
    }
}

class AppRegistry {
    private static apps = new Map<AppName, typeof BaseApp>([
        [NotepadAppClass.id, NotepadAppClass],
        [CalculatorAppClass.id, CalculatorAppClass],
        [HelpAppClass.id, HelpAppClass],
        [CameraAppClass.id, CameraAppClass],
        [DocumentsAppClass.id, DocumentsAppClass],
        [GameAppClass.id, GameAppClass],
        [WikiAppClass.id, WikiAppClass],
    ]);

    static getClass(id: AppName): typeof BaseApp {
        const appClass = this.apps.get(id);
        if (!appClass) {
            throw new Error(`App class not found for ${id}`);
        }
        return appClass;
    }
}

const DesktopIcon: FC<{ app: AppName }> = ({ app }) => {
    const { dispatch } = useAppContext();
    const appClass = AppRegistry.getClass(app);

    return (
        <div>
            <Space GapSm direction={"vertical"}>
                <Card
                    Pad
                    className={"app-icon"}
                    onClick={() => dispatch({ type: "OPEN_APP", app })}
                    style={{ cursor: "pointer", width: "100px", textAlign: "center" }}
                >
                    <FontAwesomeIcon className={"muted-heavy"} icon={appClass.icon} size={"2x"} fixedWidth />
                </Card>
                <Space justify={"center"} Wide>
                    <Small>{appClass.title}</Small>
                </Space>
            </Space>
        </div>
    );
};

export const Desktop: FC = () => {
    const { state, dispatch } = useAppContext();
    const activeApp = state.activeApp ? AppRegistry.getClass(state.activeApp) : null;
    const activeAppComponent = activeApp
        ? activeApp.getComponent(
            () => dispatch({ type: "CLOSE_APP", app: activeApp.id }),
            () => dispatch({ type: "MINIMIZE_APP", app: activeApp.id })
        )
        : null;

    return (
        <Layout>
            <Page Grow>
                <Content
                    style={{
                        height: "100vh",
                        backgroundColor: "#87ceeb",
                        position: "relative",
                    }}
                >
                    <Row Gap Pad>
                        <Col xs={24}>
                            <Space Gap><DesktopIcon app={APP_HELP} /></Space>
                        </Col>
                        <Col xs={24}>
                            <Space Gap>
                                <DesktopIcon app={APP_DOCUMENTS} />
                                <DesktopIcon app={APP_NOTEPAD} />
                                <DesktopIcon app={APP_CALCULATOR} />
                                <DesktopIcon app={APP_CAMERA} />
                            </Space>
                        </Col>
                        <Col xs={24}>
                            <Space Gap>
                                <DesktopIcon app={APP_WIKI} />
                                <DesktopIcon app={APP_SIMPLE_GAME} />
                            </Space>
                        </Col>
                    </Row>

                    {activeAppComponent}
                    <Taskbar />
                </Content>
            </Page>
        </Layout>
    );
};
