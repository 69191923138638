interface FBXNode {
    name: string;
    properties: any[];
    children: FBXNode[];
}

class FBXParser {
    private data: DataView;
    private offset: number = 0;

    constructor(buffer: ArrayBuffer) {
        this.data = new DataView(buffer);
    }

    private readUint32(): number {
        if (this.offset + 4 > this.data.byteLength) {
            throw new Error("Unexpected end of data while reading Uint32");
        }
        const value = this.data.getUint32(this.offset, true);
        this.offset += 4;
        return value;
    }


    private readUint64(): bigint {
        const value = this.data.getBigUint64(this.offset, true);
        this.offset += 8;
        return value;
    }

    private readString(length: number): string {
        const bytes = new Uint8Array(this.data.buffer, this.offset, length);
        this.offset += length;
        return new TextDecoder().decode(bytes);
    }

    private readNode(): FBXNode | null {
        const endOffset = this.readUint32();
        const numProperties = this.readUint32();
        const propertyListLength = this.readUint32();
        const nameLength = this.data.getUint8(this.offset++);

        if (endOffset === 0 || nameLength === 0) return null; // End of nodes

        const name = this.readString(nameLength);

        const properties: any[] = [];
        for (let i = 0; i < numProperties; i++) {
            properties.push(this.readProperty());
        }

        const children: FBXNode[] = [];
        while (this.offset < endOffset) {
            const child = this.readNode();
            if (child) children.push(child);
        }

        return { name, properties, children };
    }


    private readProperty(): any {
        const typeCode = this.data.getUint8(this.offset++);
        switch (String.fromCharCode(typeCode)) {
            case 'I': // Integer
                const intValue = this.data.getInt32(this.offset, true);
                this.offset += 4;
                return intValue;
            case 'D': // Double
                const doubleValue = this.data.getFloat64(this.offset, true);
                this.offset += 8;
                return doubleValue;
            case 'S': // String
                const length = this.readUint32();
                return this.readString(length);
            case 'L': // Long (64-bit integer)
                const longValue = this.readUint64();
                return longValue;
            case 'F': // Float
                const floatValue = this.data.getFloat32(this.offset, true);
                this.offset += 4;
                return floatValue;
            case 'b': // Boolean
                const boolValue = this.data.getUint8(this.offset++) !== 0;
                return boolValue;
            default:
                throw new Error(`Unsupported property type: ${typeCode}`);
        }
    }

    public parse(): FBXNode | null {
        // Skip FBX header
        this.offset += 23;

        const version = this.readUint32();
        console.log(`FBX Version: ${version}`);

        return this.readNode();
    }
}

async function loadFBX(file: File): Promise<FBXNode | null> {
    const buffer = await file.arrayBuffer();
    const parser = new FBXParser(buffer);
    return parser.parse();
}

// React component for file input and FBX parsing
const ImportFBX = () => {
    const handleFileInput = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        try {
            const fbxData = await loadFBX(file);
            console.log("Parsed FBX Data:", fbxData);
        } catch (error) {
            console.error("Error parsing FBX file:", error);
        }
    };

    return (
        <div>
            <input type="file" accept=".fbx" onChange={handleFileInput} />
        </div>
    );
};

export default ImportFBX;
