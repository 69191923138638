import React, { useState } from "react";
import {
    Button, Card, Col, Content, Divider, Paragraph, Row, Space, Title,
} from "../layout/Content";
import { Modal } from "../layout/Defaults";

export const Article: React.FC = () => {
    const [modalVisible, setModalVisible] = useState(false);

    return (
        <Content className={"pad-content"}>
            <Space direction={"vertical"} Gap>
                <Title Large className="heavy">
                    Introducing Light: A Comprehensive Library for Interactive Applications
                </Title>
                <Space GapSm align={"center"}>
                    <small>Written by <strong>OakFrame Interactive</strong></small>
                    &bull;
                    <small className="muted">© 2024</small>
                </Space>

                <Paragraph>
                    **Light** is a Node.js and npm package designed to simplify the creation of interactive
                    experiences. Inspired by platforms like Game Maker, it offers built-in functionality for handling
                    objects, events, rendering, state management, and more. Developers can use Light to build games,
                    interactive media, or applications with intuitive APIs and seamless React integration.
                </Paragraph>

                <h2>Using Globals in Light</h2>
                <Paragraph>
                    Light includes several global objects to simplify access to core systems such as `game`, `player`, `camera`, and `world`. These globals allow developers to interact with essential functionality without having to manually manage complex dependencies. For example:
                </Paragraph>

                <Card Pad Full>
                    <Paragraph>
                        <pre>
                            {`// Accessing the player object
game.player.setPosition(mouse.x, mouse.y);

// Handling a room restart
game.room.restart();

// Updating a global score
game.state.update("score", game.state.get("score") + 1);`}
                        </pre>
                    </Paragraph>
                </Card>

                <Divider />

                <h3>Example Game: Player, Enemy, Goal</h3>
                <Paragraph>
                    Below is an example game setup using Light's API. The game includes three objects: `player`, `enemy`, and `goal`. The player moves towards the mouse pointer, enemies move in random directions and change direction upon collision with walls, and reaching the goal increases the score and restarts the room. Colliding with an enemy ends the game.
                </Paragraph>

                <Card Pad Full>
                    <Paragraph>
                        <pre>
                            {`// Define player behavior
const player = new LightObject({
    name: "player",
    onStep: () => {
        const { x, y } = game.input.getMousePosition();
        player.moveTowards(x, y, 5); // Move towards mouse at speed 5
    },
    onCollision: (other) => {
        if (other.name === "goal") {
            game.state.update("score", game.state.get("score") + 1);
            game.room.restart();
        } else if (other.name === "enemy") {
            game.room.change("GameOver");
        }
    },
});

// Define enemy behavior
const enemy = new LightObject({
    name: "enemy",
    onCreate: () => {
        enemy.setVelocity(Math.random() * 2 - 1, Math.random() * 2 - 1); // Random direction
    },
    onStep: () => {
        if (enemy.isTouchingEdge()) {
            enemy.reverseDirection();
        }
    },
});

// Define goal behavior
const goal = new LightObject({
    name: "goal",
    onCreate: () => {
        goal.setPosition(Math.random() * game.room.width, Math.random() * game.room.height);
    },
});

// Initialize game room
game.room.add(player);
game.room.add(enemy);
game.room.add(goal);

// Start game
game.start();`}
                        </pre>
                    </Paragraph>
                </Card>

                <Divider />

                <h3>Conclusion</h3>
                <Paragraph>
                    Light's globals and object-based APIs make it easy to create dynamic games and applications. The ability to manage rooms, score, and interactions directly through these tools ensures a smooth development process. Try out Light today and start building your next interactive experience!
                </Paragraph>

                <Button type="primary" onClick={() => setModalVisible(true)}>
                    Learn More About Light Library
                </Button>

                <Modal
                    visible={modalVisible}
                    title="Light Library Overview"
                    content={
                        <Space direction={"vertical"} Gap>
                            <Paragraph>
                                The Light Library includes tools for asset management, scene design, and real-time event
                                handling, making it a comprehensive solution for interactive applications.
                            </Paragraph>
                        </Space>
                    }
                    onClose={() => setModalVisible(false)}
                />
            </Space>
        </Content>
    );
};
