

// Helper function to create triangles
import {Vec3, Vec3Interface} from "./Vec3";
import {RGBALike} from "../component/part/RGBEditor";
import {OakMesh, OakMeshEditorObjectItem, OakMeshEditorObjectItemType, OakMeshTriangleData} from "./OakObject";
import {ClientRenderingOptions} from "./RemotePLYLoader";
import {bias} from "./Utils";

const createTriangle = (
    v1: Vec3Interface, v2: Vec3Interface, v3: Vec3Interface,
    color: RGBALike, material: number
): OakMeshTriangleData => ({
    vertex1: v1,
    vertex2: v2,
    vertex3: v3,
    color1: color,
    color2: color,
    color3: color,
    material,
});

const lerp = (start:number, end:number, amount:number)=>{
    return (start + ((end - start) * amount));
}

export interface TriangleCountInterface {
    upper:number; lower:number
}

export const calulateResolutionIncreaseWithSettings = (start:number, r:number):number=>{
    return lerp(start, start*16, r);
}

const shapeSphereStacksUpper = 8; // vertical
const shapeSphereSlicesUpper = 14; //horizonal

export const getTriangleCountForOakMeshChild = (m: OakMesh, index: number): TriangleCountInterface => {
    let upper = 0, lower = 0;
    let slices = 0, stacks = 0;

    const child: OakMeshEditorObjectItem = m.children[index];
    if (child.type === OakMeshEditorObjectItemType.NGON) {
        upper = child.triangles.length;
        lower = child.triangles.length;
    } else {
        switch (child.type) {
            case OakMeshEditorObjectItemType.SPHERE:
                stacks = Math.floor(lerp(2, shapeSphereStacksUpper, bias(0.2, 0))) - 1; // Vertical slices
                slices = Math.floor(lerp(3, shapeSphereSlicesUpper, bias(0.2, 0))); // Horizontal slices
                lower = stacks * slices * 2;
                stacks = Math.floor(lerp(2, shapeSphereStacksUpper, bias(0.2, 1))) - 1;
                slices = Math.floor(lerp(3, shapeSphereSlicesUpper, bias(0.2, 1)));
                upper = stacks * slices * 2;
                break;

            case OakMeshEditorObjectItemType.CYLINDER:
                slices = Math.floor(lerp(3, shapeSphereSlicesUpper, bias(0.2, 0))) - 1; // Vertical divisions
                lower = slices * 4;
                slices = Math.floor(lerp(3, shapeSphereSlicesUpper, bias(0.2, 1)));
                upper = slices * 4;
                break;

            case OakMeshEditorObjectItemType.CONE:
                slices = Math.floor(lerp(3, shapeSphereSlicesUpper, bias(0.2, 0))); // Number of base slices
                lower = slices * 2; // Base and sides
                slices = Math.floor(lerp(3, shapeSphereSlicesUpper, bias(0.2, 1)));
                upper = slices * 2;
                break;

            case OakMeshEditorObjectItemType.PLANE:
                lower = 2; // Two triangles for a single plane
                upper = 2;
                break;

            case OakMeshEditorObjectItemType.CUBE:
                lower = 12; // Two triangles for a single plane
                upper = 12;
                break;

            case OakMeshEditorObjectItemType.OCTAHEDRON:
                lower = 8; // Two pyramids, each with four faces
                upper = 8;
                break;

            default:
                console.warn(`Primitive type "${child.type}" is not supported.`);
        }
    }

    return { upper, lower };
};


// Function to generate the triangle mesh for a given primitive
export const generateTrianglesForPrimitive = (type: OakMeshEditorObjectItemType, scale: Vec3Interface, position: Vec3Interface, rotation: Vec3Interface, material: number, color: RGBALike, renderingOptions?:ClientRenderingOptions): OakMeshTriangleData[] => {
    const triangles: OakMeshTriangleData[] = [];
    const { x: sx, y: sy, z: sz } = scale;

    const meshQuality = renderingOptions?.USER_OPTION_COMPATIBILITY_MODE?0:(!renderingOptions||isNaN(renderingOptions?.USER_SETTING_MESH_QUALITY))?0.75:renderingOptions?.USER_SETTING_MESH_QUALITY;

    switch (type) {
        case OakMeshEditorObjectItemType.CUBE: {
            // Define vertices for a cube centered at origin, scaled, and translated
            const vertices = [
                new Vec3().set(-sx / 2, -sy / 2, -sz / 2),
                new Vec3().set(sx / 2, -sy / 2, -sz / 2),
                new Vec3().set(sx / 2, sy / 2, -sz / 2),
                new Vec3().set(-sx / 2, sy / 2, -sz / 2),
                new Vec3().set(-sx / 2, -sy / 2, sz / 2),
                new Vec3().set(sx / 2, -sy / 2, sz / 2),
                new Vec3().set(sx / 2, sy / 2, sz / 2),
                new Vec3().set(-sx / 2, sy / 2, sz / 2),
            ];

            // Apply translation and rotation
            vertices.forEach((v) => {
                v.add(position); // Translate
                // Apply rotation if needed
            });

            // Define triangles for the cube faces
            const faces = [
                [1,0, 2], [2,0, 3], // Bottom
                [4, 5, 6], [4, 6, 7], // Top
                [0, 1, 5], [0, 5, 4], // Front
                [2, 3, 7], [2, 7, 6], // Back
                [1, 2, 6], [1, 6, 5], // Right -x
                [3, 0, 7], [7, 0, 4], // Left +x
            ];

            faces.forEach(([i1, i2, i3]) => {
                triangles.push(createTriangle(vertices[i1], vertices[i2], vertices[i3], color, material));
            });
            break;
        }

        case OakMeshEditorObjectItemType.SPHERE: {
            const stacks = Math.ceil(lerp(2, shapeSphereStacksUpper, bias(0.2,meshQuality))); // Number of vertical slices
            const slices = Math.ceil(lerp(4, shapeSphereSlicesUpper, bias(0.2,meshQuality))); // Number of horizontal slices
            const radius = Math.max(sx, sy, sz) / 2;

            for (let i = 0; i < stacks; i++) {
                const theta1 = (i / stacks) * Math.PI;
                const theta2 = ((i + 1) / stacks) * Math.PI;

                for (let j = 0; j < slices; j++) {
                    const phi1 = (j / slices) * 2 * Math.PI;
                    const phi2 = ((j + 1) / slices) * 2 * Math.PI;

                    const v1 = new Vec3().set(
                        radius * Math.sin(theta1) * Math.cos(phi1),
                        radius * Math.sin(theta1) * Math.sin(phi1),
                        radius * Math.cos(theta1)
                    ).add(position);

                    const v2 = new Vec3().set(
                        radius * Math.sin(theta2) * Math.cos(phi1),
                        radius * Math.sin(theta2) * Math.sin(phi1),
                        radius * Math.cos(theta2)
                    ).add(position);

                    const v3 = new Vec3().set(
                        radius * Math.sin(theta2) * Math.cos(phi2),
                        radius * Math.sin(theta2) * Math.sin(phi2),
                        radius * Math.cos(theta2)
                    ).add(position);

                    const v4 = new Vec3().set(
                        radius * Math.sin(theta1) * Math.cos(phi2),
                        radius * Math.sin(theta1) * Math.sin(phi2),
                        radius * Math.cos(theta1)
                    ).add(position);

                    triangles.push(createTriangle(v1, v2, v3, color, material));
                    triangles.push(createTriangle(v1, v3, v4, color, material));
                }
            }
            break;
        }

        case OakMeshEditorObjectItemType.ICOSPHERE: {
            const radius = Math.max(sx, sy, sz) / 2;

            // Helper function to normalize a vector
            const normalize = (vec:Vec3) => vec.normalize().mulI(radius).add(position);

            // Generate base icosahedron vertices
            const t = (1 + Math.sqrt(5)) / 2;
            const icosahedronVertices = [
                new Vec3().set(-1,  t,  0), new Vec3().set( 1,  t,  0), new Vec3().set(-1, -t,  0), new Vec3().set( 1, -t,  0),
                new Vec3().set( 0, -1,  t), new Vec3().set( 0,  1,  t), new Vec3().set( 0, -1, -t), new Vec3().set( 0,  1, -t),
                new Vec3().set( t,  0, -1), new Vec3().set( t,  0,  1), new Vec3().set(-t,  0, -1), new Vec3().set(-t,  0,  1)
            ];

            // Normalize vertices to form a unit sphere
            for (let i = 0; i < icosahedronVertices.length; i++) {
                icosahedronVertices[i] = normalize(icosahedronVertices[i]);
            }

            // Define base icosahedron faces
            const icosahedronFaces = [
                [0, 11, 5], [0, 5, 1], [0, 1, 7], [0, 7, 10], [0, 10, 11],
                [1, 5, 9], [5, 11, 4], [11, 10, 2], [10, 7, 6], [7, 1, 8],
                [3, 9, 4], [3, 4, 2], [3, 2, 6], [3, 6, 8], [3, 8, 9],
                [4, 9, 5], [2, 4, 11], [6, 2, 10], [8, 6, 7], [9, 8, 1]
            ];

            // Recursive subdivision function
            const subdivide = (v1:Vec3, v2:Vec3, v3:Vec3, depth:number) => {
                if (depth === 0) {
                    triangles.push(createTriangle(v1, v2, v3, color, material));
                    return;
                }

                const v12 = normalize(new Vec3().copy(v1).lerp( v2, 0.5));
                const v23 = normalize(new Vec3().copy(v2).lerp(v3, 0.5));
                const v31 = normalize(new Vec3().copy(v3).lerp( v1, 0.5));

                subdivide(v1, v12, v31, depth - 1);
                subdivide(v2, v23, v12, depth - 1);
                subdivide(v3, v31, v23, depth - 1);
                subdivide(v12, v23, v31, depth - 1);
            };

            // Subdivision depth based on mesh quality
            const subdivisionDepth = Math.ceil(lerp(0, 2, bias(0.5, meshQuality)));

            // Subdivide faces
            for (const face of icosahedronFaces) {
                const v1 = icosahedronVertices[face[0]];
                const v2 = icosahedronVertices[face[1]];
                const v3 = icosahedronVertices[face[2]];
                subdivide(v1, v2, v3, subdivisionDepth);
            }

            break;
        }

        case OakMeshEditorObjectItemType.CYLINDER: {
            const slices = Math.ceil(lerp(4, shapeSphereSlicesUpper, bias(0.2,meshQuality))); // Number of vertical divisions
            const height = sz;
            const radius = Math.max(sx, sy) / 2;

            // Generate vertices for top and bottom circles
            const topVertices = [];
            const bottomVertices = [];
            for (let i = 0; i < slices; i++) {
                const angle = (i / slices) * 2 * Math.PI;
                const x = radius * Math.cos(angle);
                const y = radius * Math.sin(angle);
                topVertices.push(new Vec3().set(x, y, height / 2).add(position));
                bottomVertices.push(new Vec3().set(x, y, -height / 2).add(position));
            }

            // Generate triangles for top and bottom caps
            for (let i = 0; i < slices; i++) {
                const next = (i + 1) % slices;
                const topCenter = new Vec3().set(0, 0, height / 2).add(position);
                const bottomCenter = new Vec3().set(0, 0, -height / 2).add(position);

                triangles.push(createTriangle(topCenter, topVertices[i], topVertices[next], color, material));
                triangles.push(createTriangle(bottomCenter, bottomVertices[next], bottomVertices[i], color, material));
            }

            // Generate triangles for the sides
            for (let i = 0; i < slices; i++) {
                const next = (i + 1) % slices;

                triangles.push(createTriangle(topVertices[i], bottomVertices[i], bottomVertices[next], color, material));
                triangles.push(createTriangle(topVertices[i], bottomVertices[next], topVertices[next], color, material));
            }
            break;
        }

        case OakMeshEditorObjectItemType.CONE: {
            const slices = Math.ceil(lerp(4, shapeSphereSlicesUpper, bias(0.2, meshQuality))); // Number of slices
            const height = sz;
            const radius = Math.max(sx, sy) / 2;

            // Generate vertices for the base
            const baseVertices = [];
            for (let i = 0; i < slices; i++) {
                const angle = (i / slices) * 2 * Math.PI;
                const x = radius * Math.cos(angle);
                const y = radius * Math.sin(angle);
                baseVertices.push(new Vec3().set(x, y, -height / 2).add(position));
            }

            const apex = new Vec3().set(0, 0, height / 2).add(position);
            const baseCenter = new Vec3().set(0, 0, -height / 2).add(position);

            // Generate triangles for the base
            for (let i = 0; i < slices; i++) {
                const next = (i + 1) % slices;
                triangles.push(createTriangle(baseCenter, baseVertices[next], baseVertices[i], color, material));
            }

            // Generate triangles for the sides
            for (let i = 0; i < slices; i++) {
                const next = (i + 1) % slices;
                triangles.push(createTriangle(apex, baseVertices[i], baseVertices[next], color, material));
            }
            break;
        }

        case OakMeshEditorObjectItemType.PLANE: {
            const halfWidth = sx / 2;
            const halfHeight = sy / 2;

            // Define vertices for the plane
            const vertices = [
                new Vec3().set(-halfWidth, -halfHeight, 0).add(position),
                new Vec3().set(halfWidth, -halfHeight, 0).add(position),
                new Vec3().set(halfWidth, halfHeight, 0).add(position),
                new Vec3().set(-halfWidth, halfHeight, 0).add(position),
            ];

            // Generate two triangles for the plane
            triangles.push(createTriangle(vertices[0], vertices[1], vertices[2], color, material));
            triangles.push(createTriangle(vertices[0], vertices[2], vertices[3], color, material));
            break;
        }

        case OakMeshEditorObjectItemType.OCTAHEDRON: {
            const radius = Math.max(sx, sy, sz) / 2;

            const top = new Vec3().set(0, 0, radius).add(position);
            const bottom = new Vec3().set(0, 0, -radius).add(position);

            const vertices = [
                new Vec3().set(radius, 0, 0).add(position),
                new Vec3().set(-radius, 0, 0).add(position),
                new Vec3().set(0, radius, 0).add(position),
                new Vec3().set(0, -radius, 0).add(position),
            ];

            // Generate triangles for the upper pyramid
            triangles.push(createTriangle(top, vertices[0], vertices[2], color, material));
            triangles.push(createTriangle(top, vertices[2], vertices[1], color, material));
            triangles.push(createTriangle(top, vertices[1], vertices[3], color, material));
            triangles.push(createTriangle(top, vertices[3], vertices[0], color, material));

            // Generate triangles for the lower pyramid
            triangles.push(createTriangle(bottom, vertices[2], vertices[0], color, material));
            triangles.push(createTriangle(bottom, vertices[1], vertices[2], color, material));
            triangles.push(createTriangle(bottom, vertices[3], vertices[1], color, material));
            triangles.push(createTriangle(bottom, vertices[0], vertices[3], color, material));
            break;
        }


        default:
            console.warn(`Primitive type "${type}" is not supported.`);
    }

    return triangles;
};
