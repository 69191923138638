import React, {FC, useState, useEffect, useCallback, ReactNode} from "react";
import { Button, Content, DropDown, Paragraph, Space, Title } from "../layout/Content";
import {FullScreenApp} from "../KeyPiece";
import {faStickyNote} from "@fortawesome/free-solid-svg-icons";
import {APP_NOTEPAD, BaseApp} from "./BaseApp";

interface WorkingDocument {
    name: string;
    data: string;
    originalData: string; // to track changes
}

const STORAGE_KEY = "notepad_documents";

export class NotepadAppClass extends BaseApp {
    static id = APP_NOTEPAD;
    static title = "Notepad";
    static icon = faStickyNote;

    static getComponent(onClose: () => void, onMinimize: () => void): ReactNode {
        return <NotepadApp onClose={onClose} onMinimize={onMinimize} />;
    }
}


const NotepadApp: FC<{ onClose?: () => void; onMinimize?: () => void }> = ({ onClose, onMinimize }) => {
    const [workingDocuments, setWorkingDocuments] = useState<WorkingDocument[]>([]);
    const [currentDocName, setCurrentDocName] = useState<string>("");

    const [showNewModal, setShowNewModal] = useState(false);
    const [showOpenModal, setShowOpenModal] = useState(false);
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [showDuplicateModal, setShowDuplicateModal] = useState(false);

    // States for handling unsaved changes on close
    const [closeInProgress, setCloseInProgress] = useState(false);
    const [unsavedDocsQueue, setUnsavedDocsQueue] = useState<WorkingDocument[]>([]);
    const [currentUnsavedIndex, setCurrentUnsavedIndex] = useState(0);
    // When > 0 means we are prompting user about unsaved changes

    const getDocumentFromName = useCallback((name: string): WorkingDocument | undefined => {
        return workingDocuments.find((doc) => doc.name === name);
    }, [workingDocuments]);

    // Load documents from local storage on mount
    useEffect(() => {
        const stored = localStorage.getItem(STORAGE_KEY);
        if (stored) {
            try {
                const docs: { name: string; data: string }[] = JSON.parse(stored);
                const loadedDocs = docs.map((doc) => ({
                    name: doc.name,
                    data: doc.data,
                    originalData: doc.data
                }));
                setWorkingDocuments(loadedDocs);
                if (loadedDocs.length > 0) {
                    setCurrentDocName(loadedDocs[0].name);
                } else {
                    const newDoc = { name: "Untitled Document", data: "", originalData: "" };
                    setWorkingDocuments([newDoc]);
                    setCurrentDocName(newDoc.name);
                }
            } catch (e) {
                console.error("Failed to parse notepad documents from storage:", e);
                const newDoc = { name: "Untitled Document", data: "", originalData: "" };
                setWorkingDocuments([newDoc]);
                setCurrentDocName(newDoc.name);
            }
        } else {
            // No docs stored, create a default one
            const newDoc = { name: "Untitled Document", data: "", originalData: "" };
            setWorkingDocuments([newDoc]);
            setCurrentDocName(newDoc.name);
        }
    }, []);

    // Save documents to localStorage
    const saveDocuments = useCallback(() => {
        const toStore = workingDocuments.map(doc => ({ name: doc.name, data: doc.data }));
        localStorage.setItem(STORAGE_KEY, JSON.stringify(toStore));
    }, [workingDocuments]);

    const handleDocumentChange = (docName: string, newData: string) => {
        setWorkingDocuments(docs => docs.map(doc => {
            if (doc.name === docName) {
                return { ...doc, data: newData };
            }
            return doc;
        }));
    };

    const handleNew = () => {
        const newName = "Untitled Document " + (workingDocuments.length + 1);
        const newDoc = { name: newName, data: "", originalData: "" };
        setWorkingDocuments([...workingDocuments, newDoc]);
        setCurrentDocName(newName);
        setShowNewModal(false);
    };

    const handleOpen = () => {
        // Just close modal as a placeholder
        setShowOpenModal(false);
    };

    const handleSave = () => {
        saveDocuments();
        // After saving, update originalData to match current data
        setWorkingDocuments(docs => docs.map(doc => ({ ...doc, originalData: doc.data })));
        setShowSaveModal(false);
    };

    const handleDuplicate = () => {
        const currentDoc = getDocumentFromName(currentDocName);
        if (currentDoc) {
            const duplicateName = currentDoc.name + " Copy";
            const newDoc = { name: duplicateName, data: currentDoc.data, originalData: currentDoc.data };
            setWorkingDocuments([...workingDocuments, newDoc]);
            setCurrentDocName(duplicateName);
        }
        setShowDuplicateModal(false);
    };

    // Attempt to close logic
    const attemptClose = () => {
        // Check if any doc has unsaved changes
        const changedDocs = workingDocuments.filter(doc => doc.data !== doc.originalData);
        if (changedDocs.length === 0) {
            // No unsaved docs, close immediately
            if (onClose){onClose();}
        } else {
            // Unsaved docs exist, show the modal for the first one
            setUnsavedDocsQueue(changedDocs);
            setCurrentUnsavedIndex(0);
            setCloseInProgress(true);
        }
    };

    const handleCloseRequest = () => {
        attemptClose();
    };

    const currentDoc = getDocumentFromName(currentDocName);

    // Handlers for the unsaved changes modal
    const handleUnsavedSave = () => {
        // User chose to save changes for this doc
        const doc = unsavedDocsQueue[currentUnsavedIndex];
        // Update originalData = data for that doc
        setWorkingDocuments(docs => docs.map(d => d.name === doc.name ? { ...d, originalData: d.data } : d));
        proceedToNextUnsavedDoc();
    };

    const handleUnsavedDiscard = () => {
        // User chose to discard changes for this doc
        const doc = unsavedDocsQueue[currentUnsavedIndex];
        // Revert data to originalData
        setWorkingDocuments(docs => docs.map(d => d.name === doc.name ? { ...d, data: d.originalData } : d));
        proceedToNextUnsavedDoc();
    };

    const handleUnsavedCancel = () => {
        // Cancel the close process entirely
        setCloseInProgress(false);
        setUnsavedDocsQueue([]);
        setCurrentUnsavedIndex(0);
    };

    const proceedToNextUnsavedDoc = () => {
        if (currentUnsavedIndex + 1 < unsavedDocsQueue.length) {
            // More unsaved docs to handle
            setCurrentUnsavedIndex(currentUnsavedIndex + 1);
        } else {
            // All unsaved docs handled, now we can actually close
            setCloseInProgress(false);
            setUnsavedDocsQueue([]);
            setCurrentUnsavedIndex(0);
            // Now all changes are resolved, proceed with close
            if (onClose){ onClose();}
        }
    };

    const menuContent = (
        <Content>
            <div style={{ cursor: "pointer" }} onClick={() => setShowNewModal(true)}>New</div>
            <div style={{ cursor: "pointer" }} onClick={() => setShowOpenModal(true)}>Open</div>
            <div style={{ cursor: "pointer" }} onClick={() => setShowSaveModal(true)}>Save</div>
            <div style={{ cursor: "pointer" }} onClick={() => setShowDuplicateModal(true)}>Duplicate</div>
        </Content>
    );

    return (
        <div style={{padding: 20}}>
            <Space justify={"end"}>
                <DropDown children={menuContent} />
            </Space>



            {/* Document List */}
            <div style={{ display: "flex", gap: "10px" }}>
                <div style={{ borderRight: "1px solid #ccc", paddingRight: "10px", minWidth: "200px" }}>
                    <Title>Open Documents</Title>
                    <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                        {workingDocuments.map(doc => {
                            const changed = doc.data !== doc.originalData;
                            const displayedName = changed ? doc.name + "*" : doc.name;
                            return (
                                <Button
                                    key={doc.name}
                                    type={doc.name === currentDocName ? "primary" : "ghost"}
                                    onClick={() => setCurrentDocName(doc.name)}
                                >
                                    {displayedName}
                                </Button>
                            );
                        })}
                    </div>
                </div>

                {/* Editor Area */}
                <div style={{ flex: "1" }}>
                    {currentDoc && (
                        <textarea
                            style={{ width: "100%", height: "70vh" }}
                            value={currentDoc.data}
                            onChange={(e) => handleDocumentChange(currentDoc.name, e.target.value)}
                        />
                    )}
                    {!currentDoc && (
                        <Paragraph>Select a document to start editing.</Paragraph>
                    )}
                </div>
            </div>

            {/* Simple Modals */}
            {showNewModal && (
                <div style={modalStyle}>
                    <Content Pad>
                        <Title>New Document</Title>
                        <Paragraph>Create a new blank document?</Paragraph>
                        <Space>
                            <Button type="primary" onClick={handleNew}>Yes</Button>
                            <Button onClick={() => setShowNewModal(false)}>Cancel</Button>
                        </Space>
                    </Content>
                </div>
            )}

            {showOpenModal && (
                <div style={modalStyle}>
                    <Content Pad>
                        <Title>Open Document</Title>
                        <Paragraph>In a real scenario, you'd pick a file here. Just click "Close" to simulate.</Paragraph>
                        <Space>
                            <Button onClick={handleOpen}>Close</Button>
                        </Space>
                    </Content>
                </div>
            )}

            {showSaveModal && (
                <div style={modalStyle}>
                    <Content Pad>
                        <Title>Save Documents</Title>
                        <Paragraph>Save current documents to localStorage?</Paragraph>
                        <Space>
                            <Button type="primary" onClick={handleSave}>Save</Button>
                            <Button onClick={() => setShowSaveModal(false)}>Cancel</Button>
                        </Space>
                    </Content>
                </div>
            )}

            {showDuplicateModal && (
                <div style={modalStyle}>
                    <Content Pad>
                        <Title>Duplicate Document</Title>
                        <Paragraph>Duplicate the current document?</Paragraph>
                        <Space>
                            <Button type="primary" onClick={handleDuplicate}>Duplicate</Button>
                            <Button onClick={() => setShowDuplicateModal(false)}>Cancel</Button>
                        </Space>
                    </Content>
                </div>
            )}

            {closeInProgress && unsavedDocsQueue.length > 0 && (
                <div style={modalStyle}>
                    <Content Pad>
                        <Title>Unsaved Changes</Title>
                        <Paragraph>
                            The document "{unsavedDocsQueue[currentUnsavedIndex].name}" has unsaved changes.
                            Would you like to Save, Discard changes, or Cancel closing?
                        </Paragraph>
                        <Space>
                            <Button type="primary" onClick={handleUnsavedSave}>Save</Button>
                            <Button type="danger" onClick={handleUnsavedDiscard}>Discard</Button>
                            <Button onClick={handleUnsavedCancel}>Cancel</Button>
                        </Space>
                    </Content>
                </div>
            )}
        </div>
    );
};

export { NotepadApp };

const modalStyle: React.CSSProperties = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "#ffffff",
    border: "1px solid #ccc",
    zIndex: 2000,
    padding: "10px"
};
