import React, { FC, useState, useEffect, ReactNode } from "react";
import { Button, Content, Space, Title, Paragraph, Input } from "../layout/Content";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { APP_WIKI, BaseApp } from "./BaseApp";
import { FullScreenApp } from "../KeyPiece";
import {Breadcrumb} from "../layout/Defaults";

// Define the Wiki data structure
export type WikiData = Record<string, string | any>;

const WIKI_DATA: WikiData = {
    Science: {
        "Animal Kingdom": {
            Mammals: "Mammals are warm-blooded vertebrates that have hair and produce milk.",
            Reptiles: "Reptiles are cold-blooded vertebrates that lay eggs and have scales.",
        },
        Plants: {
            "Flowering Plants": "Flowering plants produce seeds enclosed within a fruit.",
            "Non-Flowering Plants": "Non-flowering plants reproduce through spores or other methods.",
        },
    },
    Math: {
        Arithmetic: "Arithmetic is the branch of mathematics dealing with numbers and operations.",
        Algebra: "Algebra involves the study of symbols and the rules for manipulating them.",
        Calculus: "Calculus focuses on change, with concepts like derivatives and integrals.",
        Trigonometry: "Trigonometry studies relationships between the angles and sides of triangles.",
    },
    Arts: {
        Painting: "Painting is the practice of applying pigment to a surface to create art.",
        Music: "Music is the art of arranging sounds in time to produce melody, harmony, and rhythm.",
    },
    "History & Geography": {
        Prehistory: "Prehistory refers to the time before written records were kept.",
        "Ancient History": "Ancient history covers the time period from the beginning of writing to the early Middle Ages.",
        "Modern History": "Modern history spans from the late Middle Ages to the present.",
    },
};

class WikiAppClass extends BaseApp {
    static id = APP_WIKI;
    static title = "Wiki";
    static icon = faBook;

    static getComponent(onClose: () => void, onMinimize: () => void): ReactNode {
        return <WikiApp onClose={onClose} onMinimize={onMinimize} />;
    }
}

const WikiApp: FC<{ onClose: () => void; onMinimize: () => void }> = ({ onClose, onMinimize }) => {
    const [currentPath, setCurrentPath] = useState<string[]>([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredResults, setFilteredResults] = useState<string[]>([]);

    const getCurrentData = (): WikiData | string => {
        let data: WikiData | string = WIKI_DATA;
        for (const key of currentPath) {
            if (typeof data === "object" && key in data) {
                data = data[key] as WikiData | string;
            }
        }
        return data;
    };

    useEffect(() => {
        setFilteredResults([]);
        setSearchTerm("");
    }, [currentPath]);

    const handleSearch = (term: string) => {
        const results: string[] = [];
        const search = (data: WikiData, path: string[] = []) => {
            for (const key in data) {
                if (typeof data[key] === "string") {
                    if (
                        key.toLowerCase().includes(term.toLowerCase()) ||
                        (data[key] as string).toLowerCase().includes(term.toLowerCase())
                    ) {
                        results.push([...path, key].join(" > "));
                    }
                } else {
                    search(data[key] as WikiData, [...path, key]);
                }
            }
        };
        search(WIKI_DATA);
        setFilteredResults(results);
    };

    const currentData = getCurrentData();

    return (
        <FullScreenApp title="Wiki" onClose={onClose} onMinimize={onMinimize}>
            <Content>
                <Space direction="vertical" style={{ width: "100%" }} Gap>
                    <Title>
                        {currentPath.length === 0 ? "" : currentPath.join(" > ")}
                    </Title>
                    <Breadcrumb items={currentPath}/>
                    <Space GapSm Wide Stretch>
                        {currentPath.length > 0 && (
                        <div style={{flex:"0 0 auto"}}><Button onClick={() => setCurrentPath(currentPath.slice(0, -1))}>
                            Back
                        </Button></div>
                    )}
                    <Input
                        value={searchTerm}
                        onChange={(e) => {
                            const searchTerm = (e.target.value);
                        setSearchTerm(searchTerm);
                            handleSearch(searchTerm);
                        }}
                        placeholder="Search..."
                        style={{ width: "100%" }}
                        type="text"
                    />
                    </Space>
                    {searchTerm && filteredResults.length > 0 && (
                        <Content>
                            <Title>Search Results</Title>
                            <ul>
                                {filteredResults.map((result, index) => (
                                    <li key={index}>{result}</li>
                                ))}
                            </ul>
                        </Content>
                    )}
                    {searchTerm && filteredResults.length === 0 && (
                        <Paragraph>No results found.</Paragraph>
                    )}
                    {!searchTerm && (
                        <Content>
                            {typeof currentData === "string" ? (
                                <Paragraph>{currentData}</Paragraph>
                            ) : (
                                <ul>
                                    {Object.keys(currentData).map((key) => (
                                        <li key={key}>
                                            <span
                                                onClick={() => setCurrentPath([...currentPath, key])}
                                            >
                                               <Title> {key}</Title>
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </Content>
                    )}
                </Space>
            </Content>
        </FullScreenApp>
    );
};

export { WikiAppClass, WikiApp };
