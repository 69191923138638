import {Vec3, Vec3Interface} from "./Vec3";
import {RGBALike} from "./RGBA";
import {
    faArrowsToCircle,
    faCircle,
    faCircleNodes,
    faCube, faDatabase,
    faDiamond, faGripLinesVertical, faIcons, faPlay,
    faQuestionCircle,
    faVectorSquare
} from "@fortawesome/free-solid-svg-icons";
import {fixPrecision} from "../lib/Strings";
import {generateTrianglesForPrimitive, TriangleCountInterface} from "./GetTrianglesForPrimitive";
import {FC} from "react";
import {ClientRenderingOptions} from "./RemotePLYLoader";
import {colorPLYData, joinPLYData, PLYData, PLYObject, rotatePLYData, scalePLYData, translatePLYData} from "./PLY";

export enum OakMeshEditorObjectItemType{
    NONE=0,
    VERTEX=1,
    EDGE=2,
    TRIANGLE=3,
    NGON=4,
    PLANE=5,
    CONE=6,
    CUBE=7,
    OCTAHEDRON=8,
    CYLINDER=9,
    SPHERE=10,
    ICOSPHERE=11,
}

export const OakMeshEditorObjectItemTypeNamesSimple=["none","vertex","edge","triangle","ngon","plane","cone","cube","octahedron","cylinder","sphere","icosphere"];
export const OakMeshEditorObjectItemTypeNames=["None","Vertex","Edge","Triangle",<span><em>n</em><span className={"muted"}>-</span>Gon</span>,"Plane","Cone","Cube","Octahedron","Cylinder","Sphere","IcoScphere"];
export const OakMeshEditorObjectItemTypeIcons=[faQuestionCircle,faArrowsToCircle,faGripLinesVertical,faCircleNodes,faIcons,faDiamond,faPlay,faCube,faQuestionCircle,faDatabase,faCircle,faCircle];

export interface OakMeshEditorObjectItem {
    name?:string;
    type:OakMeshEditorObjectItemType;
    color?:RGBALike;
    scale: Vec3Interface;
    translation: Vec3Interface;
    rotation: Vec3Interface;
    plyData?:PLYData;
    triangles: OakMeshTriangleData[];
    visible:boolean;
    material: number;
}

export interface OakMeshTriangleData {
    vertex1: Vec3Interface;
    vertex2: Vec3Interface;
    vertex3: Vec3Interface;
    color1: RGBALike; // Per-vertex color
    color2: RGBALike;
    color3: RGBALike;
    material: number;
}

export interface OakMeshEditorData {
    uuid?: string;
    children: OakMeshEditorObjectItem[];
    name: string;
    data?: PLYData;
}

export async function decodeOakMFile(oakmContent: string): Promise<OakMesh> {
    const lines = oakmContent.split("\n").map(line => line.trim());
   // const children: OakMeshEditorObjectItem[] = [];

    // Parse the file header
    if (!lines[0].startsWith("oakm")) {
        throw new Error("Invalid oakm file: Missing header");
    }
    if (!lines[1].startsWith("format ascii 1.0")) {
        throw new Error("Unsupported oakm format version");
    }

    // Create an OakMesh with the parsed children
    const oakMesh = new OakMesh(undefined,);

    // Parse each primitive
    for (const line of lines.slice(2)) {
        if (!line) continue; // Skip empty lines

        const parts = line.split(/\s+/);
        //@ts-ignore
        const stringType:string = (parts[0]); // "cube", "cylinder", etc.
        const type:OakMeshEditorObjectItemType = (OakMeshEditorObjectItemTypeNamesSimple.indexOf(stringType)); // "cube", "cylinder", etc.
        const name = parts[1].replace(/"/g, ""); // Strip quotes from the name

        if (![OakMeshEditorObjectItemType.CUBE, OakMeshEditorObjectItemType.CYLINDER, OakMeshEditorObjectItemType.SPHERE].includes(type)) {
            console.warn(`Unsupported primitive type: ${type}. Skipping.`);
            continue;
        }

        // Parse properties
        const [
            x, y, z, // Translation
            rx, ry, rz, // Rotation
            sx, sy, sz, // Scale
            material, // Material index
            red, green, blue, alpha, // Color
        ] = parts.slice(2).map(parseFloat);

        // Create a new child object
        const newChild: OakMeshEditorObjectItem = {
            name,
            plyData: undefined, // Assuming primitives don't directly have PLY data
            translation: new Vec3().set(x, y, z),
            rotation: new Vec3().set(rx, ry, rz),
            scale: new Vec3().set(sx, sy, sz),
            color: {r:red, g:green, b:blue, a:alpha},
            type,
            triangles: [], // You can calculate triangles if needed later
            visible: true,
            material: material
        };

        // Add the child to the list
        oakMesh.children.push(newChild);
    }

    return oakMesh;
}

export class OakMesh implements OakMeshEditorData {


    name = "untitled mesh";
    triangles: OakMeshTriangleData[] = [];
    children: OakMeshEditorObjectItem[] = [];
    data?: PLYData;
    uuid?: string;

    /**
     * Constructs an OakMesh instance from PLYData
     */
    constructor(plyData?: PLYData, oakMesh?: OakMeshEditorData) {
        if (oakMesh) {
            this.uuid = oakMesh.uuid;
            this.name = oakMesh.name;
            this.children = oakMesh.children||[];
        }

        if (plyData) {
           // this.data = plyData;
            this.constructFromPLYData(plyData);
            //console.log(plyData);
        }
    }

    getOakMesh(): OakMeshEditorData {
        return {
            name: this.name,
            children: this.children
        }
    }

    getTriangles():OakMeshTriangleData[]{
        const tris:OakMeshTriangleData[] = [];

        this.children.forEach((obj:OakMeshEditorObjectItem, index)=>{
            tris.push(...this.getTrianglesOfChild(index));
        });

        return tris;
    }

    getTrianglesOfChild(index:number){
        const tris:OakMeshTriangleData[] = [];
            const obj = this.children[index];
        if (obj.type === OakMeshEditorObjectItemType.NGON) {
            tris.push(...obj.triangles);
        }else{
            const newTriangles = generateTrianglesForPrimitive(
                obj.type,
                obj.scale,
                obj.translation,
                obj.rotation,
                0,
                obj.color || { r: 1, g: 1, b: 1, a: 1 }
            );
            tris.push(...newTriangles);
        }
        return tris;
    }

    getTriangleCountOfChild(index:number){
        return this.getTrianglesOfChild(index).length;
    }

    /**
     * Populate triangles from PLYData.
     * Assumes positions, indices, and colors exist in the PLYData.
     */
    private constructFromPLYData(plyData: PLYData): void {
        const {positions, indices, colors} = plyData;

        if (!positions || !indices) {
            throw new Error("PLYData must include positions and indices to construct OakMesh.");
        }

        const nGonChild:OakMeshEditorObjectItem = {
            color: undefined,
            triangles:[],
            plyData: plyData,
            rotation: new Vec3(),
            scale: Vec3.identity(),
            translation: new Vec3(),
            type: OakMeshEditorObjectItemType.NGON,
            visible: true,
            material: 0
        };

        // For every set of 3 indices, construct a triangle
        for (let i = 0; i < indices.length; i += 3) {
            // Index values reference positions/colors per vertex
            const idx1 = indices[i];
            const idx2 = indices[i + 1];
            const idx3 = indices[i + 2];

            // Extract vertex positions
            const vertex1 = new Vec3().set(
                positions[idx1 * 3],
                positions[idx1 * 3 + 1],
                positions[idx1 * 3 + 2]
            );
            const vertex2 = new Vec3().set(
                positions[idx2 * 3],
                positions[idx2 * 3 + 1],
                positions[idx2 * 3 + 2]
            );
            const vertex3 = new Vec3().set(
                positions[idx3 * 3],
                positions[idx3 * 3 + 1],
                positions[idx3 * 3 + 2]
            );

            // Extract vertex colors using the same indices
            const color1 = colors
                ? {
                    r: colors[idx1 * 4],     // R
                    g: colors[idx1 * 4 + 1], // G
                    b: colors[idx1 * 4 + 2], // B
                    a: colors[idx1 * 4 + 3], // A
                }
                : {r: 1, g: 1, b: 1, a: 1};

            const color2 = colors
                ? {
                    r: colors[idx2 * 4],
                    g: colors[idx2 * 4 + 1],
                    b: colors[idx2 * 4 + 2],
                    a: colors[idx2 * 4 + 3],
                }
                : {r: 1, g: 1, b: 1, a: 1};

            const color3 = colors
                ? {
                    r: colors[idx3 * 4],
                    g: colors[idx3 * 4 + 1],
                    b: colors[idx3 * 4 + 2],
                    a: colors[idx3 * 4 + 3],
                }
                : {r: 1, g: 1, b: 1, a: 1};

            // Push the triangle
            nGonChild.triangles.push({
                vertex1,
                vertex2,
                vertex3,
                color1,
                color2,
                color3,
                material: 0,
            });

        }

        this.children.push(nGonChild);
    }

    attach(plyData: PLYData, scale: Vec3Interface = {x: 1, y: 1, z: 1}, translation: Vec3Interface = {
        x: 0,
        y: 0,
        z: 0
    }, rotation: Vec3Interface = {x: 0, y: 0, z: 0}, color?: RGBALike) {
        //const scaled = scalePLYData(plyData, scale);
        //const rotated = rotatePLYData(scaled, rotation);
        //const translated = translatePLYData(rotated, translation);
        //const colored = colorPLYData(translated, color);
        const om = new OakMesh(plyData)
        this.children.push({
            material: 0,
            rotation: rotation,
            scale: scale,
            translation: translation,
            triangles: om.getTriangles(),
            type: OakMeshEditorObjectItemType.NGON,
            visible: false
        });
    }

    join(oakMesh:OakMesh|PLYObject){
        if (oakMesh instanceof OakMesh){
           // console.log(oakMesh, oakMesh instanceof  OakMesh)
            this.children.push(...oakMesh.children);
        }
    }

    getProp(object: OakMeshEditorObjectItem, prop:string):string{
        const precision = 4;
        switch (prop){
            case "name": return `"${object.name}"`;
            case "x": return fixPrecision(object.translation.x.toFixed(precision));
            case "y": return fixPrecision(object.translation.y.toFixed(precision));
            case "z": return fixPrecision(object.translation.z.toFixed(precision));

            case "rx": return fixPrecision(object.rotation.x.toFixed(precision));
            case "ry": return fixPrecision(object.rotation.y.toFixed(precision));
            case "rz": return fixPrecision(object.rotation.z.toFixed(precision));

            case "sx": return fixPrecision(object.scale.x.toFixed(precision));
            case "sy": return fixPrecision(object.scale.y.toFixed(precision));
            case "sz": return fixPrecision(object.scale.z.toFixed(precision));

            case "material": return (object.material||0).toString();
        }

        if (object.color){
            switch (prop){
                case "red": return fixPrecision(object.color.r.toFixed(precision));
                case "green": return fixPrecision(object.color.g.toFixed(precision));
                case "blue": return fixPrecision(object.color.b.toFixed(precision));
                case "alpha": return fixPrecision(object.color.a.toFixed(precision));
            }
        }

        return "\0";
    }

    getGeometryFile(){

        const sectionVertex = (header:string, type:string, props: string[]): string[]=>{
            return [
                [ header,type,
                ...props].join(" ")
            ];
        }

       // const primitiveProps = ["x","y","z","rx","ry","rz","s","t","red","green","blue","alpha"];
        const primitiveProps = ["name","x","y","z","rx","ry","rz","sx","sy","sz","material","red","green","blue","alpha"];
        const mapPrimitives = ():string[]=>{
            //@ts-ignore
            return [
                //"",
                ...sectionVertex("primitive","property", primitiveProps),
                ...this.children.filter((child)=>{
                    return child.type!==OakMeshEditorObjectItemType.NGON;
                }).map((child)=>{
                    return `${OakMeshEditorObjectItemTypeNamesSimple[child.type].toLowerCase()} ${primitiveProps.map((prop)=>{
                        return this.getProp(child, prop);
                    }).join(" ")}`;
                })].filter(Boolean);
        }

        return [
            "oakm",
            "format ascii 1.0",
            "comment Created by KeyPiece - keypiece.org",
            ...mapPrimitives()
        ].join("\r\n");
    }

    /**
     * Reconstructs PLYData from the triangle array
     */
    getPLYData(renderingOptions?:ClientRenderingOptions): PLYData | OakMeshData {
        const plyObject = new PLYObject();

        this.children.forEach((obj:OakMeshEditorObjectItem)=>{

            let vertexCount = 0;

            const materials: number[] = [];
            const positions: number[] = [];
            const indices: number[] = [];
            const colors: number[] = [];
            const normals: number[] = [];
            const uvs: number[] = [];

            const tris:OakMeshTriangleData[] = [];


            if (obj.type === OakMeshEditorObjectItemType.NGON) {
                tris.push(...obj.triangles.map((tri)=>{
                    return {
                        ...tri,
                        material: tri.material||obj.material || 0
                    }
                }));
            }else{
                    const newTriangles = generateTrianglesForPrimitive(
                    obj.type,
                    Vec3.identity(),
                    new Vec3(),
                        new Vec3(),
                    obj.material,
                    obj.color || { r: 1, g: 1, b: 1, a: 1 },
                        renderingOptions
                );
                tris.push(...newTriangles);
            }


            (tris).forEach((triangle:OakMeshTriangleData) => {
                const {vertex1, vertex2, vertex3, color1, color2, color3} = triangle;

                // Push vertex positions
                positions.push(vertex1.x, vertex1.y, vertex1.z);
                positions.push(vertex2.x, vertex2.y, vertex2.z);
                positions.push(vertex3.x, vertex3.y, vertex3.z);

                // Assign indices
                indices.push(vertexCount, vertexCount + 1, vertexCount + 2);
                vertexCount += 3;

                // Push per-vertex colors
                colors.push(color1.r, color1.g, color1.b, color1.a);
                colors.push(color2.r, color2.g, color2.b, color2.a);
                colors.push(color3.r, color3.g, color3.b, color3.a);

                // Calculate and push normals
                const edge1 = {x: vertex2.x - vertex1.x, y: vertex2.y - vertex1.y, z: vertex2.z - vertex1.z};
                const edge2 = {x: vertex3.x - vertex1.x, y: vertex3.y - vertex1.y, z: vertex3.z - vertex1.z};

                const normal = {
                    x: edge1.y * edge2.z - edge1.z * edge2.y,
                    y: edge1.z * edge2.x - edge1.x * edge2.z,
                    z: edge1.x * edge2.y - edge1.y * edge2.x,
                };
                const length = Math.sqrt(normal.x ** 2 + normal.y ** 2 + normal.z ** 2);

                const normalizedNormal = {
                    x: normal.x / length,
                    y: normal.y / length,
                    z: normal.z / length,
                };

                for (let i = 0; i < 3; i++) {
                    normals.push(normalizedNormal.x, normalizedNormal.y, normalizedNormal.z);
                }

                // Push default UVs
                for (let i = 0; i < 3; i++) {
                    uvs.push(0, 0);
                }

                // Push default Materials
                for (let i = 0; i < 1; i++) {
                    materials.push(triangle.material, triangle.material, triangle.material);
                }
            });


            plyObject.attach({
                positions: new Float32Array(positions),
                materials: new Uint16Array(materials),
                indices: new Uint16Array(indices),
                colors: new Float32Array(colors),
                normals: new Float32Array(normals),
                uvs: new Float32Array(uvs),
            }, obj.scale, obj.translation, obj.rotation, obj.color);

        });

        return plyObject.getPLYData();

    }


}

export interface OakMeshData extends PLYData {
    positions: Float32Array;
    normals: Float32Array;
    uvs: Float32Array;
    colors: Float32Array;
    indices: Uint16Array;
    materials: Uint16Array;
}

export interface OakObjectItem {
    mesh: PLYObject;

}

export interface Scene {
    children: SceneItem[];
}

export enum SceneItemType {
    NONE = 0,
    SKYBOX = 1,
    LIGHT = 2,
    VECTOR = 3,
    OBJECT = 4,
}

export interface SceneItem {
    type: SceneItemType;
    position: Vec3;
}

export interface TriangleCountComponentProps {
    count:TriangleCountInterface;
}

export const TriangleCountComponent:FC<TriangleCountComponentProps>=({count={upper:0, lower:0}})=>{
    return (count.upper===count.lower)?<span>{count.lower}</span>:<strong className={"active"}><span>{count.lower} - {count.upper}</span></strong>;
}

export const subdivide = (triangles: OakMeshTriangleData[]): OakMeshTriangleData[] => {
    const edgeMap: Map<string, Vec3> = new Map();
    const vertexMap: Map<string, Vec3> = new Map();
    const facePoints: Vec3[] = [];
    const newTriangles: OakMeshTriangleData[] = [];

    // Step 1: Compute face points
    triangles.forEach(({ vertex1, vertex2, vertex3 }) => {
        const facePoint = new Vec3().set(
            (vertex1.x + vertex2.x + vertex3.x) / 3,
            (vertex1.y + vertex2.y + vertex3.y) / 3,
            (vertex1.z + vertex2.z + vertex3.z) / 3
        );
        facePoints.push(facePoint);
    });

    // Step 2: Compute edge points and cache them
    const getEdgePoint = (v1: Vec3Interface, v2: Vec3Interface): Vec3 => {
        const key = [v1.x, v1.y, v1.z, v2.x, v2.y, v2.z].sort().join("-");
        if (!edgeMap.has(key)) {
            edgeMap.set(
                key,
                new Vec3().set(
                    (v1.x + v2.x) / 2,
                    (v1.y + v2.y) / 2,
                    (v1.z + v2.z) / 2
                )
            );
        }
        return edgeMap.get(key)!;
    };

    // Step 3: Create new triangles with updated geometry
    triangles.forEach(({ vertex1, vertex2, vertex3, color1, color2, color3, material }, idx) => {
        const facePoint = facePoints[idx];

        const edge12 = getEdgePoint(vertex1, vertex2);
        const edge23 = getEdgePoint(vertex2, vertex3);
        const edge31 = getEdgePoint(vertex3, vertex1);

        // Update vertex positions (Catmull-Clark rule for vertex points)
        const updateVertex = (vertex: Vec3Interface): Vec3 => {
            const connectedEdges = [getEdgePoint(vertex, vertex2), getEdgePoint(vertex, vertex3)];
            const avgEdgeMidpoints = connectedEdges.reduce((acc, edge) => acc.add(edge), new Vec3()).mulI(1 / connectedEdges.length);

            return new Vec3().set(
                (vertex.x + avgEdgeMidpoints.x + facePoint.x) / 3,
                (vertex.y + avgEdgeMidpoints.y + facePoint.y) / 3,
                (vertex.z + avgEdgeMidpoints.z + facePoint.z) / 3
            );
        };

        const updatedVertex1 = updateVertex(vertex1);
        const updatedVertex2 = updateVertex(vertex2);
        const updatedVertex3 = updateVertex(vertex3);

        // Generate four new triangles
        newTriangles.push(
            { vertex1: updatedVertex1, vertex2: edge12, vertex3: facePoint, color1, color2, color3, material },
            { vertex1: edge12, vertex2: updatedVertex2, vertex3: facePoint, color1, color2, color3, material },
            { vertex1: updatedVertex2, vertex2: edge23, vertex3: facePoint, color1, color2, color3, material },
            { vertex1: edge23, vertex2: updatedVertex3, vertex3: facePoint, color1, color2, color3, material }
        );
    });

    return newTriangles;
};

export const bevel = (triangles: OakMeshTriangleData[], bevelDepth: number): OakMeshTriangleData[] => {
    const newTriangles: OakMeshTriangleData[] = [];

    // Utility function to calculate edge midpoint
    const getMidpoint = (v1: Vec3Interface, v2: Vec3Interface): Vec3 => {
        return new Vec3().set(
            (v1.x + v2.x) / 2,
            (v1.y + v2.y) / 2,
            (v1.z + v2.z) / 2
        );
    };

    // Utility function to calculate a beveled offset point
    const offsetPoint = (point: Vec3Interface, normal: Vec3Interface, depth: number): Vec3 => {
        return new Vec3().set(
            point.x + normal.x * depth,
            point.y + normal.y * depth,
            point.z + normal.z * depth
        );
    };

    // Process each triangle
    triangles.forEach((triangle) => {
        const { vertex1, vertex2, vertex3, color1, color2, color3, material } = triangle;

        // Calculate midpoints for edges
        const mid12 = getMidpoint(vertex1, vertex2);
        const mid23 = getMidpoint(vertex2, vertex3);
        const mid31 = getMidpoint(vertex3, vertex1);

        // Calculate normals for each edge (direction vector from one vertex to the other)
        const normal12 = new Vec3().set(vertex2.x - vertex1.x, vertex2.y - vertex1.y, vertex2.z - vertex1.z).normalize();
        const normal23 = new Vec3().set(vertex3.x - vertex2.x, vertex3.y - vertex2.y, vertex3.z - vertex2.z).normalize();
        const normal31 = new Vec3().set(vertex1.x - vertex3.x, vertex1.y - vertex3.y, vertex1.z - vertex3.z).normalize();

        // Offset midpoints outward to create beveled geometry
        const bevel12 = offsetPoint(mid12, normal12, bevelDepth);
        const bevel23 = offsetPoint(mid23, normal23, bevelDepth);
        const bevel31 = offsetPoint(mid31, normal31, bevelDepth);

        // Generate new triangles for the beveled edges
        newTriangles.push(
            // Original triangle with beveled edges
            { vertex1, vertex2: mid12, vertex3: mid31, color1, color2, color3, material },
            { vertex2, vertex3: mid23, vertex1: mid12, color1, color2, color3, material },
            { vertex3, vertex1: mid31, vertex2: mid23, color1, color2, color3, material },

            // Bevel edge faces
            { vertex1: mid12, vertex2: bevel12, vertex3: mid31, color1, color2, color3, material },
            { vertex2: mid23, vertex3: bevel23, vertex1: mid12, color1, color2, color3, material },
            { vertex3: mid31, vertex1: bevel31, vertex2: mid23, color1, color2, color3, material },

            // New face connecting bevel points
            { vertex1: bevel12, vertex2: bevel23, vertex3: bevel31, color1, color2, color3, material }
        );
    });

    return newTriangles;
};
