import { FC, useEffect, useState } from "react";
import {
    Button,
    Card,
    Col,
    Content,
    Divider,
    Page,
    Row,
    Space,
    Paragraph,
    Pill,
} from "../layout/Content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashCan, faEdit, faSave } from "@fortawesome/free-solid-svg-icons";
import Popconfirm, { Pagination } from "../layout/Defaults";
import MeshSelector from "./MeshSelector";
import GLRenderer from "../interactive/GLRenderer";
import GLContextProvider from "../interactive/GLContextProvider";
import { OakMesh } from "../../model/OakObject";

interface Entity {
    uuid?: string;
    name: string;
    meshUuid?: string;
    actions: Record<string, string>; // e.g., { onCreate: "function body" }
    metadata?: Record<string, any>;
    createdAt?: string;
    updatedAt?: string;
}

interface Mesh {
    uuid: string;
    name: string;
}

const EntitiesApp: FC = () => {
    const [entities, setEntities] = useState<Entity[]>([]);
    const [meshCache, setMeshCache] = useState<Record<string, Mesh>>({});
    const [selectedEntity, setSelectedEntity] = useState<Entity | null>(null);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [hasChanges, setHasChanges] = useState(false);

    const fetchStatistics = async (page: number, limit: number) => {
        setLoading(true);
        try {
            const response = await fetch(
                `${process.env.REACT_APP_DOMAIN_DELEGATOR}/entity/statistics?page=${page}&limit=${limit}`
            );
            if (!response.ok) throw new Error("Failed to fetch entity statistics");
            const data = await response.json();
            setEntities(data.data || []);
            setTotal(data.total || 0);
        } catch (err: any) {
            setError(err.message || "An unknown error occurred");
        } finally {
            setLoading(false);
        }
    };

    const fetchMesh = async (uuid: string): Promise<Mesh | null> => {
        if (meshCache[uuid]) return meshCache[uuid];
        try {
            const response = await fetch(`${process.env.REACT_APP_DOMAIN_DELEGATOR}/mesh/${uuid}`);
            if (!response.ok) throw new Error("Failed to fetch mesh data");
            const data = await response.json();
            setMeshCache((prev) => ({ ...prev, [uuid]: data }));
            return data;
        } catch (err) {
            console.error(err);
            return null;
        }
    };

    useEffect(() => {
        fetchStatistics(page, limit);
    }, [page, limit]);

    // Load mesh data for entities with meshUuid
    useEffect(() => {
        const loadMeshes = async () => {
            for (const entity of entities) {
                if (entity.meshUuid && !meshCache[entity.meshUuid]) {
                    await fetchMesh(entity.meshUuid);
                }
            }
        };
        if (entities.length > 0) loadMeshes();
    }, [entities]);

    const handleSaveEntity = async () => {
        if (!selectedEntity) return;
        try {
            const method = selectedEntity.uuid ? "PUT" : "POST";
            const url = selectedEntity.uuid
                ? `${process.env.REACT_APP_DOMAIN_DELEGATOR}/entity/${selectedEntity.uuid}`
                : `${process.env.REACT_APP_DOMAIN_DELEGATOR}/entity`;

            const response = await fetch(url, {
                method,
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(selectedEntity),
            });

            if (!response.ok) throw new Error("Failed to save entity");

            const updatedEntity = await response.json();
            setEntities((prev) =>
                selectedEntity.uuid
                    ? prev.map((ent) =>
                        ent.uuid === updatedEntity.uuid ? updatedEntity : ent
                    )
                    : [updatedEntity, ...prev]
            );

            setSelectedEntity(null);
            setHasChanges(false);
        } catch (err: any) {
            setError(err.message || "An unknown error occurred");
        }
    };

    const handleDeleteEntity = async (uuid: string) => {
        try {
            await fetch(`${process.env.REACT_APP_DOMAIN_DELEGATOR}/entity/${uuid}`, {
                method: "DELETE",
            });

            setEntities((prev) => prev.filter((ent) => ent.uuid !== uuid));
        } catch (err: any) {
            setError(err.message || "An unknown error occurred");
        }
    };

    const addEntity = () => {
        setSelectedEntity({
            name: "New Entity",
            actions: { onCreate: "", onClick: "", onUpdate: "", onDestroy: "" },
            metadata: {},
        });
        setHasChanges(true);
    };

    const updateEntityField = (field: keyof Entity, value: any) => {
        if (!selectedEntity) return;
        setSelectedEntity({ ...selectedEntity, [field]: value });
        setHasChanges(true);
    };

    return (
        <Page Grow>
            <GLContextProvider>
                <Row Full>
                    {!selectedEntity && (
                        <Col xs={24}>
                            <Content Pad>
                                <Space direction="vertical" GapSm>
                                    <Space justify="space-between">
                                        <Button onClick={addEntity}>
                                            <FontAwesomeIcon icon={faPlus} /> Add Entity
                                        </Button>
                                    </Space>
                                    <Pagination
                                        current={page}
                                        total={Math.ceil(total / limit)}
                                        onChange={(p: number) => setPage(p)}
                                    />
                                    {loading && <div>Loading...</div>}
                                    {error && <div style={{ color: "red" }}>{error}</div>}
                                    <Row GapSm>
                                        {entities.map((entity) => (
                                            <Col xs={12} md={8} lg={6} key={entity.uuid || ""}>
                                                <Card Pad>
                                                    <Space direction="vertical" GapSm>
                                                        {/*<small>{entity.uuid}</small>*/}
                                                        <Paragraph>{entity.name}</Paragraph>
                                                        <div style={{height:"128px", width:"100%"}}>
                                                        {entity.meshUuid && meshCache[entity.meshUuid] && (
                                                            <GLRenderer
                                                                key={`entity-mesh-${entity.meshUuid}`}
                                                                options={{
                                                                    autoRotate: true,
                                                                    width: "100%",
                                                                    height: 128,
                                                                    buffersUUID: "any",
                                                                    backgroundColor: [
                                                                        Math.random(),
                                                                        Math.random(),
                                                                        Math.random(),
                                                                        1,
                                                                    ],
                                                                    PLYDataGenerator: () => {
                                                                        const meshData =
                                                                            meshCache[entity.meshUuid||""];
                                                                        return meshData
                                                                            ? {
                                                                                ready: true,
                                                                                static: new OakMesh(
                                                                                    undefined,
                                                                                    meshData as OakMesh
                                                                                ).getPLYData(),
                                                                                dynamic: undefined,
                                                                                shadows: undefined,
                                                                            }
                                                                            : { ready: false };
                                                                    },
                                                                }}
                                                            />
                                                        )}</div>
                                                        <Space GapSm>
                                                            <Button
                                                                size="small"
                                                                onClick={() => setSelectedEntity(entity)}
                                                            >
                                                                <FontAwesomeIcon icon={faEdit} />
                                                            </Button>
                                                            <Popconfirm
                                                                message="Are you sure you want to delete this entity?"
                                                                onOk={() =>
                                                                    handleDeleteEntity(
                                                                        entity.uuid || ""
                                                                    )
                                                                }
                                                            >
                                                                <Button size="small" type="danger">
                                                                    <FontAwesomeIcon icon={faTrashCan} />
                                                                </Button>
                                                            </Popconfirm>
                                                        </Space>
                                                    </Space>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                </Space>
                            </Content>
                        </Col>
                    )}
                    {selectedEntity && (
                        <Col xs={24}>
                            <Content Pad>
                                <Space direction="vertical" GapSm>
                                    <Space justify="space-between">
                                        <Button onClick={() => setSelectedEntity(null)}>Back</Button>
                                        <Button
                                            type={hasChanges ? "active" : "default"}
                                            disabled={!hasChanges}
                                            onClick={handleSaveEntity}
                                        >
                                            <FontAwesomeIcon icon={faSave} /> Save
                                        </Button>
                                    </Space>
                                    <Divider />
                                    <label>
                                        Name:
                                        <input
                                            value={selectedEntity.name}
                                            onChange={(e) =>
                                                updateEntityField("name", e.target.value)
                                            }
                                        />
                                    </label>
                                    <Divider />

                                    <MeshSelector
                                        meshUuid={selectedEntity.meshUuid || null}
                                        onUpdate={(v: string | null) => {
                                            updateEntityField("meshUuid", v);
                                            if (v) fetchMesh(v);
                                        }}
                                    />
                                    <Divider />
                                    <Space direction="vertical" GapSm>
                                        Actions:
                                        {Object.entries(selectedEntity.actions).map(
                                            ([action, body]) => (
                                                <label key={action}>
                                                    {action}:
                                                    <textarea
                                                        value={body}
                                                        onChange={(e) =>
                                                            updateEntityField("actions", {
                                                                ...selectedEntity.actions,
                                                                [action]: e.target.value,
                                                            })
                                                        }
                                                    />
                                                </label>
                                            )
                                        )}
                                    </Space>
                                </Space>
                            </Content>
                        </Col>
                    )}
                </Row>
            </GLContextProvider>
        </Page>
    );
};

export default EntitiesApp;
