import {ReactNode} from "react";

export const APP_NOTEPAD = "Notepad" as const;
export const APP_CALCULATOR = "Calculator" as const;
export const APP_HELP = "Start Here" as const;
export const APP_WIKI = "Wiki" as const;
export const APP_DOCUMENTS = "Documents" as const;
export const APP_SIMPLE_GAME = "Simple Game" as const;
export const APP_CAMERA = "Camera" as const;

export type AppName =
    | typeof APP_NOTEPAD
    | typeof APP_CALCULATOR
    | typeof APP_HELP
    | typeof APP_CAMERA
    | typeof APP_SIMPLE_GAME
    | typeof APP_WIKI
    | typeof APP_DOCUMENTS;

export interface AppState {
    openApps: AppName[];
    activeApp: AppName | null;
}

export type AppAction =
    | { type: "OPEN_APP"; app: AppName }
    | { type: "CLOSE_APP"; app: AppName }
    | { type: "SWITCH_APP"; app: AppName }
    | { type: "MINIMIZE_APP"; app: AppName };

export abstract class BaseApp {
    static id: AppName;
    static title: string;
    static icon: any;
    static getComponent(onClose: () => void, onMinimize: () => void): ReactNode {
        return null;
    }
}