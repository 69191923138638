import {ClientRenderingOptions} from "./RemotePLYLoader";
import {Vec3, Vec3Interface} from "./Vec3";
import {RGBALike} from "./RGBA";
import {mat4, vec3} from "gl-matrix";
import {Surface} from "./Surface";
import {camera} from "../component/interactive/webgl-utils";

export interface PLYData {
    positions: Float32Array;
    normals: Float32Array;
    uvs: Float32Array;
    colors: Float32Array;
    indices: Uint16Array;
    materials: Uint16Array;
}

type PropertyInfo = {
    name: string;
    type: string; // Not fully utilized here but kept for completeness
};


export class PLYObject {

    data: PLYData = {
        colors: new Float32Array(),
        indices: new Uint16Array(),
        materials: new Uint16Array(),
        normals: new Float32Array(),
        uvs: new Float32Array(),
        positions: new Float32Array()
    };

    constructor(plyData?: PLYData) {
        if (plyData) {
            this.data = joinPLYData([this.data, plyData]);
        }
    }

    getPLYData(renderingOptions?:ClientRenderingOptions) {
        return this.data;
    }

    getMeshBounds(){
        const upper = (new Vec3()).set(-Infinity,-Infinity,-Infinity);
        const lower = (new Vec3()).set(Infinity,Infinity,Infinity);

        for (let i = 0; i < this.data.positions.length; i += 3) {

            const x = this.data.positions[i];
            const y = this.data.positions[i +1];
            const z = this.data.positions[i+2];


            lower.x = Math.min(lower.x, x);
            lower.y = Math.min(lower.y, y);
            lower.z = Math.min(lower.z, z);


            upper.x = Math.max(upper.x, x);
            upper.y = Math.max(upper.y, y);
            upper.z = Math.max(upper.z, z);

        }

        return {
            upper, lower
        }

    }

    getGeometricCentroid(){
        const bounds = this.getMeshBounds();
        return bounds.upper.clone().sub(bounds.lower).divI(2).add(bounds.lower);
    }

    attach(plyData: PLYData, scale: Vec3Interface = {x: 1, y: 1, z: 1}, translation: Vec3Interface = {
        x: 0,
        y: 0,
        z: 0
    }, rotation: Vec3Interface = {x: 0, y: 0, z: 0}, color?: RGBALike) {
        const scaled = scalePLYData(plyData, scale);
        const rotated = rotatePLYData(scaled, rotation);
        const translated = translatePLYData(rotated, translation);
        const colored = colorPLYData(translated, color);
        this.data = joinPLYData([this.data, color?colored:translated]);
    }

    scale(scale: Vec3Interface) {
        this.data = scalePLYData(this.data, scale)
        return this;
    }

    rotate(rotate: Vec3Interface) {
        this.data = rotatePLYData(this.data, rotate)
        return this;
    }

    translate(translate: Vec3Interface) {
        this.data = translatePLYData(this.data, translate);
        return this;
    }

    setColor(r: number, g: number, b: number, a: number) {
        for (let i = 0; i < this.data.colors.length; i += 4) {
            this.data.colors[i] = r;
            this.data.colors[i + 1] = g;
            this.data.colors[i + 2] = b;
            this.data.colors[i + 3] = a;
        }
        return this;
    }

    setMaterial(m: number) {
        for (let i = 0; i < this.data.materials.length; i += 1) {
            this.data.materials[i] = m;
        }
        return this;
    }

    setColorRGBA(rgba: RGBALike) {
        for (let i = 0; i < this.data.colors.length; i += 4) {
            this.data.colors[i] = rgba.r;
            this.data.colors[i + 1] = rgba.g;
            this.data.colors[i + 2] = rgba.b;
            this.data.colors[i + 3] = rgba.a;
        }
        return this;
    }

    applyTransformationToVertices(t: (v: Vec3Interface) => any): PLYObject {
        for (let index0 = 0; index0 < this.data.positions.length; index0 += 3) {

            let vertex0 = new Vec3().set(
                this.data.positions[index0],
                this.data.positions[index0 + 1],
                this.data.positions[index0 + 2]
            );

            const result = t(vertex0);
            this.data.positions[index0] = result.x;
            this.data.positions[index0 + 1] = result.y;
            this.data.positions[index0 + 2] = result.z;

        }
        return this;
    }

}


export function parsePLY(plyString: string): PLYData {
    // Split the string into lines
    const lines = plyString.split('\n').map(l => l.trim());

    // Data containers for the parsed data
    const positions: number[] = [];
    const normals: number[] = [];
    const uvs: number[] = [];
    const colors: number[] = [];
    const indices: number[] = [];
    const materials: number[] = [];

    // Flags and counters
    let vertexCount = 0;
    let faceCount = 0;

    const vertexProperties: PropertyInfo[] = [];

    // Parse header
    let currentLine = 0;
    while (currentLine < lines.length && lines[currentLine] !== 'end_header') {
        const line = lines[currentLine];

        if (line.startsWith('element vertex')) {
            // e.g. "element vertex 34942"
            const parts = line.split(' ');
            vertexCount = parseInt(parts[2], 10);
        } else if (line.startsWith('element face')) {
            // e.g. "element face 67184"
            const parts = line.split(' ');
            faceCount = parseInt(parts[2], 10);
        } else if (line.startsWith('property')) {
            // e.g. "property float x"
            // Only record properties under the vertex element
            // We'll assume that properties appear after "element vertex"
            // and before "element face" if we rely on PLY standard ordering.
            // If a PLY places properties after faces, that would be unusual.
            const parts = line.split(' ');
            // parts: ['property', '<type>', '<name>', ...]
            // We assume vertex properties appear before face properties.
            // The standard says: properties associated with the last mentioned element.
            // We'll rely on the PLY standard that after "element vertex" until next "element" line,
            // properties belong to the vertex. After "element face", belongs to face, etc.

            // Only add if we are currently listing vertex properties (based on vertexCount > 0 and haven't reached faceCount yet)
            // There's no strict mention that properties for vertices won't appear interspersed,
            // but typically they are contiguous. We'll assume that once we hit "element face" we stop reading vertex properties.
            // So we check if faceCount isn't set yet or we haven't encountered element face line yet.
            // The given code structure ensures that once we see "element face", we break out from reading vertex properties anyway.
            if (faceCount === 0) {
                const propType = parts[1];
                const propName = parts[2];
                vertexProperties.push({ name: propName, type: propType });
            }
        }
        currentLine++;
    }

    // Skip "end_header" line
    if (lines[currentLine] === 'end_header') {
        currentLine++;
    }

    // We have a known set of properties that we want to extract from vertexProperties.
    // We'll record the index of each property in the vertex line.
    // If a property is not found, we will assign a default value of 0.

  //  console.log(vertexProperties);

    // Create a map from property name to index in the vertex data line
    const propertyIndexMap: { [key: string]: number | undefined } = {};
    vertexProperties.forEach((propInfo, idx) => {
        propertyIndexMap[propInfo.name] = idx;
    });

    // Helper function to get the value of a property by name from a vertex line
    function getPropValue(vertexData: string[], propName: string): number {
        const idx = propertyIndexMap[propName];
        if (idx === undefined || idx < 0 || idx >= vertexData.length) {
            // Property not found or index out of range, return 0
            return 0;
        }
        const val = vertexData[idx];
        // Determine if property is color or float
        if (propName === 'red' || propName === 'green' || propName === 'blue' || propName === 'alpha') {
            // Colors are uchar, need to map to [0,1]
            const intVal = parseInt(val, 10);
            return isNaN(intVal) ? 0 : intVal / 255;
        } else {
            const floatVal = parseFloat(val);
            return isNaN(floatVal) ? 0 : floatVal;
        }
    }

    // Read vertex data
    for (let i = 0; i < vertexCount; i++) {
        const vertexLine = lines[currentLine++].trim();
        const vertexData = vertexLine.split(/\s+/);

        // positions: x,y,z
        positions.push(getPropValue(vertexData, 'x'));
        positions.push(getPropValue(vertexData, 'y'));
        positions.push(getPropValue(vertexData, 'z'));

        // normals: nx, ny, nz
        normals.push(getPropValue(vertexData, 'nx'));
        normals.push(getPropValue(vertexData, 'ny'));
        normals.push(getPropValue(vertexData, 'nz'));

        // uvs: s, t
        uvs.push(getPropValue(vertexData, 's'));
        uvs.push(getPropValue(vertexData, 't'));

        // colors: red, green, blue, alpha
        colors.push(getPropValue(vertexData, 'red'));
        colors.push(getPropValue(vertexData, 'green'));
        colors.push(getPropValue(vertexData, 'blue'));
        colors.push(getPropValue(vertexData, 'alpha'));

        materials.push(0);
    }

    // Read face data
    for (let i = 0; i < faceCount; i++) {
        const faceLine = lines[currentLine++].trim();
        const faceData = faceLine.split(/\s+/);

        // First number is the count of indices
        const count = parseInt(faceData[0], 10);
        const faceIndices = faceData.slice(1, 1 + count).map(Number);
        indices.push(...faceIndices);
    }

    return {
        positions: new Float32Array(positions),
        normals: new Float32Array(normals),
        uvs: new Float32Array(uvs),
        colors: new Float32Array(colors),
        indices: new Uint16Array(indices),
        materials: new Uint16Array(materials),
    };
}

export function translatePLYData(plyData: PLYData, translation: Vec3Interface): PLYData {

    for (let i = 0; i < plyData.positions.length; i += 3) {
        plyData.positions[i] += translation.x;
        plyData.positions[i + 1] += translation.y;
        plyData.positions[i + 2] += translation.z;
    }

    return plyData;
}

export function colorPLYData(plyData: PLYData, color?: RGBALike): PLYData {
    if (!color){return plyData;}

    for (let i = 0; i < plyData.colors.length; i += 4) {
        plyData.colors[i] = color.r;
        plyData.colors[i + 1] = color.g;
        plyData.colors[i + 2] = color.b;
        plyData.colors[i + 3] = color.a;
    }

    return plyData;
}

export function rotatePLYData(plyData: PLYData, rotation: Vec3Interface): PLYData {
    const rotationMatrix = mat4.create();

    // Convert rotation from degrees to radians
    mat4.rotateX(rotationMatrix, rotationMatrix, rotation.x * Math.PI / 180);
    mat4.rotateY(rotationMatrix, rotationMatrix, rotation.y * Math.PI / 180);
    mat4.rotateZ(rotationMatrix, rotationMatrix, rotation.z * Math.PI / 180);

    for (let i = 0; i < plyData.positions.length; i += 3) {
        // Apply rotation to positions
        const pos = [plyData.positions[i], plyData.positions[i + 1], plyData.positions[i + 2]];
        // @ts-ignore
        vec3.transformMat4(pos, pos, rotationMatrix);
        plyData.positions[i] = pos[0];
        plyData.positions[i + 1] = pos[1];
        plyData.positions[i + 2] = pos[2];

        // Apply rotation to normals
        const norm = [plyData.normals[i], plyData.normals[i + 1], plyData.normals[i + 2]];
        // @ts-ignore
        vec3.transformMat4(norm, norm, rotationMatrix);
        plyData.normals[i] = norm[0];
        plyData.normals[i + 1] = norm[1];
        plyData.normals[i + 2] = norm[2];
    }

    return plyData;
}

export function scalePLYData(plyData: PLYData, scale: Vec3Interface): PLYData {
    const scalingMatrix = mat4.create();
    mat4.scale(scalingMatrix, scalingMatrix, [scale.x, scale.y, scale.z]);

    for (let i = 0; i < plyData.positions.length; i += 3) {
        // Apply scaling to positions
        const pos = [plyData.positions[i], plyData.positions[i + 1], plyData.positions[i + 2]];
        // @ts-ignore
        vec3.transformMat4(pos, pos, scalingMatrix);
        plyData.positions[i] = pos[0];
        plyData.positions[i + 1] = pos[1];
        plyData.positions[i + 2] = pos[2];
    }

    return plyData;
}



export function drawPLY(surface: Surface, plyData: PLYData, color: string, lg: boolean = false) {
    for (let i = 0; i < plyData.indices.length; i += 3) {
        // Create Vec3 instances for each vertex of the face
        let v1 = new Vec3().set(
            -plyData.positions[3 * plyData.indices[i]],     // x
            plyData.positions[3 * plyData.indices[i] + 1], // y
            plyData.positions[3 * plyData.indices[i] + 2]  // z
        );

        let v2 = new Vec3().set(
            -plyData.positions[3 * plyData.indices[i + 1]],     // x
            plyData.positions[3 * plyData.indices[i + 1] + 1], // y
            plyData.positions[3 * plyData.indices[i + 1] + 2]  // z
        );

        let v3 = new Vec3().set(
            -plyData.positions[3 * plyData.indices[i + 2]],     // x
            plyData.positions[3 * plyData.indices[i + 2] + 1], // y
            plyData.positions[3 * plyData.indices[i + 2] + 2]  // z
        );

        lg ? camera.drawFace3Grown(surface, v1, v2, v3, color) : camera.drawFace3(surface, v1, v2, v3, color, false);
    }
}

export function joinPLYData(plyData: (PLYData | any)[]): PLYData {
    let totalPositions = 0;
    let totalColors = 0;
    let totalIndices = 0;
    let totalMaterials = 0;
    let totalUvs = 0;
    let totalNormals = 0;

    // Calculate the total size needed for each array
    plyData.forEach((dat: PLYData) => {
        if (dat) {
            totalPositions += dat.positions.length;
            totalColors += dat.colors.length;
            totalIndices += dat.indices.length;
            totalMaterials += dat.materials.length;
            totalUvs += dat.uvs.length;
            totalNormals += dat.normals.length;
        }
    });

    const positions = new Float32Array(totalPositions);
    const colors = new Float32Array(totalColors);
    const indices = new Uint16Array(totalIndices);
    const materials = new Uint16Array(totalMaterials);
    const uvs = new Float32Array(totalUvs);
    const normals = new Float32Array(totalNormals);

    let positionOffset = 0;
    let colorOffset = 0;
    let indexOffset = 0;
    let materialOffset = 0;
    let uvOffset = 0;
    let normalOffset = 0;
    let vertexOffset = 0;

    plyData.forEach((dat: PLYData) => {
        if (dat) {
            positions.set(dat.positions, positionOffset);
            colors.set(dat.colors, colorOffset);
            uvs.set(dat.uvs, uvOffset);
            materials.set(dat.materials, materialOffset);
            normals.set(dat.normals, normalOffset);

            // Adjust indices and add to the final array
            for (let i = 0; i < dat.indices.length; i++) {
                indices[indexOffset + i] = dat.indices[i] + vertexOffset;
            }

            positionOffset += dat.positions.length;
            colorOffset += dat.colors.length;
            indexOffset += dat.indices.length;
            materialOffset += dat.materials.length;
            uvOffset += dat.uvs.length;
            normalOffset += dat.normals.length;
            vertexOffset += dat.positions.length / 3;
        }
    });

    return {positions, materials, colors, normals, uvs, indices};
}

export function createPLYFile(plyData: PLYData): string {
    const { positions, indices, colors, normals } = plyData;

    // Helper function to format numbers
    const formatNumber = (num: number) => num.toFixed(6);

    // Header lines
    const header: string[] = [
        "ply",
        "format ascii 1.0",
        "comment Created by KeyPiece - keypiece.org",
        `element vertex ${positions.length / 3}`,
        "property float x",
        "property float y",
        "property float z",
        ...(normals.length > 0 ? ["property float nx", "property float ny", "property float nz"] : []),
        ...(colors.length > 0 ? ["property uchar red", "property uchar green", "property uchar blue", "property uchar alpha"] : []),
        `element face ${indices.length / 3}`,
        "property list uchar int vertex_indices",
        "end_header",
    ];

    // Convert positions to PLY vertex lines
    const vertices: string[] = [];
    const vertexCount = positions.length / 3;

    for (let i = 0; i < vertexCount; i++) {
        const position = [
            formatNumber(positions[i * 3]),
            formatNumber(positions[i * 3 + 1]),
            formatNumber(positions[i * 3 + 2]),
        ];

        const normal =
            normals.length > 0
                ? [
                    formatNumber(normals[i * 3]),
                    formatNumber(normals[i * 3 + 1]),
                    formatNumber(normals[i * 3 + 2]),
                ]
                : [];

        const color =
            colors.length > 0
                ? [
                    Math.round(colors[i * 4] * 255),
                    Math.round(colors[i * 4 + 1] * 255),
                    Math.round(colors[i * 4 + 2] * 255),
                    Math.round(colors[i * 4 + 3] * 255),
                ]
                : [];

        vertices.push([...position, ...normal, ...color].join(" "));
    }

    // Convert indices to PLY face lines
    const faces: string[] = [];
    for (let i = 0; i < indices.length; i += 3) {
        faces.push(`3 ${indices[i]} ${indices[i + 1]} ${indices[i + 2]}`);
    }

    // Combine header, vertices, and faces
    return [...header, ...vertices, ...faces].join("\n");
}

