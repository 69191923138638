export interface Vec2Interface {
    x: number;
    y:number;
}

export class Vec2 {
    x: number = 0;
    y: number = 0;


    set(x: number, y: number):Vec2 {
        this.x = x;
        this.y = y;
        return this;
    }

    add(vec2:Vec2Interface){
        this.x += vec2.x;
        this.y += vec2.y;
        return this;
    }
    sub(vec2:Vec2Interface){
        this.x -= vec2.x;
        this.y -= vec2.y;
        return this;
    }

    mulI(n:number):Vec2{
        this.x *= n;
        this.y *= n;
        return this;
    }

    clone(){
        return new Vec2().set(this.x, this.y);
    }

    copy(vec2:Vec2Interface){
       this.x = vec2.x;
       this.y = vec2.y;
        return this;
    }

    mag() {
        return Math.sqrt(this.x * this.x + this.y * this.y);
    }

    normalize() {
        let mag = this.mag();
        this.x /= mag;
        this.y /= mag;
        return this;
    }


    toDeg() {
        let normalized = this.clone().normalize();
        return (((Math.atan2(normalized.x, normalized.y + 0.0000001) / Math.PI) * 180) + 180) % 360;
    }

    toRad() {
        let normalized = this.clone().normalize();
        return (Math.atan2(normalized.x, normalized.y + 0.0000001));
    }
}