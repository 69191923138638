import { FC, useEffect, useState } from "react";
import {
    Button,
    Card,
    Col,
    Content,
    Row,
    Space,
} from "../layout/Content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Pagination } from "../layout/Defaults";
import GLRenderer from "../interactive/GLRenderer";
import { OakMesh } from "../../model/OakObject";

interface Entity {
    uuid: string;
    name: string;
    meshUuid?: string;
}

interface Mesh {
    uuid: string;
    name: string;
}

interface EntitySelectorProps {
    onUpdated: (uuid: string | null) => void;
}

const EntitySelector: FC<EntitySelectorProps> = ({ onUpdated }) => {
    const [entities, setEntities] = useState<Entity[]>([]);
    const [meshCache, setMeshCache] = useState<Record<string, Mesh>>({});
    const [selectedEntityUuid, setSelectedEntityUuid] = useState<string | null>(null);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);

    const fetchEntities = async (page: number, limit: number) => {
        setLoading(true);
        try {
            const response = await fetch(
                `${process.env.REACT_APP_DOMAIN_DELEGATOR}/entity/statistics?page=${page}&limit=${limit}`
            );
            if (!response.ok) throw new Error("Failed to fetch entities");
            const data = await response.json();
            setEntities(data.data || []);
            setTotal(data.total || 0);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const fetchMesh = async (uuid: string) => {
        if (meshCache[uuid]) return meshCache[uuid];
        try {
            const response = await fetch(`${process.env.REACT_APP_DOMAIN_DELEGATOR}/mesh/${uuid}`);
            if (!response.ok) throw new Error("Failed to fetch mesh data");
            const data = await response.json();
            setMeshCache((prev) => ({ ...prev, [uuid]: data }));
            return data;
        } catch (err) {
            console.error(err);
            return null;
        }
    };

    useEffect(() => {
        fetchEntities(page, limit);
    }, [page, limit]);

    useEffect(() => {
        entities.forEach((entity) => {
            if (entity.meshUuid) {
                fetchMesh(entity.meshUuid);
            }
        });
    }, [entities]);

    const handleSelectEntity = (uuid: string) => {
        if (selectedEntityUuid === uuid) {
            setSelectedEntityUuid(null);
            onUpdated(null);
        } else {
            setSelectedEntityUuid(uuid);
            onUpdated(uuid);
        }
    };

    return (
        <Content>
            <Space direction="vertical" GapSm>
                <Row GapSm>
                    {loading && <div>Loading...</div>}
                    {!loading &&
                        entities.map((entity) => (
                            <Col xs={8} md={6} lg={4} key={entity.uuid}>
                                <Card PadSm className={entity.uuid === selectedEntityUuid?"active":"default"} Full>
                                    <Space direction="vertical" GapSm justify={"space-between"} Full>
                                        <small><strong>{entity.name}</strong></small>
                                        {entity.meshUuid && meshCache[entity.meshUuid] && (
                                            <GLRenderer
                                                key={entity.meshUuid}
                                                options={{
                                                    autoRotate: true,
                                                    width: "100%",
                                                    height: 64,
                                                    buffersUUID: "any",
                                                    backgroundColor: [
                                                        Math.random(),
                                                        Math.random(),
                                                        Math.random(),
                                                        1,
                                                    ],
                                                    PLYDataGenerator: () => {
                                                        return {
                                                            ready: true,
                                                            static: new OakMesh(
                                                                undefined,
                                                                meshCache[entity.meshUuid||""] as OakMesh
                                                            ).getPLYData(),
                                                            dynamic: undefined,
                                                            shadows: undefined,
                                                        };
                                                    },
                                                }}
                                            />
                                        )}
                                        {/*<small>{entity.uuid}</small>*/}
                                        <Button
                                            size={"small"}
                                            type={
                                                selectedEntityUuid === entity.uuid
                                                    ? "danger"
                                                    : "active"
                                            }
                                            onClick={() => handleSelectEntity(entity.uuid)}
                                        >
                                            <FontAwesomeIcon icon={faCheck} />
                                            {selectedEntityUuid === entity.uuid
                                                ? "Deselect"
                                                : "Select"}
                                        </Button>
                                    </Space>
                                </Card>
                            </Col>
                        ))}
                </Row>
                <Pagination
                    current={page}
                    total={Math.ceil(total / limit)}
                    onChange={(p: number) => setPage(p)}
                />
            </Space>
        </Content>
    );
};

export default EntitySelector;
