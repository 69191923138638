export interface Vec3Interface {
    x:number;
    z:number;
    y:number;
}




export class Vec3 {
    x: number = 0;
    y: number = 0;
    z: number = 0;

    static identity(){
        return new Vec3().set(1, 1, 1);
    }

    static from(v:Vec3Interface) {
        return (new Vec3()).copy(v);
    }

    static fromValues(x:number, y:number, z:number) {
        return (new Vec3()).set(x, y, z);
    }

    static fromArray(arr:[number, number, number]) {
        return (new Vec3()).set(arr[0], arr[1], arr[2]);
    }

    set(x: number, y: number, z: number): Vec3 {
        this.x = x;
        this.y = y;
        this.z = z;
        return this;
    }

    clone() {
        return new Vec3().set(this.x, this.y, this.z);
    }

    lerp(v2:Vec3Interface, t:number) {
        return new Vec3().set(
            this.x + t * (v2.x - this.x),
            this.y + t * (v2.y - this.y),
            this.z + t * (v2.z - this.z)
        );
    }

    copy(v:Vec3Interface) {
        return this.set(v.x, v.y, v.z);
    }

    add(vec3:Vec3Interface) {
        this.x += vec3.x;
        this.y += vec3.y;
        this.z += vec3.z;
        return this;
    }

    sub(vec3:Vec3Interface) {
        this.x -= vec3.x;
        this.y -= vec3.y;
        this.z -= vec3.z;
        return this;
    }

    mul(vec3:Vec3Interface) {
        this.x *= vec3.x;
        this.y *= vec3.y;
        this.z *= vec3.z;
        return this;
    }

    div(vec3:Vec3) {
        this.x /= vec3.x;
        this.y /= vec3.y;
        this.z /= vec3.z;
        return this;
    }

    mulI(a: number) {
        this.x *= a;
        this.y *= a;
        this.z *= a;
        return this;
    }

    divI(a: number) {
        this.x /= a;
        this.y /= a;
        this.z /= a;
        return this;
    }

    dot(a:Vec3) {
        return (this.x * a.x + this.y * a.y + this.z * a.z);
    }

    rotX(deg:number) {
        // deg *= (Math.PI / 180);
        Vec3_TempV.y = (this.y * Math.cos(deg) - this.z * Math.sin(deg));
        Vec3_TempV.z = (this.y * Math.sin(deg) + this.z * Math.cos(deg));
        this.y = Vec3_TempV.y;
        this.z = Vec3_TempV.z;
        return this;
    }

    /** @type {function(number):Vec3} */
    rotY(deg:number) {
        //deg *= (Math.PI / 180);
        Vec3_TempV.x = (this.x * Math.cos(deg) - this.z * Math.sin(deg));
        Vec3_TempV.z = (this.x * Math.sin(deg) + this.z * Math.cos(deg));
        this.x = Vec3_TempV.x;
        this.z = Vec3_TempV.z;
        return this;
    }

    /** @type {function(number):Vec3} */
    rotZ(rad:number) {
        //deg *= (Math.PI / 180);
        //let b = new Vec3().set((this.x * Math.cos(a) - this.y * Math.sin(a)), (this.x * Math.sin(a) + this.y * Math.cos(a)), this.z);
        Vec3_TempV.x = (this.x * Math.cos(rad) - this.y * Math.sin(rad));
        Vec3_TempV.y = (this.x * Math.sin(rad) + this.y * Math.cos(rad));
        this.x = Vec3_TempV.x;
        this.y = Vec3_TempV.y;
        return this;
    }

    flipX() {
        this.x *= -1;
        return this;
    }

    flipZ() {
        this.z *= -1;
        return this;
    }

    /** @type {function():Vec3} */
    flipY() {
        this.y *= -1;
        return this;
    }

    toArray(){
        return [this.x, this.y, this.z];
    }

    cross(b:Vec3){
        return new Vec3().set(
            this.y * b.z - this.z * b.y,
            this.z * b.x - this.x * b.z,
            this.x * b.y - this.y * b.x
        )
    }

    pointTo(vec:Vec3Interface) {
        //let a = this.clone();
        // a.sub(vec).divI(a.dist(vec));
        // let d = this.dist(vec);
        this.sub(vec).invert().normalize();
        return this;
        // return a;
    }

    invert() {
        this.x *= -1;
        this.y *= -1;
        this.z *= -1;
        return this;
    }

    normalize() {
        Vec3_TempI = this.mag() + 0.0000000000001;
        this.x /= Vec3_TempI;
        this.y /= Vec3_TempI;
        this.z /= Vec3_TempI;
        return this;
    }
    mag() {
        return Math.sqrt(this.x * this.x + this.y * this.y + this.z * this.z);
    }

    dist(vec:Vec3Interface) {
        return Math.sqrt((this.x - vec.x) * (this.x - vec.x) + (this.y - vec.y) * (this.y - vec.y) + (this.z - vec.z) * (this.z - vec.z));
        // return Math.sqrt(Math.pow((this.x - vec.x), 2) + Math.pow((this.y - vec.y), 2) + Math.pow((this.z - vec.z), 2));
    }



}

let Vec3_TempI = 0, Vec3_TempV = new Vec3();
