import React, {
    createContext,
    useContext,
    useReducer,
    useState,
    ReactNode,
    FC,
    useEffect,
} from "react";
import {
    Button,
    Card,
    Col,
    Content,
    Divider,
    DropDown,
    Layout,
    Page,
    Paragraph,
    Row,
    Space,
    Title
} from "../layout/Content";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faSave} from "@fortawesome/free-regular-svg-icons";
import {
    faChalkboardTeacher,
    faChildren,
    faCog,
    faCube,
    faUser,
    faDiagramProject,
    faFolderOpen,
    faMinimize,
    faPaintBrush,
    faQuestionCircle,
    faStickyNote,
    faTimes,
    faUserNinja,
    faUserPlus,
    faVideo,
    faWindowMinimize,
    IconDefinition,
    faDragon,
    faRobot,
    faGhost,
    faCat,
    faDog,
    faSmile,
    faHatWizard,
    faPersonBooth,
    faCrown,
    faChevronLeft, faX
} from "@fortawesome/free-solid-svg-icons";
import "./Desktop.scss";
import {NotepadApp} from "../keypiece-apps/NotepadApp";
import {MeshEditorApp} from "./MeshEditorApp";
import {ProfileSelection} from "./ProfileSelection";
import SceneEditor from "./SceneEditorApp";
import SceneEditorApp from "./SceneEditorApp";
import {ClientApp} from "./ClientApp";
import CharactersApp from "./CharactersApp";
import UserCharacterProvider from "./Provider";
import TutoringApp from "./TutoringApp";
import EntitiesApp from "./EntityEditorApp";

export const ICON_MAP: Record<string, IconDefinition> = {
    faUser: faUser,
    faUserNinja: faUserNinja,
    faChildren: faChildren,
    faUserPlus: faUserPlus,
    faDragon: faDragon,
    faRobot: faRobot,
    faGhost: faGhost,
    faCat: faCat,
    faDog: faDog,
    faSmile: faSmile,
    faHatWizard: faHatWizard,
    faPersonBooth: faPersonBooth,
    faCrown: faCrown,
};

export const decodeIcon = (icon: string): IconDefinition => {
    return ICON_MAP[icon] || faUser;
};

// Persistent State Handling
class StateManager {
    static STORAGE_KEY = "desktop_state";

    static loadState(): AppState {
        const stored = localStorage.getItem(this.STORAGE_KEY);
        if (stored) {
            try {
                return JSON.parse(stored);
            } catch (e) {
                console.error("Failed to parse stored state", e);
            }
        }
        return initialState;
    }

    static saveState(state: AppState) {
        localStorage.setItem(this.STORAGE_KEY, JSON.stringify(state));
    }
}

// App State and Actions
interface Profile {
    id: number;
    name: string;
    icon: string; // Icon stored as string
}

interface AppState {
    activeApp: string | null;
    minimizedApps: string[];
    currentProfile: number | null;
    profiles: Profile[];
    userFiles: Record<number, Record<string, string>>;
    editingFile: string | null;
}

const initialState: AppState = {
    activeApp: null,
    minimizedApps: [],
    currentProfile: null,
    profiles: [],
    userFiles: {},
    editingFile: null,
};

type AppAction =
    | { type: "OPEN_APP"; app: string }
    | { type: "CLOSE_APP"; app: string }
    | { type: "MINIMIZE_APP"; app: string }
    | { type: "SWITCH_PROFILE"; profileId: number }
    | { type: "LOGOUT" }
    | { type: "OPEN_FILE"; fileName: string }
    | { type: "CLOSE_FILE" }
    | { type: "ADD_PROFILE"; profile: Profile }
    | { type: "DELETE_PROFILE"; profileId: number };

const appReducer = (state: AppState, action: AppAction): AppState => {
    switch (action.type) {
        case "OPEN_APP":
            return {
                ...state,
                activeApp: action.app,
                minimizedApps: state.minimizedApps.filter((a) => a !== action.app),
            };
        case "CLOSE_APP":
            return {
                ...state,
                activeApp: state.activeApp === action.app ? null : state.activeApp,
                minimizedApps: state.minimizedApps.filter((a) => a !== action.app),
            };
        case "MINIMIZE_APP":
            return {
                ...state,
                activeApp: null,
                minimizedApps: [...state.minimizedApps, action.app],
            };
        case "SWITCH_PROFILE":
            return {
                ...state,
                currentProfile: action.profileId,
                activeApp: null,
                minimizedApps: [],
                editingFile: null,
            };
        case "LOGOUT":
            return {...state, currentProfile: null, activeApp: null, minimizedApps: [], editingFile: null};
        case "OPEN_FILE":
            return {...state, editingFile: action.fileName};
        case "CLOSE_FILE":
            return {...state, editingFile: null};
        case "ADD_PROFILE":
            return {
                ...state,
                profiles: [...state.profiles, action.profile],
                userFiles: {...state.userFiles, [action.profile.id]: {}},
            };
        case "DELETE_PROFILE":
            const {[action.profileId]: _, ...remainingFiles} = state.userFiles;
            return {
                ...state,
                profiles: state.profiles.filter((p) => p.id !== action.profileId),
                userFiles: remainingFiles,
                currentProfile: state.currentProfile === action.profileId ? null : state.currentProfile,
            };
        default:
            return state;
    }
};

// App Context
const AppContext = createContext<{
    state: AppState;
    dispatch: React.Dispatch<AppAction>;
    getProfileById: (id: number) => Profile|undefined;
    addProfile: (name: string, icon: string) => void;
    deleteProfile: (id: number) => void;
} | null>(null);

const AppProvider: FC<{ children: ReactNode }> = ({children}) => {
    const [state, dispatch] = useReducer(appReducer, StateManager.loadState());
    const [nextProfileId, setNextProfileId] = useState(1);

    useEffect(() => {
        StateManager.saveState(state);
    }, [state]);

    const getProfileById = (id:number) => {
        return state.profiles.find((p)=>{return p.id===id;})
    }

    const addProfile = (name: string, icon: string) => {
        dispatch({type: "ADD_PROFILE", profile: {id: nextProfileId, name, icon}});
        setNextProfileId(nextProfileId + 1);
    };

    const deleteProfile = (id: number) => {
        dispatch({type: "DELETE_PROFILE", profileId: id});
    };

    return (
        <AppContext.Provider value={{state, dispatch, getProfileById, addProfile, deleteProfile}}>
            {children}
        </AppContext.Provider>
    );
};


export const useAppContext = () => {
    const context = useContext(AppContext);
    if (!context) throw new Error("useAppContext must be used within AppProvider");
    return context;
};


// Calculator App
const CalculatorApp: FC = () => (
    <div style={{padding: 20}}>
        <h2>Calculator App</h2>
        <p>Simple calculator functionality placeholder.</p>
    </div>
);


const getSimpleIcon = (icon: IconDefinition, label: string, onClick?: any) => {
    return <Col xs={8} sm={8} md={6} lg={4}>
        <Space direction={"vertical"} GapSm>
            <Card Pad Full

                  key={"new-user"}
                  onClick={onClick}
            >
                <Space justify={"space-around"}> <FontAwesomeIcon className={"muted"} icon={icon} size={"2x"}/></Space>
            </Card>
            <Space
                justify={"space-around"}><Paragraph>{label}</Paragraph></Space>
        </Space>
    </Col>;
}

// Desktop Component
const Desktop: FC = () => {
    const {state, dispatch, getProfileById} = useAppContext();


    if (!state.currentProfile) {
        return <ProfileSelection/>;
    }

    return (
        <Layout className={"fixed"}>

            {state.activeApp && (
                <Page>
                    <Content PadSm>
                        <Space justify={"end"} GapSm align={"center"}>

                            <Paragraph><strong>{state.activeApp}</strong></Paragraph>
                            <Divider/>

                            <Button
                                size={"small"}
                                onClick={() => dispatch({type: "MINIMIZE_APP", app: state.activeApp!})}
                            >
                                <Space><FontAwesomeIcon icon={faWindowMinimize} fixedWidth/></Space>
                            </Button>
                            <Button
                                type={"danger"}
                                size={"small"}
                                onClick={() => dispatch({type: "CLOSE_APP", app: state.activeApp!})}
                            >
                                <Space><FontAwesomeIcon icon={faX} fixedWidth/></Space>
                            </Button>
                        </Space>
                    </Content>
                </Page>
            )}

            {state.activeApp === "Notepad" && <NotepadApp/>}
            {state.activeApp === "Calculator" && <CalculatorApp/>}
            {state.activeApp === "Mesh Editor" && <MeshEditorApp/>}
            {state.activeApp === "Scene Editor" && <SceneEditorApp />}
            {state.activeApp === "Client" && <ClientApp />}
            {state.activeApp === "Characters" && <CharactersApp />}
            {state.activeApp === "Tutoring" && <TutoringApp />}
            {state.activeApp === "Entities" && <EntitiesApp />}

            {!state.activeApp && (
                <Page Grow style={{flex: 1, background: "#def6ff", padding: 20}}>
                    {/*<h1>{`Welcome, ${MOCK_PROFILES.find((p) => p.id === state.currentProfile)?.name}`}</h1>*/}
                    <Space direction={"vertical"} Gap>
                        <Row GapSm>

                            {getSimpleIcon(faQuestionCircle, "Start Here", () => dispatch({
                                type: "OPEN_APP",
                                app: "Notepad"
                            }))}
                            {getSimpleIcon(faCog, "Settings", () => dispatch({type: "OPEN_APP", app: "Documents"}))}

                        </Row>
                        <Row Gap>
                            <Col xs={24}>
                                <Space GapSm><Paragraph><strong>Applications & Media</strong></Paragraph>
                                    <Divider/></Space></Col>

                            {getSimpleIcon(faStickyNote, "Notepad", () => dispatch({type: "OPEN_APP", app: "Notepad"}))}
                            {getSimpleIcon(faChalkboardTeacher, "Tutoring", () => dispatch({
                                type: "OPEN_APP",
                                app: "Tutoring"
                            }))}
                            {getSimpleIcon(faCalendar, "Calendar", () => dispatch({type: "OPEN_APP", app: "Notepad"}))}

                            {getSimpleIcon(faFolderOpen, "Projects", () => dispatch({
                                type: "OPEN_APP",
                                app: "Documents"
                            }))}
                            {getSimpleIcon(faFolderOpen, "Documents", () => dispatch({
                                type: "OPEN_APP",
                                app: "Documents"
                            }))}
                            {getSimpleIcon(faFolderOpen, "Settings", () => dispatch({
                                type: "OPEN_APP",
                                app: "Documents"
                            }))}
                        </Row>
                        <Row Gap>
                            <Col xs={24}>
                                <Space GapSm><Paragraph><strong>Creative Tools</strong></Paragraph>
                                    <Divider/></Space></Col>
                            {getSimpleIcon(faPaintBrush, "Images", () => dispatch({type: "OPEN_APP", app: "Notepad"}))}
                            {getSimpleIcon(faDiagramProject, "Rooms & Scenes", () => dispatch({
                                type: "OPEN_APP",
                                app: "Scene Editor"
                            }))}
                            {getSimpleIcon(faCube, "Mesh Editor", () => dispatch({
                                type: "OPEN_APP",
                                app: "Mesh Editor"
                            }))}
                            {getSimpleIcon(faVideo, "Client", () => dispatch({type: "OPEN_APP", app: "Client"}))}
                            {getSimpleIcon(faVideo, "Characters", () => dispatch({type: "OPEN_APP", app: "Characters"}))}

                            {getSimpleIcon(faVideo, "Entities", () => dispatch({type: "OPEN_APP", app: "Entities"}))}
                            {getSimpleIcon(faVideo, "Sounds", () => dispatch({type: "OPEN_APP", app: "Documents"}))}
                            {getSimpleIcon(faVideo, "Sounds", () => dispatch({type: "OPEN_APP", app: "Documents"}))}
                        </Row>
                    </Space>


                </Page>
            )}

            <Page style={{background: "#eee"}}>
                <Content PadSm>
                    <Space align={"center"} justify={"space-between"}>
                        {/*<span>Active App: {state.activeApp || "None"}</span>*/}

                        <Space GapSm align={"center"}><FontAwesomeIcon size={"2x"}
                                               icon={decodeIcon(getProfileById(state.currentProfile)?.icon||"faUser") || faUser}/> {getProfileById(state.currentProfile)?.name}</Space>
                        {/*<span>Minimized: {state.minimizedApps.join(", ") || "None"}</span>*/}
                        <button
                            onClick={() => dispatch({type: "LOGOUT"})}
                        >
                            Switch Profile
                        </button>
                    </Space>
                </Content>
            </Page>
        </Layout>
    );
};

// Main Export
const DesktopApp = () => (
    <AppProvider>

            <UserCharacterProvider>
        <Desktop/>
            </UserCharacterProvider>

    </AppProvider>
);

export default DesktopApp;
