export function capitalizeFirstLetter(word: string): string {
    if (!word) return '';
    return word.charAt(0).toUpperCase() + word.slice(1);
}

export function fixPrecision(p:string){
    while (p.endsWith("0") && p[p.length-2] !== "."){
        p = p.slice(0,p.length-1);
    }
    return p;
}