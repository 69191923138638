import { FC, useEffect, useState } from "react";
import {
    Button,
    Card,
    Col,
    Content,
    Divider,
    Page,
    Row,
    Space,
    Paragraph,
    Pill,
} from "../layout/Content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashCan, faEdit, faSave } from "@fortawesome/free-solid-svg-icons";
import Popconfirm, { Pagination } from "../layout/Defaults";

interface Character {
    uuid?: string;
    name: string;
    owner: string;
    stats: Record<string, number>;
    inventory: { count: number; itemUUID: string }[];
    createdAt?: string;
    updatedAt?: string;
}

const CharactersApp: FC = () => {
    const [characters, setCharacters] = useState<Character[]>([]);
    const [selectedCharacter, setSelectedCharacter] = useState<Character | null>(null);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [hasChanges, setHasChanges] = useState(false);

    const fetchStatistics = async (page: number, limit: number) => {
        setLoading(true);
        try {
            const response = await fetch(
                `${process.env.REACT_APP_DOMAIN_DELEGATOR}/character/statistics?page=${page}&limit=${limit}`
            );
            if (!response.ok) throw new Error("Failed to fetch character statistics");
            const data = await response.json();
            setCharacters(data.data || []);
            setTotal(data.total || 0);
        } catch (err: any) {
            setError(err.message || "An unknown error occurred");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchStatistics(page, limit);
    }, [page, limit]);

    const handleSaveCharacter = async () => {
        if (!selectedCharacter) return;
        try {
            const method = selectedCharacter.uuid ? "PUT" : "POST";
            const url = selectedCharacter.uuid
                ? `${process.env.REACT_APP_DOMAIN_DELEGATOR}/character/${selectedCharacter.uuid}`
                : `${process.env.REACT_APP_DOMAIN_DELEGATOR}/character`;

            const response = await fetch(url, {
                method,
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(selectedCharacter),
            });

            if (!response.ok) throw new Error("Failed to save character");

            const updatedCharacter = await response.json();
            setCharacters((prev) =>
                selectedCharacter.uuid
                    ? prev.map((char) =>
                        char.uuid === updatedCharacter.uuid ? updatedCharacter : char
                    )
                    : [updatedCharacter, ...prev]
            );

            setSelectedCharacter(null);
            setHasChanges(false);
        } catch (err: any) {
            setError(err.message || "An unknown error occurred");
        }
    };

    const handleDeleteCharacter = async (uuid: string) => {
        try {
            await fetch(`${process.env.REACT_APP_DOMAIN_DELEGATOR}/character/${uuid}`, {
                method: "DELETE",
            });

            setCharacters((prev) => prev.filter((char) => char.uuid !== uuid));
        } catch (err: any) {
            setError(err.message || "An unknown error occurred");
        }
    };

    const addCharacter = () => {
        setSelectedCharacter({
            name: "New Character",
            owner: "",
            stats: {
                melee: 0,
                defense: 0,
                archery: 0,
                magic: 0,
                summoning: 0,
                mining: 0,
                fishing: 0,
                woodcutting: 0,
                fletching: 0,
                cooking: 0,
                crafting: 0,
            },
            inventory: [],
        });
        setHasChanges(true);
    };

    const updateCharacterField = (field: keyof Character, value: any) => {
        if (!selectedCharacter) return;
        setSelectedCharacter({ ...selectedCharacter, [field]: value });
        setHasChanges(true);
    };

    return (
        <Page Grow>
            <Row Full>
                {!selectedCharacter && (
                    <Col xs={24}>
                        <Content Pad>
                            <Space direction="vertical" GapSm>
                                <Space justify="space-between">
                                    <Button onClick={addCharacter}>
                                        <FontAwesomeIcon icon={faPlus} /> Add Character
                                    </Button>
                                </Space>
                                <Pagination
                                    current={page}
                                    total={Math.ceil(total / limit)}
                                    onChange={(p: number) => setPage(p)}
                                />
                                {loading && <div>Loading...</div>}
                                {error && <div style={{ color: "red" }}>{error}</div>}
                                <Row>
                                    {characters.map((character) => (
                                        <Col xs={12} md={8} lg={6} key={character.uuid || ""}>
                                            <Card Pad>
                                                <Space direction="vertical" GapSm>
                                                    <small>{character.uuid}</small>
                                                    <Paragraph>{character.name}</Paragraph>
                                                    <Space>
                                                        <Pill>
                                                            Owner: {character.owner}
                                                        </Pill>
                                                        <Button
                                                            size="small"
                                                            onClick={() => setSelectedCharacter(character)}
                                                        >
                                                            <FontAwesomeIcon icon={faEdit} />
                                                        </Button>
                                                        <Popconfirm
                                                            message="Are you sure you want to delete this character?"
                                                            onOk={() =>
                                                                handleDeleteCharacter(character.uuid || "")
                                                            }
                                                        >
                                                            <Button size="small" type="danger">
                                                                <FontAwesomeIcon icon={faTrashCan} />
                                                            </Button>
                                                        </Popconfirm>
                                                    </Space>
                                                </Space>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            </Space>
                        </Content>
                    </Col>
                )}
                {selectedCharacter && (
                    <Col xs={24}>
                        <Content Pad>
                            <Space direction="vertical" GapSm>
                                <Space justify="space-between">
                                    <Button onClick={() => setSelectedCharacter(null)}>Back</Button>
                                    <Button
                                        type={hasChanges ? "active" : "default"}
                                        disabled={!hasChanges}
                                        onClick={handleSaveCharacter}
                                    >
                                        <FontAwesomeIcon icon={faSave} /> Save
                                    </Button>
                                </Space>
                                <Divider />
                                <label>
                                    Name:
                                    <input
                                        value={selectedCharacter.name}
                                        onChange={(e) =>
                                            updateCharacterField("name", e.target.value)
                                        }
                                    />
                                </label>
                                <Divider />
                                <label>
                                    Owner:
                                    <input
                                        value={selectedCharacter.owner}
                                        onChange={(e) =>
                                            updateCharacterField("owner", e.target.value)
                                        }
                                    />
                                </label>
                                <Divider />
                                <Space direction="vertical" GapSm>
                                    Inventory:
                                    {selectedCharacter.inventory.map((item, idx) => (
                                        <Card Pad key={idx}>
                                            <Space justify="space-between">
                                                <Paragraph>
                                                    Item: {item.itemUUID} (x{item.count})
                                                </Paragraph>
                                                <Button
                                                    size="small"
                                                    type="danger"
                                                    onClick={() =>
                                                        updateCharacterField(
                                                            "inventory",
                                                            selectedCharacter.inventory.filter(
                                                                (_, i) => i !== idx
                                                            )
                                                        )
                                                    }
                                                >
                                                    <FontAwesomeIcon icon={faTrashCan} />
                                                </Button>
                                            </Space>
                                        </Card>
                                    ))}
                                    <Button
                                        onClick={() =>
                                            updateCharacterField("inventory", [
                                                ...selectedCharacter.inventory,
                                                { count: 1, itemUUID: "new-item" },
                                            ])
                                        }
                                    >
                                        Add Item
                                    </Button>
                                </Space>
                            </Space>
                        </Content>
                    </Col>
                )}
            </Row>
        </Page>
    );
};

export default CharactersApp;
