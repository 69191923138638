import {PLYCenteredCube01} from "../component/interactive/mesh/MeshTile01";
import {joinPLYData, parsePLY, PLYData} from "./PLY";


export const GRAPHICAL_LOD_LEVELS: string[] = ["na", "ld", "sd", "default", "hd"];


export interface ClientRenderingOptions {

    USER_OPTION_COMPATIBILITY_MODE: boolean;
    USER_OPTION_ACCESSIBILITY_LOW_CPU: boolean;
    USER_OPTION_LIMIT_DEVICE_PIXEL_RATIO: boolean;
    USER_OPTION_USE_RENDER_PANELS: boolean;
    USER_OPTION_DEBUG_MODE: boolean;

    USER_OPTION_SHADOWS: boolean;
    USER_OPTION_SOFT_SHADOWS: boolean;

    USER_SETTING_RENDER_ONLY: number;
    USER_SETTING_SHADOW_QUALITY: number;
    USER_SETTING_PARTICLE_QUALITY: number;
    USER_SETTING_MESH_QUALITY: number;
    USER_SETTING_SHADOW_SIZE: number;

    USER_OPTION_SOUND_EFFECT: boolean;

}

export function defaultClientRenderingOptions(): ClientRenderingOptions {

    let d = {
        USER_OPTION_COMPATIBILITY_MODE: false,
        USER_OPTION_ACCESSIBILITY_LOW_CPU: true,
        USER_OPTION_LIMIT_DEVICE_PIXEL_RATIO: true,
        USER_OPTION_USE_RENDER_PANELS: false,
        USER_OPTION_DEBUG_MODE: false,

        USER_OPTION_SHADOWS: true,
        USER_OPTION_SOFT_SHADOWS: true,

        USER_SETTING_MESH_QUALITY: 0.75,
        USER_SETTING_PARTICLE_QUALITY: 0.5,
        USER_SETTING_RENDER_ONLY: 0,
        USER_SETTING_SHADOW_QUALITY: 8,
        USER_SETTING_SHADOW_SIZE: 1024,

        USER_OPTION_SOUND_EFFECT: false
    };

    try {
        let v = window.localStorage.getItem("maag-rendering-options");
        if (v) {
            let dd = JSON.parse(v);
            d = {...d, ...dd};
        }

    } catch (e) {
        console.log("missed rendering option");
    }

    return d;
}

export class RemotePLYLoader {

    requested: string[];
    gotResults: string[];
    ready: any;
    defaultMesh: PLYData;
    renderingOptions: ClientRenderingOptions;

    constructor() {
        this.requested = [];
        this.gotResults = [];
        this.ready = [];
        this.defaultMesh = parsePLY(PLYCenteredCube01);
        this.renderingOptions = defaultClientRenderingOptions();
    }

    setClientRenderingOptions(options: ClientRenderingOptions) {
        if (options.USER_SETTING_MESH_QUALITY !== this.renderingOptions.USER_SETTING_MESH_QUALITY || options.USER_OPTION_COMPATIBILITY_MODE !== this.renderingOptions.USER_OPTION_COMPATIBILITY_MODE) {
            // this.requested = [];
            // this.ready = {};
        }
        this.renderingOptions = options;
    }

    async fetch(filename: string): Promise<PLYData> {

        const key = filename;

        if (this.requested.indexOf(key) === -1) {
            // console.log('STARTING SEARCH B AT', level, idx, lookup, `/ply/${idx}.${lookup}.ply`);

            this.requested.push(key);

            await fetch(`/ply/${key}.ply`)
                .then(response => response.text())
                .then((data) => {
                    const isPLY = data.indexOf("format ascii 1.0") !== -1;
                    this.gotResults.push(key);
                    if (isPLY) {
                        const mesh = parsePLY(data);
                        this.ready[key] = mesh;
                    } else {
                        console.error('UNABLE TO DECODE PLY NON ASCII FORMAT');
                    }
                })
                .catch((error) => {
                    this.gotResults.push(key);

                    console.log("will try again at next level", key);
                    console.error(error)
                });
        }

        return this.ready[key] || this.defaultMesh;

    }

    request(idx: string, level: number) {

        //const ref = this;
        const lookup = GRAPHICAL_LOD_LEVELS[level];

        if (this.requested.indexOf(idx.toString() + level.toString()) === -1) {
            // console.log('STARTING SEARCH B AT', level, idx, lookup, `/ply/${idx}.${lookup}.ply`);

            this.requested.push(idx.toString() + level.toString());

            fetch(`/ply/${idx}.${lookup}.ply`)
                .then(response => response.text())
                .then((data) => {
                    const isPLY = data.indexOf("format ascii 1.0") !== -1;
                    this.gotResults.push(idx.toString() + level.toString());
                    if (isPLY) {

                        const mesh = parsePLY(data);
                        this.ready[idx.toString() + level.toString()] = mesh;

                    } else {

                    }

                })
                .catch((error) => {
                    this.gotResults.push(idx.toString() + level.toString());

                    console.log("will try again at next level", idx, lookup);
                    console.error(error)
                });
        }

    }

    walkDown(idx: string, level: number): PLYData | false {
        if (level === 0) {
            return this.walkUp(idx, 0);
        }
        if (this.ready[idx.toString() + level.toString()]) {
            return this.ready[idx.toString() + level.toString()];
        }
        this.request(idx, level);
        if (this.gotResults.indexOf(idx + level) === -1) {
            return false;
        }

        return this.walkDown(idx, level - 1)
    }

    walkUp(idx: string, level: number): PLYData | false {

        // console.log("walk up", idx, level);

        if (level === GRAPHICAL_LOD_LEVELS.length) {
            return false;
        }
        if (this.ready[idx.toString() + level.toString()]) {
            return this.ready[idx.toString() + level.toString()];
        }

        this.request(idx, level);
        if (this.gotResults.indexOf(idx.toString() + level.toString()) === -1) {
            return false;
        }
        return this.walkUp(idx, level + 1);
    }

    getSearchFrom() {

        if (this.renderingOptions.USER_OPTION_COMPATIBILITY_MODE) {
            return 0;
        }

        let d = 0;


        if (this.renderingOptions.USER_SETTING_MESH_QUALITY <= 1) {
            d = 4;
        }
        if (this.renderingOptions.USER_SETTING_MESH_QUALITY <= 0.75) {
            d = 3;
        }
        if (this.renderingOptions.USER_SETTING_MESH_QUALITY <= 0.5) {
            d = 2;
        }
        if (this.renderingOptions.USER_SETTING_MESH_QUALITY <= 0.25) {
            d = 1
        }
        if (this.renderingOptions.USER_SETTING_MESH_QUALITY === 0) {
            d = 0;
        }

        return d;

    }

    getMeshByIdx(idx: string): PLYData {

        const searchFrom = this.getSearchFrom();

        return joinPLYData([this.walkDown(idx, searchFrom) || this.defaultMesh]);//// joinPLYData([this.walkDown(idx, searchFrom) || this.defaultMesh]);

    }

    getMeshRawByIdx(idx: string): PLYData | false {

        const searchFrom = this.getSearchFrom();

        return this.walkDown(idx, searchFrom);

    }

    hasMeshByIdx(idx: string) {

        const searchFrom = this.getSearchFrom();

        return !!this.walkDown(idx, searchFrom);

    }

}

export const remotePLYLoader = new RemotePLYLoader();
