
// Camera Context and Provider
import React, {createContext, FC, ReactNode, useCallback, useContext, useEffect, useRef, useState} from "react";
import {Button, Paragraph, Space} from "../layout/Content";
import {FullScreenApp} from "../KeyPiece";
import {APP_CAMERA} from "./BaseApp";

export interface CameraContextProps {
    videoRef: React.RefObject<HTMLVideoElement>;
    stream: MediaStream | null;
    cameraError: string | null;
    isStarting: boolean;
    startCamera: () => Promise<void>;
    stopCamera: () => Promise<void>;
    takePhoto: () => string | null;
}

export const CameraContext = createContext<CameraContextProps | undefined>(undefined);

export const CameraProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [stream, setStream] = useState<MediaStream | null>(null);
    const videoRef = useRef<HTMLVideoElement>(null);
    const isMountedRef = useRef<boolean>(true);
    const [cameraError, setCameraError] = useState<string | null>(null);
    const [isStarting, setIsStarting] = useState<boolean>(false);

    useEffect(() => {
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        };
    }, []);

    const startCamera = useCallback(async () => {
        setCameraError(null);
        setIsStarting(true);
        try {
            const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true });
            if (!isMountedRef.current) {
                mediaStream.getTracks().forEach((t) => t.stop());
                setIsStarting(false);
                return;
            }

            setStream(mediaStream);

            if (videoRef.current) {
                videoRef.current.srcObject = mediaStream;
                videoRef.current.onloadedmetadata = () => {
                    if (isMountedRef.current && videoRef.current) {
                        videoRef.current
                            .play()
                            .catch((err) => {
                                if (err.name !== "AbortError") {
                                    console.error("Video play error:", err);
                                }
                            })
                            .finally(() => {
                                setIsStarting(false);
                            });
                    } else {
                        setIsStarting(false);
                    }
                };
            } else {
                setIsStarting(false);
            }
        } catch (error: any) {
            console.error("Error accessing camera:", error);
            if (error.name === "NotAllowedError") {
                setCameraError("Camera access was denied. Please enable camera permissions.");
            } else {
                setCameraError("Could not access camera. Please check your device settings.");
            }
            setIsStarting(false);
        }
    }, []);

    const stopCamera = useCallback(async () => {
        if (videoRef.current) {
            try {
                videoRef.current.pause();
            } catch {}
            videoRef.current.srcObject = null;
            videoRef.current.removeAttribute("src");
            videoRef.current.load();
        }

        if (stream) {
            for (const track of stream.getTracks()) {
                track.stop();
            }
        }

        setStream(null);
    }, [stream]);

    const takePhoto = useCallback((): string | null => {
        if (!videoRef.current || !stream) return null;
        const video = videoRef.current;
        if (video.videoWidth === 0 || video.videoHeight === 0) return null;

        const canvas = document.createElement("canvas");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const ctx = canvas.getContext("2d");
        if (!ctx) return null;

        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        return canvas.toDataURL("image/png");
    }, [stream]);

    return (
        <CameraContext.Provider value={{ videoRef, startCamera, stopCamera, takePhoto, stream, cameraError, isStarting }}>
            {children}
        </CameraContext.Provider>
    );
};

export const useCameraContext = (): CameraContextProps => {
    const ctx = useContext(CameraContext);
    if (!ctx) {
        throw new Error("useCameraContext must be used within a CameraProvider");
    }
    return ctx;
};

export const CameraApp: FC<{ onClose: () => void; onMinimize: () => void }> = ({ onClose, onMinimize }) => {
    const { videoRef, startCamera, stopCamera, takePhoto, stream, cameraError, isStarting } = useCameraContext();
    const [photoData, setPhotoData] = useState<string | null>(null);

    useEffect(() => {
        // Start camera on mount
        startCamera();
    }, [startCamera]);

    const handleClose = async () => {
        await stopCamera();
        onClose();
    };

    const handleMinimize = async () => {
        await stopCamera();
        onMinimize();
    };

    const handleTakePhoto = () => {
        const data = takePhoto();
        if (data) {
            setPhotoData(data);
        }
    };

    const handleStopCamera = async () => {
        await stopCamera();
        setPhotoData(null);
    };

    const handleRestartCamera = async () => {
        await stopCamera(); // ensure fresh start
        setPhotoData(null);
        startCamera();
    };

    return (
        <FullScreenApp
            title={APP_CAMERA}
            onClose={handleClose}
            onMinimize={handleMinimize}
        >
            <Space direction="vertical" Full Gap>
                {!cameraError && (
                    <div style={{ maxHeight: "50%" }}>
                        <video
                            style={{ width: "100%", height: "100%" }}
                            ref={videoRef}
                            playsInline
                        />
                    </div>
                )}

                {cameraError && (
                    <Paragraph>
                        {cameraError}
                    </Paragraph>
                )}

                <Space Gap>
                    {!cameraError && stream && (
                        <>
                            <Button onClick={handleTakePhoto}>Take Photo</Button>
                            <Button type="danger" onClick={handleStopCamera}>
                                Stop Camera
                            </Button>
                        </>
                    )}

                    {!cameraError && !stream && (
                        <Button onClick={handleRestartCamera}>
                            {isStarting ? "Starting..." : "Start Camera"}
                        </Button>
                    )}

                    {cameraError && (
                        <Button onClick={handleRestartCamera}>
                            {isStarting ? "Starting..." : "Try Again"}
                        </Button>
                    )}
                </Space>

                {photoData && (
                    <Space direction="vertical" Gap>
                        <Paragraph>Captured Photo:</Paragraph>
                        <img src={photoData} alt="Captured" style={{ maxWidth: "100%" }} />
                    </Space>
                )}
            </Space>
        </FullScreenApp>
    );
};