import React, {FC, useState} from "react";
import {
    Button,
    Card,
    Col,
    Content,
    Divider,
    Page,
    Row,
    Space,
    Paragraph,
    Title,
} from "../layout/Content";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faArrowLeft} from "@fortawesome/free-solid-svg-icons";

interface Topic {
    id: string;
    name: string;
    completed: boolean;
    question: string;
    answer: string;
}

interface Course {
    id: string;
    name: string;
    topics: Topic[];
    completed: boolean;
}

const coursesData: Course[] = [
    {
        id: "1",
        name: "Logic",
        completed: false,
        topics: [
            { id: "1-1", name: "Introduction to Boolean Logic", completed: false, question: "What are the basic Boolean operators?", answer: "" },
            { id: "1-2", name: "Truth Tables", completed: false, question: "What is a truth table?", answer: "" },
            { id: "1-3", name: "Logic Gates", completed: false, question: "Explain the function of an AND gate.", answer: "" },
            { id: "1-4", name: "Applications of Logic in Game Development", completed: false, question: "How is Boolean logic used in decision-making systems in games?", answer: "" },
        ],
    },
    {
        id: "2",
        name: "2D Graphics",
        completed: false,
        topics: [
            { id: "2-1", name: "Introduction to 2D Graphics", completed: false, question: "What are the key differences between raster and vector graphics?", answer: "" },
            { id: "2-2", name: "Drawing with Pixels", completed: false, question: "How does a computer draw pixels on the screen?", answer: "" },
            { id: "2-3", name: "Transformations in 2D", completed: false, question: "What is a 2D transformation matrix?", answer: "" },
            { id: "2-4", name: "Rendering Pipelines", completed: false, question: "Describe the 2D rendering pipeline.", answer: "" },
        ],
    },
    {
        id: "3",
        name: "3D Rendering",
        completed: false,
        topics: [
            { id: "3-1", name: "3D Basics", completed: false, question: "What are vertices, edges, and faces in 3D graphics?", answer: "" },
            { id: "3-2", name: "Projection Matrices", completed: false, question: "What is the purpose of a projection matrix in 3D rendering?", answer: "" },
            { id: "3-3", name: "Lighting Models", completed: false, question: "What is the difference between Phong and Gouraud shading?", answer: "" },
            { id: "3-4", name: "Rendering Optimizations", completed: false, question: "What are common techniques for optimizing 3D rendering?", answer: "" },
        ],
    },
    {
        id: "4",
        name: "State Machines",
        completed: false,
        topics: [
            { id: "4-1", name: "Introduction to State Machines", completed: false, question: "What is a finite state machine (FSM)?", answer: "" },
            { id: "4-2", name: "State Transition Diagrams", completed: false, question: "How do you represent state transitions graphically?", answer: "" },
            { id: "4-3", name: "Hierarchical State Machines", completed: false, question: "What are the advantages of hierarchical state machines?", answer: "" },
            { id: "4-4", name: "Implementing State Machines in Code", completed: false, question: "Provide an example of a simple FSM implementation in code.", answer: "" },
        ],
    },
    {
        id: "5",
        name: "Game Physics",
        completed: false,
        topics: [
            { id: "5-1", name: "Newtonian Mechanics Basics", completed: false, question: "What are the three laws of motion?", answer: "" },
            { id: "5-2", name: "Collision Detection", completed: false, question: "How does a game detect collisions between objects?", answer: "" },
            { id: "5-3", name: "Physics Engines", completed: false, question: "What is the role of a physics engine in games?", answer: "" },
            { id: "5-4", name: "Simulating Rigid Bodies", completed: false, question: "What is a rigid body simulation?", answer: "" },
        ],
    },
    {
        id: "6",
        name: "AI for Games",
        completed: false,
        topics: [
            { id: "6-1", name: "Pathfinding Basics", completed: false, question: "What is the A* algorithm?", answer: "" },
            { id: "6-2", name: "Behavior Trees", completed: false, question: "Explain the structure of a behavior tree.", answer: "" },
            { id: "6-3", name: "Decision Making in AI", completed: false, question: "How do AI systems make decisions in games?", answer: "" },
            { id: "6-4", name: "AI Optimizations", completed: false, question: "What are common strategies for optimizing AI performance in games?", answer: "" },
        ],
    },
];


const TutoringApp: FC = () => {
    const [courses, setCourses] = useState<Course[]>(coursesData);
    const [selectedCourse, setSelectedCourse] = useState<Course | null>(null);
    const [selectedTopic, setSelectedTopic] = useState<Topic | null>(null);

    const markTopicCompleted = (topicId: string) => {
        if (!selectedCourse) return;
        const updatedCourse = {
            ...selectedCourse,
            topics: selectedCourse.topics.map((topic) =>
                topic.id === topicId ? {...topic, completed: true} : topic
            ),
        };

        const updatedCourses = courses.map((course) =>
            course.id === updatedCourse.id ? updatedCourse : course
        );

        setCourses(updatedCourses);
        setSelectedCourse(updatedCourse);
        setSelectedTopic(null);
    };

    const handleAnswerSubmit = (answer: string) => {
        if (!selectedTopic || !selectedCourse) return;
        const updatedTopic = {...selectedTopic, answer, completed: true};
        const updatedCourse = {
            ...selectedCourse,
            topics: selectedCourse.topics.map((topic) =>
                topic.id === updatedTopic.id ? updatedTopic : topic
            ),
        };

        const updatedCourses = courses.map((course) =>
            course.id === updatedCourse.id ? updatedCourse : course
        );

        setCourses(updatedCourses);
        setSelectedCourse(updatedCourse);
        setSelectedTopic(null);
    };

    return (
        <Page Grow>
            <Row Gap>
                {!selectedCourse && (
                    <Col xs={24}>
                        <Content Pad>
                            <Title>Courses</Title>
                            <Row GapSm>
                                {courses.map((course) => (
                                    <Col xs={12} md={8} key={course.id}>
                                        <Card Pad Full>
                                            <Paragraph>{course.name}</Paragraph>
                                            <Button onClick={() => setSelectedCourse(course)}>
                                                Open
                                            </Button>

                                            {course.topics.map((topic) => (

                                                <Space direction={"horizontal"} GapSm align={"start"}
                                                       justify={"space-between"} NoWrap>
                                                    <span className={"overflow-ellipsis no-wrap "}>{topic.name}</span>
                                                    <span>{topic.completed && (
                                                        <FontAwesomeIcon
                                                            icon={faCheckCircle}
                                                            style={{color: "green", marginLeft: "8px"}}
                                                        />
                                                    )}</span>
                                                </Space>

                                            ))}

                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </Content>
                    </Col>
                )}

                {selectedCourse && !selectedTopic && (
                    <Col xs={24}>
                        <Content Pad>
                            <Space direction="vertical" GapSm>
                                <Button onClick={() => setSelectedCourse(null)}>
                                    <FontAwesomeIcon icon={faArrowLeft}/> Back to Courses
                                </Button>
                                <Title>{selectedCourse.name}</Title>
                                <Row>
                                    {selectedCourse.topics.map((topic) => (
                                        <Col xs={12} key={topic.id}>
                                            <Card Pad>
                                                <Paragraph>
                                                    {topic.name}
                                                    {topic.completed && (
                                                        <FontAwesomeIcon
                                                            icon={faCheckCircle}
                                                            style={{color: "green", marginLeft: "8px"}}
                                                        />
                                                    )}
                                                </Paragraph>
                                                <Button
                                                    onClick={() => setSelectedTopic(topic)}
                                                    disabled={topic.completed}
                                                >
                                                    {topic.completed ? "Completed" : "Start"}
                                                </Button>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            </Space>
                        </Content>
                    </Col>
                )}

                {selectedTopic && (
                    <Col xs={24}>
                        <Content Pad>
                            <Space direction="vertical" GapSm>
                                <Button onClick={() => setSelectedTopic(null)}>
                                    <FontAwesomeIcon icon={faArrowLeft}/> Back to Topics
                                </Button>
                                <Title>{selectedTopic.name}</Title>
                                <Paragraph>{selectedTopic.question}</Paragraph>
                                <textarea
                                    rows={4}
                                    style={{width: "100%"}}
                                    placeholder="Write your answer here..."
                                    onBlur={(e) => handleAnswerSubmit(e.target.value)}
                                />
                            </Space>
                        </Content>
                    </Col>
                )}
            </Row>
        </Page>
    );
};

export default TutoringApp;
