import {Vec3Interface} from "./Vec3";

export function bias(bias:number, real:number) {
    return real / ((1 / bias - 2) * (1 - real) + 1);
}

export function RadToDeg(rad:number) {
    return rad * 57.2958;
}

export function DegToRad(deg:number) {
    return 0.0174533 * deg;
}

export const arrayRandom = (arr: any[]) => {
    return arr[Math.floor(Math.random() * (arr.length - 1))];
}
export function gain(g:number, x:number) {
    var p;
    p = (1 / g - 2) * (1 - 2 * x);
    if (x < 0.5) return x / (p + 1);
    return (p - x) / (p - 1);
}

export function angle_difference (a1:number, a2:number){
    return DegToRad(((((RadToDeg(a1) - RadToDeg(a2)) % 360) + 540) % 360) - 180);
}

export function angle_difference_rad (a1:number, a2:number){
    return (((((RadToDeg(a1) - RadToDeg(a2)) % 360) + 540) % 360) - 180);
}

export function arrayMedian(values:number[]) {
    if (values.length === 0) throw new Error("No inputs");

    values.sort(function (a, b) {
        return a - b;
    });

    var half = Math.floor(values.length / 2);

    if (values.length % 2)
        return values[half];

    return (values[half - 1] + values[half]) / 2.0;
}

export function getZAxisRotationToPoint(p1: any, p2: any): Vec3Interface {
    // Calculate the direction vector from p1 to p2
    //console.log("WTF", p1, p2);
    const direction = {
        x: p2.x - p1.x,
        y: p2.y - p1.y,
        z: p2.z - p1.z
    };

    // Calculate the angle in radians between the direction vector and the positive X-axis
    const angleRadians = Math.atan2(direction.y, direction.x);

    // Convert the angle to degrees
    const angleDegrees = (-angleRadians * 180 / Math.PI) + 90;

    // Since we only need the rotation around the Z-axis,
    // the resulting vector will have 'x' and 'y' set to 0,
    // and 'z' will have the calculated angle
    return {x: 0, y: 0, z: angleDegrees};
}

export function _generateRequiredExps() {
    let _exph = 1000;
    let _thmm = 250;
    let exp = [];
    let _msxp = _thmm * _exph;
    let _expls = 0;
    for (let i = 0; i <= 99; i++) {
        let _expr = Math.floor((bias(0.01, i / 99) * _msxp) + _expls);
        exp[i] = {
            total: _expr,
            change: _expr - _expls
        }
        _expls = _expr;
    }
    return exp;
}

export function getExpForLevel(lvl:number) {
    return _generateRequiredExps()[lvl];
}

export function getLevelForExp(exp:number) {
    let level = 0;
    let exps = _generateRequiredExps();
    for (let i = 0; i <= 99; i++) {
        if (exp >= exps[i].total) {
            level = i;
        }
    }
    return level;
}

export const monthNames = ["Au","Er","Um","Na","Mu","Fe","Sy","Oc","En","De"];
const startDate = 1699999147904;
export function convertToWorldTime(realTimestamp: number): number {
    // Define the start date as the current time in milliseconds


    // Calculate the difference in milliseconds
    const timeDifference = realTimestamp - startDate;

    // Convert milliseconds to months (1 real month = 1 game year)
    // 1 month = 30.44 days, 1 day = 24 hours, 1 hour = 60 minutes, 1 minute = 60 seconds, 1 second = 1000 milliseconds
    const monthsPassed = timeDifference / (30.44 * 24 * 60 * 60 * 1000);

    console.log(monthsPassed);
    // Calculate the game year (starting from year 1)
    const gameYear = parseFloat((monthsPassed).toFixed()) + 1;

    return gameYear;
}
