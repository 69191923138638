// MeshEditorRenderer.tsx
import {useRef, useEffect, useState, FC} from 'react';
import {mat4} from 'gl-matrix';
import {
    initShaderProgram,
    initBuffers,
    createShadowMap,
    renderSceneFromLightPerspective,
    drawSceneShadowMapped, debugVisualizeShadowMap,
} from './webgl-utils';
import {Camera} from "../../model/Camera";
import { debugFS, debugVS} from "../../model/ShaderPrograms";
import {Vec2, Vec2Interface} from "../../model/Vec2";
import {Vec3, Vec3Interface} from "../../model/Vec3";
import {ClientRenderingOptions, defaultClientRenderingOptions} from "../../model/RemotePLYLoader";
import {Surface} from "../../model/Surface";
import {QuickInput, QuickInputEvent} from "../../model/QuickInput";
import {arrayMedian, DegToRad} from "../../model/Utils";
import {OakMesh, OakMeshEditorObjectItemType, OakMeshEditorObjectItemTypeIcons} from "../../model/OakObject";
import {PLYData, PLYObject} from "../../model/PLY";
import {GizmoElement} from "./GizmoElement";


const camera = new Camera();
camera.from.set(0, 15, 3.5);
camera.to.set(0, 0, 1.8);
camera.fov = 25;

interface MeshEditorRendererProps {
    PLYDataFunction?:(m?: OakMesh)=> PLYData;
    plyData?: ((camera: Camera, surface: Surface, mousePos: Vec2Interface, toWorld: Vec3Interface) => PLYData | undefined);
    ply?: PLYData;
    style?: any;
    importCamera?: Camera;
    key?: any;
    onTap?: (mousePos: Vec2Interface, toWorld: Vec3Interface) => void
    onContext?: (mousePos: Vec2Interface, toWorld: Vec3Interface) => void
    setCanvasRef?: (canvas: HTMLCanvasElement) => void;
    tools?: React.ReactNode;
    isDebug?: boolean;
    renderingOptions?:ClientRenderingOptions;
}

const MeshEditorRenderer: React.FC<MeshEditorRendererProps> = ({
                                                                   setCanvasRef,
                                                                   isDebug,
                                                                   PLYDataFunction,
                                                                   plyData,
                                                                   ply,
                                                                   style,
                                                                   key,
                                                                   tools,
                                                                   importCamera = camera,
                                                                   onTap,
                                                                   onContext,
    renderingOptions
                                                               }) => {

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const canvas2DRef = useRef<HTMLCanvasElement>(null);

    const [enableOrigin, setEnableOrigin] = useState<boolean>(false);


    const [selectedTool, setSelectedTool] = useState<string>("selection");
    const [selectedAction, setSelectedAction] = useState<string>("vertex");

    const [surface, setSurface] = useState<Surface>();

    const mousePos = new Vec2();
    const toWorld = new Vec3();

    let shadowMap: any;
    let buffers: any;

    if (!importCamera) {
        importCamera = camera;
    }


    // Define the initial rotation for the cube
    const initialRotation = {
        x: 0,
        y: 0,
        z: 0,
    };

    // Define the color for the cube (RGBA format)
    const cubeColor = new Float32Array([
        1.0, 1.0, 1.0, 1.0, // White
        // Add more colors if the cube requires different colors for each face
    ]);

    // Define the scene object
    const scene = {
        color: cubeColor,
        rotation: initialRotation,
    };

    // Define the projection matrix for the world
    const fieldOfView = importCamera.fov * Math.PI / 180; // in radians
    const aspect = 640 / 480; // canvas width/height
    const zNear = 0.1;
    const zFar = 10.0;
    const projectionMatrix = mat4.create(); // Creates a new identity matrix
    mat4.perspective(projectionMatrix, fieldOfView, aspect, zNear, zFar);

    const onMouseMove = (event: any) => {
        if (!event || !event.target || !event.target.width) {
            return
        }
        mousePos.x = event.offsetX / event.target.offsetWidth;
        mousePos.y = event.offsetY / event.target.offsetHeight;
        //console.log('mp',mousePos)
    }

    const onBlur = () => {
        mousePos.x = -999;
        mousePos.y = -999;
        toWorld.set(-9999, -9999, 0);
    }




    useEffect(() => {
        if (!canvasRef.current) return;

        const w = canvasRef.current.parentElement?.offsetWidth || 100;
        const h = canvasRef.current.parentElement?.offsetHeight || 100;

        if (!canvas2DRef.current) {
            return
        }
        const surface = new Surface(canvas2DRef.current);
        const canvas2d = canvas2DRef.current;


        canvasRef.current.width = w * surface.getScaling();
        canvasRef.current.height = h * surface.getScaling();
        canvas2DRef.current.width = w * surface.getScaling();
        canvas2DRef.current.height = h * surface.getScaling();
        //surface._element.style.transform="scale(50%)";

        const gl: any = canvasRef.current.getContext('webgl');
        if (!gl) {
            alert('Unable to initialize WebGL. Your browser may not support it.');
            return;
        }

        if (setCanvasRef) {
            setCanvasRef(canvas2d);
            setSurface(surface);
        }

        const quickInput = new QuickInput(canvas2d, {
            key: (event: QuickInputEvent) => {
                console.info("KEY", event.key);
            },
            tap: (event: QuickInputEvent) => {

                if (event.tap) {

                    // @ts-ignore
                    console.log("TAP", event.target, event.target?.offsetWidth || 1, event.target?.offsetY, surface.getWidth());
                    console.log(event);

                    if (event && event.target && event.target) {
                        //@ts-ignore
                        // mousePos.x = event.tap.x / event.target?.offsetWidth || 1;
                        //@ts-ignore
                        //  mousePos.y = event.tap.y /event.target?.offsetHeight ||1 ;
                    }

                    // @ts-ignore
                    const worldPos = importCamera.projection.toWorld(surface, mousePos, importCamera.getFromWithUserZoomPositionRotation());

                    if (onTap) {
                        onTap(mousePos, worldPos);
                    }

                    //runner.handleUIClick(camera, mousePos, toWorld);
                }
            }, context: (event: QuickInputEvent) => {
                //  console.log('CONTEXT MENU', event);
                if (event.context) {
                    //@ts-ignore
                    mousePos.x = event.target.offsetX / event.target.offsetWidth;
                    //@ts-ignore
                    mousePos.y = event.target.offsetY / event.target.offsetHeight;

                    // @ts-ignore
                    const worldPos = importCamera.projection.toWorld(surface, mousePos, importCamera.getFromWithUserZoomPositionRotation());

                    if (onContext) {
                        onContext(mousePos, worldPos);
                    }

                    console.log("DEFAULT RENDERER CONTEXT MENU");

                }

            }, drag: (event: QuickInputEvent) => {
                if (event.drag) {
                    importCamera.userRotation.z += DegToRad(event.drag.x);
                    importCamera.userRotation.x -= DegToRad(event.drag.y);
                    importCamera.userRotation.x = arrayMedian([-1.15, importCamera.userRotation.x, 0.35])
                }
            },
            scale: (event: QuickInputEvent) => {
                // @ts-ignore
                importCamera.zoom /= event.scale;
                importCamera.zoom = arrayMedian([0.25, importCamera.zoom, 10]);
                console.log('SCALE', event);
            },
            wheel: (event: QuickInputEvent) => {
                // @ts-ignore
                importCamera.zoom += event.wheel.y * 1;//delta.getDeltaT();
                importCamera.zoom = arrayMedian([0.25, importCamera.zoom, 10]);
                console.log('wheel', event, importCamera);
            }
        });

        canvas2d.addEventListener('mousemove', onMouseMove);
        canvas2d.addEventListener('mouseout', onBlur);

        // surface.getContext().width = window.innerWidth * surface.getScaling();
        // surface.getContext().height = window.innerHeight * surface.getScaling();


        const clientRenderingOptions = renderingOptions||defaultClientRenderingOptions();
        if (!renderingOptions){
            console.warn("No Rendering Options provided");
        }

        try {
            let s = window.localStorage.getItem("maag-rendering-options");
            if (s) {
                let d: ClientRenderingOptions = JSON.parse(s);
                if (d.USER_SETTING_SHADOW_SIZE) {
                    clientRenderingOptions.USER_SETTING_SHADOW_SIZE = d.USER_SETTING_SHADOW_SIZE;
                }
            }

        } catch (e) {

        }


        const shadowVS = `attribute vec3 aVertexPosition;
uniform mat4 uLightViewMatrix;
uniform mat4 uLightProjectionMatrix;

void main(void) {
    gl_Position = uLightProjectionMatrix * uLightViewMatrix * vec4(aVertexPosition, 1.0);
}`;
        const shadowFS = `precision ${clientRenderingOptions.USER_OPTION_COMPATIBILITY_MODE ? "lowp" : "mediump"} float;

vec4 encodeFloat (float depth) {
    const vec4 bitShift = vec4(
    256 * 256 * 256,
    256 * 256,
    256,
    1.0
    );
    const vec4 bitMask = vec4(
    0,
    1.0 / 256.0,
    1.0 / 256.0,
    1.0 / 256.0
    );
    vec4 comp = fract(depth * bitShift);
    comp -= comp.xxyz * bitMask;
    return comp;
}


void main(void) {
    // Since we only care about depth, there is no need to set a color
    gl_FragColor = encodeFloat(gl_FragCoord.z);
}`;

        const cameraVS = `
attribute vec3 aVertexPosition;
attribute vec4 aVertexColor;
attribute vec3 aVertexNormal; // Add normal attribute
attribute vec4 aMaterialProperties; // Packed material data 

uniform mat4 uModelViewMatrix;
uniform mat4 uProjectionMatrix;
uniform mat4 uLightViewMatrix;
uniform mat4 uLightProjectionMatrix;

varying vec4 vShadowCoord;
varying vec4 vColor;
varying vec3 vNormal; // Pass normal to fragment shader
varying vec3 mNormal; // Pass normal to fragment shader
varying vec4 vMaterialProperties; // Pass packed material properties to fragment shader

void main(void) {
    gl_Position = uProjectionMatrix * uModelViewMatrix * vec4(aVertexPosition, 1.0);
    vShadowCoord = uLightProjectionMatrix * uLightViewMatrix * vec4(aVertexPosition, 1.0);
    vColor = aVertexColor;
    vNormal = aVertexNormal; // Set the normal
    mNormal = vec3(uModelViewMatrix * vec4(aVertexNormal, 0.0));
    vMaterialProperties = aMaterialProperties; // Pass to fragment shader
}`;

        const cameraFS =
`precision ${clientRenderingOptions.USER_OPTION_COMPATIBILITY_MODE ? "lowp" : "mediump"} float;

uniform sampler2D uShadowMap;
uniform vec3 uLightDirection; // Light direction uniform

varying vec4 vShadowCoord;
varying lowp vec4 vColor;
varying vec3 vNormal; // Normal passed from vertex shader
varying vec3 mNormal; // Normal passed from vertex shader
varying vec4 vMaterialProperties; // Receive packed material properties

// Unpack material properties
float getDiffuse() { return vMaterialProperties.x; }
float getSpecular() { return vMaterialProperties.y; }
float getReflections() { return vMaterialProperties.z; }
float getEmission() { return vMaterialProperties.w; }

const int size = 0;

float decodeFloat (vec4 color) {
    const vec4 bitShift = vec4(
        1.0 / (256.0 * 256.0 * 256.0),
        1.0 / (256.0 * 256.0),
        1.0 / 256.0,
        1.0
    );
    return dot(color, bitShift);
}

vec4 AmbientWorldLight(){
    vec4 ambientCool = vec4(0.5, 0.5, 0.55, 1.0);
    vec4 ambientWarm = vec4(0.6, 0.5, 0.5, 1.0);
    vec4 skyLight = mix(ambientWarm, ambientCool, 0.5 * (1.0 + dot(mNormal, vec3(0.0, 0.0, 1.0))));
    return skyLight;
}

float Rim(){
    float rimLight = (1.0 - max(0.0, dot(normalize(mNormal), normalize(vNormal))));
    return rimLight;
}

float customStep(float v, int stepSize) {
    float stepValue = 1.0 / float(stepSize);
    return floor(v / stepValue) * stepValue;
}

float visibility(){
    float shadowAcneRemover = -max(0.00125 * (1.0 - dot(vNormal, uLightDirection) ), 0.00001);
    vec3 shadowCoord = (vShadowCoord.xyz / vShadowCoord.w) * 0.5 + 0.5; // Transform to [0, 1] range
    shadowCoord.z -= shadowAcneRemover;
    float shadowDepth = decodeFloat(texture2D(uShadowMap, shadowCoord.xy));
    return shadowCoord.z <= shadowDepth ? 1.0 : 0.0;
}

float bias(float b, float x){
    return x / ((1.0 / b - 2.0) * (1.0 - x) + 1.0);
}

float Edge(){

    vec2 poissonDisk[8];
    poissonDisk[0] = vec2(0.06407013, 0.05409927);
    poissonDisk[1] = vec2(0.7366577, 0.5789394);
    poissonDisk[2] = vec2(-0.6270542, -0.5320278);
    poissonDisk[3] = vec2(-0.4096107, 0.8411095);
    poissonDisk[4] = vec2(0.6849564, -0.4990818);
    poissonDisk[5] = vec2(-0.874181, -0.04579735);
    poissonDisk[6] = vec2(0.9989998, 0.0009880066);
    poissonDisk[7] = vec2(-0.004920578, -0.9151649);

    float occlusion = 0.0;
    float occlusionSamples = 0.0;

    vec3 shadowCoord = (vShadowCoord.xyz / vShadowCoord.w) * 0.5 + 0.5; // Transform to [0, 1] range
    float shadowAcneRemover = 0.001;
    shadowCoord.z -= shadowAcneRemover;
    float texelSize = 1.0 / ${clientRenderingOptions.USER_SETTING_SHADOW_SIZE}.0;
    
    for (int i = 0; i < 1; i++) {
      
        vec2 sampleLocation = shadowCoord.xy + (poissonDisk[i]*(texelSize*2.0));

        float texelDepth = decodeFloat(texture2D(uShadowMap, sampleLocation));
        if (shadowCoord.z < texelDepth) {
            occlusion += bias(0.99, clamp(normalize(shadowCoord.z - texelDepth) * 20.0, 0.0, 1.0));
        }
        occlusionSamples += 1.0;
    }

    return clamp((1.0 - (occlusion / occlusionSamples)), 0.0, 1.0);
}

float OrthoLightSoft() {
     vec2 poissonDisk[64];
poissonDisk[0] = vec2(-0.613392, 0.617481);
poissonDisk[1] = vec2(0.170019, -0.040254);
poissonDisk[2] = vec2(-0.299417, 0.791925);
poissonDisk[3] = vec2(0.645680, 0.493210);
poissonDisk[4] = vec2(-0.651784, 0.717887);
poissonDisk[5] = vec2(0.421003, 0.027070);
poissonDisk[6] = vec2(-0.817194, -0.271096);
poissonDisk[7] = vec2(-0.705374, -0.668203);
poissonDisk[8] = vec2(0.977050, -0.108615);
poissonDisk[9] = vec2(0.063326, 0.142369);
poissonDisk[10] = vec2(0.203528, 0.214331);
poissonDisk[11] = vec2(-0.667531, 0.326090);
poissonDisk[12] = vec2(-0.098422, -0.295755);
poissonDisk[13] = vec2(-0.885922, 0.215369);
poissonDisk[14] = vec2(0.566637, 0.605213);
poissonDisk[15] = vec2(0.039766, -0.396100);
poissonDisk[16] = vec2(0.751946, 0.453352);
poissonDisk[17] = vec2(0.078707, -0.715323);
poissonDisk[18] = vec2(-0.075838, -0.529344);
poissonDisk[19] = vec2(0.724479, -0.580798);
poissonDisk[20] = vec2(0.222999, -0.215125);
poissonDisk[21] = vec2(-0.467574, -0.405438);
poissonDisk[22] = vec2(-0.248268, -0.814753);
poissonDisk[23] = vec2(0.354411, -0.887570);
poissonDisk[24] = vec2(0.175817, 0.382366);
poissonDisk[25] = vec2(0.487472, -0.063082);
poissonDisk[26] = vec2(-0.084078, 0.898312);
poissonDisk[27] = vec2(0.488876, -0.783441);
poissonDisk[28] = vec2(0.470016, 0.217933);
poissonDisk[29] = vec2(-0.696890, -0.549791);
poissonDisk[30] = vec2(-0.149693, 0.605762);
poissonDisk[31] = vec2(0.034211, 0.979980);
poissonDisk[32] = vec2(0.503098, -0.308878);
poissonDisk[33] = vec2(-0.016205, -0.872921);
poissonDisk[34] = vec2(0.385784, -0.393902);
poissonDisk[35] = vec2(-0.146886, -0.859249);
poissonDisk[36] = vec2(0.643361, 0.164098);
poissonDisk[37] = vec2(0.634388, -0.049471);
poissonDisk[38] = vec2(-0.688894, 0.007843);
poissonDisk[39] = vec2(0.464034, -0.188818);
poissonDisk[40] = vec2(-0.440840, 0.137486);
poissonDisk[41] = vec2(0.364483, 0.511704);
poissonDisk[42] = vec2(0.034028, 0.325968);
poissonDisk[43] = vec2(0.099094, -0.308023);
poissonDisk[44] = vec2(0.693960, -0.366253);
poissonDisk[45] = vec2(0.678884, -0.204688);
poissonDisk[46] = vec2(0.001801, 0.780328);
poissonDisk[47] = vec2(0.145177, -0.898984);
poissonDisk[48] = vec2(0.062655, -0.611866);
poissonDisk[49] = vec2(0.315226, -0.604297);
poissonDisk[50] = vec2(-0.780145, 0.486251);
poissonDisk[51] = vec2(-0.371868, 0.882138);
poissonDisk[52] = vec2(0.200476, 0.494430);
poissonDisk[53] = vec2(-0.494552, -0.711051);
poissonDisk[54] = vec2(0.612476, 0.705252);
poissonDisk[55] = vec2(-0.578845, -0.768792);
poissonDisk[56] = vec2(-0.772454, -0.090976);
poissonDisk[57] = vec2(0.504440, 0.372295);
poissonDisk[58] = vec2(0.155736, 0.065157);
poissonDisk[59] = vec2(0.391522, 0.849605);
poissonDisk[60] = vec2(-0.620106, -0.328104);
poissonDisk[61] = vec2(0.789239, -0.419965);
poissonDisk[62] = vec2(-0.545396, 0.538133);
poissonDisk[63] = vec2(-0.178564, -0.596057);

float occlusion = 0.0;
float occlusionSamples = 0.0;

vec3 shadowCoord = (vShadowCoord.xyz / vShadowCoord.w) * 0.5 + 0.5; // Transform to [0, 1] range
    float shadowAcneRemover = max(0.00125 * (1.0 - dot(vNormal, uLightDirection) ), 0.0005) * (1.0 - ${(((clientRenderingOptions.USER_SETTING_SHADOW_QUALITY/128 )*(clientRenderingOptions.USER_SETTING_SHADOW_SIZE/512))).toFixed(4)});
shadowCoord.z += shadowAcneRemover;
float texelSize = 1.0 / ${(clientRenderingOptions.USER_SETTING_SHADOW_SIZE / 8).toFixed(4)};

float b = 1.0; // Reduced bias for smoother transitions

for (int i = 0; i < ${clientRenderingOptions.USER_SETTING_SHADOW_QUALITY}; i++) {
    float depthDifference = abs(shadowCoord.z - decodeFloat(texture2D(uShadowMap, shadowCoord.xy)));
    float samplingRadius = mix(1.5, 0.5, clamp(depthDifference / b, 0.0, 1.0)) * texelSize * ${((clientRenderingOptions.USER_SETTING_SHADOW_QUALITY / 128)*(clientRenderingOptions.USER_SETTING_SHADOW_SIZE/512)).toFixed(4)}; // Adjusted for smoother transitions
    vec2 sampleLocation = shadowCoord.xy + poissonDisk[i] * samplingRadius;
    
    // Check if the sample location is within the bounds of the texture and fade near edges
   // float edgeFade = smoothstep(0.0, 0.0, min(min(sampleLocation.x, 1.0 - sampleLocation.x), min(sampleLocation.y, 1.0 - sampleLocation.y)));
    if (sampleLocation.x > 0.0 && sampleLocation.x < 1.0 && sampleLocation.y > 0.0 && sampleLocation.y < 1.0) {
        float texelDepth = decodeFloat(texture2D(uShadowMap, sampleLocation));
        if (shadowCoord.z <= texelDepth) {
            occlusion += 1.0;//*edgeFade;
        }
    } else {
        occlusion += 1.0;
    }
    occlusionSamples += 1.0;
}

return clamp(occlusion / occlusionSamples, 0.0, 1.0);

}

void main(void) {
    // Calculate ambient light contribution
    vec3 ambientLight = AmbientWorldLight().rgb;

    float vis = ${(clientRenderingOptions.USER_OPTION_SHADOWS).toString()} ? max(0.2, visibility()) : 1.0;

    // Soft shadow factor
    float shadowFactor = max(0.2, ${(!clientRenderingOptions.USER_OPTION_SHADOWS).toString()} ? 1.0 : (${clientRenderingOptions.USER_OPTION_SOFT_SHADOWS.toString()} ?  min(max(0.3,vis),OrthoLightSoft()) : vis));

    // Adjust shadow factor for material properties
    shadowFactor = mix(shadowFactor, 1.0, getEmission() * 0.6); // Emission overrides shadows more strongly
    shadowFactor = mix(shadowFactor, 0.8, getReflections() * 0.2); // Translucent materials reduce shadow darkness slightly

    // Calculate diffuse lighting using Lambertian reflection
    float diffuseLight = max(dot(normalize(vNormal), normalize(uLightDirection)), 0.0) * getDiffuse();

    // Add emission directly, scaled by diffuse color
    vec3 emission = vec3(getEmission()) * vColor.rgb;

    // Specular reflection using Blinn-Phong or PBR-style specular
    vec3 viewDir = normalize(mNormal); // Assume view direction points to the camera
    vec3 halfVector = normalize(normalize(uLightDirection) + viewDir);

    // Adjust specular intensity based on diffuse
    float specularFactor = pow(max(dot(normalize(vNormal), halfVector), 0.0), 1.0 / (getSpecular() + 0.001));
    float specularBrightness = getSpecular()*specularFactor * shadowFactor * clamp(getDiffuse(), 0.1, 1.0);

    // Introduce base color bias into the specular reflection
    vec3 rawSpecular = vec3(1.0, 1.0, 1.0) * specularBrightness;
    vec3 specular = (getSpecular() > 0.0) 
        ? mix(rawSpecular, rawSpecular * vColor.rgb, 0.1) // Bias specular 10% towards base color
        : vec3(0.0);

    // Reflections contribution, only calculated if reflections > 0
    vec3 reflection = (getReflections() > 0.0) 
        ? vec3(getReflections()) * specular 
        : vec3(0.0);

    // Combine base lighting
    vec3 baseLighting = ambientLight + diffuseLight;

    // Final color composition
    vec3 litColor = (baseLighting * vColor.rgb + specular + reflection) * shadowFactor;
    vec3 finalColor = litColor + emission;

    // Render layers or debug outputs
    int renderLayer = ${clientRenderingOptions.USER_SETTING_RENDER_ONLY};

    if (renderLayer == 1) { // Color Layer
        gl_FragColor = vec4(vColor.rgb, 1.0);
    } else if (renderLayer == 2) { // World Normals
        gl_FragColor = vec4(vNormal * 0.5 + 0.5, 1.0); // Remap to [0, 1]
    } else if (renderLayer == 3) { // Model Normals
        gl_FragColor = vec4(mNormal * 0.5 + 0.5, 1.0); // Remap to [0, 1]
    } else if (renderLayer == 4) { // Shadows
        gl_FragColor = vec4(OrthoLightSoft() * vec3(1.0), 1.0);
    } else if (renderLayer == 5) { // Visibility
        gl_FragColor = vec4(visibility() * vec3(1.0), 1.0);
    } else if (renderLayer == 6) { // Diffuse Light
        gl_FragColor = vec4(baseLighting, 1.0);
    } else if (renderLayer == 7) { // Shadow Coordinates
        gl_FragColor = vec4(vShadowCoord.xyz * vec3(1.0, 1.0, -1.0), 1.0);
    } else if (renderLayer == 8) { // Ambient Light
        gl_FragColor = vec4(ambientLight, 1.0);
    } else if (renderLayer == 9) { // Specular Intensity
        gl_FragColor = vec4(specular, 1.0);
    } else if (renderLayer == 10) { // Emission
        gl_FragColor = vec4(emission, 1.0);
    } else if (renderLayer == 11) { // Reflection
        gl_FragColor = vec4(reflection, 1.0);
    } else { // Final Render
        gl_FragColor = vec4(finalColor, vColor.a);
    }
}
`;

        const SHADOW_MAP_SIZE = clientRenderingOptions.USER_SETTING_SHADOW_SIZE || 512;
        const shadowShaderProgram = initShaderProgram(gl, shadowVS, shadowFS);
        const cameraShaderProgram = initShaderProgram(gl, cameraVS, cameraFS);
        const debugProgram = initShaderProgram(gl, debugVS, debugFS);


        let then = 0;

        const dS = document.createElement('canvas');
        const debugSurface = new Surface(dS);
        debugSurface.setSize(128,128);



        // Update rotation based on time
        const render = (now: number) => {

            if (!importCamera) {
                return;
            }

            if (!canvasRef.current) return;

            const w = canvasRef.current.parentElement?.offsetWidth || 100;
            const h = canvasRef.current.parentElement?.offsetHeight || 100;

            canvasRef.current.width = w * surface.getScaling();
            canvasRef.current.height = h * surface.getScaling();

            if (!canvas2DRef.current) return;



            canvas2DRef.current.width = w * surface.getScaling();
            canvas2DRef.current.height = h * surface.getScaling();

            setSurface(surface);

            //  importCamera.userRotation.z = Date.now() / 1000;

            importCamera.aspect = w / h;


            now *= 0.001; // Convert time to seconds
            const deltaTime = now - then;
            then = now;

            if ( !cameraShaderProgram || !shadowShaderProgram || !debugProgram) {
                return
            }

            const rawPLY = new PLYObject();
            if (plyData) {
                const res = plyData(importCamera, surface, mousePos, toWorld);
                if (res) {
                    rawPLY.attach(res);
                }
            }
            if (ply) {
                rawPLY.attach(ply);
            }
            if (PLYDataFunction){
                rawPLY.attach( PLYDataFunction() );
            }

            importCamera.userPosition.copy(rawPLY.getGeometricCentroid());
            importCamera.getProjection().set(importCamera);

            if (rawPLY) {

                if (!shadowMap || gl.checkFramebufferStatus(gl.FRAMEBUFFER) !== gl.FRAMEBUFFER_COMPLETE) {
                    if (shadowMap) {
                        // Cleanup if there's an existing shadowMap with incomplete framebuffer
                        gl.deleteFramebuffer(shadowMap.framebuffer);
                        gl.deleteTexture(shadowMap.texture);
                        gl.deleteRenderbuffer(shadowMap.renderbuffer);
                    }

                    shadowMap = createShadowMap(gl, SHADOW_MAP_SIZE, SHADOW_MAP_SIZE); // Or any other suitable resolution
                   // console.log("Creating shadow map", shadowMap);

                    // Bind the framebuffer to check its status
                    gl.bindFramebuffer(gl.FRAMEBUFFER, shadowMap.framebuffer);
                    if (gl.checkFramebufferStatus(gl.FRAMEBUFFER) !== gl.FRAMEBUFFER_COMPLETE) {
                        console.error('Framebuffer is not complete after recreation');
                        // Handle the error appropriately
                    }
                    // Unbind the framebuffer after checking
                    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
                }

                buffers = initBuffers(gl, rawPLY.getPLYData(), buffers);


                const lightProjectionMatrix = mat4.create();
                const lightViewMatrix = mat4.create();
                const lightPosition: any = [-20, 10, 10];
                const lightOrthoZoom = Math.max(1, importCamera.getZoom());
                mat4.ortho(lightProjectionMatrix, -8 * lightOrthoZoom, 8 * lightOrthoZoom, -8 * lightOrthoZoom, 8 * lightOrthoZoom, 0.1, 100.0); // Adjust the values to match the scene size
                mat4.lookAt(lightViewMatrix, lightPosition, [0, 0, 0], [0, 0, 1]);


                renderSceneFromLightPerspective(gl, shadowShaderProgram, shadowMap, buffers, lightProjectionMatrix, lightViewMatrix);
                drawSceneShadowMapped(gl, cameraShaderProgram, buffers, shadowMap, importCamera, lightProjectionMatrix, lightViewMatrix);
                // if (renderingOptions && renderingOptions.USER_OPTION_DEBUG_MODE) {
                // debugVisualizeShadowMap(gl, debugProgram, shadowMap.texture);
                //}
            }


            surface.clear();


                const pos = new Vec3();
                pos.z = 1;
               //pos.copy(entry.position);
                const cam = importCamera.getFromWithUserZoomPositionRotation(); // TODO check this calculation
                importCamera.projection.set(importCamera);

               const textPos = importCamera.projection.toScreen(surface, pos, cam);


            if (clientRenderingOptions.USER_OPTION_DEBUG_MODE) {
                surface.drawText(textPos.x,textPos.y,"hello world",{});

                surface.drawDebugOriginLines(surface, importCamera,0.5);

                const position = new Vec3().set(0, 0, 0);
                const radius = 0.25;
                const segments = 36;

                const debugCamera = importCamera.clone()
                //debugCamera.fov = 10;
                debugCamera.zoom = 1;
                debugCamera.aspect = 1;
                debugCamera.getProjection().set(debugCamera);

                const debugDrawCross = (surface: Surface): void => {

                    surface.drawCircleFromPerspective(debugSurface, new Vec3(), 2, 16, debugCamera, "#0000FF", 2);
                };
                // Use the draw function
                surface.drawDebugAtScreenCoords(
                    surface,                   // Main rendering surface
                    new Vec2().set(0.5, 0.5),  // Center of the screen
                    debugSurface,              // Pre-initialized debug surface
                    debugDrawCross,            // Debug draw function
                    new Vec2().set(128, 128)   // Size of the debug overlay
                );

            }
            //surface.drawDebugOriginGrid(surface, importCamera,9);

           //TODO draw DEBUG STUFF HERE

            fr = requestAnimationFrame(render);
        };

        let fr = requestAnimationFrame(render);

        return () => {
            cancelAnimationFrame(fr);


            if (canvas2d) {
                canvas2d.removeEventListener('mousemove', onMouseMove);
                canvas2d.removeEventListener('mouseout', onBlur);
            }

            if (gl) {
                //   if (shaderProgram){gl.deleteShader(shaderProgram);}
                //  if(shadowShaderProgram){gl.deleteShader(shadowShaderProgram);}
                //  if(cameraShaderProgram){gl.deleteShader(cameraShaderProgram);}
                // if(debugProgram){gl.deleteShader(debugProgram);}

                // Assuming you have a way to keep track of all the buffers you create
               // buffers.forEach((buffer:WebGLBuffer) => gl.deleteBuffer(buffer));

                if (shadowMap) {
                    gl.deleteFramebuffer(shadowMap.framebuffer);
                    gl.deleteTexture(shadowMap.texture);
                    gl.deleteRenderbuffer(shadowMap.renderbuffer);
                }

                // Add similar cleanup for any other WebGL resources you create...
                console.log("CLEANUP GL");
            }

        }
    }, [plyData, ply, PLYDataFunction, renderingOptions]);

    // TODO Implement dimension vector gizmos
    const [gizmos, setGizmos] = useState<any[]>([
        {
            screenPosition:new Vec2().set(0.5,0.5),
            position:new Vec3().set(0,0,0),
            rotation:new Vec3().set(Math.random(),Math.random(),Math.random()),
            scale: new Vec3().set(1,1,1)
        }
    ]);

    return (
        <div key={key} className={"inline-canvas"}
             style={{position: "relative", height: "100%", ...style, overflow: "hidden"}}>
            <canvas className={"abs fixed"} ref={canvasRef}/>
            <canvas className={"abs fixed"} ref={canvas2DRef}/>
            {!isDebug && <div className={"abs passthrough"} style={{height:"100%"}}>{tools}</div>}

        </div>
    );
};

export default MeshEditorRenderer;
