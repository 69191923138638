import {ClientRenderingOptions, defaultClientRenderingOptions} from "../../model/RemotePLYLoader";
import {Button, Space} from "../layout/Content";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLightbulb} from "@fortawesome/free-regular-svg-icons";
import {Select} from "../layout/Defaults";
import {faBug, faDrawPolygon} from "@fortawesome/free-solid-svg-icons";
import {useEffect, useState} from "react";


interface Props {
    children?: React.ReactNode;
    style?: any,
    clientRenderingOptions: ClientRenderingOptions,
    onChange: (options: ClientRenderingOptions) => void;
    fps?:number;
}

const RenderSettingsPanel: React.FC<Props> = ({children, style, fps = 0, clientRenderingOptions, onChange}) => {

    const [options, setOptions] = useState<ClientRenderingOptions>(clientRenderingOptions||defaultClientRenderingOptions());

    useEffect(()=>{
        setOptions(clientRenderingOptions);
    },[clientRenderingOptions])

    const availableResolutions:number[] = [];
    for (let i = window.devicePixelRatio; i>0; i--){
        availableResolutions.push(i);
    }

    const onUpdateSettings = (e:any)=>{
        window.localStorage.setItem("maag-rendering-options",JSON.stringify(e));
        onChange(e);
    }

    return (
        <div className={"Panel Light Gap Column"} style={{    width: "fit-content"}}>

            <Space GapSm direction={"vertical"}>
                <span>FPS <strong style={{fontFamily:"monospace"}}>{((fps<100)?"0":"")+((fps))}</strong>f/s</span>
            <hr />

                <Space direction={"vertical"} GapSm>
                    <small>Debug Mode</small>
                    <Button className={`${!!options.USER_OPTION_DEBUG_MODE?"active":"default"}`} onClick={(e: any) => {
                        onUpdateSettings({...options, ...{USER_OPTION_DEBUG_MODE: !options.USER_OPTION_DEBUG_MODE}});
                    }}>
                        <Space GapSm><FontAwesomeIcon icon={faBug} fixedWidth/><small>Debug Mode {options.USER_OPTION_DEBUG_MODE?"Enabled":"Disabled"}</small></Space>
                    </Button>
                </Space>

                <Button className={`${!!options.USER_OPTION_COMPATIBILITY_MODE?"active":"default"}`} onClick={(e: any) => {
                    onUpdateSettings({...options, ...{USER_OPTION_COMPATIBILITY_MODE: !options.USER_OPTION_COMPATIBILITY_MODE}});
                }}>
                    <Space GapSm><FontAwesomeIcon icon={faLightbulb} fixedWidth/><small>Compatibility Mode {options.USER_OPTION_COMPATIBILITY_MODE?"Enabled":"Disabled"}</small></Space>
                </Button>

            {/*<div> Compatibility Mode <input type={"checkbox"} checked={options.USER_OPTION_COMPATIBILITY_MODE} onChange={(e: any) => {*/}
            {/*    onUpdateSettings({...options, ...{USER_OPTION_COMPATIBILITY_MODE: e.target.checked}});*/}
            {/*}}/></div>*/}

            {/*<div> Limit Render Resolution <input type={"checkbox"} checked={options.USER_OPTION_LIMIT_DEVICE_PIXEL_RATIO} onChange={(e: any) => {*/}
            {/*    onUpdateSettings({...options, ...{USER_OPTION_LIMIT_DEVICE_PIXEL_RATIO: e.target.checked}});*/}
            {/*}}/></div>*/}

                {window.devicePixelRatio!==1&&<Space direction={"vertical"} GapSm>
            <small>Limit Render Resolution</small>
            <Button className={`${!options.USER_OPTION_LIMIT_DEVICE_PIXEL_RATIO?"active":"default"}`} onClick={(e: any) => {
                onUpdateSettings({...options, ...{USER_OPTION_LIMIT_DEVICE_PIXEL_RATIO: !options.USER_OPTION_LIMIT_DEVICE_PIXEL_RATIO}});
            }}>
                <Space GapSm><FontAwesomeIcon icon={faDrawPolygon} fixedWidth/><small>Rendering Resolution {options.USER_OPTION_LIMIT_DEVICE_PIXEL_RATIO?"Limited":"Full"}</small></Space>
            </Button>
            </Space>}

            {/*<div> Mobile CPU Improvements <input type={"checkbox"} checked={options.USER_OPTION_ACCESSIBILITY_LOW_CPU} onChange={(e: any) => {*/}
            {/*    onUpdateSettings({...options, ...{USER_OPTION_ACCESSIBILITY_LOW_CPU: e.target.checked}});*/}
            {/*}}/></div>*/}

            {/*<div> Enable Sound Effects<input type={"checkbox"} checked={options.USER_OPTION_SOUND_EFFECT} onChange={(e: any) => {*/}
            {/*    onUpdateSettings({...options, ...{USER_OPTION_SOUND_EFFECT: e.target.checked}});*/}
            {/*}}/></div>*/}

            <hr/>

            {/*<div> Render Upgraded 3d Objects <input type={"checkbox"} checked={options.USER_OPTION_USE_RENDER_PANELS} onChange={(e: any) => {*/}
            {/*    onUpdateSettings({...options, ...{USER_OPTION_USE_RENDER_PANELS: e.target.checked}});*/}
            {/*}}/></div>*/}



            <Button className={`${!!options.USER_OPTION_SHADOWS?"active":"default"}`} onClick={(e: any) => {
                onUpdateSettings({...options, ...{USER_OPTION_SHADOWS: !options.USER_OPTION_SHADOWS}});
            }}>
                <Space GapSm><FontAwesomeIcon icon={faLightbulb} fixedWidth/><small>Shadows {options.USER_OPTION_SHADOWS?"Enabled":"Disabled"}</small></Space>
            </Button>

            {/*<div> Enable Shadows <input type={"checkbox"} checked={options.USER_OPTION_SHADOWS} onChange={(e: any) => {*/}
            {/*    onUpdateSettings({...options, ...{USER_OPTION_SHADOWS: e.target.checked}});*/}
            {/*}}/></div>*/}

            {(options.USER_OPTION_SHADOWS)&&<div> Shadowmap Size  <select value={options.USER_SETTING_SHADOW_SIZE} onChange={(e: any) => {
                onUpdateSettings({...options, ...{USER_SETTING_SHADOW_SIZE: e.target.value}});
            }}><option value={256}>256 (LD)</option><option value={512}>512 (default)</option><option value={1024}>1024 (HD)</option>
                <option value={1024*2}>2048 (2K)</option>
                <option value={1024*4}>4096 (4K)</option>
            </select></div>}

            {(options.USER_OPTION_SHADOWS)&&<Space direction={"vertical"} GapSm>
                <small>Limit Render Resolution</small>
                <Button className={`${!!options.USER_OPTION_SOFT_SHADOWS?"active":"default"}`} onClick={(e: any) => {
                    onUpdateSettings({...options, ...{USER_OPTION_SOFT_SHADOWS: !options.USER_OPTION_SOFT_SHADOWS}});
                }}>
                    <Space GapSm><FontAwesomeIcon icon={faDrawPolygon} fixedWidth/><small>Soft Shadows {options.USER_OPTION_SOFT_SHADOWS?"Enabled":"Disabled"}</small></Space>
                </Button>
            </Space>}


                {(options.USER_OPTION_SHADOWS &&options.USER_OPTION_SOFT_SHADOWS)&&<div> Shadow Quality <input type={"range"} value={options.USER_SETTING_SHADOW_QUALITY} min={1} max={32} step={1} onChange={(e: any) => {
                    onUpdateSettings({...options, ...{USER_SETTING_SHADOW_QUALITY: parseInt(e.target.value)}});
                }}/></div>}

            {(!options.USER_OPTION_COMPATIBILITY_MODE)&&<div> Mesh Quality <input type={"range"} value={options.USER_SETTING_MESH_QUALITY} min={0} max={1} step={0.25} onChange={(e: any) => {
                onUpdateSettings({...options, ...{USER_SETTING_MESH_QUALITY: parseFloat(e.target.value)}});
            }}/></div>}



            <div> Debug Render Layer   <select defaultValue={options.USER_SETTING_RENDER_ONLY} onChange={(e: any) => {
                onUpdateSettings({...options, ...{USER_SETTING_RENDER_ONLY: parseInt(e.target.value)}});
            }}>
                <option value={"0"}>Final Render</option>
                <option value={"1"}>Base Color</option>
                <option value={"2"}>World Normals</option>
                <option value={"3"}>Model View Normals</option>
                <option value={"4"}>Shadows</option>
                <option value={"5"}>Shadow Visibility</option>
                <option value={"6"}>Diffuse Light</option>
                <option value={"7"}>Shadow UV</option>
                <option value={"8"}>Ambient Light</option>
                <option value={"9"}>Specular Intensity</option>
                <option value={"10"}>Emission</option>
                <option value={"11"}>Reflection</option>
            </select></div>


                {/*    if (renderLayer == 1) { // Color Layer
        gl_FragColor = vec4(vColor.rgb, 1.0);
    } else if (renderLayer == 2) { // World Normals
        gl_FragColor = vec4(vNormal * 0.5 + 0.5, 1.0); // Remap to [0, 1]
    } else if (renderLayer == 3) { // Model Normals
        gl_FragColor = vec4(mNormal * 0.5 + 0.5, 1.0); // Remap to [0, 1]
    } else if (renderLayer == 4) { // Shadows
        gl_FragColor = vec4(shadowFactor * vec3(1.0), 1.0);
    } else if (renderLayer == 5) { // Visibility
        gl_FragColor = vec4(visibility() * vec3(1.0), 1.0);
    } else if (renderLayer == 6) { // Diffuse Light
        gl_FragColor = vec4(baseLighting, 1.0);
    } else if (renderLayer == 7) { // Shadow Coordinates
        gl_FragColor = vec4(vShadowCoord.xyz * vec3(1.0, 1.0, -1.0), 1.0);
    } else if (renderLayer == 8) { // Ambient Light
        gl_FragColor = vec4(ambientLight, 1.0);
    } else if (renderLayer == 9) { // Specular Intensity
        gl_FragColor = vec4(specular, 1.0);
    } else if (renderLayer == 10) { // Emission
        gl_FragColor = vec4(emission, 1.0);
    } else if (renderLayer == 11) { // Reflection
        gl_FragColor = vec4(reflection, 1.0);
    } else { // Final Render
        gl_FragColor = vec4(finalColor, vColor.a);
    }*/}

            {children}

            </Space>
        </div>
    );
};

export default RenderSettingsPanel;
