import { Vec3 } from "./Vec3";
import {Projection} from "./Projection";
import {Vec2} from "./Vec2";
import {Surface} from "./Surface";


function triangleGrow(p1:Vec2, p2:Vec2, p3:Vec2, amt:number) {
    var center = new Vec2();
    center.x = (p1.x + p2.x + p3.x) / 3;
    center.y = (p1.y + p2.y + p3.y) / 3;

    var nv = new Vec2();

    p1.copy(nv.copy(p1).sub(center).mulI((nv.mag() + amt) / (nv.mag())).add(center));
    p2.copy(nv.copy(p2).sub(center).mulI((nv.mag() + amt) / (nv.mag())).add(center));
    p3.copy(nv.copy(p3).sub(center).mulI((nv.mag() + amt) / (nv.mag())).add(center));
}

export class Camera {

    public focus: Vec3;
    public from: Vec3;
    public to: Vec3;
    public up: Vec3;
    private tmpv: Vec3;
    public fov: number;
    public zoom: number;
    public aspect: number;
    public projection: Projection;
    public animation;
    private _tmp;

    public userRotation;
    public userPosition;
    public tick:number;

    constructor() {
        this.from = (new Vec3()).set(0, 100, 30);
        this.userRotation = new Vec3();
        this.userPosition = new Vec3();
        this.to = new Vec3();
        this.up = new Vec3().set(0, 0, 1);
        this.tmpv = new Vec3();
        this.focus = new Vec3();
        this.fov = 45;
        this.zoom = 1;
        this.aspect = 1;
        this.tick = 0;
        this.projection = new Projection();
        this.animation = {
            active: false,
            startfrom: new Vec3(),
            startto: new Vec3(),
            pos: 0,
            gain: 0.5,
            speed: 0,
            finishfrom: new Vec3(),
            finishto: new Vec3()
        };
        this._tmp = {
            v1: new Vec3(),
            v2: new Vec3(),
            v3: new Vec3(),
            v4: new Vec3(),
            p1: new Vec2(),
            p2: new Vec2(),
            p3: new Vec2(),
            drawntris: 0,
            culltris: 0,
            _t: [],
            from_camera_scale_x: 0,
            from_camera_scale_y: 0,
            sr: 0,
            sh: 0,
            sc: 0,
            vx: 0,
            vy: 0,
            lightingvec: 0
        }
    }

    getFromWithUserZoom() {
       // return this.from.clone().mulI(this.zoom);
    }
    getFromWithUserZoomPosition() {
       // return this.from.clone().add(this.userPosition).mulI(this.zoom);
    }
    getFromWithUserZoomPositionRotationGL() {
        return this.from.clone().rotX(this.userRotation.x).rotZ(this.userRotation.z).mulI(this.zoom).add(this.to).add(this.userPosition.clone());
    }

    getFromWithUserZoomPositionRotation() {
        return this.from.clone().rotX(this.userRotation.x).rotZ(-this.userRotation.z).mulI(this.zoom).add(this.to).add(this.userPosition.clone().flipX()); //TODO maybe fix from default flipX
    }

    getToWithUserPosition() {
        return this.to.clone().add(this.userPosition.clone().flipX());
    }

    getToWithUserPositionGL():any {
        return this.to.clone().add(this.userPosition.clone());
    }

    getProjection() {
        return this.projection;
    }

    clone() {
        const c = new Camera();
        c.from = this.from.clone();
        c.to = this.to.clone();
        c.userPosition = this.userPosition.clone();
        c.userRotation = this.userRotation.clone();
        c.aspect = this.aspect;
        c.zoom = this.zoom;
        c.fov = this.fov;

        return c;
    }


    update() {
        /* if (this.animation.active) {
             this.from.x = lerp(this.animation.startfrom.x, this.animation.finishfrom.x, gain(this.animation.pos, this.animation.gain));
             this.from.y = lerp(this.animation.startfrom.y, this.animation.finishfrom.y, gain(this.animation.pos, this.animation.gain));
             this.from.z = lerp(this.animation.startfrom.z, this.animation.finishfrom.z, gain(this.animation.pos, this.animation.gain));

             this.to.x = lerp(this.animation.startto.x, this.animation.finishto.x, gain(this.animation.pos, this.animation.gain));
             this.to.y = lerp(this.animation.startto.y, this.animation.finishto.y, gain(this.animation.pos, this.animation.gain));
             this.to.z = lerp(this.animation.startto.z, this.animation.finishto.z, gain(this.animation.pos, this.animation.gain));

             this.animation.pos += this.animation.speed;
             if (this.animation.pos >= 1) {
                 this.animation.active = false;
             }
         }*/
    }



    drawFace3(surface: Surface, v1:Vec3, v2:Vec3, v3:Vec3, color:string, stroke:boolean=true) {
        this.projection.toScreen(surface, v1, this.getFromWithUserZoomPositionRotation(), this._tmp.p1);
        this.projection.toScreen(surface, v2, this.getFromWithUserZoomPositionRotation(), this._tmp.p2);
        this.projection.toScreen(surface, v3, this.getFromWithUserZoomPositionRotation(), this._tmp.p3);
        //triangleGrow(this._tmp.p1, this._tmp.p2, this._tmp.p3, 2);
        surface.getContext().strokeStyle = color || "#000";
        surface.getContext().fillStyle = color || "#000";
        surface.getContext().beginPath();
        surface.getContext().moveTo(this._tmp.p1.x, this._tmp.p1.y);
        surface.getContext().lineTo(this._tmp.p2.x, this._tmp.p2.y);
        surface.getContext().lineTo(this._tmp.p3.x, this._tmp.p3.y);
        (stroke)?(surface.getContext().stroke()):(surface.getContext().fill());
    }

    drawFace3Grown(surface: Surface, v1:Vec3, v2:Vec3, v3:Vec3, color:string) {
        this.projection.toScreen(surface, v1, this.getFromWithUserZoomPositionRotation(), this._tmp.p1);
        this.projection.toScreen(surface, v2, this.getFromWithUserZoomPositionRotation(), this._tmp.p2);
        this.projection.toScreen(surface, v3, this.getFromWithUserZoomPositionRotation(), this._tmp.p3);
        triangleGrow(this._tmp.p1, this._tmp.p2, this._tmp.p3, 4*surface.getScaling());
        surface.getContext().fillStyle = color || "#000";
        surface.getContext().beginPath();
        surface.getContext().moveTo(this._tmp.p1.x, this._tmp.p1.y);
        surface.getContext().lineTo(this._tmp.p2.x, this._tmp.p2.y);
        surface.getContext().lineTo(this._tmp.p3.x, this._tmp.p3.y);
        surface.getContext().fill();
    }


    getZoom() {
        return this.zoom;
    }

}
