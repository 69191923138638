import React, {createContext, useCallback, useEffect, useRef, useState} from 'react';
import {
    initShaderProgram,
    initBuffers,
    createShadowMap,
    light,
    renderSceneFromLightPerspective, drawSceneShadowMapped, debugVisualizeShadowMap, VBOHelper, isProgramValidForContext
} from "./webgl-utils";
import {Camera} from "../../model/Camera";
import {mat4} from "gl-matrix";
import {Vec3, Vec3Interface} from "../../model/Vec3";
import {Vec2Interface} from "../../model/Vec2";
import {Surface} from "../../model/Surface";
import {PLYData} from "../../model/PLY";

export interface RenderSceneCallbackOptions {
    secondaryCanvasRef: React.RefObject<HTMLCanvasElement>;
    camera?: Camera;
    autoRotate?: boolean;
    PLYDataGenerator?: (camera?:Camera, surface?:Surface, mousePos?:Vec2Interface, toWorld?:Vec3Interface) => {
        static: PLYData | undefined;
        dynamic: PLYData | undefined;
        shadows: PLYData | undefined;
    };
    backgroundColor: [number, number, number, number];
    buffersUUID?: string;
    width: number | string;
    height: number;
}

export interface GLContext {
    renderScene: (options: RenderSceneCallbackOptions) => null;
}

// Create the context with a default value
export const GLContext = createContext<GLContext>({
    renderScene: () => null
});

type GLContextProviderProps = {
    children: React.ReactNode;
};

const canvas = document.createElement('canvas');

const GLContextProvider: React.FC<GLContextProviderProps> = ({children}) => {
    const glContextRef = useRef<WebGLRenderingContext | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [basicShaderProgram, setBasicShaderProgram] = useState<WebGLProgram | null>(null);
    const [shadowShaderProgram, setShadowShaderProgram] = useState<WebGLProgram | null>(null);
    const [cameraShaderProgram, setCameraShaderProgram] = useState<WebGLProgram | null>(null);


    const [flagShaderRebuild, setFlagShaderRebuild] = useState<boolean>(true);

    //  const [basicShaderProgram, setBasicShaderProgram] = useState<WebGLProgram | null>(null);


    const defaultCamera = new Camera();
    defaultCamera.from.set(0, 15, 15);
    defaultCamera.to.set(0, 0, 0.5);
    defaultCamera.fov = 5;

    let shadowMap: any;

    let buffers: any = {};

    const updateCanvasSize = (canvas: HTMLCanvasElement, width: number | string, height: number) => {
        const dpr = window.devicePixelRatio || 1;
        if (typeof width === 'string' && width === '100%' && canvas.parentElement) {
            canvas.width = canvas.parentElement.offsetWidth * dpr;
            canvas.height = height * dpr;
            canvas.style.width = `${canvas.parentElement.offsetWidth}px`;
            canvas.style.height = `${height}px`;
        } else if (typeof width === 'number') {
            canvas.width = width * dpr;
            canvas.height = height * dpr;
            canvas.style.width = `${width}px`;
            canvas.style.height = `${height}px`;
        }
    }

    const loadShadersAndInitializeProgram = async (_sp: any, v: string, f: string) => {
        const gl = glContextRef.current;
        if (!gl) return;

        try {
            const vertexShaderResponse = await fetch(v);
            const fragmentShaderResponse = await fetch(f);

            if (!vertexShaderResponse.ok || !fragmentShaderResponse.ok) {
                throw new Error('Shader source loading failed');
            }

            const vsSource = await vertexShaderResponse.text();
            const fsSource = await fragmentShaderResponse.text();

            const shaderProgram = initShaderProgram(gl, vsSource, fsSource);
            _sp(shaderProgram);
        } catch (error) {
            console.error('Error loading shaders:', error);
        }
    };

    const [shadersReady, setShadersReady] = useState(false);

    // Function to initialize shaders
    const initializeShaders = useCallback(async () => {
        console.log('INITIALIZE SHADERS');
        const gl = glContextRef.current;

        if (gl) {
            if (basicShaderProgram) {
                gl.deleteShader(basicShaderProgram);
            }
            if (shadowShaderProgram) {
                gl.deleteShader(shadowShaderProgram);
            }
            if (cameraShaderProgram) {
                gl.deleteShader(cameraShaderProgram);
            }
        }
        await loadShadersAndInitializeProgram(setBasicShaderProgram, '/ply/shader/default.vs.glsl', '/ply/shader/default.fs.glsl');
        await loadShadersAndInitializeProgram(setShadowShaderProgram, '/ply/shader/default.shadow.vs.glsl', '/ply/shader/default.shadow.fs.glsl');
        await loadShadersAndInitializeProgram(setCameraShaderProgram, '/ply/shader/default.camera.vs.glsl', '/ply/shader/default.camera.fs.glsl');
        setShadersReady(true); // Set the flag indicating shaders are ready
    }, []);

    useEffect(() => {
        canvasRef.current = canvas;
        glContextRef.current = canvas.getContext('webgl');
        const gl = glContextRef.current;
        if (!gl) return;
        if (flagShaderRebuild) {
            setShadersReady(false);
            initializeShaders();
            setFlagShaderRebuild(false);
        }
    }, [flagShaderRebuild, initializeShaders]);


    const setupShadowmap = (gl: WebGLRenderingContext) => {
        if (!shadowMap || gl.checkFramebufferStatus(gl.FRAMEBUFFER) !== gl.FRAMEBUFFER_COMPLETE) {
            if (shadowMap) {
                // Cleanup if there's an existing shadowMap with incomplete framebuffer
                gl.deleteFramebuffer(shadowMap.framebuffer);
                gl.deleteTexture(shadowMap.texture);
                gl.deleteRenderbuffer(shadowMap.renderbuffer);
            }

            shadowMap = createShadowMap(gl, 512, 512); // Or any other suitable resolution
            console.log("Creating shadow map", shadowMap);

            if (!shadowMap || !gl) {
                // rebuildWebGLContext = Date.now();
                return;
            }

            // Bind the framebuffer to check its status
            gl.bindFramebuffer(gl.FRAMEBUFFER, shadowMap.framebuffer);
            if (gl.checkFramebufferStatus(gl.FRAMEBUFFER) !== gl.FRAMEBUFFER_COMPLETE) {
                console.error('Framebuffer is not complete after recreation');
                // Handle the error appropriately
            }
            // Unbind the framebuffer after checking
            gl.bindFramebuffer(gl.FRAMEBUFFER, null);
        }
    }

    let surface:Surface|undefined;

    const renderScene = useCallback((options: RenderSceneCallbackOptions) => {
        const gl = glContextRef.current;
        const canvas = canvasRef.current;
        if (!surface){
            surface = new Surface(canvas as HTMLCanvasElement);
        }
        const secondaryCanvas = options.secondaryCanvasRef.current;

        if (!gl || glContextRef.current === null || !canvas || !secondaryCanvas || !basicShaderProgram || !shadowShaderProgram || !cameraShaderProgram || !shadersReady) return null;


        if (flagShaderRebuild) {
            console.log("REBUILDING SHADERS....");
            return null;
        }

        if (!isProgramValidForContext(glContextRef.current, basicShaderProgram)) {
            console.error("Shader program is not valid for this context.");
            setFlagShaderRebuild(true); // Trigger shader rebuilding
            return null;
        }

        // Function to update canvas size based on options
        const updateCanvasSize = () => {
            const dpr = window.devicePixelRatio || 1;
            if ( canvas.height !==options.height*dpr || canvas.width !== (parseInt(options.width.toString())*dpr)) {
                if (typeof options.width === 'number' && typeof options.height === 'number') {
                    canvas.width = options.width * dpr;
                    canvas.height = options.height * dpr;
                    canvas.style.width = `${options.width}px`;
                    canvas.style.height = `${options.height}px`;
                } else if (typeof options.width === 'string' && options.width === '100%' && canvas.parentElement) {
                    const parentWidth = canvas.parentElement.offsetWidth;
                    canvas.width = parentWidth * dpr;
                    canvas.height = options.height * dpr;
                    canvas.style.width = `${parentWidth}px`;
                    canvas.style.height = `${options.height}px`;
                }
            }
        };

        updateCanvasSize();
        gl.viewport(0, 0, canvas.width, canvas.height);

        gl.clearColor(...options.backgroundColor);
        gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

        const bufferUUID = options.buffersUUID || "any";
        const camera = options.camera || defaultCamera; // Use provided camera or a new one


        if (options.PLYDataGenerator) {
            const {static: staticData, dynamic: dynamicData, shadows: shadowData} = options.PLYDataGenerator(camera, surface, {x:0.5, y:0}, new Vec3());

            if (staticData) {


                buffers[bufferUUID] = initBuffers(gl, staticData, buffers[bufferUUID]);
                //  console.log(bufferUUID, buffers[bufferUUID].elements);
                if (options.autoRotate) {
                    camera.userRotation.z = Date.now() / 1000;
                }
                // @ts-ignore
                camera.aspect = options.width / options.height;
                // ... other camera setup

                setupShadowmap(gl);

                if (!shadowMap) {
                    return null;
                }

                const lightProjectionMatrix = mat4.create();
                const lightViewMatrix = mat4.create();

                const lightPosition: any = light.getFromWithUserZoomPositionRotationGL();
                const lightOrthoZoom = Math.max(1, 1 + (camera.getZoom()));
                const cameraTo = light.getToWithUserPositionGL();


                mat4.ortho(lightProjectionMatrix, -25 * lightOrthoZoom, 25 * lightOrthoZoom, -10 * lightOrthoZoom, 10 * lightOrthoZoom, 1, 200.0); // Adjust the values to match the scene size
                mat4.lookAt(lightViewMatrix, [lightPosition.x, lightPosition.y, lightPosition.z], [cameraTo.x, cameraTo.y, cameraTo.z], [0, 0, 1]);


                //if (renderingOptions && renderingOptions.USER_OPTION_SHADOWS) {

                renderSceneFromLightPerspective(gl, shadowShaderProgram, shadowMap, buffers[bufferUUID], lightProjectionMatrix, lightViewMatrix);
                drawSceneShadowMapped(gl, cameraShaderProgram, buffers[bufferUUID], shadowMap, camera, lightProjectionMatrix, lightViewMatrix);

                //drawScene(gl, basicShaderProgram, buffers[bufferUUID], camera);


                // ... additional drawScene calls for dynamic and shadow data
            }
        }

        const secondaryContext = secondaryCanvas.getContext('2d');
        if (secondaryContext && canvas && canvas.height && canvas.width && secondaryCanvas.height && secondaryCanvas.width) {
            secondaryContext.clearRect(0, 0, secondaryCanvas.width, secondaryCanvas.height);
            secondaryContext.drawImage(canvas, 0, 0, secondaryCanvas.width, secondaryCanvas.height);
        }

        return null;

        //   return canvas.toDataURL();
    }, [shadersReady]);

    return (
        <GLContext.Provider value={{renderScene}}>
            {children}
        </GLContext.Provider>
    );
};

export default GLContextProvider;
