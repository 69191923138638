import { ClientRenderingOptions } from "./RemotePLYLoader";
import { Camera } from "./Camera";
import { Vec3 } from "./Vec3";
import {Vec2} from "./Vec2";

export class Surface {
    private _element: HTMLCanvasElement;
    private _scaling: number;

    constructor(element: HTMLCanvasElement) {
        this._element = element;

        let dpr = window.devicePixelRatio || 1;

        try {
            const settings = window.localStorage.getItem("maag-rendering-options");
            if (settings) {
                const config: ClientRenderingOptions = JSON.parse(settings);
                if (config.USER_OPTION_LIMIT_DEVICE_PIXEL_RATIO) {
                    dpr = 1;
                }
            }
        } catch (error) {
            console.error("Error loading rendering options:", error);
        }

        this._scaling = dpr;
    }

    getWidth(): number {
        return this._element.width;
    }

    getHeight(): number {
        return this._element.height;
    }

    setSize(x: number, y:number){
        this._element.height = y;
        this._element.width = x;
    }

    getElement(){
        return this._element;
    }

    getContext(): CanvasRenderingContext2D {
        const context = this._element.getContext("2d");
        if (!context) {
            throw new Error("Unable to get 2D context.");
        }
        return context;
    }

    clear(): this {
        const ctx = this.getContext();
        ctx.clearRect(0, 0, this.getWidth(), this.getHeight());
        return this;
    }

    getScaling(): number {
        return this._scaling;
    }

    drawText(
        x: number,
        y: number,
        text: string,
        options: { size?: number; color?: string; background?: string } = {}
    ): void {
        const ctx = this.getContext();
        const fontSize = (options.size || 20) * this._scaling;

        ctx.font = `${fontSize}px monospace`;
        ctx.fillStyle = options.color || "#000";

        if (options.background) {
            ctx.textAlign = "center";
            const textWidth = ctx.measureText(text).width;
            const textHeight = fontSize;
            const padding = 10;

            ctx.fillStyle = options.background;
            ctx.fillRect(
                x - textWidth / 2 - padding,
                y - textHeight - padding,
                textWidth + padding * 2,
                textHeight + padding * 2
            );
        }

        ctx.fillStyle = options.color || "#000";
        ctx.textAlign = "left";
        ctx.fillText(text, x, y);
    }

    private drawLine(
        x1: number,
        y1: number,
        x2: number,
        y2: number,
        color: string = "#000",
        lineWidth: number = 1
    ): void {
        const ctx = this.getContext();
        ctx.beginPath();
        ctx.moveTo(x1, y1);
        ctx.lineTo(x2, y2);
        ctx.strokeStyle = color;
        ctx.lineWidth = lineWidth*this.getScaling();
        ctx.stroke();
    }

    drawLinesFromPerspective(surface: Surface, points: Vec3[], camera: Camera, color: string = "#FF0000", lineWidth: number = 1): void {
        if (points.length % 2 !== 0) {
            throw new Error("Points array must contain pairs of Vec3 objects to form lines.");
        }

        const ctx = this.getContext();
      //  ctx.save();

        const cam = camera.getFromWithUserZoomPositionRotation();

        for (let i = 0; i < points.length; i += 2) {
            const start = points[i];
            const end = points[i + 1];

            // Convert 3D points to 2D screen coordinates using the camera's projection
            const startScreen = camera.projection.toScreen(surface, start, cam);
            const endScreen = camera.projection.toScreen(surface, end, cam);

            // Scale coordinates to canvas dimensions
            const x1 = startScreen.x  ;
            const y1 = startScreen.y ;
            const x2 = endScreen.x ;
            const y2 = endScreen.y ;

            surface.drawLine(x1, y1, x2, y2, color, lineWidth/surface.getScaling());
        }

        //ctx.restore();
    }

    drawDebugOriginLines(surface: Surface, camera: Camera, length: number = 10): void {
        const origin = (new Vec3()).set(0, 0, 0);
        const xEnd = (new Vec3()).set(length, 0, 0);
        const yEnd = (new Vec3()).set(0, length, 0);
        const zEnd = (new Vec3()).set(0, 0, length);
        // X-axis (Red)
        this.drawLinesFromPerspective(surface, [origin, xEnd], camera, "#FF0000", 2);
        // Y-axis (Green)
        this.drawLinesFromPerspective(surface, [origin, yEnd], camera, "#00FF00", 2);
        // Z-axis (Blue)
        this.drawLinesFromPerspective(surface, [origin, zEnd], camera, "#0000FF", 2);
    }

    drawCircleFromPerspective(
        surface: Surface,
        position: Vec3,
        radius: number,
        segments: number,
        camera: Camera,
        color: string = "#FF0000",
        lineWidth: number = 1,
        cutoffAngle: number = Math.PI * 2, // Full circle by default
        offsetAngle: number = 0 // No offset by default
    ): void {
        if (segments < 3) {
            throw new Error("A circle requires at least 3 segments.");
        }

        if (cutoffAngle <= 0 || cutoffAngle > Math.PI * 2) {
            throw new Error("Cutoff angle must be between 0 and 2π radians.");
        }

        const points: Vec3[] = [];
        const angleStep = cutoffAngle / segments;

        for (let i = 0; i <= segments; i++) {
            const angle = offsetAngle + i * angleStep;
            const x = position.x + radius * Math.cos(angle);
            const y = position.y + radius * Math.sin(angle);
            const z = position.z;

            const currentPoint = new Vec3().set(x, y, z);
            points.push(currentPoint);
        }

        const lineSegments: Vec3[] = [];
        for (let i = 0; i < points.length - 1; i++) {
            lineSegments.push(points[i], points[i + 1]);
        }

        // Draw the circle using the line segments
        this.drawLinesFromPerspective(surface, lineSegments, camera, color, lineWidth);
    }



    drawDebugOriginGrid(surface: Surface, camera: Camera, gridWidth: number = 3): void {
        const halfWidth = gridWidth / 2;

        // Draw horizontal grid lines (parallel to X-axis)
        for (let i = -halfWidth; i <= halfWidth; i++) {
            const start = new Vec3().set(-halfWidth, i, 0); // Start of the line
            const end = new Vec3().set(halfWidth, i, 0); // End of the line
            this.drawLinesFromPerspective(surface, [start, end], camera, "#CCCCCC", 1); // Gray lines
        }

        // Draw vertical grid lines (parallel to Y-axis)
        for (let i = -halfWidth; i <= halfWidth; i++) {
            const start = new Vec3().set(i, -halfWidth, 0); // Start of the line
            const end = new Vec3().set(i, halfWidth, 0); // End of the line
            this.drawLinesFromPerspective(surface, [start, end], camera, "#CCCCCC", 1); // Gray lines
        }
/*
        // Highlight the middle square with thicker lines
        const middleColor = "#FFAA00"; // Orange
        const middleLineWidth = 2;

        // Middle square's lines
        const midTopLeft = new Vec3().set(-0.5, -0.5, 0);
        const midTopRight = new Vec3().set(0.5, -0.5, 0);
        const midBottomLeft = new Vec3().set(-0.5, 0.5, 0);
        const midBottomRight = new Vec3().set(0.5, 0.5, 0);

        // Top line
        this.drawLinesFromPerspective(surface, [midTopLeft, midTopRight], camera, middleColor, middleLineWidth);
        // Right line
        this.drawLinesFromPerspective(surface, [midTopRight, midBottomRight], camera, middleColor, middleLineWidth);
        // Bottom line
        this.drawLinesFromPerspective(surface, [midBottomRight, midBottomLeft], camera, middleColor, middleLineWidth);
        // Left line
        this.drawLinesFromPerspective(surface, [midBottomLeft, midTopLeft], camera, middleColor, middleLineWidth);*/
    }


    drawDebugAtScreenCoords(
        mainSurface: Surface,
        screenCoords: Vec2, // Normalized screen coordinates [0, 1]
        debugSurface: Surface, // Pre-initialized surface for drawing debug visuals
        debugDrawCallback: (surface: Surface) => void,
        size: Vec2 = new Vec2().set(128, 128) // Size of the debug overlay in pixels
    ): void {
        const mainCtx = mainSurface.getContext();
        const debugCtx = debugSurface.getContext();

        size.mulI(debugSurface.getScaling());

        // Clear the debug surface
        debugCtx.clearRect(0, 0, debugSurface.getWidth(), debugSurface.getHeight());

        // Render debug visuals to the debug surface
        debugDrawCallback(debugSurface);

        // Map normalized screen coordinates to pixel coordinates on the main surface
        const pixelX = screenCoords.x * mainSurface.getWidth() - size.x / 2;
        const pixelY = screenCoords.y * mainSurface.getHeight() - size.y / 2;

        // Overlay the debug surface onto the main surface
        mainCtx.drawImage(
            debugSurface.getElement(),
            0, 0, debugSurface.getWidth(), debugSurface.getHeight(), // Source dimensions
            pixelX, pixelY, size.x, size.y                // Destination dimensions
        );
    }


}
