// Vertex shader program

const SHADOW_MAP_SIZE = 512;


export const vsSource = `
      attribute vec4 aVertexPosition;
      attribute vec4 aVertexColor;
      uniform mat4 uModelViewMatrix;
      uniform mat4 uProjectionMatrix;
      varying lowp vec4 vColor;
      void main(void) {
        gl_Position = uProjectionMatrix * uModelViewMatrix * aVertexPosition;
        vColor = aVertexColor;
      }
    `;

// Fragment shader program
export const fsSource = `
      varying lowp vec4 vColor;
      void main(void) {
        gl_FragColor = vColor;
      }
    `;

export const shadowVS = `attribute vec3 aVertexPosition;
uniform mat4 uLightViewMatrix;
uniform mat4 uLightProjectionMatrix;

void main(void) {
    gl_Position = uLightProjectionMatrix * uLightViewMatrix * vec4(aVertexPosition, 1.0);
}`;
export const shadowFS = `precision mediump float;

vec4 encodeFloat (float depth) {
    const vec4 bitShift = vec4(
    256 * 256 * 256,
    256 * 256,
    256,
    1.0
    );
    const vec4 bitMask = vec4(
    0,
    1.0 / 256.0,
    1.0 / 256.0,
    1.0 / 256.0
    );
    vec4 comp = fract(depth * bitShift);
    comp -= comp.xxyz * bitMask;
    return comp;
}


void main(void) {
    // Since we only care about depth, there is no need to set a color
    gl_FragColor = encodeFloat(gl_FragCoord.z);
}`;


export const cameraVS = `attribute vec3 aVertexPosition;
attribute vec4 aVertexColor;
attribute vec3 aVertexNormal; // Add normal attribute

uniform mat4 uModelViewMatrix;
uniform mat4 uProjectionMatrix;
uniform mat4 uLightViewMatrix;
uniform mat4 uLightProjectionMatrix;

varying vec4 vShadowCoord;
varying vec4 vColor;
varying vec3 vNormal; // Pass normal to fragment shader
varying vec3 mNormal; // Pass normal to fragment shader

void main(void) {
    gl_Position = uProjectionMatrix * uModelViewMatrix * vec4(aVertexPosition, 1.0);
    vShadowCoord = uLightProjectionMatrix * uLightViewMatrix * vec4(aVertexPosition, 1.0);
    vColor = aVertexColor;
    vNormal = aVertexNormal; // Set the normal
    mNormal = vec3(uModelViewMatrix * vec4(aVertexNormal, 0.0));

}`;

export const cameraFS = `precision mediump float;
uniform sampler2D uShadowMap;
uniform vec3 uLightDirection; // Light direction uniform

varying vec4 vShadowCoord;
varying lowp vec4 vColor;
varying vec3 vNormal; // Normal passed from vertex shader
varying vec3 mNormal; // Normal passed from vertex shader

const int size = 0;

float decodeFloat (vec4 color) {
    const vec4 bitShift = vec4(
        1.0 / (256.0 * 256.0 * 256.0),
        1.0 / (256.0 * 256.0),
        1.0 / 256.0,
        1.0
    );
    return dot(color, bitShift);
}

vec4 AmbientWorldLight(){
    vec4 ambientCool = vec4(0.302, 0.451, 0.471, 1.0);
    vec4 ambientWarm = vec4(0.765, 0.573, 0.400, 1.0);
    vec4 skyLight = mix(ambientWarm, ambientCool, 0.5 * (1.0 + dot(mNormal, vec3(0.0, 0.0, 1.0))));
    return skyLight;
}

float Rim(){
    float rimLight = (1.0 - max(0.0, dot(normalize(mNormal), normalize(vNormal))));
    return rimLight;
}

float customStep(float v, int stepSize) {
    float stepValue = 1.0 / float(stepSize);
    return floor(v / stepValue) * stepValue;
}

float visibility(){
    vec3 shadowCoord = (vShadowCoord.xyz / vShadowCoord.w) * 0.5 + 0.5; // Transform to [0, 1] range
    float shadowAcneRemover = 0.001;
    shadowCoord.z -= shadowAcneRemover;
    float shadowDepth = decodeFloat(texture2D(uShadowMap, shadowCoord.xy));
    return shadowCoord.z <= shadowDepth ? 1.0 : 0.5;
}

float bias(float b, float x){
    return x / ((1.0 / b - 2.0) * (1.0 - x) + 1.0);
}

float Edge(){

    vec2 poissonDisk[8];
    poissonDisk[0] = vec2(0.06407013, 0.05409927);
    poissonDisk[1] = vec2(0.7366577, 0.5789394);
    poissonDisk[2] = vec2(-0.6270542, -0.5320278);
    poissonDisk[3] = vec2(-0.4096107, 0.8411095);
    poissonDisk[4] = vec2(0.6849564, -0.4990818);
    poissonDisk[5] = vec2(-0.874181, -0.04579735);
    poissonDisk[6] = vec2(0.9989998, 0.0009880066);
    poissonDisk[7] = vec2(-0.004920578, -0.9151649);

    float occlusion = 0.0;
    float occlusionSamples = 0.0;

    vec3 shadowCoord = (vShadowCoord.xyz / vShadowCoord.w) * 0.5 + 0.5; // Transform to [0, 1] range
    float shadowAcneRemover = 0.001;
    shadowCoord.z -= shadowAcneRemover;
    float texelSize = 1.0 / ${SHADOW_MAP_SIZE}.0;
    
    for (int i = 0; i < 1; i++) {
      
        vec2 sampleLocation = shadowCoord.xy + (poissonDisk[i]*(texelSize*2.0));

        float texelDepth = decodeFloat(texture2D(uShadowMap, sampleLocation));
        if (shadowCoord.z < texelDepth) {
            occlusion += bias(0.99, clamp(normalize(shadowCoord.z - texelDepth) * 20.0, 0.0, 1.0));
        }
        occlusionSamples += 1.0;
    }

    return clamp((1.0 - (occlusion / occlusionSamples)), 0.0, 1.0);
}

float OrthoLightSoft() {
     vec2 poissonDisk[64];
poissonDisk[0] = vec2(-0.613392, 0.617481);
poissonDisk[1] = vec2(0.170019, -0.040254);
poissonDisk[2] = vec2(-0.299417, 0.791925);
poissonDisk[3] = vec2(0.645680, 0.493210);
poissonDisk[4] = vec2(-0.651784, 0.717887);
poissonDisk[5] = vec2(0.421003, 0.027070);
poissonDisk[6] = vec2(-0.817194, -0.271096);
poissonDisk[7] = vec2(-0.705374, -0.668203);
poissonDisk[8] = vec2(0.977050, -0.108615);
poissonDisk[9] = vec2(0.063326, 0.142369);
poissonDisk[10] = vec2(0.203528, 0.214331);
poissonDisk[11] = vec2(-0.667531, 0.326090);
poissonDisk[12] = vec2(-0.098422, -0.295755);
poissonDisk[13] = vec2(-0.885922, 0.215369);
poissonDisk[14] = vec2(0.566637, 0.605213);
poissonDisk[15] = vec2(0.039766, -0.396100);
poissonDisk[16] = vec2(0.751946, 0.453352);
poissonDisk[17] = vec2(0.078707, -0.715323);
poissonDisk[18] = vec2(-0.075838, -0.529344);
poissonDisk[19] = vec2(0.724479, -0.580798);
poissonDisk[20] = vec2(0.222999, -0.215125);
poissonDisk[21] = vec2(-0.467574, -0.405438);
poissonDisk[22] = vec2(-0.248268, -0.814753);
poissonDisk[23] = vec2(0.354411, -0.887570);
poissonDisk[24] = vec2(0.175817, 0.382366);
poissonDisk[25] = vec2(0.487472, -0.063082);
poissonDisk[26] = vec2(-0.084078, 0.898312);
poissonDisk[27] = vec2(0.488876, -0.783441);
poissonDisk[28] = vec2(0.470016, 0.217933);
poissonDisk[29] = vec2(-0.696890, -0.549791);
poissonDisk[30] = vec2(-0.149693, 0.605762);
poissonDisk[31] = vec2(0.034211, 0.979980);
poissonDisk[32] = vec2(0.503098, -0.308878);
poissonDisk[33] = vec2(-0.016205, -0.872921);
poissonDisk[34] = vec2(0.385784, -0.393902);
poissonDisk[35] = vec2(-0.146886, -0.859249);
poissonDisk[36] = vec2(0.643361, 0.164098);
poissonDisk[37] = vec2(0.634388, -0.049471);
poissonDisk[38] = vec2(-0.688894, 0.007843);
poissonDisk[39] = vec2(0.464034, -0.188818);
poissonDisk[40] = vec2(-0.440840, 0.137486);
poissonDisk[41] = vec2(0.364483, 0.511704);
poissonDisk[42] = vec2(0.034028, 0.325968);
poissonDisk[43] = vec2(0.099094, -0.308023);
poissonDisk[44] = vec2(0.693960, -0.366253);
poissonDisk[45] = vec2(0.678884, -0.204688);
poissonDisk[46] = vec2(0.001801, 0.780328);
poissonDisk[47] = vec2(0.145177, -0.898984);
poissonDisk[48] = vec2(0.062655, -0.611866);
poissonDisk[49] = vec2(0.315226, -0.604297);
poissonDisk[50] = vec2(-0.780145, 0.486251);
poissonDisk[51] = vec2(-0.371868, 0.882138);
poissonDisk[52] = vec2(0.200476, 0.494430);
poissonDisk[53] = vec2(-0.494552, -0.711051);
poissonDisk[54] = vec2(0.612476, 0.705252);
poissonDisk[55] = vec2(-0.578845, -0.768792);
poissonDisk[56] = vec2(-0.772454, -0.090976);
poissonDisk[57] = vec2(0.504440, 0.372295);
poissonDisk[58] = vec2(0.155736, 0.065157);
poissonDisk[59] = vec2(0.391522, 0.849605);
poissonDisk[60] = vec2(-0.620106, -0.328104);
poissonDisk[61] = vec2(0.789239, -0.419965);
poissonDisk[62] = vec2(-0.545396, 0.538133);
poissonDisk[63] = vec2(-0.178564, -0.596057);

    float occlusion = 0.0;
    float occlusionSamples = 0.0;

    vec3 shadowCoord = (vShadowCoord.xyz / vShadowCoord.w) * 0.5 + 0.5; // Transform to [0, 1] range
    float shadowAcneRemover = 0.0001; // Increased to reduce shadow acne
    shadowCoord.z -= shadowAcneRemover;
    float texelSize = 1.0 / ${SHADOW_MAP_SIZE}.0;

    float bias = 0.0001; // Reduced bias for smoother transitions

    for (int i = 0; i < 8; i++) {
        float depthDifference = abs(shadowCoord.z - decodeFloat(texture2D(uShadowMap, shadowCoord.xy)));
        float samplingRadius = mix(1.5, 0.5, clamp(depthDifference / bias, 0.0, 1.0)) * texelSize * ${4*4}.0; // Adjusted for smoother transitions
        vec2 sampleLocation = shadowCoord.xy + poissonDisk[i] * samplingRadius;

        float texelDepth = decodeFloat(texture2D(uShadowMap, sampleLocation));
        if (shadowCoord.z <= texelDepth) {
            occlusion += 1.0;
        }
        occlusionSamples += 1.0;
    }

    return clamp(occlusion / occlusionSamples, 0.0, 1.0);
}



void main(void) {
    vec3 shadowCoord = (vShadowCoord.xyz / vShadowCoord.w) * 0.5 + 0.5; // Transform to [0, 1] range

    float shadowAcneRemover = 0.000001;
    shadowCoord.z -= shadowAcneRemover;
    
    float texelSize = 1.0 / ${SHADOW_MAP_SIZE}.0;
    float amountInLight = 0.0;
    
    float sizeF = 1.0;

    for (int x = -size; x <= size; x++) {
        for (int y = -size; y <= size; y++) {
            vec2 sampleLocation = shadowCoord.xy + vec2(x, y) * texelSize;
            float texelDepth = decodeFloat(texture2D(uShadowMap, sampleLocation));
            if (shadowCoord.z < texelDepth) {
                amountInLight += 1.0;
            }
        }
    }
    amountInLight /= sizeF;
    amountInLight = (OrthoLightSoft());
    
    float ambientStrength = 0.6; // Ambient light intensity
    float lightEffect = (min(0.4,max(dot(normalize(vNormal), normalize(uLightDirection)), 0.0)) + ambientStrength);
    
    vec3 color = vColor.rgb + (vec3(1.0,1.0,1.0) * Rim() * 0.1);
    vec3 litColor =  (color) * (lightEffect) * (min(min(visibility(),amountInLight),0.6)+0.4);


    gl_FragColor = (AmbientWorldLight() * 0.2) + vec4(litColor ,1.0);
}`;


export const debugVS = `attribute vec2 aPosition;
attribute vec2 aUV;
varying vec2 vUV;

void main() {
    vUV = aUV;
    gl_Position = vec4(aPosition, 0.0, 1.0);
}
`;
export const debugFS = `precision mediump float;
uniform sampler2D uShadowMap;
varying vec2 vUV;

void main() {
    float shadow = texture2D(uShadowMap, vUV).r;
    gl_FragColor = vec4(shadow, shadow, shadow, 1.0);
}`;