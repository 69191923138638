import {VectorEditor} from "./VectorEditor";
import {Vec3} from "../../model/Vec3";
import {OakMesh, OakMeshEditorObjectItem} from "../../model/OakObject";
import {RGBEditor} from "./RGBEditor";
import {RGBALike} from "../../model/RGBA";
import {FC, useEffect, useState} from "react";
import {MATERIAL_PROPERTIES} from "../interactive/webgl-utils";
import {Space} from "../layout/Content";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPalette} from "@fortawesome/free-solid-svg-icons";

export interface GeometryPanelProps {
    selectedModifyGeometryIndex?: number;
    selectedPLYObject: OakMesh;
    setSelectedPLYObject: any;
    handleEdit: any;
}

export const GeometryPanel: FC<GeometryPanelProps> = ({
                                                          selectedPLYObject,
                                                          selectedModifyGeometryIndex,
                                                          setSelectedPLYObject,
                                                          handleEdit
                                                      }) => {

    const [ob, setOb] = useState<OakMeshEditorObjectItem>();

    useEffect(() => {
        if (selectedPLYObject && selectedModifyGeometryIndex !== undefined) {
            setOb(selectedPLYObject.children[selectedModifyGeometryIndex]);
        }
    }, [selectedPLYObject, selectedModifyGeometryIndex])

    // @ts-ignore
    if (isNaN(selectedModifyGeometryIndex) || selectedModifyGeometryIndex === undefined) {
        return <span>No Index Selected</span>;
    }

    if (!ob) {
        return <span>No Geometry Selected</span>
    }

    return <Space direction={"vertical"} Gap>
            <Space GapSm align={"center"}><FontAwesomeIcon className={"muted"} icon={faPalette} fixedWidth/> <span>Material</span></Space>
       <Space NoWrap GapSm> <RGBEditor label={""} color={selectedPLYObject.children[selectedModifyGeometryIndex].color} onChange={(c: RGBALike) => {
            selectedPLYObject.children[selectedModifyGeometryIndex].color = c;
            setSelectedPLYObject(new OakMesh(undefined, {
                ...selectedPLYObject,
                children: [...selectedPLYObject.children],
            }));
            handleEdit(selectedPLYObject);
        }}/>

        <select className={"wide"} defaultValue={selectedPLYObject.children[selectedModifyGeometryIndex].material.toString()} onChange={(e)=>{
            const value = parseInt(e.target.value);
            selectedPLYObject.children[selectedModifyGeometryIndex].material = value;
            setSelectedPLYObject(new OakMesh(undefined, {
                ...selectedPLYObject,
                children: [...selectedPLYObject.children],
            }));
            handleEdit(selectedPLYObject);
        }}>
            {MATERIAL_PROPERTIES.map((material, idx)=>{
                return <option value={idx.toString()}>{material.name}</option>
            })}
        </select>
       </Space>

        <VectorEditor label={"Position"} vec={Vec3.from(ob.translation)}
                      onChange={(v: Vec3) => {
                          selectedPLYObject.children[selectedModifyGeometryIndex].translation = v;
                          setSelectedPLYObject(new OakMesh(undefined, {
                              ...selectedPLYObject,
                              children: [...selectedPLYObject.children],
                          }));
                          handleEdit(selectedPLYObject);
                      }}/>

        <VectorEditor label={"Scale"} vec={Vec3.from(ob.scale)}
                      onChange={(v: Vec3) => {
                          selectedPLYObject.children[selectedModifyGeometryIndex].scale = v;
                          setSelectedPLYObject(new OakMesh(undefined, {
                              ...selectedPLYObject,
                              children: [...selectedPLYObject.children],
                          }));
                          handleEdit(selectedPLYObject);
                      }}/>

        <VectorEditor label={"Rotation"} vec={Vec3.from(ob.rotation)} increment={45}
                      onChange={(v: Vec3) => {
                          selectedPLYObject.children[selectedModifyGeometryIndex].rotation = v;
                          setSelectedPLYObject(new OakMesh(undefined, {
                              ...selectedPLYObject,
                              children: [...selectedPLYObject.children],
                          }));
                          handleEdit(selectedPLYObject);
                      }}/>

        {/*<RGBEditor label={"Color"} color={ob.color} onChange={(c: RGBALike) => {*/}
        {/*    selectedPLYObject.children[selectedModifyGeometryIndex].color = c;*/}
        {/*    setSelectedPLYObject(new OakMesh(undefined, {*/}
        {/*        ...selectedPLYObject,*/}
        {/*        children: [...selectedPLYObject.children],*/}
        {/*    }));*/}
        {/*    handleEdit(selectedPLYObject);*/}
        {/*}}/>*/}
    </Space>


}