import {FC, useEffect, useState} from "react";
import {
    Button,
    Card,
    Col,
    Content,
    Divider,
    Page,
    Row,
    Space,
    Paragraph,
    Pill, DropDown,
} from "../layout/Content";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faPlus,
    faTrashCan,
    faEdit,
    faSave,
    faCube,
    faCircleNodes,
    faChevronLeft,
    faShareAlt,
    faSprayCan,
    faGear,
    faBorderAll,
    faBorderNone,
    faCloudSun,
    faCubes,
    faSliders,
    faPencil,
    faScrewdriverWrench
} from "@fortawesome/free-solid-svg-icons";
import Popconfirm, {Pagination} from "../layout/Defaults";
import {
    OakMesh,
    OakMeshEditorObjectItemType,
    OakMeshEditorObjectItemTypeIcons,
    OakMeshEditorObjectItemTypeNames, TriangleCountComponent
} from "../../model/OakObject";
import MeshEditorRenderer from "../interactive/MeshEditorRenderer";
import {Vec2Interface} from "../../model/Vec2";
import {Vec3Interface} from "../../model/Vec3";
import {createPLYFile, PLYData, PLYObject} from "../../model/PLY";
import {faCopy, faHandPointer, faSun} from "@fortawesome/free-regular-svg-icons";
import RenderSettingsPanel from "../interactive/RenderSettingsPanel";
import {ClientRenderingOptions, defaultClientRenderingOptions, remotePLYLoader} from "../../model/RemotePLYLoader";
import {GeometryPanel} from "../part/GeometryPanel";
import {RGBEditor} from "../part/RGBEditor";
import {RGBALike} from "../../model/RGBA";
import {MATERIAL_PROPERTIES} from "../interactive/webgl-utils";
import {getTriangleCountForOakMeshChild} from "../../model/GetTrianglesForPrimitive";
import {Camera} from "../../model/Camera";
import EntitySelector from "./EntitySelector";
import GLContextProvider from "../interactive/GLContextProvider";

interface SceneItem {
    uuid?: string;
    type: string;
    name: string;
    position: { x: number; y: number; z: number };
    rotation: { x: number; y: number; z: number };
    scale: { x: number; y: number; z: number };
}

interface Scene {
    uuid?: string;
    name: string;
    children: SceneItem[];
    createdAt: string;
    updatedAt: string;
}


const importCamera = new Camera();
importCamera.from.set(0, 10, 10);
importCamera.to.set(0, 0, 0);
importCamera.fov = 20;
importCamera.zoom = 1;

const SceneEditor: FC = () => {
    const [scenes, setScenes] = useState<Scene[]>([]);

    const [selectedScene, setSelectedScene] = useState<Scene | null>(null);
    const [selectedWindow, setSelectedWindow] = useState<string>("");

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [hasChanges, setHasChanges] = useState(false);
    const [saving, setSaving] = useState(false);


    const [renderingOptions, setRenderingOptions] = useState<ClientRenderingOptions>(defaultClientRenderingOptions());


    const fetchScenes = async (page: number, limit: number) => {
        setLoading(true);
        try {
            const response = await fetch(
                `${process.env.REACT_APP_DOMAIN_DELEGATOR}/scene/statistics?page=${page}&limit=${limit}`
            );
            if (!response.ok) throw new Error("Failed to fetch scenes");
            const data = await response.json();
            setScenes(data.data);
            setTotal(data.total);
        } catch (err: any) {
            setError(err.message || "An unknown error occurred");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchScenes(page, limit);
    }, [page, limit]);

    const handleSaveScene = async () => {
        if (!selectedScene) return;
        try {
            const method = selectedScene.uuid ? "PUT" : "POST";
            const url = selectedScene.uuid
                ? `${process.env.REACT_APP_DOMAIN_DELEGATOR}/scene/${selectedScene.uuid}`
                : `${process.env.REACT_APP_DOMAIN_DELEGATOR}/scene`;

            const response = await fetch(url, {
                method,
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(selectedScene),
            });

            if (!response.ok) throw new Error("Failed to save scene");

            const updatedScene = await response.json();
            setScenes((prev) =>
                selectedScene.uuid
                    ? prev.map((scene) =>
                        scene.uuid === updatedScene.uuid ? updatedScene : scene
                    )
                    : [updatedScene, ...prev]
            );

            setSelectedScene(null);
            setHasChanges(false);
        } catch (err: any) {
            setError(err.message || "An unknown error occurred");
        }
    };

    const handleDeleteScene = async (uuid: string) => {
        try {
            await fetch(`${process.env.REACT_APP_DOMAIN_DELEGATOR}/scene/${uuid}`, {
                method: "DELETE",
            });

            setScenes((prev) => prev.filter((scene) => scene.uuid !== uuid));
        } catch (err: any) {
            setError(err.message || "An unknown error occurred");
        }
    };

    const addScene = () => {
        setSelectedScene({
            name: "New Scene",
            children: [],
            createdAt: "",
            updatedAt: "",
        });
        setHasChanges(true);
    };

    const updateSceneField = (field: keyof Scene, value: any) => {
        if (!selectedScene) return;
        setSelectedScene({...selectedScene, [field]: value});
        setHasChanges(true);
    };


    const getRenderMeshData = (scene?: Scene): PLYData => {
        // cameraLeftEye.userRotation.z = Date.now() / 5000;
        const mesh = new OakMesh();//new PLYObject();

        const debugMesh = new PLYObject(remotePLYLoader.getMeshByIdx("floor.grass.01")).scale({
            x: 1,
            y: 1,
            z: 1
        }).translate({x: 0, y: 0, z: 0}).getPLYData();
        mesh.attach(debugMesh);


        //const selectedMesh = (m || selectedPLYObject || new PLYObject(remotePLYLoader.getMeshByIdx("furnace.01")));//.getPLYData(renderingOptions);
        //mesh.join(selectedMesh);

        //const om = new OakMesh();
        //om.attach()

        return (mesh.getPLYData(renderingOptions));
    }

    const toggleWindow = (t: string) => {
        if (t === selectedWindow) {
            setSelectedWindow("");
            return;
        }
        setSelectedWindow(t);
    }

    const getViewport = (selectedScene: Scene) => {

        return <div style={{height: "100%"}}>
            <MeshEditorRenderer
                renderingOptions={renderingOptions}
                onTap={(mousePos: Vec2Interface, toWorld: Vec3Interface) => {
                    //console.log(mousePos);
                    //   if (selectedModifyGeometryIndex>=0) {
                    //     setSelectedModifyGeometryIndex(-1);
                    // }
                }}
                tools={
                    <Space direction={"vertical"} justify={"space-between"} Full>
                        <Space direction={"horizontal"} justify={"space-between"} PadSm>

                            <Space direction={"vertical"} justify={"space-between"} GapSm>

                                <Card PadSm style={{width: "auto", display: "inline-block"}}>
                                    <Space align={"center"} GapSm>
                                        <Button size={"small"} onClick={() => {
                                            setHasChanges(false);
                                            setSelectedScene(null);
                                            // setSelectedPLYObject(undefined);
                                        }}><FontAwesomeIcon icon={faChevronLeft} fixedWidth/></Button>
                                        <Button
                                            size={"small"}
                                            onClick={() => handleSaveScene()}
                                            disabled={!hasChanges || saving}
                                        >
                                            {saving ? "Saving..." : <FontAwesomeIcon icon={faSave} fixedWidth/>}
                                        </Button>
                                        <Button
                                            size={"small"}
                                            onClick={() => {

                                            }}
                                            //  disabled={!hasChanges || saving}
                                        >
                                            <FontAwesomeIcon icon={faShareAlt} fixedWidth/>
                                        </Button>
                                    </Space>
                                </Card>

                                <div style={{display: "inline-flex"}}>
                                    <Space GapSm justify={"start"} direction={"vertical"} NoWrap>

                                        <Card PadSm style={{height: "100%"}}><Space align={"center"}
                                                                                    direction={"vertical"} GapSm>
                                            {/*<label><strong>{capitalizeFirstLetter(selectedTool)}</strong> Tool</label>*/}
                                            <button>button</button>
                                            <button>button</button>
                                        </Space></Card>

                                    </Space>
                                </div>

                            </Space>

                            <DropDown icon={faPlus} label={"Add Geometry"}>
                                <Content className={"limits"}>
                                    <Space GapSm Wrap direction={"vertical"}>
                                        {/* <Button size={"small"}>Add Vertex</Button>
                                        <Button size={"small"}><FontAwesomeIcon
                                            icon={OakMeshEditorObjectItemTypeIcons[OakMeshEditorObjectItemType.EDGE]}
                                            fixedWidth/> Add Edge</Button>
                                        <Button size={"small"}><FontAwesomeIcon
                                            icon={OakMeshEditorObjectItemTypeIcons[OakMeshEditorObjectItemType.TRIANGLE]}
                                            fixedWidth/> Add Triangle</Button>*/}
                                        Add shit here
                                    </Space>
                                </Content>
                            </DropDown>

                            <Space direction={"vertical"} GapSm>
                                <DropDown label={"Rendering Settings"} icon={faGear}>
                                    <RenderSettingsPanel clientRenderingOptions={renderingOptions}
                                                         onChange={(options) => {
                                                             console.log("GOT NEW RENDERING OPTIONS", options);
                                                             setRenderingOptions(options);
                                                         }}/>
                                </DropDown>


                                <Button onClick={() => {
                                    console.log("GOT NEW RENDERING OPTIONS", renderingOptions.USER_OPTION_SHADOWS);
                                    const newOptions: ClientRenderingOptions =
                                        {
                                            ...renderingOptions,
                                            USER_OPTION_SHADOWS: !renderingOptions.USER_OPTION_SHADOWS
                                        };
                                    setRenderingOptions(newOptions);
                                }} type={renderingOptions.USER_OPTION_SHADOWS ? "active" : "default"}><FontAwesomeIcon
                                    icon={renderingOptions.USER_OPTION_SHADOWS ? faSun : faCloudSun}
                                    fixedWidth/></Button>
                            </Space>

                        </Space>


                        <Space direction={"horizontal"} NoWrap PadSm GapSm style={{maxHeight: "30vh"}}>
                            <Space NoWrap>
                                <Space direction={"vertical"} GapSm justify={"end"}>
                                    <Button
                                        className={selectedWindow === "property" ? "active" : "default"}
                                        onClick={() => {
                                            toggleWindow("property")
                                        }}><FontAwesomeIcon icon={faScrewdriverWrench} fixedWidth/><span className={"hide-sm no-wrap"}> Attributes</span></Button>
                                    <Button
                                        className={selectedWindow === "objects" ? "active" : "default"}
                                        onClick={() => {
                                            toggleWindow("objects")
                                        }}><FontAwesomeIcon icon={faCubes} fixedWidth/><span className={"hide-sm no-wrap"}> Entity Browser</span></Button>


                                    {/*<Button disabled={selectedModifyGeometryIndex===-1}><FontAwesomeIcon icon={faWandMagicSparkles} fixedWidth/><span className={"hide-sm"}> Modifiers</span></Button>*/}
                                </Space></Space>


                            {selectedWindow === "property" && (<div style={{}}>
                                    <Card PadSm style={{
                                    overflowY: "scroll",
                                    height: "100%",
                                    width: "100%"
                                }}>

                                    <Col xs={24}>
                                        <Content>
                                            <Space direction="vertical" GapSm>
                                                <Space justify="space-between">
                                                    <Button
                                                        type={hasChanges ? "active" : "default"}
                                                        disabled={!hasChanges}
                                                        onClick={handleSaveScene}
                                                    >
                                                        <FontAwesomeIcon icon={faSave}/> Save
                                                    </Button>
                                                </Space>
                                                <Divider/>
                                                <label>
                                                    Name:
                                                    <input
                                                        value={selectedScene.name}
                                                        onChange={(e) =>
                                                            updateSceneField("name", e.target.value)
                                                        }
                                                    />
                                                </label>
                                                <Divider/>
                                                <Space direction="vertical" GapSm>
                                                    {selectedScene.children.map((child, idx) => (
                                                        <Card Pad key={idx}>
                                                            <Space justify="space-between">
                                                                <Paragraph>{child.name}</Paragraph>
                                                                <Button
                                                                    size="small"
                                                                    type="danger"
                                                                    onClick={() =>
                                                                        updateSceneField(
                                                                            "children",
                                                                            selectedScene.children.filter(
                                                                                (_, i) => i !== idx
                                                                            )
                                                                        )
                                                                    }
                                                                >
                                                                    <FontAwesomeIcon icon={faTrashCan}/>
                                                                </Button>
                                                            </Space>
                                                        </Card>
                                                    ))}
                                                    <Button
                                                        onClick={() =>
                                                            updateSceneField("children", [
                                                                ...selectedScene.children,
                                                                {
                                                                    type: "mesh",
                                                                    name: "New Child",
                                                                    position: {x: 0, y: 0, z: 0},
                                                                    rotation: {x: 0, y: 0, z: 0},
                                                                    scale: {x: 1, y: 1, z: 1},
                                                                },
                                                            ])
                                                        }
                                                    >
                                                        Add Child
                                                    </Button>
                                                </Space>
                                            </Space>
                                        </Content>
                                    </Col></Card></div>
                            )}
                            {selectedWindow === "objects" && (<Card PadSm style={{
                                    overflowY: "scroll",
                                    height: "100%",
                                    width: "100%"
                                }}>

                                    <Col xs={24}>
                                        <GLContextProvider>
                                            <Space direction="vertical" GapSm>

                                                    <EntitySelector onUpdated={(e) => {
                                                        console.log(e);
                                                    }}/>

                                            </Space></GLContextProvider>
                                    </Col></Card>
                            )}


                        </Space>


                    </Space>
                }
                importCamera={importCamera}
                PLYDataFunction={() => {
                    return getRenderMeshData(selectedScene)
                }}/>
        </div>;
    }


    return (
        <Page Grow>
            {selectedScene && getViewport(selectedScene)}

            {!selectedScene && (<Row>
                    <Col xs={24}>
                        <Content Pad>
                            <Space direction="vertical" GapSm>
                                <Space justify="space-between">
                                    <Button onClick={addScene}>
                                        <FontAwesomeIcon icon={faPlus}/> Add Scene
                                    </Button>
                                </Space>
                                <Pagination
                                    current={page}
                                    total={Math.ceil(total / limit)}
                                    onChange={(p: number) => setPage(p)}
                                />
                                {loading && <div>Loading...</div>}
                                {error && <div style={{color: "red"}}>{error}</div>}
                                <Row>
                                    {scenes.map((scene) => (
                                        <Col xs={12} md={8} lg={6} key={scene.uuid || ""}>
                                            <Card Pad>
                                                <Space direction="vertical" GapSm>
                                                    <Paragraph>{scene.name}</Paragraph>
                                                    <Space>
                                                        <Pill>
                                                            <FontAwesomeIcon icon={faCube}/>{" "}
                                                            {scene.children.length} Items
                                                        </Pill>
                                                        <Button
                                                            size="small"
                                                            onClick={() => setSelectedScene(scene)}
                                                        >
                                                            <FontAwesomeIcon icon={faEdit}/>
                                                        </Button>
                                                        <Popconfirm
                                                            message="Are you sure you want to delete this scene?"
                                                            onOk={() => handleDeleteScene(scene.uuid || "")}
                                                        >
                                                            <Button size="small" type="danger">
                                                                <FontAwesomeIcon icon={faTrashCan}/>
                                                            </Button>
                                                        </Popconfirm>
                                                    </Space>
                                                </Space>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            </Space>
                        </Content>
                    </Col> </Row>
            )}


        </Page>
    );
};

export default SceneEditor;
