import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import {useUserCharacterContext} from "./Provider";
import {LoginModal} from "../part/LoginModal";
import {Button} from "../layout/Content";

const UserSelectComponent: React.FC = () => {
    const {
        userProfile,
        queryUserProfile,
        hasQueriedUserProfile,
        characters,
        queryCharacters,
        currentCharacter,
        setCurrentCharacter
    } = useUserCharacterContext();

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            await queryUserProfile();
            await queryCharacters();
            setLoading(false);
        };

        if (!hasQueriedUserProfile()) {
            fetchData();
        } else {
            setLoading(false);
        }
    }, [queryUserProfile, queryCharacters, hasQueriedUserProfile]);

    if (loading) {
        return (
            <div style={{ textAlign: 'center', marginTop: '2rem' }}>
                <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                <p>Loading...</p>
            </div>
        );
    }

    if (!userProfile) {
        return (
            <div style={{ textAlign: 'center', marginTop: '2rem' }}>
                <p>No user signed in.</p> <LoginModal children={<Button>Sign In</Button>}/>
            </div>
        );
    }

    return (
        <div style={{ padding: '1rem' }}>
            <h2>Welcome, {userProfile.attributes.displayName || userProfile.attributes.username}!</h2>
            <p>Provider: {userProfile.provided.provider || 'Unknown'}</p>

            {characters.length > 0 ? (
                <div style={{ marginTop: '1rem' }}>
                    <label htmlFor="character-select">Select a Character:</label>
                    <select
                        id="character-select"
                        style={{ marginLeft: '0.5rem', padding: '0.5rem', borderRadius: '4px' }}
                        defaultValue={currentCharacter?.uuid||""}
                        onChange={(e)=>{
                            const uuid = e.target.value;
                            const character = characters.find((c)=>{return c.uuid === uuid});
                            if (character) {
                                setCurrentCharacter(character);
                            }
                        }}
                    >
                        <option>select a character</option>
                        {characters.map((character:any) => (
                            <option key={character.uuid} value={character.uuid}>
                                {character.name}
                            </option>
                        ))}
                    </select>
                </div>
            ) : (
                <p>No characters available.</p>
            )}
        </div>
    );
};

export default UserSelectComponent;
