import React, { useState, useEffect, useRef, createContext, useContext } from 'react';
import {useUserCharacterContext} from "./Provider";
import UserSelectComponent from "./UserSelectComponent";

interface SceneData {
    uuid: string;
    name: string;
    description: string;
    isActive: boolean;
}

interface Character {
    uuid: string;
    name: string;
    owner: string;
    scene?: string;
}

interface PlayerData {
    characterId: string;
    name: string;
    position: PlayerPosition;
}

interface PlayerPosition {
    x: number;
    y: number;
}

interface ServerMessage {
    sceneUuid: string;
    data: {
        type: string;
        characterId?: string;
        position?: PlayerPosition;
        name?: string;
        changes?: { characterId: string; position: PlayerPosition; name: string }[];
    };
}

export const ClientApp: React.FC = () => {
    const [scenes, setScenes] = useState<SceneData[]>([]);
    const [connectedScene, setConnectedScene] = useState<string | null>(null);
    const [websocket, setWebSocket] = useState<WebSocket | null>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const [players, setPlayers] = useState<PlayerData[]>([]);
    const { characters, currentCharacter, setCurrentCharacter, queryCharacters } = useUserCharacterContext();

    useEffect(() => {
        fetch('http://localhost:8003/scene')
            .then((res) => res.json())
            .then(setScenes)
            .catch(console.error);
    }, []);

    const joinScene = (sceneUuid: string) => {
        if (!currentCharacter) return alert('Please select a character before joining a scene.');

        fetch('http://localhost:8003/scene/join', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ characterId: currentCharacter.uuid, sceneUuid }),
        })
            .then(() => {
                setConnectedScene(sceneUuid);
                const ws = new WebSocket(`ws://localhost:9000`);
                setWebSocket(ws);

                ws.onopen = () =>
                    ws.send(JSON.stringify({ type: 'authenticate', characterId: currentCharacter.uuid, sceneUuid }));

                ws.onmessage = (event) => {
                    const message: ServerMessage = JSON.parse(event.data);
                    if (message.sceneUuid === sceneUuid) {
                        handleServerMessage(message);
                    }
                };

                ws.onclose = () => {
                    setConnectedScene(null);
                    setWebSocket(null);
                    setPlayers([]);
                };
            })
            .catch(console.error);
    };

    const leaveScene = () => websocket?.close();

    // Modify handleServerMessage to handle bulk_update type
    const handleServerMessage = (message: ServerMessage) => {
        const { data }:{data:any} = message;
        switch (data.type) {
            case 'player_joined':
                setPlayers((prev) => {
                    const exists = prev.some((player) => player.characterId === data.characterId);
                    if (!exists && data.characterId && data.name && data.position) {
                        return [...prev, { characterId: data.characterId, name: data.name, position: data.position }];
                    }
                    return prev;
                });
                break;

            case 'player_moved':
                setPlayers((prev) =>
                    prev.map((player) =>
                        player.characterId === data.characterId
                            ? { ...player, position: data.position! }
                            : player
                    )
                );
                break;

            case 'player_left':
                setPlayers((prev) => prev.filter((player) => player.characterId !== data.characterId));
                break;

            case 'bulk_update':
                if (data.changes) {
                    setPlayers((prev) => {
                        const updatedPlayers = new Map<string, PlayerData>(
                            prev.map((player) => [player.characterId, player])
                        );
                        data.changes.forEach(({ characterId, position, name }:any) => {
                            updatedPlayers.set(characterId, { characterId, position, name });
                        });
                        return Array.from(updatedPlayers.values());
                    });
                }
                break;

            default:
                console.warn(`Unhandled message type: ${data.type}`);
        }
    };

    useEffect(() => {
        if (currentCharacter?.scene) joinScene(currentCharacter.scene);
    }, [currentCharacter]);

    useEffect(() => {
        if (canvasRef.current && connectedScene && websocket) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            if (!ctx) return;

            const handleClick = (e: MouseEvent) => {
                const rect = canvas.getBoundingClientRect();
                const x = e.clientX - rect.left;
                const y = e.clientY - rect.top;

                websocket?.send(
                    JSON.stringify({
                        _event:Math.floor((Math.random()+1)*100000000 ).toString(16),
                        type: 'player_move',
                        sceneUuid: connectedScene,
                        characterId: currentCharacter?.uuid,
                        data: { x, y },
                    })
                );
            };

            canvas.addEventListener('click', handleClick);

            const drawPlayers = () => {
                ctx.clearRect(0, 0, canvas.width, canvas.height);

                players.forEach(({ position, name }) => {
                    ctx.fillStyle = 'blue';
                    ctx.fillRect(position.x - 10, position.y - 10, 20, 20);
                    ctx.fillStyle = 'black';
                    ctx.font = '12px Arial';
                    ctx.textAlign = 'center';
                    ctx.fillText(name, position.x, position.y + 20);
                });

                requestAnimationFrame(drawPlayers);
            };

            drawPlayers();

            return () => canvas.removeEventListener('click', handleClick);
        }
    }, [connectedScene, websocket, players, currentCharacter]);

    return (
        <div>
            <UserSelectComponent/>
            <h1>Select Character</h1>
            <select value={currentCharacter?.uuid || ''} onChange={(e) => setCurrentCharacter(characters.find((char) => char.uuid === e.target.value) || null)}>
                <option value="" disabled>
                    Select a character
                </option>
                {characters.map((char) => (
                    <option key={char.uuid} value={char.uuid}>
                        {char.name}
                    </option>
                ))}
            </select>
            {currentCharacter && <span>{JSON.stringify(currentCharacter)}</span>}
            <h1>Available Scenes</h1>
            {scenes.map((scene) => (
                <div key={scene.uuid}>
                    <h2>{scene.name}</h2>
                    <p>{scene.description}</p>
                    <p>Status: {scene.isActive ? 'Active' : 'Inactive'}</p>
                    <button onClick={() => joinScene(scene.uuid)}>Join Scene</button>
                </div>
            ))}
            {connectedScene && (
                <div>
                    <h2>Connected to Scene</h2>
                    <p>Scene UUID: {connectedScene}</p>
                    <canvas ref={canvasRef} width={500} height={300} style={{ border: '1px solid black' }} />
                    <button onClick={leaveScene}>Leave Scene</button>
                </div>
            )}
        </div>
    );
};
