// 3d App
import {FC, useEffect, useState} from "react";
import {
    bevel,
    decodeOakMFile,
    OakMesh,
    OakMeshData,
    OakMeshEditorObjectItem,
    OakMeshEditorObjectItemType,
    OakMeshEditorObjectItemTypeIcons,
    OakMeshEditorObjectItemTypeNames, subdivide, TriangleCountComponent
} from "../../model/OakObject";
import {ClientRenderingOptions, defaultClientRenderingOptions, remotePLYLoader} from "../../model/RemotePLYLoader";
import {
    Button,
    Card,
    Col,
    Content,
    Divider,
    DropDown,
    Page,
    Paragraph,
    Pill,
    Row,
    Separator,
    Space
} from "../layout/Content";
import MeshEditorRenderer from "../interactive/MeshEditorRenderer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBorderAll,
    faBorderNone,
    faChevronLeft,
    faCircleNodes, faCloudSun,
    faCube, faCubes, faGear, faPalette,
    faPencil, faPlus, faShareAlt, faSliders, faSprayCan, faWandMagicSparkles
} from "@fortawesome/free-solid-svg-icons";
import {faCopy, faHandPointer, faObjectGroup, faSave, faSun, faTrashCan} from "@fortawesome/free-regular-svg-icons";
import RenderSettingsPanel from "../interactive/RenderSettingsPanel";
import {Camera} from "../../model/Camera";
import DefaultRenderer, {UseControlsEnum} from "../interactive/DefaultRenderer";
import {Vec3, Vec3Interface} from "../../model/Vec3";
import Badge from "../part/Badge";
import {RGBEditor} from "../part/RGBEditor";
import {RGBALike} from "../../model/RGBA";
import {GeometryPanel} from "../part/GeometryPanel";
import {Vec2Interface} from "../../model/Vec2";
import {capitalizeFirstLetter} from "../../lib/Strings";
import {getTriangleCountForOakMeshChild} from "../../model/GetTrianglesForPrimitive";
import {createPLYFile, parsePLY, PLYData, PLYObject} from "../../model/PLY";
import {MATERIAL_PROPERTIES} from "../interactive/webgl-utils";
import {Pagination, Popconfirm} from "../layout/Defaults";
import GLContextProvider from "../interactive/GLContextProvider";
import GLRenderer from "../interactive/GLRenderer";
import ImportFBX from "./ImportFBX";


const importCamera = new Camera();
importCamera.from.set(0, 10, 10);
importCamera.to.set(0, 0, 0);
importCamera.fov = 20;
importCamera.zoom = 1;

const previewCamera = new Camera();
previewCamera.from.set(0, 10, 3);
previewCamera.to.set(0, 0, 0);
previewCamera.fov = 20;
previewCamera.zoom = 1;

const iconCamera = new Camera();
iconCamera.from.set(0, 10, 3);
iconCamera.to.set(0, 0, 0);
iconCamera.fov = 40;
iconCamera.zoom = .5;

const deleteMesh = async (id: string): Promise<void> => {
    await fetch(`${process.env.REACT_APP_DOMAIN_DELEGATOR}/mesh/${id}`, {method: 'DELETE'});
};

export const MeshEditorApp: FC = () => {

    const [meshData, setMeshData] = useState<OakMesh[]>([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(16);
    const [total, setTotal] = useState(0);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [gridView, setGridView] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const [hasChanges, setHasChanges] = useState(false);
    const [saving, setSaving] = useState(false);

    const [selectedPLYObject, setSelectedPLYObject] = useState<OakMesh>();
    const [displayPLYObject, setDisplayPLYObject] = useState<OakMesh>();

    const [selectedTool, setSelectedTool] = useState<string>("selection"); //"no"
    const [selectedAction, setSelectedAction] = useState<string>("vertex");

    const [selectedModifyGeometryIndex, setSelectedModifyGeometryIndex] = useState<number>(-1);

    const [selectedObjectDetailsWindow, setSelectedObjectDetailsWindow] = useState<string>("none");
    const toggleSelectedObjectDetailsWindow=(t:string)=>{
        if (t===selectedObjectDetailsWindow){
            setSelectedObjectDetailsWindow("none")
            return;
        }
        setSelectedObjectDetailsWindow(t);
    }
    const toggleSelectedTool=(t:string)=>{
        if (t===selectedTool){
            setSelectedTool("no");
            return;
        }
        setSelectedTool(t);
    }

    const setPaginationPage=(p:number)=>{
        setPage(p);
        fetchPagedMeshes(p,limit,"");
    }

    const [renderingOptions, setRenderingOptions] = useState<ClientRenderingOptions>(defaultClientRenderingOptions());

    const mergeMeshData = (existingMeshes: OakMesh[], fetchedMeshes: OakMesh[]): OakMesh[] => {
        // Create a map of existing meshes by UUID for quick lookup
        const existingMeshMap = new Map(existingMeshes.filter(mesh => mesh.uuid).map(mesh => [mesh.uuid, mesh]));

        // Replace or add fetched meshes
        fetchedMeshes.forEach((fetchedMesh) => {
            if (fetchedMesh.uuid && existingMeshMap.has(fetchedMesh.uuid)) {
                // Replace matching UUIDs
                existingMeshMap.set(fetchedMesh.uuid, fetchedMesh);
            } else {
                // Add new fetched meshes
                existingMeshMap.set(fetchedMesh.uuid || Math.random().toString(36).substr(2, 9), fetchedMesh);
            }
        });

        // Return combined meshes (retaining local entries without UUIDs)
        return [
            ...existingMeshes.filter(mesh => !mesh.uuid), // Keep local meshes without UUID
            ...Array.from(existingMeshMap.values()),     // Add/replace fetched meshes
        ];
    };

    const fetchPagedMeshes = async (page: number, limit: number, search: string): Promise<void> => {
        setLoading(true);
        setError(null);

        try {
            const response = await fetch(
                `${process.env.REACT_APP_DOMAIN_DELEGATOR}/mesh/statistics?page=${page}&limit=${limit}&search=${encodeURIComponent(search)}`
            );
            if (!response.ok) {
                throw new Error('Failed to fetch mesh data');
            }

            const data = await response.json();
            const fetchedMeshes = data.data.map((mesh: any) => new OakMesh(undefined, mesh));

            // Merge fetched meshes with existing mesh data
         //   setMeshData((prevMeshData) => mergeMeshData(prevMeshData, fetchedMeshes));
            setMeshData(fetchedMeshes);
            setTotal(data.total);
        } catch (error: any) {
            setError(error.message || 'An unknown error occurred');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPagedMeshes(page, limit, search);
    }, [page, limit, search]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
        setPage(1); // Reset to first page on search
    };

    const handleNextPage = () => {
        if (page * limit < total) {
            setPage((prev) => prev + 1);
        }
    };

    const handlePreviousPage = () => {
        if (page > 1) {
            setPage((prev) => prev - 1);
        }
    };

    const handleSave = async (mesh: OakMesh | undefined) => {
        if (!mesh) {
            console.error("No mesh selected for saving");
            return;
        }

        setSaving(true);

        try {
            const response = await fetch(
                `${process.env.REACT_APP_DOMAIN_DELEGATOR}/mesh${mesh.uuid ? `/${mesh.uuid}` : ''}`,
                {
                    method: mesh.uuid ? 'PUT' : 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify(mesh),
                }
            );

            if (!response.ok) {
                throw new Error('Failed to save mesh');
            }

            const data = await response.json();
            const savedMesh = new OakMesh(undefined, data);

            // Update meshData
            setMeshData((prevMeshData) => {
                if (mesh.uuid) {
                    // Replace the existing mesh with matching UUID
                    return prevMeshData.map((m) => (m.uuid === mesh.uuid ? savedMesh : m));
                } else {
                    // Add the new mesh if no UUID existed
                    return [...prevMeshData, savedMesh];
                }
            });

            // Update the selectedPLYObject
            setSelectedPLYObject(savedMesh);
            setHasChanges(false); // Reset changes after saving
        } catch (error) {
            console.error('Failed to save mesh:', error);
        } finally {
            setSaving(false);
        }
    };

    // Mark changes when editing
    const handleEdit = (mesh: OakMesh) => {
        setSelectedPLYObject(mesh);
       // setSelectedObjectDetailsWindow("property");
        setHasChanges(true);
    };


    const handleDelete = async (meshId: string | undefined, index: number) => {

        try {
            if (meshId) {
                await deleteMesh(meshId);
                //  fetchPagedMeshes(page, limit, search);
            }
            meshData.splice(index, 1)
            setMeshData(
                [...meshData]
            );
            return;
        } catch (error) {
            console.error('Failed to delete mesh:', error);
        }
    };


// Function to handle file selection and reading
    const importPLY = (): void => {
        // Create a hidden file input element
        const input = document.createElement("input");
        input.type = "file";
        input.accept = ".ply"; // Accept only PLY files or remove if you allow any file
        input.style.display = "none"; // Ensure it doesn't interfere with UI
        // Add event listener for file selection
        input.addEventListener("change", async (event: Event) => {
            const target = event.target as HTMLInputElement;
            const file = target.files?.[0];
            if (!file) {
                console.warn("No file selected.");
                return;
            }
            try {
                // Use FileReader to read the file asynchronously
                const fileContent = await readFileAsync(file);
                if (fileContent) {
                    // Parse the PLY content into OakMeshData (mock implementation)
                    const parsedMesh = new OakMesh(parsePLY(fileContent), {
                        name: file.name,
                        children: [],
                    });
                    // Update the meshData state
                    //setMeshData([parsedMesh, ...meshData]);
                    setSelectedPLYObject(parsedMesh);
                    setHasChanges(true);
                    console.log("File imported successfully.");
                }
            } catch (error) {
                console.error("Error reading file:", error);
            }
        });
        // Append to body and trigger click to ensure compatibility
        document.body.appendChild(input);
        input.click();
        document.body.removeChild(input); // Clean up after the operation
    };

    const importOakM = (): void => {
        // Create a hidden file input element
        const input = document.createElement("input");
        input.type = "file";
        input.accept = ".oakm"; // Accept only PLY files or remove if you allow any file
        input.style.display = "none"; // Ensure it doesn't interfere with UI
        // Add event listener for file selection
        input.addEventListener("change", async (event: Event) => {
            const target = event.target as HTMLInputElement;
            const file = target.files?.[0];
            if (!file) {
                console.warn("No file selected.");
                return;
            }
            try {
                // Use FileReader to read the file asynchronously
                const fileContent = await readFileAsync(file);
                if (fileContent) {
                    const parsedMesh = await decodeOakMFile(fileContent);
                    // Update the meshData state
                    setMeshData([parsedMesh, ...meshData]);
                    console.log("File imported successfully.");
                }
            } catch (error) {
                console.error("Error reading file:", error);
            }
        });
        // Append to body and trigger click to ensure compatibility
        document.body.appendChild(input);
        input.click();
        document.body.removeChild(input); // Clean up after the operation
    };

// Helper function to read file contents asynchronously
    const readFileAsync = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e: ProgressEvent<FileReader>) => {
                const result = e.target?.result as string;
                resolve(result);
            };
            reader.onerror = (error) => reject(error);
            reader.readAsText(file); // Read file as text for PLY format
        });
    };

    // Function to create a new mesh
    const createNewMesh = (): void => {
        const newMesh = new OakMesh(undefined, {name: "default mesh", children: []})
        setSelectedPLYObject(newMesh);
        //setMeshData([newMesh, ...meshData]);
    };

    const getRenderMeshData = (m?: OakMesh): PLYData => {
        // cameraLeftEye.userRotation.z = Date.now() / 5000;
        const mesh = new OakMesh();//new PLYObject();
        if (gridView) {
            const debugMesh = new PLYObject(remotePLYLoader.getMeshByIdx("debug.02")).setColor(0.5, 0.5, 0.5, 1).scale({
                x: 1,
                y: 1,
                z: 1
            }).translate({x: 0, y: 0, z: 0}).setMaterial(1).getPLYData();
            mesh.attach(debugMesh);
        }

        const selectedMesh = (m || selectedPLYObject || new PLYObject(remotePLYLoader.getMeshByIdx("furnace.01")));//.getPLYData(renderingOptions);
        mesh.join(selectedMesh);

        //const om = new OakMesh();
        //om.attach()

       return (mesh.getPLYData(renderingOptions));
    }

    const handleDuplicateMesh = (mesh: OakMesh) => {
        // Create a deep copy of the mesh and reset its UUID
        const duplicatedMesh = new OakMesh(undefined, {
            children: mesh.children,
            uuid: undefined, // Clear UUID for the duplicated mesh
            name: `${mesh.name} (Copy)`,
        });
       // setMeshData((prevMeshData) => [duplicatedMesh, ...prevMeshData]);
        setSelectedPLYObject(duplicatedMesh); // Select the duplicated mesh for editing
        setHasChanges(true);
    };

    const createPrimitive = async (type: OakMeshEditorObjectItemType, filename: string) => {

        if (!selectedPLYObject) {
            return;
        }

        const newPrimitive: OakMeshEditorObjectItem = {
            plyData: undefined,
            rotation: new Vec3(),
            scale: Vec3.identity(),
            translation: new Vec3(),
            triangles: [],//oakM.getTriangles(),
            type: type,
            visible: true,
            material: 0
        }

        selectedPLYObject.children.push(newPrimitive);
        setSelectedPLYObject(new OakMesh(selectedPLYObject.data, {
            ...selectedPLYObject,
            children: [...selectedPLYObject.children],
        }));
        setHasChanges(true);
    }

    const duplicateChildObject = (selectedPLYObject: OakMesh, index: number) => {
        // Get the child object to be duplicated
        const originalChild = selectedPLYObject.children[index];
        if (!originalChild) {
            console.error("Invalid index: No child object found at the specified index.");
            return;
        }

        // Create a deep copy of the original child object
        const duplicatedChild:OakMeshEditorObjectItem = {
            ...originalChild,
           // children: [...originalChild.children],
        };

        // Insert the duplicated child immediately after the original one
        const updatedChildren = [
            ...selectedPLYObject.children.slice(0, index + 1),
            duplicatedChild,
            ...selectedPLYObject.children.slice(index + 1),
        ];

        // Update the selectedPLYObject with the new children array
        setSelectedPLYObject(
            new OakMesh(selectedPLYObject.data, {
                ...selectedPLYObject,
                children: updatedChildren,
            })
        );

        // Mark that changes have been made
        setHasChanges(true);
    };


    const removeChildObject = (selectedPLYObject: OakMesh, index: number) => {
        const nc = selectedPLYObject.children.splice(index, 1);
        setSelectedPLYObject(new OakMesh(selectedPLYObject.data, {
            ...selectedPLYObject,
            children: [...selectedPLYObject.children],
        }));
        setHasChanges(true);
    }

    const getViewport = (selectedPLYObject: OakMesh) => {

        return <div style={{height: "100%"}}>
            <div style={{height: "100%"}}>
                <MeshEditorRenderer
                    renderingOptions={renderingOptions}
                    onTap={(mousePos: Vec2Interface, toWorld: Vec3Interface)=>{
                        //console.log(mousePos);
                        if (selectedModifyGeometryIndex>=0) {
                       //     setSelectedModifyGeometryIndex(-1);
                        }
                    }}
                    tools={
                        <Space direction={"vertical"} justify={"space-between"} Full>
                        <Space direction={"horizontal"} justify={"space-between"} PadSm>

                        <Space direction={"vertical"} justify={"space-between"} GapSm>

                            <Card PadSm style={{width: "auto", display: "inline-block"}}>
                                <Space align={"center"} GapSm>
                                    <Button size={"small"} onClick={() => {
                                        setHasChanges(false);
                                        setSelectedPLYObject(undefined);
                                    }}><FontAwesomeIcon icon={faChevronLeft} fixedWidth/></Button>
                                    <Button
                                        size={"small"}
                                        onClick={() => handleSave(selectedPLYObject)}
                                        disabled={!hasChanges || saving}
                                    >
                                        {saving ? "Saving..." : <FontAwesomeIcon icon={faSave} fixedWidth/>}
                                    </Button>
                                    <Button
                                        size={"small"}
                                        onClick={() => {
                                            console.log(selectedPLYObject.getGeometryFile());
                                            console.log(createPLYFile(selectedPLYObject.getPLYData({
                                                USER_OPTION_ACCESSIBILITY_LOW_CPU: false,
                                                USER_OPTION_COMPATIBILITY_MODE: false,
                                                USER_OPTION_DEBUG_MODE: false,
                                                USER_OPTION_LIMIT_DEVICE_PIXEL_RATIO: false,
                                                USER_OPTION_SHADOWS: false,
                                                USER_OPTION_SOFT_SHADOWS: false,
                                                USER_OPTION_SOUND_EFFECT: false,
                                                USER_OPTION_USE_RENDER_PANELS: false,
                                                USER_SETTING_PARTICLE_QUALITY: 0,
                                                USER_SETTING_RENDER_ONLY: 0,
                                                USER_SETTING_SHADOW_QUALITY: 0,
                                                USER_SETTING_SHADOW_SIZE: 0,
                                                USER_SETTING_MESH_QUALITY:1})));
                                        }}
                                      //  disabled={!hasChanges || saving}
                                    >
                                        <FontAwesomeIcon icon={faShareAlt} fixedWidth/>
                                    </Button>
                                </Space>
                            </Card>

                            <div style={{display:"inline-flex"}}>
                            <Space GapSm justify={"start"} direction={"vertical"} NoWrap>

                                <Card PadSm style={{height: "100%"}}><Space align={"center"} direction={"vertical"} GapSm>
                                    {/*<label><strong>{capitalizeFirstLetter(selectedTool)}</strong> Tool</label>*/}
                                    <button className={[selectedTool === "selection" ? "active" : "default","block"].join(" ")} onClick={() => {
                                        toggleSelectedTool("selection");
                                    }}><Space GapSm justify={"center"}><FontAwesomeIcon icon={faHandPointer} fixedWidth/><small className={"hide-sm"}>select</small></Space></button>
                                    <button className={[selectedTool === "paint" ? "active" : "default","block"].join(" ")} onClick={() => {
                                        setSelectedTool("paint")
                                    }}><Space GapSm justify={"center"}><FontAwesomeIcon icon={faSprayCan} fixedWidth/><small className={"hide-sm"}>paint</small></Space></button>
                                </Space></Card>


                                {selectedTool === "selection" &&
                                    <Card PadSm>  <Space GapSm align={"center"} direction={"vertical"}>
                                        {/*<label><strong>{capitalizeFirstLetter(selectedAction)}</strong> Mode</label>*/}
                                        <button className={[selectedAction === "object" ? "active" : "default","block"].join(" ")} onClick={() => {
                                            setSelectedAction("object");
                                        }}><Space GapSm justify={"center"}><FontAwesomeIcon icon={faCube} fixedWidth/><small className={"hide-sm"}>object</small></Space></button>
                                        <button className={[selectedAction === "vertex" ? "active" : "default","block"].join(" ")} onClick={() => {
                                            setSelectedAction("vertex");
                                        }}><Space GapSm justify={"center"}><FontAwesomeIcon icon={OakMeshEditorObjectItemTypeIcons[OakMeshEditorObjectItemType.VERTEX]} fixedWidth/><small className={"hide-sm"}>vertex</small></Space></button>
                                        <button className={[selectedAction === "face" ? "active" : "default","block"].join(" ")} onClick={() => {
                                            setSelectedAction("face");
                                        }}><Space GapSm justify={"center"}><FontAwesomeIcon icon={faCircleNodes} fixedWidth/><small className={"hide-sm"}>face</small></Space></button>
                                    </Space></Card>}

                            </Space>
                            </div>

                        </Space>

                            <DropDown icon={faPlus} label={"Add Geometry"}>
                                <Content className={"limits"}>
                                    <Space GapSm Wrap direction={"vertical"}>
                                       {/* <Button size={"small"}>Add Vertex</Button>
                                        <Button size={"small"}><FontAwesomeIcon
                                            icon={OakMeshEditorObjectItemTypeIcons[OakMeshEditorObjectItemType.EDGE]}
                                            fixedWidth/> Add Edge</Button>
                                        <Button size={"small"}><FontAwesomeIcon
                                            icon={OakMeshEditorObjectItemTypeIcons[OakMeshEditorObjectItemType.TRIANGLE]}
                                            fixedWidth/> Add Triangle</Button>*/}
                                        <Button size={"small"} onClick={() => {
                                            createPrimitive(OakMeshEditorObjectItemType.PLANE, "cone.02.na")
                                        }}><FontAwesomeIcon
                                            icon={OakMeshEditorObjectItemTypeIcons[OakMeshEditorObjectItemType.PLANE]}
                                            fixedWidth/> Add Plane</Button>
                                        <Button size={"small"} onClick={() => {
                                            createPrimitive(OakMeshEditorObjectItemType.CONE, "cone.02.na")
                                        }}>Add Cone</Button>
                                        <Button size={"small"} onClick={() => {
                                            createPrimitive(OakMeshEditorObjectItemType.CUBE, "cube.02.na")
                                        }}><FontAwesomeIcon
                                            icon={OakMeshEditorObjectItemTypeIcons[OakMeshEditorObjectItemType.CUBE]}
                                            fixedWidth/> Add Cube</Button>
                                        <Button size={"small"} onClick={() => {
                                            createPrimitive(OakMeshEditorObjectItemType.OCTAHEDRON, "cone.02.na")
                                        }}>Add Octahedron</Button>
                                        <Button size={"small"} onClick={() => {
                                            createPrimitive(OakMeshEditorObjectItemType.CYLINDER, "cylinder.02.na")
                                        }}><FontAwesomeIcon
                                            icon={OakMeshEditorObjectItemTypeIcons[OakMeshEditorObjectItemType.CYLINDER]}
                                            fixedWidth/> Add Cylinder</Button>
                                        <Button size={"small"} onClick={() => {
                                            createPrimitive(OakMeshEditorObjectItemType.SPHERE, "sphere.02.na")
                                        }}><FontAwesomeIcon
                                            icon={OakMeshEditorObjectItemTypeIcons[OakMeshEditorObjectItemType.SPHERE]}
                                            fixedWidth/> Add Sphere</Button>
                                    </Space>
                                </Content>
                            </DropDown>

                            <Space direction={"vertical"} GapSm>
                                <DropDown label={"Rendering Settings"} icon={faGear}>
                                    <RenderSettingsPanel clientRenderingOptions={renderingOptions}
                                                         onChange={(options) => {
                                                             console.log("GOT NEW RENDERING OPTIONS", options);
                                                             setRenderingOptions(options);
                                                         }}/>
                                </DropDown>


                                <Button onClick={() => {
                                    setGridView(!gridView);
                                    console.log('TOGGLE GRIDVIEW');
                                }} type={gridView?"active":"default"}><FontAwesomeIcon icon={gridView?faBorderAll:faBorderNone} fixedWidth/></Button>


                                <Button onClick={() => {
                                    console.log("GOT NEW RENDERING OPTIONS",renderingOptions.USER_OPTION_SHADOWS);
                                    const newOptions:ClientRenderingOptions =
                                        {
                                        ...renderingOptions,
                                            USER_OPTION_SHADOWS: !renderingOptions.USER_OPTION_SHADOWS
                                        };
                                    setRenderingOptions(newOptions);
                                }} type={renderingOptions.USER_OPTION_SHADOWS?"active":"default"}><FontAwesomeIcon icon={renderingOptions.USER_OPTION_SHADOWS?faSun:faCloudSun} fixedWidth/></Button>
                            </Space>

                        </Space>


                            {(selectedTool==="selection"||selectedModifyGeometryIndex>=0)&&
                                <Space direction={"horizontal"}  NoWrap PadSm GapSm style={{maxHeight:"30vh"}}>
                                <Space NoWrap>
                                    <Space direction={"vertical"} GapSm justify={"end"}>
                                        <Button className={(selectedObjectDetailsWindow==="geometry")?"active":"default"} disabled={false} onClick={()=>{
                                            toggleSelectedObjectDetailsWindow("geometry")
                                        }}><FontAwesomeIcon icon={faCubes} fixedWidth/><span className={"hide-sm"}> Geometry</span></Button>
                                        <Button className={(selectedModifyGeometryIndex>=0&&selectedObjectDetailsWindow==="property")?"active":"default"} disabled={selectedModifyGeometryIndex===-1} onClick={()=>{
                                            toggleSelectedObjectDetailsWindow("property")
                                        }}><FontAwesomeIcon icon={faSliders} fixedWidth/><span className={"hide-sm"}> Properties</span></Button>
                                        {/*<Button disabled={selectedModifyGeometryIndex===-1}><FontAwesomeIcon icon={faWandMagicSparkles} fixedWidth/><span className={"hide-sm"}> Modifiers</span></Button>*/}
                                    </Space></Space>
                                {(selectedModifyGeometryIndex>=0&&selectedObjectDetailsWindow==="property")&&
                                    <div style={{}}><Card PadSm style={{overflowY:"scroll", height:"100%", width:"100%"}}>
                                    <GeometryPanel selectedPLYObject={selectedPLYObject} selectedModifyGeometryIndex={selectedModifyGeometryIndex} handleEdit={handleEdit} setSelectedPLYObject={setSelectedPLYObject}/>
                                    </Card></div>}

                                    {(selectedModifyGeometryIndex>=0&&selectedObjectDetailsWindow==="material")&&
                                    <div style={{}}><Card PadSm style={{overflowY:"scroll", height:"100%", width:"100%"}}>
                                        <RGBEditor label={""} color={selectedPLYObject.children[selectedModifyGeometryIndex].color} onChange={(c: RGBALike) => {
                                            selectedPLYObject.children[selectedModifyGeometryIndex].color = c;
                                            setSelectedPLYObject(new OakMesh(undefined, {
                                                ...selectedPLYObject,
                                                children: [...selectedPLYObject.children],
                                            }));
                                            handleEdit(selectedPLYObject);
                                        }}/>

                                        <select defaultValue={selectedPLYObject.children[selectedModifyGeometryIndex].material.toString()} onChange={(e)=>{
                                            const value = parseInt(e.target.value);
                                            selectedPLYObject.children[selectedModifyGeometryIndex].material = value;
                                            setSelectedPLYObject(new OakMesh(undefined, {
                                                ...selectedPLYObject,
                                                children: [...selectedPLYObject.children],
                                            }));
                                            handleEdit(selectedPLYObject);
                                        }}>
                                            {MATERIAL_PROPERTIES.map((material, idx)=>{
                                                return <option value={idx.toString()}>{material.name}</option>
                                            })}
                                        </select>
                                    </Card></div>}
                                {(selectedObjectDetailsWindow==="geometry")&&<div style={{}}>
                                        <Space GapSm direction={"vertical"} NoWrap style={{height:"100%"}}>

                                            <div>
                                                <Space GapSm align={"center"} direction={"horizontal"} justify={"end"}>
                                                    <label>Mesh Geometry
                                                        ( <Space justify={"center"}
                                                                 style={{display: "inline-flex"}}><small><strong>{selectedPLYObject.children.length.toString()}</strong></small>
                                                            <FontAwesomeIcon icon={faCube} fixedWidth/></Space> )</label>

                                                </Space></div>
                                            <Space style={{height:"100%", overflow:"hidden"}} direction={"horizontal"} NoWrap GapSm>
                                                <Card PadSm style={{overflowY:"scroll", height:"100%", width: "100%"}}>

                                                    <Space GapSm direction={"vertical"}>
                                                        {selectedPLYObject.children.map((ob, index) => {
                                                            return <Card PadSm className={selectedModifyGeometryIndex===index?"active":"default"} key={index.toString()+JSON.stringify(ob)}>
                                                                <Space GapSm align={"center"}
                                                                       justify={"space-between"}>

                                                                    <Space GapSm align={"center"}><FontAwesomeIcon
                                                                        icon={OakMeshEditorObjectItemTypeIcons[ob.type]}
                                                                        fixedWidth/>

                                                                        <RGBEditor label={""} color={ob.color} onChange={(c: RGBALike) => {
                                                                            selectedPLYObject.children[index].color = c;
                                                                            setSelectedPLYObject(new OakMesh(undefined, {
                                                                                ...selectedPLYObject,
                                                                                children: [...selectedPLYObject.children],
                                                                            }));
                                                                            handleEdit(selectedPLYObject);
                                                                        }}/>

                                                                        <select className={"hide-sm"} defaultValue={ob.material.toString()} onChange={(e)=>{
                                                                            const value = parseInt(e.target.value);
                                                                            selectedPLYObject.children[index].material = value;
                                                                            setSelectedPLYObject(new OakMesh(undefined, {
                                                                                ...selectedPLYObject,
                                                                                children: [...selectedPLYObject.children],
                                                                            }));
                                                                            handleEdit(selectedPLYObject);
                                                                        }}>
                                                                            {MATERIAL_PROPERTIES.map((material, idx)=>{
                                                                                return <option value={idx.toString()}>{material.name}</option>
                                                                            })}
                                                                        </select>

                                                                        <Button size={"small"} type={"ghost"} onClick={() => {
                                                                            setSelectedModifyGeometryIndex(index);
                                                                            setSelectedObjectDetailsWindow("property");
                                                                        }}><FontAwesomeIcon icon={faPencil} fixedWidth/></Button>

                                                                        <span>{OakMeshEditorObjectItemTypeNames[ob.type]}</span>
                                                                        <span>{ob.name}</span>

                                                                    </Space>

                                                                    <span className={"hide-sm"}><small><strong><TriangleCountComponent count={getTriangleCountForOakMeshChild(selectedPLYObject, index)}/></strong> <FontAwesomeIcon
                                                                        icon={faCircleNodes} fixedWidth/></small></span>


                                                                    <Space GapSm>
                                                                        <Button size={"small"} type={"ghost"} onClick={() => {
                                                                            duplicateChildObject(selectedPLYObject, index);
                                                                        }}><FontAwesomeIcon icon={faCopy} fixedWidth/></Button>
                                                                        <Popconfirm message={"Are you sure you want to Delete?"} onOk={()=>{
                                                                            removeChildObject(selectedPLYObject, index);
                                                                        }}><Button size={"small"} type={"danger"}><FontAwesomeIcon icon={faTrashCan} fixedWidth/></Button></Popconfirm>


                                                                    </Space>
                                                                </Space>
                                                            </Card>
                                                        })}
                                                    </Space>


                                                </Card>
                                            </Space>
                                        </Space>
                                    </div>}

                            </Space>}


                        </Space>
                    }
                    importCamera={importCamera}
                    PLYDataFunction={()=>{return getRenderMeshData(selectedPLYObject)}}/>
            </div>

        </div>;
    }

    return (
        <Page Grow style={{background:"#fafafa"}}>
            <Row Full>
                {selectedPLYObject && <Col xs={24}>
                    {getViewport(selectedPLYObject)}
                </Col>}
                {!selectedPLYObject && <Col xs={24}>
                    <Content Pad>
                        <Space direction={"vertical"} GapSm>

                        <Space align={"center"} GapSm>
                            <Divider/>
                            <Button onClick={createNewMesh}>Create New Mesh</Button>
                            <Button onClick={importPLY}>Import from PLY</Button>
                            <ImportFBX/>
                            <Button onClick={importOakM}>Import from OakM</Button>
                        </Space>

                            <Pagination current={page} total={Math.ceil(total/limit)} onChange={(p:number)=>{
                                setPaginationPage(p);
                            }} />

                        {error && <div style={{color: 'red'}}>{error}</div>}
                        {loading && <div>Loading...</div>}



                        <Row GapSm>
                            <GLContextProvider>
                            {meshData.map((m: OakMesh, index) => {
                                return <Col xs={12} md={12} lg={6} onMouseEnter={() => {
                                    setDisplayPLYObject(m);
                                }}><Card PadSm>

                                    <Space GapSm justify={"space-between"} align={"center"}>
                                        <div style={{width: "100%", height:"10vh",minHeight: "2em"}}>
                                            {/*<DefaultRenderer
                                            useControls={UseControlsEnum.NOSCROLL}
                                            importCamera={iconCamera} plyData={()=>{return getRenderMeshData(m)}}/>*/}
                                            <GLRenderer
                                                key={m.uuid||`${page}-${index}`}
                                            options={{
                                                autoRotate: true,
                                                width: "100%",
                                                height: 128,
                                                buffersUUID: "any",
                                                backgroundColor:[Math.random(), Math.random(), Math.random(), 1],
                                                PLYDataGenerator:()=>{
                                                    return {
                                                        ready: false,
                                                        static: m.getPLYData(),
                                                        dynamic: undefined,
                                                        shadows: undefined,
                                                    };
                                                //return getRenderMeshData(m)
                                            }}}/>
                                        </div>
                                        <Paragraph className={"overflow-ellipsis"}><small>{m.name || "unnamed mesh"} <sup>{m.uuid ? `` :
                                            <Badge/>}</sup></small></Paragraph>
                                        <Space GapSm>
                                            <Pill>
                                                <Space GapSm
                                                       justify={"center"}>
                                                    <Space justify={"center"}>
                                                        <small><strong>{m.children.length}</strong></small>
                                                        <FontAwesomeIcon icon={faCube}
                                                                         fixedWidth/></Space><Separator/><Space
                                                    justify={"center"}><small><strong>{m.getTriangles().length}</strong></small>
                                                    <FontAwesomeIcon icon={faCircleNodes}
                                                                     fixedWidth/></Space></Space></Pill>
                                        </Space>
                                        <Space GapSm>
                                            <Button size={"small"} onClick={() => {
                                                setSelectedPLYObject(m);
                                                setHasChanges(false);
                                            }}>Edit</Button>
                                            <Button
                                                size="small"
                                                onClick={() => handleDuplicateMesh(m)}
                                            >
                                                Duplicate
                                            </Button>
                                            <Button size={"small"} onClick={() => handleSave(m)}>Save</Button>
                                            <Popconfirm message={"Are you sure you want to Delete?"} onOk={()=>{
                                                handleDelete(m.uuid, index)
                                            }}><Button size={"small"} type={"danger"}><FontAwesomeIcon icon={faTrashCan} fixedWidth/></Button></Popconfirm>
                                        </Space>
                                    </Space>
                                </Card>
                                </Col>
                            })}
                            </GLContextProvider>
                        </Row>
                        </Space>
                    </Content>
                </Col>}
            </Row>
        </Page>
    );
}