import {
    Col,
    Content,
    Layout,
    Page,
    Row,
    Paragraph,
    Space,
    Title,
    Divider,
    Button, Card, Input, Link, Pill, Small, Separator, Highlight, Spacer
} from "./layout/Content";
import React from 'react';
import {Footer, Header, Navigation} from "./partial/Navigation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowRight, faBookBookmark, faChartLine,
    faCircleHalfStroke,
    faDiagramSuccessor,
    faDumpsterFire, faShare, faShareAlt,
    faSignature, faUpRightFromSquare
} from "@fortawesome/free-solid-svg-icons";
import {Blockquote, InlineIcon, Select, Statistic, Tooltip} from "./layout/Defaults";
import Badge from "./part/Badge";
import {Trademark} from "./part/Trademark";
import {GetBookModal} from "./part/GetBookModal";


const svgStyle = {
    // minWidth: '400px',
    maxWidth: '800px',
    minHeight: '300px',
    maxHeight: '600px',
    width: '100%',
    height: '100%',
    overflow: 'visible',
};

const PastelShapesSVG = () => (
    <svg viewBox="0 0 800 600" xmlns="http://www.w3.org/2000/svg" style={svgStyle}>
        {/* Background */}

        {/* Overlapping Circles */}
        <circle cx="400" cy="300" r="250" fill="#009be6" opacity="0.9"/>
        <circle cx="550" cy="200" r="160" fill="#e497f1" opacity="0.8"/>

        {/* Rectangle with Subtle Gradient */}
        <defs>
            <linearGradient id="grad" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" style={{stopColor: '#03396c', stopOpacity: 0.41}}/>
                <stop offset="100%" style={{stopColor: '#005b96', stopOpacity: 0.41}}/>
            </linearGradient>
        </defs>
        <rect x="0" y="250" width="700" height="200" fill="url(#grad)"/>

        {/* Diagonal Lines */}
        <line x1="400" y1="600" x2="950" y2="400" stroke="#03396c" strokeWidth="5" opacity="0.2"/>
        <line x1="400" y1="0" x2="950" y2="600" stroke="#03396c" strokeWidth="5" opacity="0.2"/>

    </svg>
);


const LandingPage: React.FC = () => {
    return (
        <Layout>
            <Header/>
            <Page Grow className="app-main">
                <Content className={"limits wide pad-h pad-bottom"}>
                    <Row Gap>
                        <Col md={24} lg={12} xl={12} className={"pattern01 rounded"}>
                            <Space align={"center"} justify={"center"} Full>
                                <Row>
                                    <Col xs={24} md={24} lg={16} xl={12}>
                                        <Space direction="vertical">

                                            <Content style={{padding: "80px 20px"}}>
                                                <Space direction={"vertical"} Gap>
                                                    <Title Large style={{fontWeight:900}}>Research<span
                                                        className={"muted-heavy"}>,</span><br/>Design<span
                                                        className={"muted-heavy"}>, and </span><br/>Development.</Title>

                                                    <p className={"muted"}><strong>Transformative Software
                                                        Architecture</strong> with <strong>Organizational Change
                                                        Management</strong> through the patterns and methodologies
                                                        of <strong>Stable Systems</strong>.</p>
                                                </Space>
                                            </Content>

                                        </Space>
                                    </Col>
                                </Row></Space>
                        </Col>
                        <Col md={24} lg={12} xl={12}>
                            <Space justify="space-between" direction="vertical" GapSm Full>
                                <Card className={"pad pattern02 full"}>

                                    <Space align={"center"} Full>

                                    <Space direction="vertical" GapSm Wide>


                                        <Paragraph>
                                            <h2>OakFrame Foundational Models</h2>
                                        </Paragraph>
                                        <Paragraph>Our <strong>Foundational Models</strong> are easy to implement, high
                                            quality, low latency, and extremely cost effective.</Paragraph>
                                        <Card Pad>
                                            <Space GapSm direction={"vertical"}>
                                                <Paragraph><strong>OakLLM Fnd0.Moderation &mdash;</strong> <em className={"muted"}>Quick
                                                    Structured Language Classification</em></Paragraph>
                                                <Space GapSm Stretch justify={"space-around"}>
                                                    <Statistic title={<span>Model<br/>Params.</span>} value={"8.03b"}/>
                                                    <Separator/>
                                                    <Statistic title={<span>Response<br/>Time</span>} value={"260ms"}/>
                                                    <Separator/>
                                                    <Statistic title={<span>Cost per<br/>Token</span>} value={<span><span
                                                        className={"muted-heavy"}></span><Highlight>Free<sup style={{
                                                        color: "grey",
                                                        fontSize: "40%"
                                                    }}>1</sup></Highlight></span>}/>
                                                </Space>
                                            </Space>
                                        </Card>
                                        <small><strong><sup>1</sup></strong> Usage and rate-limits may apply. See full
                                            details for more information.</small>
                                        <ul>
                                            <li><Link href={"/"}>Moderation & Classification</Link></li>
                                            <li><Link href={"/"}>Unrestricted Text Generation</Link></li>
                                            <li><Link href={"/"}>Image Description & Captioning</Link></li>
                                        </ul>
                                        <Space GapSm align={"center"}><a href={"https://clusterfx.org/databox/models"}> Explore
                                            all the Models at <strong>ClusterFX</strong><Trademark/></a><FontAwesomeIcon fixedWidth
                                                                                                             icon={faUpRightFromSquare}/></Space>
                                    </Space></Space>

                                </Card>


                            </Space>
                        </Col>



                        <Col xs={24}>
                            <Title Large style={{
                                borderTop: "12px solid #c0e0f055",
                                paddingTop: "40px",
                                textAlign: "center"
                            }}> Explore <strong>Solutions</strong> by <strong>OakFrame</strong></Title>

                        </Col>


                        <Col xs={24} md={24}>


                            <Row Gap>

                                <Col xs={26} md={24} lg={16}>
                                    <Card Full Pad className={""}>
                                        <Row Gap Full>
                                            <Col xs={24} md={12} lg={12}>

                                                <div style={{
                                                    backgroundImage: `url("/asset/landing/building-on-unstable-services.png")`,
                                                    height: "100%",
                                                    width: "100%",
                                                    minHeight: "300px",
                                                    maxWidth: "100%",
                                                    backgroundPosition: "50% 50%",
                                                    backgroundSize: "contain",
                                                    backgroundRepeat: "no-repeat"
                                                }}></div>

                                            </Col>
                                            <Col xs={24} md={12} lg={12}>
                                                <Space justify="space-between" direction="vertical" GapSm Full>

                                                    <Space direction="vertical" GapSm>

                                                        <h1>Building on Unstable Services</h1>
                                                        <Paragraph>
                                                                <em>
                                                                    A Practical Guide to Decoupling, Service Isolation, and Scalability
                                                                </em>
                                                        </Paragraph>
                                                        <Paragraph>
                                                            In an age where software needs to be fast, adaptable, and built to handle complex demands, stable systems has emerged as a transformative approach for modern applications.
                                                        </Paragraph><Paragraph>
                                                            A comprehensive strategy for implementing <strong>stable system</strong> design patterns that balance innovation with reliability, scalability with simplicity, and speed with security.
                                                        </Paragraph>

                                                        <Space GapSm id="our-expertise" direction={"vertical"}>
                                                            <Small className={"muted-heavy"}
                                                                   style={{fontWeight: "bold"}}>Whether you’re a software architect, developer, DevOps engineer, or tech leader, this book provides everything you need to design, implement, and manage microservices architectures that drive real business value.
                                                            </Small>
                                                        </Space>

                                                        <GetBookModal/>


                                                    </Space>
                                                    <Space direction="vertical" justify="end">
                                                        <Space GapSm>
                                                            <Pill className={"ghost"}>Hardcover Print & E-Book</Pill>
                                                            <Pill className={"ghost"}>Over 300 Pages</Pill>
                                                            <Pill className={"ghost"}>Releases 2025</Pill>
                                                        </Space>
                                                    </Space>

                                                </Space>
                                            </Col>

                                        </Row>
                                    </Card>
                                </Col>
                                <Col xs={24} md={24} lg={8}>
                                    <Card className={"comment pad rounded active"} Full>

                                        <Space direction={"vertical"} Full GapSm justify={"space-between"}>
                                            <Space GapSm direction={"vertical"}>
                                                <Paragraph className={"muted"}>Pre-release <strong>Chapters 1 - 3</strong> are now available for <strong>free</strong> for a limited time.</Paragraph>

                                                <Paragraph>
                                                    We at <strong>OakFrame Interactive Ltd</strong>, and the Author, are
                                                    interested in hearing your technical feedback. In exchange, you can
                                                    keep this pre-release preview of the entire <strong>Part 1</strong>:
                                                </Paragraph>
                                            </Space>

                                                <Space direction={"vertical"} NoWrap GapSm>
                                                    <ol>
                                                        <li><strong>Introduction to Microservices:</strong><br/>Definitions,
                                                            Core Principals, Evolution from Monoliths
                                                        </li>
                                                        <li><strong>Architecting for Instability:</strong><br/> Risk
                                                            Profiles, Decoupling, Testing at Service Boundaries
                                                        </li>
                                                        <li><strong>Building with Intentional Design:</strong><br/> Impact
                                                            of Unintentional Design Flaws, Business Objectives
                                                        </li>
                                                    </ol>
                                                    <Paragraph><em>Get the entire <strong>Part 1</strong> (Chapters 1 - 3) <strong>Pre-Release E-Book</strong> sent to your email inbox!</em></Paragraph>
                                                    <Button href="/publishing" icon={<FontAwesomeIcon fixedWidth icon={faBookBookmark}/>} type={"ghost"} className={"wide"}><strong>More Information & Recent Publications</strong></Button>
                                                </Space>

                                        </Space>

                                    </Card>


                                </Col>

                            </Row>

                        </Col>


                        <Col xs={24}>
                            <Content>
                            <Space direction="vertical" Gap Full Wide>
                                <Title style={{
                                    fontSize: "200%",
                                    fontWeight: "100",
                                    textAlign: "center"
                                }}><strong>ClusterFX</strong><Trademark/> Distributed <strong>Computation</strong> and <strong>Tooling</strong> </Title>
                            </Space>
                            </Content>
                        </Col>

                        <Col xs={24} lg={12}>

                            <Content className={""}>

                            <Card Full Pad>
                                <Space direction={"vertical"} justify={"space-between"} Full>

                                    <Row Full>
                                        <Col xs={24}>

                                            <div style={{
                                                backgroundImage: `url("/asset/landing/clusterfx-promo.png")`,
                                                height: "100%",
                                                width: "100%",
                                                minHeight: "300px",
                                                maxWidth: "100%",
                                                backgroundPosition: "50% 50%",
                                                backgroundSize: "contain",
                                                backgroundRepeat: "no-repeat"
                                            }}></div>

                                        </Col>
                                        <Col xs={24}>

                                            <Space direction="vertical" GapSm>

                                                <Space GapSm align={"center"} justify={"center"}><a href={"https://clusterfx.org"}> Early Access to the <strong>ClusterFX</strong><Trademark/> Platform</a><FontAwesomeIcon fixedWidth
                                                                                                                                                                                                         icon={faUpRightFromSquare}/></Space>
                                            </Space>


                                        </Col>
                                    </Row>


                                </Space>
                            </Card></Content>
                        </Col>


                        <Col xs={24} lg={12} style={{maxHeight: "initial"}}>
                            <Space justify="space-around" direction="vertical" GapSm Full>
                                <Content className={""}>
                                    <Space direction="vertical" Gap Full Wide>
                                        <h2 style={{
                                            fontWeight: "100",
                                        }} className={""}>Integrate <strong>Directly</strong> into your <strong>Website</strong>, <strong>Database</strong>, or <strong>Analytics</strong></h2>
                                        <Paragraph>
                                            Our Flagship AI & ML Toolkit and Interface is now available. Provision and
                                            fine-tune models, curate and classify prompts and examples.
                                        </Paragraph>
                                        <Paragraph>
                                            <Space direction="vertical" GapSm Wide>
                                                <Space GapSm align={"center"} NoWrap><InlineIcon/> <span><strong>API-Driven</strong> Endpoints for Seamless Integration</span></Space>
                                                <Space GapSm align={"center"} NoWrap><InlineIcon/> <span>Open-Source Solutions for <strong>Flexibility</strong> and <strong>Control</strong></span></Space>
                                                <Space GapSm align={"center"} NoWrap><InlineIcon/> <span>Custom <strong>Model Provisioning</strong> and <strong>Fine-Tuning</strong></span></Space>
                                                <Space GapSm align={"center"} NoWrap><InlineIcon/> <span>Simplified <strong>Data Classification</strong> and <strong>Example Curation</strong></span></Space>
                                            </Space>
                                        </Paragraph>
                                    </Space>
                                </Content>
                            </Space>
                        </Col>

                        <Col xs={24}>
                            <Title style={{
                                fontSize: "200%",
                                fontWeight: "100",
                                textAlign: "center"
                            }}> <strong>Integrations</strong> and Technical <strong>Change Management</strong></Title>
                        </Col>

                        <Col xs={24} md={8}>
                            <Card Full Pad>
                                <Space justify="space-between" direction="vertical" GapSm Full>
                                    <Space direction="vertical" GapSm>
                                        <h2>Microservice Architecture & Development Consulting</h2>
                                        <Paragraph>
                                            <Space direction={"vertical"}>
                                                <em>Transform your development</em>
                                            </Space>
                                        </Paragraph>
                                        <Paragraph>
                                            We excel in Microservice architecture, providing scalable and
                                            robust solutions that ensure seamless integration and high
                                            availability. Our approach guarantees that your systems are
                                            flexible, efficient, and future-proof.
                                        </Paragraph>
                                        <Col xs={24} md={24}>
                                            <Space GapSm id="our-expertise" direction={"vertical"}>
                                                <Paragraph> <Small className={"muted-heavy"} style={{fontWeight: "bold"}}>Clients at
                                                    all
                                                    Scales, Startup, Small-medium Business, Enterprise, State /
                                                    Municipality, including Government at a Federal Level.</Small></Paragraph>
                                            </Space>
                                        </Col>
                                    </Space>
                                </Space>
                            </Card>
                        </Col>

                        <Col xs={24} md={8}>
                            <Card Full Pad>
                                <Space justify="space-between" direction="vertical" GapSm Full>
                                    <Space direction="vertical" GapSm>
                                        <h2>Embedded Technologies & Hardware Integration</h2>
                                        <Paragraph>
                                            <Space direction="vertical">
                                                <em>Explore Embedded Solutions</em>
                                            </Space>
                                        </Paragraph>
                                        <Paragraph>
                                            We specialize in hardware-level coding and embedded device integration,
                                            ensuring that all components operate in harmony. Our expertise supports
                                            high-performance solutions across various applications, making your system
                                            both efficient and reliable.
                                        </Paragraph>
                                        <Col xs={24} md={24}>
                                            <Space GapSm id="embedded-expertise" direction="vertical">
                                                <Paragraph>  <Small className="muted-heavy" style={{fontWeight: "bold"}}>Expertise in
                                                    IoT, Consumer Electronics, Industrial Automation, and Medical
                                                    Devices, we can handle product development from even the prototype
                                                    phase.</Small></Paragraph>
                                            </Space>
                                        </Col>
                                    </Space>
                                </Space>
                            </Card>
                        </Col>

                        <Col xs={24} md={8}>
                            <Card Full Pad>
                                <Space justify="space-between" direction="vertical" GapSm Full>
                                    <Space direction="vertical" GapSm>
                                        <h2>Iterative Development & Agile Change Management</h2>
                                        <Paragraph>
                                            <Space direction="vertical">
                                                <em>Improve with Every Iteration</em>
                                            </Space>
                                        </Paragraph>
                                        <Paragraph>
                                            Our iterative approach enables us to deliver ongoing improvements and
                                            respond swiftly to changing requirements. This agile methodology ensures
                                            alignment with your goals, offering measurable value throughout each
                                            development cycle.
                                        </Paragraph>
                                        <Col xs={24} md={24}>
                                            <Space GapSm id="agile-expertise" direction="vertical">
                                                <Paragraph><Small className="muted-heavy" style={{fontWeight: "bold"}}>Customized
                                                    for Startups, SMBs, and Enterprise Teams, to gradually introduce
                                                    faster turnaround times.</Small></Paragraph>
                                            </Space>
                                        </Col>
                                    </Space>
                                </Space>
                            </Card>
                        </Col>


                        <Col xs={24}>

                            {/*{<Space direction={"horizontal"} justify={"space-around"} align={"center"} GapSm style={{padding:"20pt 0"}} Wrap>
                                <img src={"/asset/landing/logo-dfb.png"} className={"client-logo"}/>
                                <img src={"/asset/landing/logo-stw.png"} className={"client-logo"}/>
                                <img src={"/asset/landing/logo-fcc.png"} className={"client-logo"}/>
                                <img src={"/asset/landing/logo-freddie.png"} className={"client-logo"}/>
                                <img src={"/asset/landing/logo-plano-sm.png"} className={"client-logo"}/>
                            </Space>}*/}

                            <Space direction="vertical" justify="center" GapSm>

                                <Title Large style={{
                                    borderTop: "12px solid #c0e0f055",
                                    paddingTop: "40px",
                                    textAlign: "center"
                                }}> More <strong>Resilience</strong> at your <strong>Organization</strong></Title>
                            </Space>

                        </Col>


                        {/*  <Col xs={24} md={8}>
                            <Card Full Pad>
                                <Space justify="space-between" direction="vertical" GapSm Full>
                                    <Space direction="vertical" GapSm>
                                        <h3>Techniteens Workshop</h3>
                                       <Paragraph><em>April 25th&mdash;30th, 2025</em><br/>
                                           <em>9AM&mdash;4PM</em><br />
                                           <a href={"/course"}>Full Course Details</a></Paragraph>
                                        <Paragraph>
                                            Explore our hands-on learning material and upcoming <strong>Lewisville, Texas</strong> Techniteens Workshop. Designed for young-adults aged 12-16 to understand and experience a Product Develpment Lifecycle.
                                        </Paragraph>
                                        <Paragraph>
                                            Exploring topics:
                                            <ul>
                                                <li>Day 1 - Computers & Control Systems</li>
                                                <li>Day 2 - Programming & Software</li>
                                                <li>Day 3 - Hardware & Integration</li>
                                                <li>Day 4 - Hands-on Build Lab</li>
                                                <li>Day 5 - Hands-on Build Lab pt. 2</li>
                                                <li>Day 6 - QA & Product Launch</li>
                                            </ul>
                                        </Paragraph>
                                    </Space>
                                    <Space direction="vertical" justify="end">
                                        <Space GapSm><Pill>Scalable</Pill><Pill>Reliable</Pill></Space>
                                    </Space>
                                </Space>
                            </Card>
                        </Col>

                        <Col xs={24} md={8}>
                            <Card Full Pad>
                                <Space justify="space-between" direction="vertical" GapSm Full>
                                    <Space direction="vertical" GapSm>
                                        <h3>Technical Lead Accelerator</h3>
                                        <Paragraph>
                                            Did you know it's possible to get Technical and Leadership Certifications <em>before</em> you've left college?
                                        </Paragraph>
                                    </Space>
                                    <Space direction="vertical" justify="end">
                                        <Space GapSm><Pill>Scalable</Pill><Pill>Reliable</Pill></Space>
                                    </Space>
                                </Space>
                            </Card>
                        </Col>
*/}

                        <Col xs={24} md={12}>


                            <Space GapSm id="our-expertise">
                                <h2>Our Expertise</h2>
                                <Paragraph>
                                    We are pioneers in <em>Microservices Architecture</em>, delivering scalable and
                                    robust solutions tailored to meet the unique needs of our clients.

                                </Paragraph>
                                <Paragraph>
                                <Space GapSm>
                                    <strong>Web & Realtime Communications</strong>
                                    &mdash;
                                    <strong>Distributed Hardware Integration</strong>
                                    &mdash;
                                    <strong>Frontend & Component Development</strong>
                                    &mdash;
                                    <strong>Backend Engineering</strong>
                                    &mdash;
                                    <strong>Cloud Computing & Data Transformation</strong>
                                    &mdash;
                                    <strong>Video Game Development</strong>
                                    &mdash;
                                    <strong>Interactive Installations & Pop-Ups</strong>
                                </Space>
                                </Paragraph>
                            </Space>
                        </Col>


                        <Col xs={24} md={12} className="card active pattern04 rounded">
                            <Space direction="vertical" Full>

                                <div style={{
                                    height: "100%",
                                    width: "100%",
                                    minHeight: "200px",
                                    borderRadius: "1em",
                                }}>
                                    <Space direction="vertical" Gap Full Pad align={"center"} justify={"center"}>

                                    <Blockquote>
                                        <h3>
                                            ClusterFX revolutionized our workflows by providing simple, robust APIs that scaled with our needs. It's a game-changer for modern tech stacks.
                                        </h3>
                                    </Blockquote>
                                    <Blockquote>
                                        <h3>
                                            The open-source tools provided by <strong>OakFrame</strong> allowed us to maintain full control over our data and processes, all while reducing costs.
                                        </h3>
                                    </Blockquote>
                                    </Space>
                                </div>


                            </Space>
                        </Col>

                        <Col xs={24}>
                            <Space direction="vertical">
                                <Title style={{
                                    fontSize: "200%",
                                    fontWeight: "100",
                                    textAlign: "center"
                                }}> <strong>OakFrame</strong> Product Family</Title>
                            </Space>
                        </Col>

                        <Col xs={12} md={8}>
                            <Card Full Pad className={"secondary"}>
                                <Space direction={"vertical"} justify={"space-between"} Full GapSm>
                                    <h3>API Endpoints & Services, Enterprise Consulting</h3>
                                    <Paragraph className={"hide-sm"}>
                                        Transform your infrastructure and enhance your applications with our comprehensive API services,
                                        providing robust and scalable solutions for your data and
                                        functionality needs.
                                    </Paragraph>

                                    <Space GapSm><Paragraph><strong>Architecture</strong> &mdash; <strong>Design</strong> &mdash; <strong>Development</strong></Paragraph></Space>
                                    <Paragraph>  <Space GapSm align={"center"}><a href={"/contact"}> Contact Us</a> <FontAwesomeIcon fixedWidth icon={faArrowRight}/></Space></Paragraph>

                                </Space>
                            </Card>
                        </Col>

                        <Col xs={12} md={8}>
                            <Card Full Pad>
                                <Space direction={"vertical"} justify={"space-between"} Full GapSm>
                                    <h3>KeyPiece Interactive Programming and Storytelling Tool</h3>
                                    <Paragraph className={"hide-sm"}>
                                        Empower your creativity with KeyPiece, our innovative tool designed for interactive programming and storytelling. Perfect for both beginners and professionals, KeyPiece enhances your ability to craft immersive narratives and dynamic content.
                                    </Paragraph>

                                    <Space GapSm>
                                        <Paragraph> <strong>Programming</strong> &mdash; <strong>Storytelling</strong> &mdash; <strong>Education</strong></Paragraph>
                                    </Space>
                                    <Paragraph> <Space GapSm align={"center"}>
                                        <a href="/desktop">keypiece.org</a>
                                        <FontAwesomeIcon fixedWidth icon={faArrowRight} />
                                    </Space></Paragraph>
                                </Space>
                            </Card>
                        </Col>

                        <Col xs={12} md={8}>
                            <Card Full Pad className={"disabled"}>
                                <Space direction={"vertical"} justify={"space-between"} Full GapSm>
                                    <h3>The Peek<Trademark /></h3>
                                    <Paragraph className={"hide-sm"}>
                                        The Peek<Trademark /> is our vibrant online community for photo and story enthusiasts. Share your experiences, connect with others, and explore a world of creativity and collaboration in a dynamic, user-driven environment.
                                    </Paragraph>
                                    <Space GapSm>
                                        <Paragraph> <strong>Community</strong> &mdash; <strong>Storytelling</strong> &mdash; <strong>Photography</strong></Paragraph>
                                    </Space>
                                    <Space GapSm align={"center"}>
                                        <strong>Coming Soon</strong>
                                    </Space>
                                </Space>
                            </Card>
                        </Col>

                        <Col xs={12} md={8}>
                            <Card Full Pad>
                                <Space direction={"vertical"} justify={"space-between"} Full GapSm>
                                    <h3>ScrapsJS</h3>
                                    <Paragraph className={"hide-sm"}>
                                        ScrapsJS is an open-source inline JavaScript editing and running library/framework. Widely used by KeyPiece and OakFrame's internal documentation pages, ScrapsJS makes scripting and development fast and intuitive.
                                    </Paragraph>
                                    <Space GapSm>
                                        <Paragraph>  <strong>Open Source</strong> &mdash; <strong>JavaScript</strong> &mdash; <strong>Inline Editing</strong></Paragraph>
                                    </Space>
                                    <Paragraph> <Space GapSm align={"center"}>
                                        <a href="https://github.com/OakFrame/scraps-js">ScrapsJS GitHub</a>
                                        <FontAwesomeIcon fixedWidth icon={faArrowRight} />
                                    </Space></Paragraph>
                                </Space>
                            </Card>
                        </Col>

                        <Col xs={12} md={8}>
                            <Card Full Pad className={"disabled"}>
                                <Space direction={"vertical"} justify={"space-between"} Full GapSm>
                                    <h3>swuirm</h3>
                                    <Paragraph className={"hide-sm"}>
                                        swuirm enhances Docker, Docker Compose, Docker Swarm, and Terraform by adding SSH, build and deployment configurations, and environment management, all within a user-friendly UI for streamlined workflows.
                                    </Paragraph>
                                    <Space GapSm>
                                        <Paragraph><strong>Containerization</strong> &mdash; <strong>Deployment</strong> &mdash; <strong>Infrastructure</strong></Paragraph>
                                    </Space>
                                    <Space GapSm align={"center"}>
                                        <strong>Coming Soon</strong>
                                    </Space>
                                </Space>
                            </Card>
                        </Col>

                        <Col xs={12} md={8}>
                            <Card Full Pad className={"disabled"}>
                                <Space direction={"vertical"} justify={"space-between"} Full GapSm>
                                    <h3>DataBox</h3>
                                    <Paragraph className={"hide-sm"}>
                                        DataBox is an open-source desktop application designed for data engineers. It features tools for image and video manipulation, data tagging, audio-to-text conversion, AI integrations, and cross-platform data management.
                                    </Paragraph>
                                    <Space GapSm>
                                        <Paragraph>   <strong>Data Engineering</strong> &mdash; <strong>AI Integration</strong> &mdash; <strong>Cross-Platform</strong></Paragraph>
                                    </Space>
                                    <Space GapSm align={"center"}>
                                        <strong>Coming Soon</strong>
                                    </Space>
                                </Space>
                            </Card>
                        </Col>

                    </Row>
                </Content>
                <Footer/>
            </Page>
        </Layout>

    );
}

export default LandingPage;
