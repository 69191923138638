
import React, {FC, useState} from "react";
import {Button, Card, Col, Content, Layout, Page, Paragraph, Row, Space} from "../layout/Content";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserPlus} from "@fortawesome/free-solid-svg-icons";
import {Modal, Select} from "../layout/Defaults";
import {decodeIcon, ICON_MAP, useAppContext} from "./Desktop";


// Profile Selection Component

export const ProfileSelection: FC = () => {
    const {state, dispatch, addProfile, deleteProfile} = useAppContext();
    const [showModal, setShowModal] = useState(false);
    const [newProfileName, setNewProfileName] = useState("");
    const [selectedIcon, setSelectedIcon] = useState("faUser");

    const handleAddProfile = () => {
        if (newProfileName.trim()) {
            addProfile(newProfileName.trim(), selectedIcon);
            setNewProfileName("");
            setSelectedIcon("faUser");
            setShowModal(false);
        }
    };

    return (
        <Layout className={"fixed"}>
            <Page Grow Pad>

                <Space direction="vertical" justify="space-around" Full>
                    <Content>
                        <Row Gap>
                            <Col xs={24}>
                                <Space align={"center"}>
                                    <h1>Select a Profile</h1>
                                </Space>
                            </Col>
                            {state.profiles.map((profile) => (
                                <Col key={profile.id} xs={12} sm={8} md={6} lg={4}>
                                    <Card
                                        Pad
                                        Full
                                        onClick={() => dispatch({type: "SWITCH_PROFILE", profileId: profile.id})}
                                    >
                                        <Space justify="space-around" align={"center"} direction={"vertical"} Full>
                                            <FontAwesomeIcon icon={decodeIcon(profile.icon)} size="2x"/>
                                        </Space>
                                        {/*    <div style={{position:"absolute", right: 8, top: 8}}> <Button*/}
                                        {/*    size="small"*/}
                                        {/*    type="danger"*/}
                                        {/*    onClick={() => deleteProfile(profile.id)}*/}
                                        {/*>*/}
                                        {/*    <FontAwesomeIcon icon={faTimes}/>*/}
                                        {/*</Button></div>*/}
                                    </Card>
                                    <Space justify="space-around">
                                        <Paragraph>{profile.name}</Paragraph>
                                    </Space>
                                </Col>
                            ))}
                            <Col xs={12} sm={8} md={6} lg={4}>
                                <Card Pad Full onClick={() => setShowModal(true)}>
                                    <Space justify="space-around" align={"center"} direction={"vertical"} Full>
                                        <FontAwesomeIcon icon={faUserPlus} size="2x"/>
                                    </Space>
                                </Card>
                                <Space justify="space-around">
                                    <Paragraph>New Profile</Paragraph>
                                </Space>
                            </Col>
                        </Row> </Content>
                </Space>

            </Page>

            <Modal visible={showModal} title={""} onClose={() => setShowModal(false)}>
                <Space direction={"vertical"} GapSm>
                    <h3>Create New Profile</h3>
                    <input
                        placeholder="Profile Name"
                        value={newProfileName}
                        onChange={(e) => setNewProfileName(e.target.value)}
                    />
                    <Paragraph><strong>Profile Avatar:</strong></Paragraph>
                    <Select
                        defaultValue={selectedIcon}
                        options={[
                            {
                                element: <span><FontAwesomeIcon fixedWidth icon={decodeIcon("faUser")}/> User</span>,
                                value: "faUser",
                                category: "People"
                            },
                            {
                                element: <span><FontAwesomeIcon fixedWidth
                                                                icon={decodeIcon("faUserNinja")}/> Ninja</span>,
                                value: "faUserNinja",
                                category: "People"
                            },
                            {
                                element: <span><FontAwesomeIcon fixedWidth
                                                                icon={decodeIcon("faChildren")}/> Children</span>,
                                value: "faChildren",
                                category: "People"
                            },
                            {
                                element: <span><FontAwesomeIcon fixedWidth icon={decodeIcon("faPersonBooth")}/> Booth Person</span>,
                                value: "faPersonBooth",
                                category: "People"
                            },
                            {
                                element: <span><FontAwesomeIcon fixedWidth icon={decodeIcon("faCrown")}/> Royal</span>,
                                value: "faCrown",
                                category: "People"
                            },
                            {
                                element: <span><FontAwesomeIcon fixedWidth icon={decodeIcon("faGhost")}/> Ghost</span>,
                                value: "faGhost",
                                category: "Fantasy"
                            },
                            {
                                element: <span><FontAwesomeIcon fixedWidth
                                                                icon={decodeIcon("faDragon")}/> Dragon</span>,
                                value: "faDragon",
                                category: "Fantasy"
                            },
                            {
                                element: <span><FontAwesomeIcon fixedWidth icon={decodeIcon("faRobot")}/> Robot</span>,
                                value: "faRobot",
                                category: "Fantasy"
                            },
                            {
                                element: <span><FontAwesomeIcon fixedWidth
                                                                icon={decodeIcon("faHatWizard")}/> Wizard</span>,
                                value: "faHatWizard",
                                category: "Fantasy"
                            },
                            {
                                element: <span><FontAwesomeIcon fixedWidth icon={decodeIcon("faCat")}/> Cat</span>,
                                value: "faCat",
                                category: "Animals"
                            },
                            {
                                element: <span><FontAwesomeIcon fixedWidth icon={decodeIcon("faDog")}/> Dog</span>,
                                value: "faDog",
                                category: "Animals"
                            },
                            {
                                element: <span><FontAwesomeIcon fixedWidth icon={decodeIcon("faSmile")}/> Smile</span>,
                                value: "faSmile",
                                category: "Miscellaneous"
                            },
                        ]}
                        onSelected={(e) => {
                            setSelectedIcon(e);
                        }}
                    />
                    <select
                        value={selectedIcon}
                        onChange={(e) => setSelectedIcon(e.target.value)}
                    >
                        {Object.keys(ICON_MAP).map((key) => (
                            <option key={key} value={key}>
                                <FontAwesomeIcon icon={decodeIcon(key)}/> {key}
                            </option>
                        ))}
                    </select>
                    <Button onClick={handleAddProfile}>Create</Button>
                </Space>
            </Modal>

        </Layout>
    );
};
