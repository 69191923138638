import {Vec3Interface} from "./Vec3";

export class AudioPlayer {
    private audioContext: AudioContext;

    constructor() {
        this.audioContext = new AudioContext();
    }

    public async resumeAudioContext() {
        if (this.audioContext.state === 'suspended') {
            await this.audioContext.resume();
        }
    }

    public setListenerPosition(position: Vec3Interface) {
        const listener = this.audioContext.listener;
        if (listener && listener.positionX && listener.positionY && listener.positionZ) {
            listener.positionX.setValueAtTime(position.x, this.audioContext.currentTime);
            listener.positionY.setValueAtTime(position.y, this.audioContext.currentTime);
            listener.positionZ.setValueAtTime(position.z, this.audioContext.currentTime);
        }
    }

    public setListenerOrientation(lookAt: Vec3Interface, up: Vec3Interface = { x: 0, y: 0, z: -1 }) {
        const listener = this.audioContext.listener;
        // console.log(listener);
        if (listener && listener.forwardX) {
            listener.forwardX.setValueAtTime(lookAt.x, this.audioContext.currentTime);
            listener.forwardY.setValueAtTime(lookAt.y, this.audioContext.currentTime);
            listener.forwardZ.setValueAtTime(lookAt.z, this.audioContext.currentTime);
            listener.upX.setValueAtTime(up.x, this.audioContext.currentTime);
            listener.upY.setValueAtTime(up.y, this.audioContext.currentTime);
            listener.upZ.setValueAtTime(up.z, this.audioContext.currentTime);
        }
    }

    public async playSound(filePath: string, position: Vec3Interface) {
        const panner = this.audioContext.createPanner();
        //if (!panner || !panner.positionX || !this.audioContext.listener || !this.audioContext.listener.positionX){
        // var audio = new Audio(filePath);
        //  audio.play();
        //}
        panner.panningModel = 'HRTF';
        panner.distanceModel = 'inverse';
        panner.refDistance = 1;
        panner.maxDistance = 1000;
        panner.rolloffFactor = 0.25;
        panner.coneInnerAngle = 360;
        panner.coneOuterAngle = 0;
        panner.coneOuterGain = 0;

        panner.positionX.setValueAtTime(position.x, this.audioContext.currentTime);
        panner.positionY.setValueAtTime(position.y, this.audioContext.currentTime);
        panner.positionZ.setValueAtTime(position.z, this.audioContext.currentTime);

        panner.connect(this.audioContext.destination);

        const response = await fetch(filePath);
        const arrayBuffer = await response.arrayBuffer();
        const audioBuffer = await this.audioContext.decodeAudioData(arrayBuffer);

        const soundSource = this.audioContext.createBufferSource();
        soundSource.buffer = audioBuffer;
        soundSource.connect(panner);
        soundSource.start();
    }
}

export let audioPlayer = new AudioPlayer();

export function resetAudioPlayer(){
    audioPlayer = new AudioPlayer();
}