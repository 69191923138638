import {Vec3, Vec3Interface} from "../../../model/Vec3";

import {arrayMedian} from "../../../model/Utils";
import {remotePLYLoader} from "../../../model/RemotePLYLoader";
import {parsePLY, PLYData, PLYObject} from "../../../model/PLY";

export const PLYCenteredCube01 = `ply
format ascii 1.0
comment Created by Blender 3.6.4 - www.blender.org
element vertex 36
property float x
property float y
property float z
property float nx
property float ny
property float nz
property float s
property float t
property uchar red
property uchar green
property uchar blue
property uchar alpha
element face 12
property list uchar uint vertex_indices
end_header
-0.500000 -0.500000 0.500000 -1.000000 0.000000 0.000000 0.200059 0.000098 87 165 104 255
-0.500000 0.500000 -0.500000 -1.000000 0.000000 0.000000 0.200059 0.400019 87 165 104 255
-0.500000 -0.500000 -0.500000 -1.000000 0.000000 0.000000 0.000098 0.200059 87 165 104 255
-0.500000 0.500000 0.500000 0.000000 1.000000 0.000000 0.400020 0.200059 87 165 104 255
0.500000 0.500000 -0.500000 0.000000 1.000000 0.000000 0.400020 0.599980 87 165 104 255
-0.500000 0.500000 -0.500000 0.000000 1.000000 0.000000 0.200059 0.400019 87 165 104 255
0.500000 0.500000 0.500000 1.000000 0.000000 0.000000 0.599980 0.400019 87 165 104 255
0.500000 -0.500000 -0.500000 1.000000 0.000000 0.000000 0.599981 0.799941 87 165 104 255
0.500000 0.500000 -0.500000 1.000000 0.000000 0.000000 0.400020 0.599980 87 165 104 255
0.500000 -0.500000 0.500000 0.000000 -1.000000 0.000000 0.799941 0.599980 87 165 104 255
-0.500000 -0.500000 -0.500000 0.000000 -1.000000 0.000000 0.799941 0.999902 87 165 104 255
0.500000 -0.500000 -0.500000 0.000000 -1.000000 0.000000 0.599981 0.799941 87 165 104 255
0.500000 0.500000 -0.500000 0.000000 0.000000 -1.000000 0.400020 0.599980 87 165 104 255
-0.500000 -0.500000 -0.500000 0.000000 0.000000 -1.000000 0.400020 0.999902 87 165 104 255
-0.500000 0.500000 -0.500000 0.000000 0.000000 -1.000000 0.200059 0.799941 87 165 104 255
-0.500000 0.500000 0.500000 0.000000 0.000000 1.000000 0.799941 0.200059 87 165 104 255
0.500000 -0.500000 0.500000 0.000000 0.000000 1.000000 0.799941 0.599980 87 165 104 255
0.500000 0.500000 0.500000 0.000000 0.000000 1.000000 0.599980 0.400019 87 165 104 255
-0.500000 -0.500000 0.500000 -1.000000 0.000000 0.000000 0.200059 0.000098 87 165 104 255
-0.500000 0.500000 0.500000 -1.000000 0.000000 0.000000 0.400020 0.200059 87 165 104 255
-0.500000 0.500000 -0.500000 -1.000000 0.000000 0.000000 0.200059 0.400019 87 165 104 255
-0.500000 0.500000 0.500000 0.000000 1.000000 0.000000 0.400020 0.200059 87 165 104 255
0.500000 0.500000 0.500000 0.000000 1.000000 0.000000 0.599980 0.400019 87 165 104 255
0.500000 0.500000 -0.500000 0.000000 1.000000 0.000000 0.400020 0.599980 87 165 104 255
0.500000 0.500000 0.500000 1.000000 0.000000 0.000000 0.599980 0.400019 87 165 104 255
0.500000 -0.500000 0.500000 1.000000 0.000000 0.000000 0.799941 0.599980 87 165 104 255
0.500000 -0.500000 -0.500000 1.000000 0.000000 0.000000 0.599981 0.799941 87 165 104 255
0.500000 -0.500000 0.500000 0.000000 -1.000000 0.000000 0.799941 0.599980 87 165 104 255
-0.500000 -0.500000 0.500000 0.000000 -1.000000 0.000000 0.999902 0.799941 87 165 104 255
-0.500000 -0.500000 -0.500000 0.000000 -1.000000 0.000000 0.799941 0.999902 87 165 104 255
0.500000 0.500000 -0.500000 0.000000 0.000000 -1.000000 0.400020 0.599980 87 165 104 255
0.500000 -0.500000 -0.500000 0.000000 0.000000 -1.000000 0.599981 0.799941 87 165 104 255
-0.500000 -0.500000 -0.500000 0.000000 0.000000 -1.000000 0.400020 0.999902 87 165 104 255
-0.500000 0.500000 0.500000 0.000000 0.000000 1.000000 0.799941 0.200059 87 165 104 255
-0.500000 -0.500000 0.500000 0.000000 0.000000 1.000000 0.999902 0.400019 87 165 104 255
0.500000 -0.500000 0.500000 0.000000 0.000000 1.000000 0.799941 0.599980 87 165 104 255
3 0 1 2
3 3 4 5
3 6 7 8
3 9 10 11
3 12 13 14
3 15 16 17
3 18 19 20
3 21 22 23
3 24 25 26
3 27 28 29
3 30 31 32
3 33 34 35`;


export function randomFrom(arr: any[]) {
    return arr[Math.floor(arr.length * Math.random())];
}

let animations = ["none", "idle", "walk", "run", "harvest"];

class Accumulator {
    lastTime: number;
    stepSize: number;

    constructor() {
        this.lastTime = Date.now();
        this.stepSize = 1000;
    }

    update() {

    }
}


let MeshTorso01: PLYData | undefined;
let MeshCube01 = parsePLY(PLYCenteredCube01);
let MeshCube02: PLYData | undefined;
let MeshHead01: PLYData | undefined;
let MeshEyes01: PLYData | undefined;
let MeshHair01: PLYData | undefined;
let MeshEars01: PLYData | undefined;

interface CharacterPLYOptions {
    timeScale: number;
}

const itemEquipmentScaleFactor = 1.01;

export function generateCharacterPLYData(details?: any, animation: string = "none", options: CharacterPLYOptions = {timeScale: 1}) {

    MeshTorso01 = remotePLYLoader.getMeshByIdx("torso.01");
    MeshCube02 = remotePLYLoader.getMeshByIdx("cube.02");
    MeshHead01 = remotePLYLoader.getMeshByIdx("head.02");
    MeshEars01 = remotePLYLoader.getMeshByIdx("ears.01");


    MeshEyes01 = details.body.face ? remotePLYLoader.getMeshRawByIdx("eyes.0" + details.body.face)||undefined : undefined;

    MeshHair01 = details.body.hair ? remotePLYLoader.getMeshRawByIdx("hair.0" + details.body.hair)||undefined : undefined;

    /* if (!MeshTorso01) {
         if (remotePLYLoader.hasMeshByIdx("torso.01")) {
             MeshTorso01 = remotePLYLoader.getMeshByIdx("torso.01");
         } else {
             remotePLYLoader.getMeshByIdx("torso.01");
         }
     }
     if (!MeshCube02) {
         if (remotePLYLoader.hasMeshByIdx("cube.02")) {
             MeshCube02 = remotePLYLoader.getMeshByIdx("cube.02");
         } else {
             remotePLYLoader.getMeshByIdx("cube.02");
         }
     }*/


    const dateMod = (Date.now() + ((details.body.height + details.body.thick + details.body.width + details.body.shape) * 10000)) / options.timeScale;

    const character = new PLYObject();

    //  character.attach(parsePLY(MeshGrass02), new Vec3().set(1,1,1),new Vec3().set(0,0,0), new Vec3().set(0,0,0))
    //character.attach(MeshPine01, new Vec3().set(0,0,0), new Vec3().set(0,0,0))

    let speed1 = 90;
    let speed2 = Math.min(420, Math.max(90, 90 * (-0.5 + ((details.body.height + details.body.thick + details.body.width) / 3))));
    let speed3 = 320;
    let speed4 = speed3 * 2;

    let sideArms = 0;
    let WalkOppositeSwingForward = 0;// = Math.sin(Date.now()/speed1);
    let WalkOppositeSwingDouble = 0;// = Math.sin(Date.now()/(speed1/2));

    let ArmSwingL = 0;
    let ArmSwingR = 0;
    let ArmThrowL = 0;
    let ArmThrowR = 0;

    let ArmLX = 0;
    let ArmLY = 0;
    let ArmLZ = 0;

    let ArmRX = 0;
    let ArmRY = 0;
    let ArmRZ = 0;

    let LegLX = 0;
    let LegLY = 0;
    let LegLZ = 0;

    let LegRX = 0;
    let LegRY = 0;
    let LegRZ = 0;

    let TorsoZ = 0;
    let TorsoX = 0;
    let TorsoY = 0;

    let HeadRotX = 0;
    let HeadRotY = 0;

    let HipsZ = 0;

    let legLength = 1;
    let armLength = 1;
    let hipHeight = 0.2;
    let torsoHeight = 0.5;
    let bodyWidth = 1;
    let bodyDepth = 0.35;
    let bodyThick = 0.3;

    let headHeight = 0.8;
    let headWidth = 0.5;


    if (details && details.body) {
        legLength += (details.body.height);
        armLength += (details.body.height);
        hipHeight += (details.body.height / 3);
        torsoHeight += (details.body.height / 2);

        bodyWidth += (0.6 + details.body.height / 2) * (((details.body.width) + ((details.body.thick) / 2) - (details.body.shape / 6)) / (1 + Math.abs((details.body.shape / 2) - 0.5)));
        bodyDepth += (0.6 + details.body.height / 2) * ((details.body.width / 4) + (details.body.thick / 2));
        bodyThick += (0.6 + details.body.height / 2) * ((details.body.thick + details.body.width) / 8)//+((details.body.width)/2);

    }


    let TT = Math.max(0, details.body.shape - 0.62) / 2;
    let BB = Math.max(0, details.body.shape - 0.62) / 4;

    const hipAdjustForTorso = 1 - ((-0.5) * (details.body.shape - 0.5));
    const armsAdjustForTorso = 1 - ((0.5) * (details.body.shape - 0.5));
    const armsSizeAdjustForTorso = Math.min(1, 1 - ((0.5) * (details.body.shape - 0.5)));


    const torso = new PLYObject(MeshTorso01 || MeshCube02 || MeshCube01);
    torso.setColor(235 / 255, 184 / 255, 152 / 255, 1);
    if (details && details.body.skinColor) {
        torso.setColorRGBA(details.body.skinColor);
    }

    torso.applyTransformationToVertices((vertex:Vec3Interface) => { // 0 = MASC 1 = FEM
        const v = new Vec3().copy(vertex);
        let offZ = vertex.z;

        if (offZ > 0 && offZ < 0.25 && v.y > 0) {
            v.y += (TT * 2) * (1 - (Math.abs(v.z - 0.09) * 2));
        }
        if (offZ < -0.20 && offZ > -0.60 && v.y < 0) {
            v.y -= BB * 2;
        }

        v.x *= (1 - ((offZ) * (details.body.shape - 0.5)));

        return v;
    });
    //sideArms *= 1-armsAdjustForTorso;

    torso.translate(new Vec3().set(0, 0, 0.5));
    torso.scale(new Vec3().set(bodyWidth, bodyDepth, torsoHeight));


    const hips = new PLYObject(MeshCube02 || MeshCube01);

    if (details && details.body.personalColor) {
        hips.setColorRGBA(details.body.personalColor);
    }


    hips.applyTransformationToVertices((vertex) => { // 0 = MASC 1 = FEM
        const v = new Vec3().copy(vertex);
        let offZ = vertex.z;
        if (offZ > 0 && v.y < 0) {
            v.y -= BB * 2;
        }
        return v;
    });
    hips.translate(new Vec3().set(0, 0, -0.5));
    hips.scale(new Vec3().set(bodyWidth * hipAdjustForTorso, bodyDepth, hipHeight));


    const legR = new PLYObject(MeshCube02 || MeshCube01);
    legR.translate(new Vec3().set(0, 0, -0.5));
    legR.setColor(235 / 255, 184 / 255, 152 / 255, 1);
    if (details && details.body.skinColor) {
        legR.setColorRGBA(details.body.skinColor);
    }


    legR.scale(new Vec3().set(bodyThick, bodyThick, legLength));


    const legL = new PLYObject(MeshCube02 || MeshCube01);
    legL.translate(new Vec3().set(0, 0, -0.5));
    legL.setColor(235 / 255, 184 / 255, 152 / 255, 1);
    if (details && details.body.skinColor) {
        legL.setColorRGBA(details.body.skinColor);
    }


    legL.scale(new Vec3().set(bodyThick, bodyThick, legLength));


    const armL = new PLYObject(MeshCube02 || MeshCube01);
    armL.translate(new Vec3().set(0, 0, -0.5));
    armL.setColor(235 / 255, 184 / 255, 152 / 255, 1);
    armL.scale(new Vec3().set(bodyThick * armsSizeAdjustForTorso, bodyThick * armsSizeAdjustForTorso, armLength));
    if (details && details.body.skinColor) {
        armL.setColorRGBA(details.body.skinColor);
    }




    armL.rotate(new Vec3().set(0, 90, 0));

    const armR = new PLYObject(MeshCube02 || MeshCube01);
    armR.translate(new Vec3().set(0, 0, -0.5));
    armR.scale(new Vec3().set(bodyThick * armsSizeAdjustForTorso, bodyThick * armsSizeAdjustForTorso, armLength));
    armR.setColor(235 / 255, 184 / 255, 152 / 255, 1);
    if (details && details.body.skinColor) {
        armR.setColorRGBA(details.body.skinColor);
    }



    armR.rotate(new Vec3().set(0, -90, 0));


    const modHeadX = (1 - (details.body.shape / 6)) || 1;

    const head = new PLYObject(MeshHead01 || MeshCube02 || MeshCube01);
    head.setColor(235 / 255, 184 / 255, 152 / 255, 1);
    if (details && details.body.skinColor) {
        head.setColorRGBA(details.body.skinColor);
    }

    if (MeshEyes01) {
        head.attach(MeshEyes01);
    }
    head.translate(new Vec3().set(0, 0, 0.5));
    const hS = arrayMedian([1,(bodyDepth + bodyThick + bodyWidth) / 6,4] );

    let hasHelmetCoveringHair = false;
    let removeEars = false;

    if (MeshHair01 && details.body.hair && details.body.hairColor && !hasHelmetCoveringHair){
        head.attach(new PLYObject(MeshHair01).setColorRGBA(details.body.hairColor).getPLYData(),undefined,new Vec3().set(0, 0, 0.5));
    }
    if (MeshEars01 && !removeEars){
        head.attach(new PLYObject(MeshEars01).setColorRGBA(details.body.skinColor).getPLYData(),undefined,new Vec3().set(0, 0, 0.5));
    }

    head.scale(new Vec3().set(headWidth * modHeadX * hS, headWidth * hS, headHeight * hS));


    switch (animation) {
        case "idle":
            //WalkOppositeSwingForward = Math.sin(Date.now()/speed1);
            //WalkOppositeSwingDouble = Math.sin(Date.now()/(speed1/2));
            // sideArms = 1;
            ArmLY = -30 + (40 * (((details.body.shape / 1.75) - ((details.body.height / 2))) - 0.4)) + (Math.sin(dateMod / speed3) * 3);//armsAdjustForTorso
            ArmRY = 30 - (40 * (((details.body.shape / 1.75) - ((details.body.height / 2))) - 0.4)) - (Math.sin(dateMod / speed3) * 3);//armsAdjustForTorso
            TorsoZ = -Math.sin(dateMod / speed4) * 4;
            TorsoX = -Math.sin(dateMod / speed3) * 1;
            TorsoY = -Math.sin(dateMod / speed4) * 1;

            break;

        case "walk":

            //    const rWild = Math.sin(dateMod / speed3) * Math.sin(dateMod / speed2);
            //  const rWild2 = Math.sin(dateMod / speed1) * Math.sin(dateMod / speed2);
            // const rWild3 = Math.sin(dateMod / speed3) * Math.sin(dateMod / speed1);

            ArmLY = (-30 + (details.body.shape * 40)) + (Math.sin((dateMod + 250) / speed2) * 15); // up down
            ArmLZ = -Math.sin(dateMod / speed2) * 35; // forward back
            ArmRY = (30 - (details.body.shape * 40)) + (Math.sin((dateMod + 250) / speed2) * 15); // up down
            ArmRZ = -Math.sin(dateMod / speed2) * 35; // forward back

            TorsoY = -Math.sin(dateMod / (speed1)) * 5;
            TorsoZ = -Math.sin(dateMod / speed2) * 15;
            TorsoX = -5 + ((15 * ((details.body.goofy - 0.5) * 2)) - Math.sin((dateMod - 250) / (speed1 * 2)) * 10);
            HeadRotY = -Math.sin((dateMod - 250) / (speed1 / 2)) * (2 + ((details.body.goofy - 0.5) * 4));

            const legRunSpread = 10 + (details.body.goofy * 10);

            LegRX = Math.sin(dateMod / speed2) * 65;
            LegRY = -(legRunSpread / 2) - (Math.sin((dateMod - 500) / speed2) * (legRunSpread));

            LegLX = -Math.sin(dateMod / speed2) * 65;
            LegLY = (legRunSpread / 2) - (Math.sin((dateMod - 500) / speed2) * (legRunSpread));

            sideArms = 0.5 + (0.5 + (-details.body.width / 2));
            break;

        case "chop":
            //WalkOppositeSwingForward = Math.sin(Date.now() / speed1);
            // WalkOppositeSwingDouble = Math.sin(Date.now() / (speed1 / 2));
            ArmSwingR = -Math.sin(dateMod / speed1);
            ArmRY = -45 + -(Math.sin(dateMod / speed1) * 25);

            sideArms = 1;
            TorsoZ = -Math.sin(dateMod / speed1) * 25;

            break;

        case "mine":
            //WalkOppositeSwingForward = Math.sin(Date.now() / speed1);
            // WalkOppositeSwingDouble = Math.sin(Date.now() / (speed1 / 2));
            // ArmThrowL = -Math.sin(Date.now() / speed1);
            ArmLX = -Math.sin(dateMod / speed1) * 35;
            ArmLY = 0;
            ArmLZ = -110;

            ArmRX = -Math.sin(dateMod / speed1) * 35;
            ArmRY = 0;
            ArmRZ = 110;
            sideArms = 0;
            TorsoX = -Math.sin(dateMod / speed1) * 25;
            break;

        case "farm":
            //WalkOppositeSwingForward = Math.sin(Date.now() / speed1);
            // WalkOppositeSwingDouble = Math.sin(Date.now() / (speed1 / 2));
            //ArmSwingL =  45 -Math.sin(dateMod / speed1);
            ArmRZ = 23 + (Math.sin(dateMod / speed2) * 50);

            //  ArmLY = 23 + -(Math.sin(dateMod / speed1) * 25);
            TorsoX = -25 + (-Math.sin(dateMod / speed2) * 25);

            sideArms = 1;
            //TorsoZ = -Math.sin(dateMod / speed1) * 25;

            break;

        case "push":
        case "crft":
            //WalkOppositeSwingForward = Math.sin(Date.now() / speed1);
            // WalkOppositeSwingDouble = Math.sin(Date.now() / (speed1 / 2));
            // ArmThrowL = -Math.sin(Date.now() / speed1);
            ArmLX = Math.sin(dateMod / speed2) * 25;
            ArmRX = -Math.sin(dateMod / speed2) * 25;
            ArmLY = 0;
            ArmLZ = -90;
            ArmRZ = 90;
            sideArms = 0;
            TorsoX = -Math.sin(dateMod / speed2) * 10;
            break;

        case "wonky":
            //WalkOppositeSwingForward = Math.sin(Date.now() / speed1);
            // WalkOppositeSwingDouble = Math.sin(Date.now() / (speed1 / 2));
            // ArmThrowL = -Math.sin(Date.now() / speed1);
            ArmLX = Math.sin(dateMod / speed1) * 25;
            ArmLY = 0;
            ArmLZ = -90;
            sideArms = 0;
            TorsoX = -Math.sin(dateMod / speed2) * 25;
            break;

            case "smth":
            //WalkOppositeSwingForward = Math.sin(Date.now() / speed1);
            // WalkOppositeSwingDouble = Math.sin(Date.now() / (speed1 / 2));
            // ArmThrowL = -Math.sin(Date.now() / speed1);
            ArmLX = Math.sin(dateMod / speed1) * 25;
            ArmLY = 0;
            ArmLZ = -90;
            ArmRZ = 90;
                ArmRX = 25 + -Math.sin(dateMod / speed2) * 35;
                sideArms = 0;
            TorsoX = -Math.sin(dateMod / speed2) * 25;
            break;


        case "teleport":
            //WalkOppositeSwingForward = Math.sin(Date.now() / speed1);
            // WalkOppositeSwingDouble = Math.sin(Date.now() / (speed1 / 2));
            // ArmThrowL = -Math.sin(Date.now() / speed1);

            // ArmLX = Math.sin(dateMod / speed1) * 25; // arm twist out

            const rWild = Math.sin(dateMod / speed3) * Math.sin(dateMod / speed2);
            const rWild2 = Math.sin(dateMod / speed1) * Math.sin(dateMod / speed2);
            const rWild3 = Math.sin(dateMod / speed3) * Math.sin(dateMod / speed1);

            ArmLY = Math.sin(dateMod / speed3) * 25 * rWild; // up down
            ArmLZ = Math.sin(dateMod / speed4) * 25 * rWild3; // forward back
            ArmRY = Math.sin(dateMod / speed3) * 25 * rWild; // up down
            ArmRZ = Math.sin(dateMod / speed4) * 25 * rWild3; // forward back


            //ArmLY = 0;
            //ArmLZ = -90;
            //sideArms = 0;
            TorsoX = -Math.sin(dateMod / speed4) * 25;
            const TorsoX2 = -Math.sin(dateMod / (speed4 / 2)) * 25;

            TorsoZ = -Math.sin(dateMod / (speed4)) * 25;


            LegLX = (-TorsoX2) - (Math.sin(dateMod / speed3) * 25 * rWild); // up down
            LegLY = TorsoZ + (15 + ((Math.sin(dateMod / (speed4 * 5)) * Math.sin(dateMod / (speed3 * 6))) * 25)); // forward back
            LegRX = (-TorsoX2) + (Math.sin(dateMod / speed3) * 25 * rWild); // up down
            LegRY = TorsoZ - (15 + ((Math.sin(dateMod / (speed4 * 5)) * Math.sin(dateMod / (speed3 * 6))) * 25)); // forward back


            break;

        case "sit":
            //WalkOppositeSwingForward = Math.sin(Date.now()/speed1);
            //WalkOppositeSwingDouble = Math.sin(Date.now()/(speed1/2));
            // sideArms = 1;
            ArmLY = -30 + (40 * (((details.body.shape / 1.75) - ((details.body.height / 2))) - 0.4)) + (Math.sin(dateMod / speed3) * 3);//armsAdjustForTorso
            ArmRY = 30 - (40 * (((details.body.shape / 1.75) - ((details.body.height / 2))) - 0.4)) - (Math.sin(dateMod / speed3) * 3);//armsAdjustForTorso
            TorsoZ = -Math.sin(dateMod / speed4) * 4;
            TorsoX = -Math.sin(dateMod / speed3) * 1;
            TorsoY = -Math.sin(dateMod / speed4) * 1;


            LegLX = 85+(Math.sin(dateMod / speed4) * 5);
            LegLY = 15;

            LegRX = 85+(-Math.sin(dateMod / speed4) * 5);
            LegRY = -15;

            break;

    }
    const bounce = Math.abs(WalkOppositeSwingForward / 12);
    const bounce2 = Math.abs(WalkOppositeSwingForward / 6);

    let characterLift = 0;
    if (animation === "teleport") {
        characterLift = 1 + ((Math.sin(dateMod / speed4) * Math.sin((dateMod - 240) / speed3)) * 0.62);
    }
    if (animation === "walk") {
        characterLift = Math.abs(Math.sin(dateMod / speed2)) / 3;
    }

    if (animation === "sit") {
        characterLift = -legLength;
    }

    torso.attach(armL.getPLYData(), new Vec3().set(1, 1, 1), new Vec3().set(((-bodyWidth / 1.75) + (bodyThick / 2)) * armsAdjustForTorso, 0, (torsoHeight - 0.1)),
        new Vec3().set(
            (WalkOppositeSwingForward * 25) + (ArmLX),
            (-45 * sideArms) + (ArmLY),
            (-WalkOppositeSwingForward * 25) + (ArmSwingL * 45) + (ArmLZ)
        ));
    torso.attach(armR.getPLYData(), new Vec3().set(1, 1, 1), new Vec3().set(((bodyWidth / 1.75) - (bodyThick / 2)) * armsAdjustForTorso, 0, (torsoHeight - 0.1)),
        new Vec3().set(
            (-WalkOppositeSwingForward * 25) + ArmRX,
            (45 * sideArms) + ArmRY,
            (-WalkOppositeSwingForward * 25) + (ArmSwingR * 45) + ArmRZ));

    torso.attach(head.getPLYData(), new Vec3().set(1, 1, 1), new Vec3().set(0, 0, torsoHeight + bounce), new Vec3().set(HeadRotX, HeadRotY, WalkOppositeSwingForward * 15));

    hips.attach(torso.getPLYData(), new Vec3().set(1, 1, 1), new Vec3().set(0, 0, 0), new Vec3().set((TorsoX), TorsoY, (-WalkOppositeSwingForward * 25) + (TorsoZ)));


    hips.attach(legR.getPLYData(), new Vec3().set(1, 1, 1), new Vec3().set(((bodyWidth / 2) - (bodyThick / 2)) * hipAdjustForTorso, 0, (-hipHeight) + 0.1), new Vec3().set(LegRX, LegRY, LegRZ));
    hips.attach(legL.getPLYData(), new Vec3().set(1, 1, 1), new Vec3().set((((-bodyWidth / 2)) + (bodyThick / 2)) * hipAdjustForTorso, 0, (-hipHeight) + 0.1), new Vec3().set(LegLX, LegLY, LegLZ));

    character.attach(hips.getPLYData(), new Vec3().set(1, 1, 1), new Vec3().set(0, 0, characterLift + legLength + (hipHeight - 0.1) + bounce2), new Vec3().set(0, 0, (WalkOppositeSwingForward * 5) + (HipsZ)));


    //character.attach(MeshCube01, new Vec3().set(1,1,1),new Vec3().set(0,0,0), new Vec3().set(0,0,0))

    return character.getPLYData();

}