import React, { createContext, useContext, useEffect, useState } from 'react';

// User Interfaces
interface UserDetailOption {
    value: string;
    public: boolean;
}

type UserDetailOptionType = Record<string, UserDetailOption>;

export interface UserAttribute {
    displayName: string;
    username: string;
    userDetails?: {
        gender?: UserDetailOption; // mars|venus|mars-and-venus|non-binary|genderless
        birthday?: UserDetailOption;
    };
}

export interface ProvidedAttribute {
    provider?: string; // e.g., 'google', 'facebook'
    region?: string;
    profilePictureUrl?: string; // Often provided by social logins
    firstName?: string;
    lastName?: string;
    locale?: string;
    email?: string; // Assuming email can be edited, depending on your use case
}

export interface UserProfile {
    googleId?: string;
    uuid: string;
    attributes: UserAttribute;
    provided: ProvidedAttribute;
}

// Character Interfaces
interface Character {
    uuid: string;
    name: string;
    owner: string;
    scene?: string;
}

// Combined Context Types
interface UserCharacterContextType {
    userProfile: UserProfile | null;
    characters: Character[];
    currentCharacter: Character | null;
    setUserProfile: (userProfile: UserProfile | null) => void;
    getUserProfile: () => UserProfile | null;
    queryUserProfile: () => Promise<void>;
    hasQueriedUserProfile: () => boolean;
    setCharacters: (characters: Character[]) => void;
    setCurrentCharacter: (character: Character | null) => void;
    getCurrentCharacter: () => Character | null;
    queryCharacters: () => Promise<void>;
}

const UserCharacterContext = createContext<UserCharacterContextType | undefined>(undefined);

export const useUserCharacterContext = (): UserCharacterContextType => {
    const context = useContext(UserCharacterContext);
    if (!context) throw new Error('useUserCharacterContext must be used within a UserCharacterProvider');
    return context;
};

export const UserCharacterProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
    const [hasQueriedUser, setHasQueriedUser] = useState<boolean>(false);

    const [characters, setCharacters] = useState<Character[]>([]);
    const [currentCharacter, setCurrentCharacter] = useState<Character | null>(null);

    const getUserProfile = () => userProfile;

    const queryUserProfile = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_DOMAIN_DELEGATOR}/profile`, {
                method: 'GET',
                mode: 'cors',
                credentials: 'include', // This tells the browser to include cookies with the request
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const data = await response.json();
                setUserProfile(data);
            } else {
                console.log('User not signed in');
            }
        } catch (error) {
            console.error('Error fetching user profile:', error);
        } finally {
            setHasQueriedUser(true);
        }
    };

    const hasQueriedUserProfile = () => hasQueriedUser;

    const queryCharacters = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_DOMAIN_DELEGATOR}/character/statistics?page=1&limit=50`);
            if (response.ok) {
                const data = await response.json();
                setCharacters(data.data || []);
            } else {
                console.error('Failed to fetch characters.');
            }
        } catch (error) {
            console.error('Error fetching characters:', error);
        }
    };

    const getCurrentCharacter = () => currentCharacter;

    return (
        <UserCharacterContext.Provider
            value={{
                userProfile,
                characters,
                currentCharacter,
                setUserProfile,
                getUserProfile,
                queryUserProfile,
                hasQueriedUserProfile,
                setCharacters,
                setCurrentCharacter,
                getCurrentCharacter,
                queryCharacters,
            }}
        >
            {children}
        </UserCharacterContext.Provider>
    );
};

export default UserCharacterProvider;
