import React, { FC, useState, useEffect, useRef, ReactNode } from "react";
import { Button, Content, Space, Title } from "../layout/Content";
import { faGamepad } from "@fortawesome/free-solid-svg-icons";
import {FullScreenApp} from "../KeyPiece";
import {APP_SIMPLE_GAME, BaseApp} from "./BaseApp";

const GameAppClass = class extends BaseApp {
    static id = APP_SIMPLE_GAME;
    static title = "Game";
    static icon = faGamepad;

    static getComponent(onClose: () => void, onMinimize: () => void): ReactNode {
        return <GameApp onClose={onClose} onMinimize={onMinimize} />;
    }
};

const getRandomPosition = (containerSize: number, elementSize: number) =>
    Math.random() * (containerSize - elementSize);

const GameApp: FC<{ onClose: () => void; onMinimize: () => void }> = ({ onClose, onMinimize }) => {
    const [room, setRoom] = useState<"start" | "game" | "gameover">("start");
    const [score, setScore] = useState(0);
    const containerRef = useRef<HTMLDivElement>(null);
    const [playerPos, setPlayerPos] = useState({ x: 0, y: 0 });
    const [enemyPos, setEnemyPos] = useState({ x: 0, y: 0 });
    const [goalPos, setGoalPos] = useState({ x: 0, y: 0 });
    const [enemyVelocity, setEnemyVelocity] = useState({ dx: 2, dy: 2 });

    useEffect(() => {
        if (room === "game") {
            const container = containerRef.current;
            if (container) {
                const { clientWidth, clientHeight } = container;
                setEnemyPos({
                    x: getRandomPosition(clientWidth, 30),
                    y: getRandomPosition(clientHeight, 30),
                });
                setGoalPos({
                    x: getRandomPosition(clientWidth, 30),
                    y: getRandomPosition(clientHeight, 30),
                });
            }
        }
    }, [room]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (room !== "game") return;

            setEnemyPos((prev) => {
                const container = containerRef.current;
                if (!container) return prev;

                const { clientWidth, clientHeight } = container;
                let { x, y } = prev;
                let { dx, dy } = enemyVelocity;

                if (x + 30 >= clientWidth || x <= 0) dx *= -1;
                if (y + 30 >= clientHeight || y <= 0) dy *= -1;

                setEnemyVelocity({ dx, dy });

                return { x: x + dx, y: y + dy };
            });
        }, 16);

        return () => clearInterval(interval);
    }, [room, enemyVelocity]);

    const checkCollision = (a: { x: number; y: number }, b: { x: number; y: number }, size: number) =>
        Math.abs(a.x - b.x) < size && Math.abs(a.y - b.y) < size;

    useEffect(() => {
        if (room === "game") {
            if (checkCollision(playerPos, enemyPos, 30)) {
             //   setRoom("gameover");
            } else if (checkCollision(playerPos, goalPos, 30)) {
                setScore((prev) => prev + 1);
                setRoom("game");
            }
        }
    }, [playerPos, enemyPos, goalPos, room]);

    const handleMouseMove = (e: React.MouseEvent) => {
        const container = containerRef.current;
        if (container) {
            const rect = container.getBoundingClientRect();
            setPlayerPos({
                x: e.clientX - rect.left - 15,
                y: e.clientY - rect.top - 15,
            });
        }
    };

    return (
        <FullScreenApp title="Game" onClose={onClose} onMinimize={onMinimize}>
            {room === "start" && (
                <Content style={{ textAlign: "center" }}>
                    <Title>Welcome to the Game!</Title>
                    <Button onClick={() => setRoom("game")}>Start Game</Button>
                </Content>
            )}
            {room === "game" && (
                <Space Stretch Full><div
                    ref={containerRef}
                    onMouseMove={handleMouseMove}
                    style={{
                        width: "100%",
                        height: "100%",
                        border: "2px solid black",
                        position: "relative",
                        cursor: "none",
                        overflow: "hidden",
                    }}
                >
                    <div
                        style={{
                            position: "absolute",
                            width: 30,
                            height: 30,
                            backgroundColor: "blue",
                            borderRadius: "50%",
                            left: playerPos.x,
                            top: playerPos.y,
                        }}
                    ></div>
                    <div
                        style={{
                            position: "absolute",
                            width: 30,
                            height: 30,
                            backgroundColor: "red",
                            left: enemyPos.x,
                            top: enemyPos.y,
                        }}
                    ></div>
                    <div
                        style={{
                            position: "absolute",
                            width: 30,
                            height: 30,
                            backgroundColor: "orange",
                            borderRadius: "50%",
                            left: goalPos.x,
                            top: goalPos.y,
                        }}
                    ></div>
                </div></Space>
            )}
            {room === "gameover" && (
                <Content style={{ textAlign: "center" }}>
                    <Title>Game Over</Title>
                    <p>Your score: {score}</p>
                    <Button onClick={() => setRoom("start")}>Restart</Button>
                    <Button onClick={() => setRoom("game")}>Play Again</Button>
                </Content>
            )}

        </FullScreenApp>
    );
};

export { GameAppClass, GameApp };
